import React, { useRef, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { myprofile } from "../../Constant/Index";
import ReUse from "../../services/helpers/reUse";
import AppService from "../../services/appServices";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  ResponseMessageActions,
  clearResponseMessageAfterDelay,
  errorMessage,
  successMessage,
} from "../../redux/slices/responseMessage.slice";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";

function Siderbar({ profilePic, firstName, handleProfileUpload }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [file, setFile] = useState();
  const inputRef = useRef(null);

  const handleUploadClick = () => {
    // 👇 We redirect the click event onto the hidden input element
    inputRef.current?.click();
  };

  const logOutCustomer = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const apiResp = await AppService.customerLogout();
      const { message, response } = apiResp.data;
      setLoading(false);
      dispatch(successMessage(message));
      await ReUse.logOutUser();
      setTimeout(() => {
        dispatch(successMessage("Navigating..."));
        setTimeout(() => {
          dispatch(successMessage(""));
          navigate("/sign-in");
        }, 1000);
      }, 1000);
    } catch (err) {
      setLoading(false);
      dispatch(errorMessage(err?.response?.data?.message));
      dispatch(clearResponseMessageAfterDelay());
      dispatch(successMessage(""));
    }
  };

  return (
    <>
      {/* Side bar section */}
      <div className="siderbar-tabs">
        <div className="sidebar-img">
          <img
            className="img-fluid"
            src={
              profilePic && profilePic !== null
                ? profilePic
                : ReUse.defaultAvatar({ username: firstName })
            }
            onError={ReUse.onImageError}
          />
          {/* 👇 Notice the `display: hidden` on the input */}
          <input
            type="file"
            ref={inputRef}
            onChange={handleProfileUpload}
            style={{ display: "none" }}
          />
          <div className="side-abr-icon" onClick={handleUploadClick}>
            <p>Change Picture</p>
            {/* {file ? `${file.name}` : ""} */}
          </div>
        </div>
        <ul className="sidebar-ul">
          <li>
            <NavLink to="/my-profile">My Profile</NavLink>
          </li>
          <li>
            <NavLink to="/change-password">Change Password</NavLink>
          </li>
          <li>
            <NavLink to="/my-orders">My Orders</NavLink>
          </li>

          <li>
            <NavLink to="/my-addresses">My Addresses</NavLink>
          </li>

          <li>
            <a onClick={(e) => logOutCustomer(e)}>
              {loading ? "loading" : "Logout"}
            </a>
            <ResponseMessage />
          </li>
        </ul>
      </div>
      {/* ================ */}
    </>
  );
}

export default Siderbar;
