import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { attach } from "../../Constant/Index";
import "../../assets/css/quote.css";
import PageHeader from "../../Components/Pageheader";
import ReUse from "../../services/helpers/reUse";
import {
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import { AuthActions } from "../../redux/slices/auth.slice";
import AppService from "../../services/appServices";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Errors from "../../Components/ReUse/Errors";
import RenderImage from "../../Components/ReUse/RenderImage";
import MeasurementField from "./MeasurementField";
import NoRecord from "../../Components/ReUse/NoRecord";
import CustomButton from "../../Components/ReUse/Components/Button";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";

function Getquote() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const { state } = location ?? {};
	const { productMeasurements, productId } = state ?? {};

	const [loading, setLoading] = useState(false);

	const [fileForDesign, setFileForDesign] = useState();
	const [fileuploadForDesign, setFileuploadForDesign] = useState();

	const [fileForEmbroidery, setFileForEmbroidery] = useState();
	const [fileuploadForEmbroidery, setFileuploadForEmbroidery] = useState();

	const [measurements, setMeasurements] = useState({});

	const [allErrors, setAllErrors] = useState({
		"First Name": "",
		"Last Name": "",
		email: "",
		phone: "",
	});
	const [fieldsData, setFieldsData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
	});

	function handleChange(e) {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		const { value, name } = e.target;
		setFieldsData({
			...fieldsData,
			[name]: value,
		});
	}

	const onSubmitHandler = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const { first_name, last_name, email, phone } = fieldsData;

			const fields = {
				"First Name": first_name,
				"Last Name": last_name,
				email,
				phone,
				fileForDesign,
				fileForEmbroidery,
			};
			const allErrors = ReUse.validateRequired({ fields: fields });

			// Check if there are any errors
			if (Object.keys(allErrors).length > 0) {
				setAllErrors(allErrors);
				setLoading(false);
				ReUse.scrollToTop();
				return;
			}

			if (Object.keys(measurements)?.length < productMeasurements?.length) {
				dispatch(errorMessage("Please Fill in your measurements"));
				dispatch(clearResponseMessageAfterDelay());
				setLoading(false);
				return;
			}

			const payLoad = {
				first_name: first_name,
				last_name: last_name,
				email,
				phone,
				product_id: productId,
				measurements: measurements,
				design_img: fileuploadForDesign,
				embroidery_img: fileuploadForEmbroidery,
			};

			const apiResp = await AppService.customerAddQuote(payLoad);
			const { message, response } = apiResp.data;
			setLoading(false);
			dispatch(successMessage(message));
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/");
				}, 1000);
			}, 1000);

			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
			dispatch(successMessage(""));
		}
	};

	const handleFileUploadForDesign = (e) => {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		ReUse.handleFileUploads(e).then((result) => {
			const { fileOutput, fileUpload } = result;
			setFileForDesign(fileOutput);
			setFileuploadForDesign(fileUpload);
		});
	};

	const handleFileUploadForEmbroidery = (e) => {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		ReUse.handleFileUploads(e).then((result) => {
			const { fileOutput, fileUpload } = result;
			setFileForEmbroidery(fileOutput);
			setFileuploadForEmbroidery(fileUpload);
		});
	};

	const measurementFieldProps = {
		productMeasurements,
		setMeasurements,
		measurements,
	};
	const generateId = ReUse.makeId();

	const MeasurementFieldComponent = () => {
		return (
			<>
				{productMeasurements?.map((currentMeasurement, i) => (
					<MeasurementField
						key={generateId}
						measurementFieldProps={{
							...measurementFieldProps,
							currentMeasurement,
							currentIndex: i,
						}}
					/>
				))}
			</>
		);
	};

	if (productMeasurements?.length === 0 || !productMeasurements) {
		return <NoRecord />;
	}

	return (
		<>
			<PageHeader pagename="get a quote" />
			<Errors allErrors={allErrors} />
			<section className="get-quote-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="get-quaote-form">
								<form>
									<div className="row">
										<div className="col-md-12">
											<div className="get-quote-head">
												<h5>Personal Information</h5>
											</div>
										</div>
										<div className="col-md-6">
											<div className="get-quote-input">
												<input
													type="text"
													placeholder="First Name"
													onChange={handleChange}
													name="first_name"
													value={fieldsData?.first_name}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="get-quote-input">
												<input
													type="text"
													placeholder="Last Name"
													onChange={handleChange}
													name="last_name"
													value={fieldsData?.last_name}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="get-quote-input">
												<input
													type="text"
													placeholder="Email"
													onChange={handleChange}
													name="email"
													value={fieldsData?.email}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="get-quote-input">
												<input
													type="text"
													placeholder="Phone"
													onChange={handleChange}
													name="phone"
													value={fieldsData?.phone}
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="get-quote-head">
												<h5>Upload Images for Customization</h5>
											</div>
										</div>
										<div className="col-md-6">
											<div className="application-button-input get-quote-upload">
												<button>
													<span>
														Upload an image of the design.
														<img
															className="img-fluid"
															alt="attach-img"
															src={attach}
														/>
													</span>
													<input
														type="file"
														className="for-hide-quote-input"
														accept="image/*"
														onChange={handleFileUploadForDesign}
													/>
												</button>
											</div>
										</div>
										<div className="col-md-6">
											<div className="application-button-input get-quote-upload">
												<button>
													<span>
														Upload an image of the embroidery.
														<img
															className="img-fluid"
															alt="attach-img"
															src={attach}
														/>
													</span>
													<input
														type="file"
														className="for-hide-quote-input"
														accept="image/*"
														onChange={handleFileUploadForEmbroidery}
													/>
												</button>
											</div>
										</div>
										<RenderImage file={fileuploadForDesign} />
										<RenderImage file={fileuploadForEmbroidery} />
										<div className="col-md-12">
											<div className="get-quote-head">
												<h5>Provide measurements</h5>
											</div>
										</div>

										<MeasurementFieldComponent />

										<div className="col-md-12">
											<div className="get-quaote-button">
												<CustomButton
													loading={loading}
													onClickHandler={onSubmitHandler}
													customClassName="custom-btn"
												>
													Submit
												</CustomButton>
												<ResponseMessage />
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ========================= */}
		</>
	);
}

export default Getquote;
