import React, { useEffect, useState } from "react";
import ReUse from "../../services/helpers/reUse";
import AppService from "../../services/appServices";
import CatsSubcats from "./CatsSubcats";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ProductsFilterActions } from "../../redux/slices/productsFilter.slice";
import {
	ResponseMessageActions,
	clearResponseMessageAfterDelay,
	errorMessage,
} from "../../redux/slices/responseMessage.slice";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import FieldError from "../../Components/ReUse/FieldError";

const ProductsParamsFilter = () => {
	const dispatch = useDispatch();

	const [cats, setCats] = useState([]);
	const [loading3, setLoading3] = useState(false);
	const [fieldsData, setFieldsData] = useState({
		minPrice: null,
		maxPrice: null,
	});

	const [allErrors, setAllErrors] = useState({
		minPrice: null,
		maxPrice: null,
	});

	function handleChange(e) {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		const { value, name } = e.target;
		setFieldsData({
			...fieldsData,
			[name]: value,
		});
	}

	const onSubmitHandler = async (e) => {
		e.preventDefault();

		try {
			const { minPrice, maxPrice } = fieldsData;

			const allErrors = ReUse.validateRequired({ fields: fieldsData });

			// Check if there are any errors
			if (Object.keys(allErrors).length > 0) {
				setAllErrors(allErrors);
				return;
			}

			if (minPrice > maxPrice) {
				// toast.error("Min price cannot be greater than max price");
				dispatch(errorMessage("Min price cannot be greater than max price"));
				dispatch(clearResponseMessageAfterDelay());
				return;
			}

			if (maxPrice < minPrice) {
				dispatch(errorMessage("Max price cannot be less than Min price"));
				dispatch(clearResponseMessageAfterDelay());
				return;
			}

			dispatch(
				ProductsFilterActions.setMinMaxPrices({
					minPrice: parseInt(minPrice),
					maxPrice: parseInt(maxPrice),
				}),
			);
			ReUse.scrollToTop();
			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
		} catch (err) {
			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
		}
	};

	useEffect(() => {
		const getAllCats = AppService.getAllCats();
		ReUse.getApiData(getAllCats, setCats, setLoading3);
	}, []);

	return (
		<>
			<div className="col-md-3">
				<div className="all-product-accordian">
					<div class="accordion" id="accordionExample">
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingOne">
								<button
									class="accordion-button"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#collapseOne"
									aria-expanded="true"
									aria-controls="collapseOne"
								>
									All Categories
								</button>
							</h2>
							<div
								id="collapseOne"
								class="accordion-collapse collapse show"
								aria-labelledby="headingOne"
								data-bs-parent="#accordionExample"
							>
								<div class="accordion-body">
									<div className="inner-accordian">
										{ReUse.mapItems(loading3, cats, CatsSubcats)}
									</div>
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingTwo">
								<button
									class="accordion-button collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#collapseTwo"
									aria-expanded="false"
									aria-controls="collapseTwo"
								>
									Price
								</button>
							</h2>
							<div
								id="collapseTwo"
								class="accordion-collapse collapse"
								aria-labelledby="headingTwo"
								data-bs-parent="#accordionExample"
							>
								<div class="accordion-body">
									<div className="all-price-li">
										<ul>
											<li>$0.00 - $100.00</li>
											<li>$100.00 - $200.00</li>
											<li>$200.00 - $300.00</li>
											<li>$300.00 - $500.00</li>
											<li>$500.00+</li>
										</ul>
									</div>
									<div className="price-input">
										<div className="input-1">
											<input
												type="number"
												placeholder="$Min"
												onChange={handleChange}
												name="minPrice"
												value={fieldsData?.minPrice}
											/>

											<FieldError fieldName={allErrors?.minPrice} />
										</div>
										<div className="input-1">
											<input
												type="number"
												placeholder="$Max"
												onChange={handleChange}
												name="maxPrice"
												value={fieldsData?.maxPrice}
											/>

											<FieldError fieldName={allErrors?.maxPrice} />
										</div>
										<div className="input-1-btn">
											{/* <button>Go</button> */}
											<button onClick={(e) => onSubmitHandler(e)}>Go</button>
											<ResponseMessage />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProductsParamsFilter;
