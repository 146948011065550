import React from "react";


const NoAccess = () => {
  const CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div className="notFound-container" style={CSSProperties}>
      <p className="mt-4"> You don't have access to this content.</p>
    </div>
  );
};

export default NoAccess;
