import React from "react";
import PageHeader from "../Pageheader";
import ReUse from "../../services/helpers/reUse";

const StaticPageContent = ({ data }) => {
	const { type, value, meta_title, meta_keyword, meta_description } =
		data ?? {};

	return (
		<>
			<PageHeader pagename="Terms & Condition" />
			{/* Terms And Condition Section */}
			<section className="terms-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="terms-condition-all-para">
								<h1>{meta_title}</h1>
								{ReUse.dangerouslySetInnerHTML({ content: value })}
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ========================== */}
		</>
	);
};

export default StaticPageContent;
