import React from "react";
import { Link } from "react-router-dom";
import { blog1, blog2, blog3 } from "../Constant/Index";

import AppService from "./../services/appServices";
import ReUse from "./../services/helpers/reUse";
import BlogCard from "./BlogCard";
import { useState } from "react";
import { useEffect } from "react";

function Blogsmain() {
	const [apiData, setApiData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const allBlogs = AppService.allBlogs();
		ReUse.getApiData(allBlogs, setApiData, setLoading);
	}, []);

	return (
		<>
			{/* Blogs Components Section */}
			<section className="blog-main-sec wow animate__animated animate__flipInX">
				<div className="container">
					<div className="slide-head">
						<h4>Blogs</h4>
						<h4>
							<Link to="/blogs">view all</Link>
						</h4>
					</div>
					<div className="row">
						{ReUse.mapItems(loading, apiData, BlogCard)}
					</div>
				</div>
			</section>
		</>
	);
}

export default Blogsmain;
