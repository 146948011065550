import React from "react";
import {
	detailslider,
	detailslider2,
	detailslider3,
	detailslider4,
} from "../Constant/Index";
import ReUse from "../services/helpers/reUse";

const ProductSlide = ({ slide }) => {
	return (
		<>
			<div className="all-borders">
				<img
					className="img-fluid"
					src={slide}
					alt="customized"
					onError={ReUse.onImageError}
				/>
			</div>
		</>
	);
};

export default ProductSlide;
