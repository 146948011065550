import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import { blogdetailimg } from "../../Constant/Index";
import "../../assets/css/blogdetail.css";
import Footer from "../../Components/Footer";

import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";

import { Link, useLocation } from "react-router-dom";
import ObjBasedApiDataLoader from "../../Components/ReUse/ReUsableHOC/ObjBasedApiDataLoader";
import BlogDetailContent from "../../Components/PID/BlogDetailContent";

function Blogdetail() {
	const location = useLocation();
	const getId = location?.state || null;

	const currentSlug = ReUse.extractIdFromCurrentUrl();

	function PIDDetail({ loading, data }) {
		return <BlogDetailContent data={data} />;
	}

	const EnhancedPIDDetail = ObjBasedApiDataLoader(PIDDetail);

	const [apiData, setApiData] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const ShowBlog = AppService.ShowBlog({ blogId: currentSlug });
		ReUse.getApiData(ShowBlog, setApiData, setLoading);
	}, [currentSlug]);

	return <EnhancedPIDDetail data={apiData} loading={loading} />;
}

export default Blogdetail;
