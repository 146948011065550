import React, { useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { secondlogo } from "../../Constant/Index";

import { UIActions } from "../../redux/slices/ui.slice";
import AppService from "../../services/appServices";
import { AuthActions } from "../../redux/slices/auth.slice";
import { useDispatch, useSelector } from "react-redux";
import { emailRegExp, resetForm } from "../../services/helpers/constants";

import ReUse from "../../services/helpers/reUse";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { async } from "q";
import Errors from "../../Components/ReUse/Errors";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import {
  ResponseMessageActions,
  clearResponseMessageAfterDelay,
  errorMessage,
  successMessage,
} from "../../redux/slices/responseMessage.slice";
import CustomButton from "../../Components/ReUse/Components/Button";
import FieldError from "../../Components/ReUse/FieldError";

function Signin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(true);
  const [isRememberMe, setIsRememberMe] = useState(false);

  const [allErrors, setAllErrors] = useState({
    email: "",
    password: "",
  });
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const handleChange = async (e) => {
    const resetErrors = ReUse.resetErrors({ fields: allErrors });
    setAllErrors(resetErrors);
    await ReUse.getInputFieldsData({
      e: e,
      setFieldsData: setUserData,
      fieldsData: userData,
    });
  };

  const passwordVisibilityHandler = (e) => {
    e.preventDefault();
    setIsPasswordVisible(!isPasswordVisible);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const { email, password } = userData;

      const fields = { email, password };
      const allErrors = ReUse.validateRequired({ fields: fields });

      // Check if there are any errors
      if (Object.keys(allErrors).length > 0) {
        setAllErrors(allErrors);
        setLoading(false);
        ReUse.scrollToTop();
        return;
      }

      const payload = {
        email,
        password,
        remember_me: isRememberMe ? 1 : 0,
      };

      const apiResp = await AppService.customerSignIn(payload);
      const { message, response } = apiResp.data;
      setLoading(false);
      dispatch(successMessage(message));
      dispatch(AuthActions.userAuthentication(response?.data));
      setLoggedIn(true);
      setTimeout(() => {
        dispatch(successMessage("Navigating..."));
        setTimeout(() => {
          dispatch(successMessage(""));
        }, 1000);
      }, 1000);

      ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
      ReUse.resetForm({ fields: userData }); // userData is an object
    } catch (err) {
      setLoading(false);
      setLoggedIn(false);
      dispatch(errorMessage(err?.response?.data?.message));
      dispatch(clearResponseMessageAfterDelay());
      dispatch(successMessage(""));
      ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
      ReUse.resetForm({ fields: userData }); // userData is an object
    }
  };

  if (loggedIn) {
    // Redirect to the originally requested route, or to a default route if no 'from' state is available
    const { pathname } = location.state?.from ?? {};
    return <Navigate to={pathname || "/"} />;
  }

  const PasswordVisiblity = () => {
    if (isPasswordVisible) {
      return (
        <i
          class="fa fa-eye-slash"
          aria-hidden="true"
          onClick={passwordVisibilityHandler}
        ></i>
      );
    } else {
      return (
        <i
          class="fa fa-eye"
          aria-hidden="true"
          onClick={passwordVisibilityHandler}
        ></i>
      );
    }
  };

  return (
    <>
      {/* Sign-in Section */}
      <Errors allErrors={allErrors} />
      <section className="login-sec sign-in-sec">
        <div className="container">
          <div className="row">
            <div className="login-page-logo">
              <Link to="/">
                <figure>
                  <img
                    className="img-fluid"
                    alt="login-page-logo"
                    src={secondlogo}
                  />
                </figure>
              </Link>
            </div>
            <div className="col-md-6">
              <div className="welcome-back-movement welcome-back-signin-movement">
                <div className="welcome-back-sec welcome-back-sec-signin">
                  <h3>Hello, Friend!</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Fusce laoreet mauris sit amet venenatis cursus. Fusce quis
                    libero orci. Donec posuere lacus id felis hendrerit dapibus.
                    Mauris
                  </p>
                  <div className="login-signin-btn">
                    <Link to="/sign-up">Sign Up</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="login-form">
                <form>
                  <div className="row">
                    <div className="form-head">
                      <h3>Sign In</h3>
                    </div>
                    <div className="col-md-12">
                      <div className="form-login-input">
                        <input
                          type="email"
                          placeholder="Email"
                          onChange={handleChange}
                          name="email"
                          value={userData?.email}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-login-input">
                        <input
                          type={isPasswordVisible ? "password" : "text"}
                          value={userData?.password}
                          onChange={handleChange}
                          placeholder="**********"
                          name="password"
                        />
                        <div className="password-icon">
                          <PasswordVisiblity />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="remeber-forget">
                        <div className="remeber-check-box">
                          <input
                            type="checkbox"
                            onChange={(e) => setIsRememberMe(!isRememberMe)}
                            value={isRememberMe}
                            name="remember_me"
                          />
                          <label for="vehicle1">Remember Me</label>
                        </div>
                        <div className="forgot-password-link">
                          <Link to="/enter-email">Forgot Password?</Link>
                        </div>
                      </div>
                    </div>
                    <div className="login-signup-btn">
                      <a>
                        <CustomButton
                          loading={loading}
                          onClickHandler={onSubmitHandler}
                          customClassName="btn"
                        >
                          Sign In
                        </CustomButton>
                      </a>
                      <ResponseMessage />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-12"></div>
          </div>
        </div>
      </section>
      {/* ================ */}
    </>
  );
}

export default Signin;
