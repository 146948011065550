import React, { useState } from "react";
import Slider from "react-slick";
import {
	customizeproduct1,
	customizeproduct2,
	customizeproduct3,
	customizeproduct5,
	customizeproduct6,
	customizeproductmain,
} from "../Constant/Index";
import ReUse from "../services/helpers/reUse";
import ProductSlide from "./ProductSlide";

function Slide2({ images, thumbnail }) {
	const [nav1, setNav1] = useState();
	const [nav2, setNav2] = useState();

	return (
		<div>
			<Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
				{ReUse.mapArray(false, images, ProductSlide, "slide")}
			</Slider>

			<Slider
				asNavFor={nav1}
				ref={(slider2) => setNav2(slider2)}
				slidesToShow={5}
				swipeToSlide={true}
				focusOnSelect={true}
				arrows={false}
			>
				{ReUse.mapArray(false, images, ProductSlide, "slide")}
			</Slider>
		</div>
	);
}

export default Slide2;
