import logo from "./logo.svg";
import "./App.css";
import "../src/assets/css/style.css";
import Publicroutes from "./pages/routes/Publicroutes";
import WOW from "wowjs";
import { useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AutoLogout from "./Components/AutoLogout";

function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);
  return (
    <>
      <AutoLogout />
      <ToastContainer />
      {/* <Publicroutes /> */}
      {loading ? (
        <>
          <div class="loading-container">
            <div class="box-loading">
              <div class="box">
                <div className="box-sahara">
                  <p>S</p>
                </div>
              </div>
              <div class="box">
                <div className="box-sahara">
                  <p>A</p>
                </div>
              </div>
              <div class="box">
                {" "}
                <div className="box-sahara">
                  <p>H</p>
                </div>
              </div>
              <div class="box">
                {" "}
                <div className="box-sahara">
                  <p>A</p>
                </div>
              </div>

              <div class="box">
                {" "}
                <div className="box-sahara">
                  <p>R</p>
                </div>
              </div>
              <div class="box">
                {" "}
                <div className="box-sahara">
                  <p>A</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Publicroutes />
      )}
    </>
  );
}

export default App;
