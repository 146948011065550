import React from "react";
import PropTypes from "prop-types";
import { WithContext as ReactTags } from "react-tag-input";

const SelectedAttributeValues = ({
  attributeOptions,
  delimiters,
  handleAttributeOptionDelete,
  handleAttributeOptionAddition,
  handleAttributeOptionDrag,
  handleAttributeOptionClick,
  selectedAttribute,
  currentIndex,
}) => {
  const { value, label } = selectedAttribute || {};

  return (
    <div className="col-md-12 mt-2 mb-2">
      <div className="row movement-of-react-tags">
        <div className="col-md-4">
          <div className="all-color-size-head">
            <div className="color-heading">
              <h5>{label}</h5>
            </div>
          </div>
        </div>

        <div className="col-md-8">
          <div className="all-color-size-input">
            <ReactTags
              tags={attributeOptions[currentIndex]}
              delimiters={delimiters}
              handleDelete={(option) =>
                handleAttributeOptionDelete({
                  attributeOption: option || {},
                  attributeIndex: currentIndex || 0,
                  attributeType: label || "",
                })
              }
              handleAddition={(option) =>
                handleAttributeOptionAddition({
                  attributeOption: option || {},
                  attributeIndex: currentIndex || 0,
                  attributeType: label || "",
                })
              }
              // handleDrag={handleAttributeOptionDrag}
              // handleTagClick={handleAttributeOptionClick}
              inputFieldPosition="bottom"
              autocomplete
              editable
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SelectedAttributeValues.propTypes = {
  attributeOptions: PropTypes.array.isRequired,
  delimiters: PropTypes.array.isRequired,
  handleAttributeOptionDelete: PropTypes.func.isRequired,
  handleAttributeOptionAddition: PropTypes.func.isRequired,
  handleAttributeOptionDrag: PropTypes.func.isRequired,
  handleAttributeOptionClick: PropTypes.func.isRequired,
  selectedAttribute: PropTypes.object,
  index: PropTypes.number.isRequired,
};

export default SelectedAttributeValues;
