import React, { useEffect, useState } from "react";
import "../../assets/css/faq.css";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import FaqAccordion from "./FaqAccordion";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";

function Faq() {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllFAQs = AppService.getAllFAQs();
    ReUse.getApiData(getAllFAQs, setApiData, setLoading);
  }, []);

  return (
    <>
      <PageHeader pagename="FAQ's" />
      {/* Faq section */}
      <section className="faq-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="faq-all">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  {ReUse.mapItems(loading, apiData, FaqAccordion)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======================== */}
    </>
  );
}

export default Faq;
