import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { secondlogo } from "../../Constant/Index";
import ReUse from "../../services/helpers/reUse";
import { toast } from "react-toastify";
import AppService from "../../services/appServices";
import { useDispatch } from "react-redux";
import {
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import CustomButton from "../../Components/ReUse/Components/Button";
import FieldError from "../../Components/ReUse/FieldError";

function Createnewpassword() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [loading, setLoading] = useState(false);

	const [allErrors, setAllErrors] = useState({
		password: "",
		confirm_password: "",
	});

	const [fieldsData, setFieldsData] = useState({
		password: "",
		confirm_password: "",
	});

	const [email, setEmail] = useState("");

	function handleChange(e) {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		const { value, name } = e.target;
		setFieldsData({
			...fieldsData,
			[name]: value,
		});
	}

	const onSubmitHandler = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const { password, confirm_password } = fieldsData;

			const fields = {
				password,
				confirm_password: confirm_password,
			};
			const allErrors = ReUse.validateRequired({ fields: fields });

			// Check if there are any errors
			if (Object.keys(allErrors).length > 0) {
				setAllErrors(allErrors);
				setLoading(false);
				return;
			}

			if (password !== confirm_password) {
				setLoading(false);
				dispatch(errorMessage("Passwords do not match"));
				dispatch(clearResponseMessageAfterDelay());
				return;
			}

			const payLoad = {
				password,
				confirm_password,
				email,
			};

			const apiResp = await AppService.customerForgotPasswordReset(payLoad);
			const { message, response } = apiResp.data;
			setLoading(false);
			dispatch(successMessage(message));
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/my-profile");
				}, 1000);
			}, 1000);

			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
			dispatch(successMessage(""));
		}
	};

	useEffect(() => {
		setEmail(location?.state?.email);
	}, [location?.state?.email]);

	return (
		<>
			{/* Enter Email Section */}
			<section className="login-sec sign-in-sec create-new-password-sec">
				<div className="container">
					<div className="row">
						<div className="login-page-logo">
							<Link to="/">
								<figure>
									<img
										className="img-fluid"
										alt="login-page-logo"
										src={secondlogo}
									/>
								</figure>
							</Link>
						</div>
						<div className="col-md-6">
							<div className="div-for-all-movement">
								<div className="enter-email-movement create-newpass-res">
									<div className="welcome-back-sec">
										<h3>Welcome Back!</h3>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit.
											Fusce laoreet mauris sit amet venenatis cursus. Fusce quis
											libero orci. Donec posuere lacus id felis hendrerit
											dapibus. Mauris
										</p>
										<div className="login-signin-btn">
											<Link to="/sign-in">Sign in</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="login-form">
								<form>
									<div className="row">
										<div className="form-head">
											<h3>Create New Password</h3>
										</div>
										<div className="col-md-12">
											<div className="form-login-input">
												<input
													type="password"
													placeholder="password"
													onChange={handleChange}
													name="password"
													value={fieldsData?.password}
												/>
												<FieldError fieldName={allErrors?.password} />
												<div className="password-icon">
													<i class="fa fa-eye-slash" aria-hidden="true"></i>
												</div>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-login-input">
												<input
													type="password"
													placeholder="confirm password"
													onChange={handleChange}
													name="confirm_password"
													value={fieldsData?.confirm_password}
												/>

												<FieldError fieldName={allErrors?.confirm_password} />
												<div className="password-icon">
													<i class="fa fa-eye-slash" aria-hidden="true"></i>
												</div>
											</div>
										</div>

										<div className="login-signup-btn">
											{/* <Link to="/sign-in">Submit</Link> */}
											<a>
												<CustomButton
													loading={loading}
													onClickHandler={onSubmitHandler}
													customClassName="btn"
												>
													Submit
												</CustomButton>
											</a>
											<ResponseMessage />
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ================ */}
		</>
	);
}

export default Createnewpassword;
