import React from "react";
import { girls, newsletter1, newsletter2 } from "../Constant/Index";
import { useState } from "react";
import ReUse from "../services/helpers/reUse";
import AppService from "../services/appServices";
import { useDispatch } from "react-redux";
import { UIActions } from "../redux/slices/ui.slice";
import { toast } from "react-toastify";
import { AuthActions } from "../redux/slices/auth.slice";
import { useNavigate } from "react-router";
import ResponseMessage from "./ReUse/ResponseMessage";
import {
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../redux/slices/responseMessage.slice";
import CustomButton from "./ReUse/Components/Button";
import FieldError from "./ReUse/FieldError";

function Newsletter() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [allErrors, setAllErrors] = useState({
		email: "",
		password: "",
	});

	const [data, setData] = useState({
		email: "",
	});

	function handleChange(e) {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		const { value, name } = e.target;
		setData({
			...data,
			[name]: value,
		});
	}

	const onSubmitHandler = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const { email } = data;

			const fields = { email };
			const allErrors = ReUse.validateRequired({ fields: fields });

			// Check if there are any errors
			if (Object.keys(allErrors).length > 0) {
				setAllErrors(allErrors);
				setLoading(false);
				return;
			}

			const payload = {
				email,
			};

			const apiResp = await AppService.newsletter(payload);
			const { message, response } = apiResp.data;
			setLoading(false);
			dispatch(successMessage(message));
			setTimeout(() => {
				dispatch(successMessage("Processing..."));
				setTimeout(() => {
					dispatch(successMessage(""));
				}, 1000);
			}, 1000);

			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: data }); // data is an object
			ReUse.scrollToTop();
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: data }); // data is an object
			dispatch(successMessage(""));
		}
	};

	const { email } = data ?? {};

	return (
		<>
			{/* News Letter Section */}
			<section className="news-letter-sec wow animate__animated animate__bounceIn">
				<div className="container">
					<div className="Newsletter-all">
						<div className="row">
							<div className="col-md-3">
								<div className="newsletter-img newsletter-img-for-move">
									<figure>
										<img
											className="img-fluid"
											alt="news-letter-img"
											src={newsletter1}
										/>
									</figure>
								</div>
							</div>

							<div className="col-md-6">
								<div className="news-all">
									<div className="newsletter-dv">
										<h3>Newsletter</h3>
										<p>
											Subcribe to get information about products and coupons
										</p>
									</div>
									<div className="news-letter-input">
										<input
											type="email"
											placeholder="Email Address"
											onChange={handleChange}
											name="email"
											value={email}
										/>
										<FieldError fieldName={allErrors?.email} />
										<ResponseMessage />
										<div className="newsletter-btn">
											<CustomButton
												loading={loading}
												onClickHandler={onSubmitHandler}
												customClassName="subscribe-btn"
											>
												Subscribe
											</CustomButton>
										</div>
									</div>
								</div>
							</div>

							<div className="col-md-3">
								<div className="newsletter-img">
									<figure>
										<img
											className="img-fluid"
											alt="news-letter-img-"
											src={newsletter2}
										/>
									</figure>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Newsletter;
