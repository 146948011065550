import React from "react";
import {
  sellerheaderlogo,
  sellerlogo,
  sellerprofilelogo,
} from "../Constant/Index";
import "../assets/css/seller-profile.css";
import ReUse from "../services/helpers/reUse";

const SellerHeader = ({ sellerInfo }) => {
  const { role, personal_details, business_details } = sellerInfo ?? {};

  const { id, name, email, phone, dob, image } = personal_details ?? {};

  const {
    business_name,
    company_address_1,
    company_address_2,
    business_website,
    description,
  } = business_details ?? {};

  const { street_address, city, zipcode, state, country } =
    company_address_1 ?? {};

  return (
    <>
      {/* seller header starts here */}
      <section className="seller-header-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <div className="seller-logo">
                <a href="#">
                  <figure>
                    <img src={sellerlogo} className="img-fluid" alt="" />
                  </figure>
                </a>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="seller-search-wrapper">
                <form action="">
                  <div className="form-group row">
                    <input type="text" className="form-control" />
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="seller-profile-logo-wrapper">
                <a href="#">
                  <figure>
                    <img
                      className="img-fluid"
                      alt=""
                      src={
                        image !== null
                          ? image
                          : ReUse.defaultThumbnailImage({
                              content: "No+Image+Available",
                            })
                      }
                      onError={ReUse.onImageError}
                    />
                  </figure>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="seller-profile-name-wrapper">
                <div className="seller-img-wrapper">
                  <figure>
                    <img
                      className="img-fluid"
                      alt=""
                      src={
                        image !== null
                          ? image
                          : ReUse.defaultThumbnailImage({
                              content: "No+Image+Available",
                            })
                      }
                      onError={ReUse.onImageError}
                    />
                  </figure>
                </div>
                <div className="seller-content-wrapper">
                  <h4>{name}</h4>
                  <h6>{role}</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </section>
      {/* seller header ends here */}
    </>
  );
};

export default SellerHeader;
