import React from "react";
import AccessControl from "../Auth/AccessControl";
import SellerHeader from "../SellerHeader";
import SellerSidebar from "../SellerSidebar";
import { Link } from "react-router-dom";
import { business, street, zipcodeimg } from "../../Constant/Index";
import { useState } from "react";

const SellerProfileContent = ({ data }) => {
  const [showpass, setshowpass] = useState(false);
  const handleshow = () => {
    setshowpass(true);
  };
  const hideshow = () => {
    setshowpass(false);
  };

  const { role, personal_details, business_details } = data ?? {};

  const { id, name, email, phone, dob, image } = personal_details ?? {};

  const {
    business_name,
    company_address_1,
    company_address_2,
    business_website,
    description,
  } = business_details ?? {};

  const { street_address, city, zipcode, state, country } =
    company_address_1 ?? {};

  return (
    <>
      <AccessControl allowedRoles={["seller"]}>
        <SellerHeader sellerInfo={data} />
        <section className="seller-dashboard">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 ps-0">
                <SellerSidebar />
              </div>
              <div className="col-lg-8">
                <div className="edit-profile-card seller-reviews-tabs">
                  <div className="edit-heading-wrapper">
                    <h2>My Profile</h2>
                  </div>
                  <div className="edit-details-wrapper">
                    <div className="detail-heading-wrapper">
                      <h3>Personal Details</h3>
                      <Link to="/seller-edit-profile">
                        <button className="edit">Edit</button>
                      </Link>
                    </div>
                    <div className="detail-content-wrapper">
                      <ul>
                        <li>
                          <span className="detail-icon">
                            <i class="fa fa-user" aria-hidden="true"></i>
                          </span>
                          <span className="txt">{name}</span>
                        </li>
                        <li>
                          <span className="detail-icon">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                          </span>
                          <span className="txt">{email}</span>
                        </li>
                        <li>
                          <span className="detail-icon">
                            <i class="fa fa-phone" aria-hidden="true"></i>
                          </span>
                          <span className="txt">{phone}</span>
                        </li>
                        <li>
                          <span className="detail-icon">
                            <i
                              class="fa fa-calendar-minus-o"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span className="txt">{dob}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="edit-details-wrapper Business-detail">
                    <div className="detail-heading-wrapper">
                      <h3>Business Details</h3>
                      <Link to="/seller-edit-business-profile">
                        <button className="edit">Edit</button>
                      </Link>
                    </div>
                    <div className="detail-content-wrapper">
                      <ul>
                        <li>
                          <span className="detail-icon">
                            <img className="img-fluid" src={business} />
                          </span>
                          <span className="txt">{business_name}</span>
                        </li>
                        <li>
                          <span className="detail-icon">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                          </span>
                          <span className="txt">Address 1</span>
                        </li>
                        <li>
                          <span className="detail-icon">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                          </span>
                          <span className="txt">Address 2</span>
                        </li>
                      </ul>
                      <div className="form-group row">
                        <div className="col-lg-6 for-no-padding">
                          <ul>
                            <li>
                              <span className="detail-icon">
                                <img className="img-fluid" src={street} />
                              </span>
                              <span className="txt">{street_address}</span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 for-no-padding">
                          <ul>
                            <li>
                              <span className="txt">{city}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-lg-6 for-no-padding">
                          <ul>
                            <li>
                              <span className="detail-icon">
                                <img
                                  src={zipcodeimg}
                                  className="img-fluid"
                                  alt=""
                                />
                              </span>
                              <span className="txt">{zipcode}</span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 for-no-padding">
                          <ul>
                            <li>
                              <span className="txt">{state}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <span className="detail-icon">
                            <i class="fa fa-phone" aria-hidden="true"></i>
                          </span>
                          <span className="txt">{business_details?.phone}</span>
                        </li>
                        <li>
                          <span className="detail-icon">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                          </span>
                          <span className="txt">{business_details?.email}</span>
                        </li>
                        <li>
                          <span className="detail-icon">
                            <i class="fa fa-globe" aria-hidden="true"></i>
                          </span>
                          <span className="txt">{business_website}</span>
                        </li>
                        <li>
                          <span className="detail-icon">
                            <i class="fa fa-medkit" aria-hidden="true"></i>
                            {/* <img className="img-fluid" src={des}/> */}
                          </span>
                          <span className="txt">{description}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="edit-details-wrapper Password-wrap">
                    <div className="detail-heading-wrapper">
                      <h3>Password</h3>

                      <Link to="/seller-change-password">
                        {" "}
                        <button className="edit">Edit</button>
                      </Link>
                    </div>
                    <div className="detail-content-wrapper">
                      <div className="form-groups row">
                        <div
                          className="col-lg-4"
                          style={{ position: "relative" }}
                        >
                          <input
                            type={showpass ? "text" : "password"}
                            className="form-control"
                            placeholder="************"
                          />
                          <div className="eye-wrapper">
                            <span onClick={handleshow}>
                              {!showpass ? <i className="fa fa-eye"></i> : ""}
                            </span>
                            <span onClick={hideshow}>
                              {showpass ? (
                                <i
                                  class="fa fa-eye-slash"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </AccessControl>
    </>
  );
};

export default SellerProfileContent;
