import React from "react";
import { Link } from "react-router-dom";
import PageHeader from "../Pageheader";
import AccessControl from "../Auth/AccessControl";
import Siderbar from "../../pages/Profile/Siderbar";
import ReUse from "../../services/helpers/reUse";
import { zipicon } from "../../Constant/Index";

const ProfileDetailContent = ({ data }) => {
	const { profile_image, first_name, last_name, email, phone, dob } =
		data ?? {};

	return (
		<>
			<PageHeader pagename="my profile" />
			{/* My Profile Section */}
			<AccessControl allowedRoles={["customer"]}>
				<section className="my-profile-all">
					<div className="container">
						<div className="row">
							<div className="col-md-2">
								<Siderbar
									// loading={loading}
									profilePic={profile_image}
									firstName={first_name}
								/>
							</div>
							<div className="col-md-10">
								<div className="show-profile">
									<div className="row">
										<div className="col-md-10">
											<div className="show-my-profile">
												<span className="for-align">
													<i class="fa fa-user" aria-hidden="true"></i>
												</span>
												<span>
													{" "}
													<p>{first_name + " " + last_name}</p>
												</span>
											</div>
											<div className="show-my-profile">
												<span className="for-align">
													<i class="fa fa-envelope" aria-hidden="true"></i>
												</span>
												<span>
													{" "}
													<p>{email}</p>
												</span>
											</div>
											<div className="show-my-profile">
												<span className="for-align">
													<i class="fa fa-phone" aria-hidden="true"></i>
												</span>
												<span>
													<p>{phone}</p>
												</span>
											</div>
											<div className="show-my-profile">
												<span className="for-align">
													<i
														class="fa fa-calendar-minus-o"
														aria-hidden="true"
													></i>
												</span>
												<p> {ReUse.dateFormat({ date: dob })}</p>
											</div>
											<div className="show-my-profile no-border-for-profile">
												<span className="for-align">
													<img className="img" alt="icon-img" src={zipicon} />
												</span>
												<span>
													{" "}
													<p>12245</p>
												</span>
											</div>
										</div>

										<div className="col-md-2">
											<div className="profile-edit-btn">
												<Link to="/edit-profile">Edit</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</AccessControl>

			{/* ========================== */}
		</>
	);
};

export default ProfileDetailContent;
