import React from "react";
import ReUse from "../../services/helpers/reUse";
import { ProductDetailActions } from "../../redux/slices/productDetail.slice";
import { useDispatch, useSelector } from "react-redux";

const ProductVariationOptions = (data) => {
	const dispatch = useDispatch();

	const { extraData } = data ?? {};

	const { objectData, keyName, currentIndex } = data ?? {};
	const selectedVariant = useSelector((state) => state.productDetail);
	const { selectedVariantType, selectedVariations } = selectedVariant ?? {};

	const variantValueHandler = (key, value) => {
		const isValidColor = ReUse.isValidColor({ stringInput: value });
		const selectedValue = isValidColor ? key?.toString() : value?.toString();

		const payload = {
			selectedValue: ReUse.addHyphenIfSpace({ word: selectedValue }),
			valueCategory: isValidColor ? "color" : extraData,
		};
		dispatch(ProductDetailActions.setSelectedVariant(payload));
	};

	const isVariantSelected = (key, value) => {
		return selectedVariantType?.includes(
			ReUse.isValidColor({ stringInput: value })
				? key?.toString()
				: ReUse.addHyphenIfSpace({ word: value })?.toString(),
		);
	};

	const VariationBtnComponent = () => {
		return (
			<button
				className={`${
					isVariantSelected(keyName, objectData[keyName])
						? "selected"
						: "normal"
				}`}
				normal
				style={{
					backgroundColor: ReUse.isValidColor(objectData[keyName])
						? objectData[keyName]
						: "#ffffff",
				}}
			>
				<h5>
					{ReUse.isValidColor({ stringInput: objectData[keyName] })
						? ""
						: objectData[keyName]}
				</h5>
			</button>
		);
	};

	return (
		<>
			<div
				className="radio-btn product_variations"
				key={currentIndex}
				onClick={() => variantValueHandler(keyName, objectData[keyName])}
			>
				<VariationBtnComponent />
			</div>
		</>
	);
};

export default ProductVariationOptions;
