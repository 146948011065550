import React from "react";
import { wishlist1 } from "../../Constant/Index";
import ReUse from "../../services/helpers/reUse";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { CartActions } from "../../redux/slices/cart.slice";

const ProductCart = ({ currentIndex, ...otherProps }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const {
		brand_id,
		brand_name,
		category_id,
		category_name,
		current_stock,
		description,
		discount,
		discount_type,
		images,
		product_name,
		thumbnail,
		unit_price,
		product_id,
		qty,
		currentStock,
	} = otherProps ?? {};

	const customerRemoveProductCart = async (e) => {
		e.preventDefault();

		try {
			dispatch(CartActions.Remove_Cart_Item(currentIndex));
		} catch (err) {
			console.error("Error:", err);
		}
	};

	const handleIncrement = (e) => {
		e.preventDefault();
		dispatch(CartActions.INCREMENT_PRODUCT_QTY(currentIndex));
	};
	const handleDecrement = (e) => {
		e.preventDefault();
		dispatch(CartActions.DECREMENT_PRODUCT_QTY(currentIndex));
	};

	const isStockAvailable = () => {
		return !(qty <= currentStock);
	};

	return (
		<tr>
			<td>
				<div className="img-icon">
					<figure>
						<img
							className="img-fluid"
							alt="wish-list-img"
							src={
								thumbnail !== null
									? thumbnail
									: ReUse.defaultThumbnailImage({
											content: "No+Image+Available",
									  })
							}
							onError={ReUse.onImageError}
						/>
					</figure>
				</div>
			</td>
			<td>
				<p>{product_name}</p>
			</td>
			<td>
				<p>
					<span>${unit_price}</span>
				</p>
			</td>
			<td>
				{" "}
				<div className="quantity quantity-for-cart">
					<div className="quantity-all-stu">
						<input value={qty} min="0" />
						<button className="btn-qunatity-1" disabled={isStockAvailable()}>
							<i
								class="fa fa-angle-up"
								aria-hidden="true"
								onClick={handleIncrement}
							></i>
						</button>
						<button className="btn-qunatity-2">
							<i
								class="fa fa-angle-down"
								aria-hidden="true"
								onClick={handleDecrement}
							></i>
						</button>
					</div>
				</div>
			</td>
			<td>
				<p>
					<span>
						$
						{ReUse.calculateSubtotalPrice({
							unitPrice: unit_price,
							quantity: qty,
						})}
					</span>
				</p>
			</td>
			<td>
				{/* <button >remove</button> */}
				<i
					class="fa fa-times"
					aria-hidden="true"
					onClick={customerRemoveProductCart}
				></i>
			</td>
		</tr>
	);
};

export default ProductCart;
