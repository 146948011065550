import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  homecategories1,
  homecategories2,
  homecategories3,
  homecategories4,
} from "../Constant/Index";
import TopcategoryCard from "./TopcategoryCard";
import ReUse from "../services/helpers/reUse";
import AppService from "../services/appServices";

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function Topcategories() {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const topSellingProducts = AppService.topSellingProducts();
    ReUse.getApiData(topSellingProducts, setApiData, setLoading);
  }, []);

  return (
    apiData?.length > 0 && (
      <>
        <section className="top-categories-sec-all wow animate__animated animate__lightSpeedInRight">
          <div className="container">
            <div className="top-categories-head">
              <h4>Top Seeling Products</h4>
            </div>

            <Slider {...settings}>
              {ReUse.mapItems(loading, apiData, TopcategoryCard)}
            </Slider>
          </div>
        </section>
      </>
    )
  );
}

export default Topcategories;
