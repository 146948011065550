import { useState } from "react";
import ReUse from "../../services/helpers/reUse";

export const SellerAddProduct = (attributes = {}) => {
  // const attributes = {
  //     color: ["red", "blue"],
  //     size: ["small", "medium"],
  // };

  const generateVariations = (attributes) => {
    const keys = Object.keys(attributes);
    const attributeOptions = keys.map((key) => attributes[key]);

    const variations = generateCartesianProduct(...attributeOptions)?.map(
      (combination, index) => {
        const variation = {};
        combination?.forEach((option, i) => {
          variation[keys[i]] = option;
        });
        return {
          [`variation${index + 1}`]: Object?.values(variation)?.join("-"),
        };
      }
    );

    return variations;
  };

  function generateCartesianProduct(...sets) {
    /**
     * sets argument structure
     * var attributes = {
     * color: ["red", "blue"],
     * size: ["small", "medium"],
     * measure: ["10cm", "20cm"]
     * };
     *
     */

    return sets?.reduce(
      (acc, set) => {
        return acc?.flatMap((x) => set?.map((y) => [...x, y]));
      },
      [[]]
    );
  }

  const generatedVariations = generateVariations(attributes);

  const useVariantDataHandler = () => {
    const [variantData, setVariantData] = useState([]);
    const [bulkVariantData, setBulkVariantData] = useState([]);
    const [bulkVariantMOQs, setBulkVariantMOQs] = useState([]);

    const variantDataHandler = (e, index) => {
      const { name, value } = e.target;
      const isNumberOrText = ReUse.isNumberOrText({ stringInput: value });
      setVariantData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          [name]: isNumberOrText ? parseInt(value) : value,
        };
        return newData;
      });
    };

    const bulkVariantDataHandler = (e, index) => {
      // OUTPUT EXAMPLE  const data = [
      // 	{
      // 		qty: 10,
      // 		sku: "123-abc",
      // 		moqs: [
      // 			{
      // 				moq: 100,
      // 				price: 1000,
      // 			},
      // 		],
      // 	},
      // ];

      const { name, value } = e.target;
      const isNumberOrText = ReUse.isNumberOrText({ stringInput: value });
      setBulkVariantData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          [name]: isNumberOrText ? parseInt(value) : value,
          moqs: [],
        };
        return newData;
      });
    };

    const variantMOQsHandler = ({
      index = null,
      variantData = [],
      MOQsData = [],
    }) => {



      const newData = [...variantData];

      const getRecord = newData[index];

      if (getRecord && getRecord?.moqs) {
        getRecord.moqs = MOQsData || [];
      }

      setBulkVariantData(newData);
    };

    return [
      variantData,
      variantDataHandler,
      bulkVariantData,
      bulkVariantDataHandler,
      bulkVariantMOQs,
      variantMOQsHandler,
    ];
  };

  return {
    generatedVariations,
    useVariantDataHandler,
  };
};

// const selectedAttributes = [
//     {
//         attribute: "color",
//         values: [
//             { value: "sdsds", label: "sdsds" },
//             { value: "sds", label: "sdds" },
//         ],
//     },
//     {
//         attribute: "green",
//         values: [
//             { value: "sdsds", label: "sdsds" },
//             { value: "sds", label: "sdds" },
//         ],
//     },
// ];
