import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { footerlogo } from "../Constant/Index";
import ReUse from "../services/helpers/reUse";
import AppService from "../services/appServices";
import FooterSocial from "./FooterSocial";

function Footer() {
	const [apiData, setApiData] = useState([]);
	const [footerSettings, setFooterSettings] = useState({});
	const [settingloader, setSettingloader] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const socialMedia = AppService.socialMedia();
		ReUse.getApiData(socialMedia, setApiData, setLoading);

		const settings = AppService.settings();
		ReUse.getApiData(settings, setFooterSettings, setSettingloader);
	}, []);

	const {
		name,
		phone,
		web_logo,
		mobile_logo,
		email,
		colors,
		footer_logo,
		copyright_text,
		download_app_apple_stroe,
		download_app_google_stroe,
		fav_icon,
		language,
	} = footerSettings ?? {};

	const { primary, secondary } = colors ?? {};

	return (
		<>
			{/* Footer Section */}
			<section className="footer-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<div className="footer-all widget1">
								<div className="footer-logo">
									<figure>
										<img
											className="img-fluid"
											alt="footer-logo-img"
											src={
												footer_logo && footer_logo !== null
													? footer_logo
													: ReUse.defaultThumbnailImage({
															content: "No+Image+Available",
													  })
											}
											onError={ReUse.onImageError}
										/>
									</figure>
								</div>
								<div className="footer-text">
									<p>
										Lorem Ipsum is simply dummy text of the printing and
										typesetting industry.Lorem Ipsum has been the industry's
										standard.
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-2">
							<div className="footer-all widget2">
								<div className="footer-head">
									<h4>Menu</h4>
								</div>
								<div className="footer-all">
									<ul>
										<li>
											<Link to="/">Home</Link>
										</li>
										<li>
											<Link to="/about_us">About Us</Link>
										</li>
										<li>
											<Link to="/contact-us">Contact Us</Link>
										</li>
										<li>
											<Link to="/career">Careers</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-2">
							<div className="footer-all widget2">
								<div className="footer-head">
									<h4>Category</h4>
								</div>
								<div className="footer-all">
									<ul>
										<li>
											<Link to="/product-fashion">Food shop</Link>
										</li>
										<li>
											<Link to="/electronics">electronics</Link>
										</li>
										<li>
											<Link to="/new-fashion-collection">Fashion</Link>
										</li>
										<li>
											<Link to="/art-craft-product">Arts & Crafts</Link>
										</li>
										<li>
											<Link>Beauty & Personal Care</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-2">
							<div className="footer-all widget2">
								<div className="footer-head">
									<h4>Legal</h4>
								</div>
								<div className="footer-all">
									<ul>
										<li>
											<Link to="/faq">faq</Link>
										</li>
										<li>
											<Link to="/terms_condition">Terms & Condition</Link>
										</li>
										<li>
											<Link to="/privacy_policy">Privacy Policy</Link>
										</li>
										<li>
											<Link to="/delivery_info_page">Delivery Information</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="footer-all widget2">
								<div className="footer-head">
									<h4>Contact Info</h4>
								</div>
								<div className="footer-all">
									<div className="footer-address">
										<span>
											<i class="fa fa-map-marker" aria-hidden="true"></i>
										</span>
										<span>
											<p>Vack Sample acdb No.815, Kita, UK 80361</p>
										</span>
									</div>
									<div className="footer-address">
										<span>
											<i class="fa fa-phone" aria-hidden="true"></i>
										</span>
										<span>
											<p>
												<a href="tel:(+12) 345 678 9">{phone}</a>
											</p>
										</span>
									</div>
									<div className="footer-address">
										<span>
											<i class="fa fa-envelope" aria-hidden="true"></i>
										</span>
										<span>
											<p>
												<a href="mailto:support@domain.com">{email}</a>
											</p>
										</span>
									</div>

									<div className="footer-social-link">
										<ul>{ReUse.mapItems(loading, apiData, FooterSocial)}</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="copy-right">
					<p>{copyright_text}</p>
				</div>
			</section>
			{/* =========================== */}
		</>
	);
}

export default Footer;
