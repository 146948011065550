import { Modal } from "bootstrap";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import {
  reviewimg,
  reviewimg1,
  reviewimg2,
  reviewimg3,
} from "../../Constant/Index";
import AccessControl from "../../Components/Auth/AccessControl";
import { useEffect } from "react";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import SellerReviewCard from "./SellerReviewCard";
import GlobalModal from "../../Components/ReUse/Modals/GlobalModal";

function Sellerreview() {


  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const sellerGetReviews = AppService.sellerGetReviews();
    ReUse.getApiData(sellerGetReviews, setApiData, setLoading);
  }, []);

  return (
    <>
      <AccessControl allowedRoles={["seller"]}>
        <SellerHeader />
        {/* Seller profile starts here */}
        <section className="seller-dashboard">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 ps-0">
                <SellerSidebar />
              </div>
              <div className="col-lg-8">
                <div className="edit-profile-card seller-reviews-tabs">
                  <div className="edit-heading-wrapper">
                    <h2>Reviews & Feedback</h2>
                  </div>
                  {ReUse.mapItems(loading, apiData?.data, SellerReviewCard)}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Seller profile ends here */}
      </AccessControl>
    </>
  );
}

export default Sellerreview;
