import React from "react";
import { Link } from "react-router-dom";

const FooterSocial = (propsData) => {
  const { name, link, icon } = propsData ?? {};
  return (
    <>
      <li>
        <Link to={link}>
          <i class="fa fa-facebook" aria-hidden="true"></i>
        </Link>
      </li>
    </>
  );
};

export default FooterSocial;
