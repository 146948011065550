import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import Siderbar from "./Siderbar";
import ReUse from "../../services/helpers/reUse";
import { toast } from "react-toastify";
import AppService from "../../services/appServices";
import { useDispatch } from "react-redux";
import AccessControl from "../../Components/Auth/AccessControl";
import UserAddresses from "./UserAddresses";
import MyAddresses from "./MyAddresses";
import AddressAddModal from "./AddressAddModal";
import {
	ResponseMessageActions,
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import CustomButton from "../../Components/ReUse/Components/Button";
import FieldError from "../../Components/ReUse/FieldError";

function Editprofile() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState();
	const [fileupload, setFileupload] = useState();

	const [allErrors, setAllErrors] = useState({
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		dob: "",
		image: "",
	});

	const [fieldsData, setFieldsData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		dob: "",
		image: "",
	});

	function handleChange(e) {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		const { value, name } = e.target;
		setFieldsData({
			...fieldsData,
			[name]: value,
		});
	}

	const onSubmitHandler = async (e) => {
		e.preventDefault();

		setLoading(true);

		

		try {
			const { first_name, last_name, phone, dob, image, email } = fieldsData;

			const fields = {
				first_name,
				last_name,
				phone,
				dob,
				email,
			};
			const allErrors = ReUse.validateRequired({ fields: fields });

			// Check if there are any errors
			if (Object.keys(allErrors).length > 0) {
				setAllErrors(allErrors);
				setLoading(false);
				return;
			}

			const payLoad = {
				first_name,
				last_name,
				phone,
				dob,
				image: file,
				email,
			};

			const apiResp = await AppService.customerUpdateProfile(payLoad);
			const { message, response } = apiResp.data;
			setLoading(false);
			dispatch(successMessage(message));
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/my-profile");
				}, 1000);
			}, 1000);

			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
			dispatch(successMessage(""));
		}
	};

	const handleProfileUpload = (e) => {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		ReUse.handleFileUploads(e).then((result) => {
			const { fileOutput, fileUpload } = result;
			setFile(fileOutput);
			setFileupload(fileUpload);
		});
	};

	const { first_name, last_name, email, phone, dob } = fieldsData ?? {};



	return (
		<>
			<PageHeader pagename="Edit My Profile" />
			{/* Edit Profle Seciton */}
			<AccessControl allowedRoles={["customer"]}>
				<section className="edit-profile-sec my-profile-all">
					<div className="container">
						<div className="row">
							<div className="col-md-2">s
								<Siderbar handleProfileUpload={handleProfileUpload} />
							</div>
							<div className="col-md-10">
								<div className="all-edit-screen">
									<div className="row">
										<div className="col-md-10">
											<form>
												<div className="row">
													<div className="col-md-6">
														<div className="edit-profile-input">
															<input
																type="text"
																placeholder="first name"
																onChange={handleChange}
																name="first_name"
																value={first_name}
															/>
															<FieldError fieldName={allErrors?.first_name} />
															<div className="icon-of-edit-profile">
																<i class="fa fa-user" aria-hidden="true"></i>
															</div>
														</div>
													</div>
													<div className="col-md-6">
														<div className="edit-profile-input">
															<input
																type="text"
																placeholder="last name"
																onChange={handleChange}
																name="last_name"
																value={last_name}
															/>
															<FieldError fieldName={allErrors?.last_name} />
															<div className="icon-of-edit-profile">
																<i class="fa fa-user" aria-hidden="true"></i>
															</div>
														</div>
													</div>
													<div className="col-md-6">
														<div className="edit-profile-input">
															<input
																type="email"
																placeholder="Email "
																onChange={handleChange}
																name="email"
																value={email}
															/>
															<FieldError fieldName={allErrors?.email} />
															<div className="icon-of-edit-profile">
																<i
																	class="fa fa-envelope"
																	aria-hidden="true"
																></i>
															</div>
														</div>
													</div>
													<div className="col-md-6">
														<div className="edit-profile-input">
															<input
																type="text"
																placeholder="phone"
																onChange={handleChange}
																name="phone"
																value={phone}
															/>
															<FieldError fieldName={allErrors?.phone} />
															<div className="icon-of-edit-profile">
																<i class="fa fa-phone" aria-hidden="true"></i>
															</div>
														</div>
													</div>
													<div className="col-md-6">
														<div className="edit-profile-input">
															<input
																type="text"
																placeholder="DOB"
																onChange={handleChange}
																name="dob"
																value={dob}
															/>
															<FieldError fieldName={allErrors?.dob} />
															<div className="icon-of-edit-profile">
																<i
																	class="fa fa-calendar-minus-o"
																	aria-hidden="true"
																></i>
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>

										<div className="col-md-2">
											<div className="profile-edit-btn">
												<a>
													<CustomButton
														loading={loading}
														onClickHandler={onSubmitHandler}
														customClassName="btn"
													>
														Submit
													</CustomButton>
												</a>
												<ResponseMessage />
											</div>
										</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</section>
			</AccessControl>

			{/* ============================ */}
		</>
	);
}

export default Editprofile;
