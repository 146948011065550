import React, { useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import { organic } from "../../Constant/Index";
import ReUse from "../../services/helpers/reUse";
import AppService from "../../services/appServices";
import CMProductCard from "./CMProductCard";

export const CustomizableProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllCustomizableProducts = AppService.getAllCustomizableProducts({
      categoryId: null,
      subCategoryId: null,
      minPrice: null,
      maxPrice: null,
      pageNumber: 1,
    });
    ReUse.getApiData(getAllCustomizableProducts, setProducts, setLoading);
  }, []);

  const sectionStyles = {
    backgroundColor: "#7dab3c",
  };

  return (
    <>
      {" "}
      <section className="oraganic-food-sec">
        <div className="container">
          <div className="organic-all">
            <div className="row">
              <div
                className="col-md-4 for-back-color-and-raduis"
                style={sectionStyles}
              >
                <div className="background-head">
                  <h3>Customizable Products</h3>
                </div>
                <div className="organic-img">
                  <figure>
                    <img
                      className="img=fluid"
                      alt="organic-pic"
                      src={organic}
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row all-class-for-padding">
                  {ReUse.mapItems(false, products?.data, CMProductCard)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
