import React from "react";
import Select from "react-select";
import SelectedAttributeValues from "./SelectedAttributeValues";
import BulkVariantMeta from "./BulkVariantMeta";
import ReUse from "../../services/helpers/reUse";

const BulkVariant = ({ inventoryVariantProps }) => {
  const {
    bulkVariantData,
    bulkVariantDataHandler,
    bulkVariantMOQs,
    variantMOQsHandler,
    extractAttributeOptions,
    setSelectedAttributes,
    selectedAttributes,
    attributeOptions,
    delimiters,
    handleAttributeOptionDelete,
    handleAttributeOptionAddition,
    handleAttributeOptionDrag,
    handleAttributeOptionClick,
    generatedVariations,
  } = inventoryVariantProps ?? {};

  const generateId = ReUse.makeId();
  return (
    <>
      <div className="col-md-12">
        <div className="all-select-dv">
          <div className="mcq-dv-all">
            <h5>Add Varaitions / Attribute</h5>
          </div>

          <Select
            isMulti
            name="colors"
            options={extractAttributeOptions}
            className="basic-multi-select mt-2 mb-2"
            classNamePrefix="select"
            onChange={setSelectedAttributes}
          />
        </div>
      </div>

      {selectedAttributes?.map((selectedAttribute, index) => (
        <SelectedAttributeValues
          attributeOptions={attributeOptions}
          delimiters={delimiters}
          handleAttributeOptionDelete={handleAttributeOptionDelete}
          handleAttributeOptionAddition={handleAttributeOptionAddition}
          handleAttributeOptionDrag={handleAttributeOptionDrag}
          handleAttributeOptionClick={handleAttributeOptionClick}
          selectedAttribute={selectedAttribute}
          index={index}
          key={generateId}
        />
      ))}

      {generatedVariations?.map((variation, index) => {
        return (
          <BulkVariantMeta
            key={generateId}
            variation={variation}
            index={index}
            bulkVariantDataHandler={bulkVariantDataHandler}
            bulkVariantData={bulkVariantData}
            variantMOQsHandler={variantMOQsHandler}
          />
        );
      })}
    </>
  );
};

export default BulkVariant;
