import React from "react";
import { icon1, icon2, icon3, icon4, icon5 } from "../../Constant/Index";

const Benefits = () => {
  return (
    <>
      <section className="differnt-sec ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="all-icons-in-one-dv">
                <div className="icon-with-text">
                  <figure>
                    <img className="img-fluid" alt="i-dv" src={icon1} />
                  </figure>
                  <div className="my-text">
                    <h5>free delivery</h5>
                    <p>For all orders over $30</p>
                  </div>
                </div>
                <div className="icon-with-text">
                  <figure>
                    <img className="img-fluid" alt="i-dv" src={icon2} />
                  </figure>
                  <div className="my-text">
                    <h5>90 days return</h5>
                    <p>If goods have problems</p>
                  </div>
                </div>
                <div className="icon-with-text">
                  <figure>
                    <img className="img-fluid" alt="i-dv" src={icon4} />
                  </figure>
                  <div className="my-text">
                    <h5>100% Secure Payment</h5>
                    <p>Guarantee Secure Payments</p>
                  </div>
                </div>
                <div className="icon-with-text">
                  <figure>
                    <img className="img-fluid" alt="i-dv" src={icon5} />
                  </figure>
                  <div className="my-text">
                    <h5>24/7 Dedicated Support</h5>
                    <p>Anywhere & anytime</p>
                  </div>
                </div>
                <div className="icon-with-text">
                  <figure>
                    <img className="img-fluid" alt="i-dv" src={icon3} />
                  </figure>
                  <div className="my-text">
                    <h5>Daily Offers</h5>
                    <p>Discount up to 70 % Off</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Benefits;
