// AccessControl.js
import React from "react";
import { useSelector } from "react-redux";
import NoAccess from "../ReUse/NoAccess";
import LoaderForApi from "../ReUse/LoaderForApi";
import { Link, useLocation, useNavigate } from "react-router-dom";

const loginCSSProperties = {
	fontSize: "11px",
	backgroundColor: "rgb(125, 171, 60)",
	color: "rgb(255, 255, 255)",
	border: "unset",
	width: "100px",
	borderRadius: "6px",
	fontFamily: "poppins-regular",
	padding: "8px 10px",
	textDecoration: "unset",
	margin: "auto",
	display: "flex",
	justifyContent: "center",
};

const AccessControl = ({
	allowedRoles,
	children,
	isMessageDisplay = true,
	isButtonDisplay = true,
	buttonMessage = "Sign In",
	buttonClassName = "",
}) => {
	const navigate = useNavigate();
	const location = useLocation();

	const currentUser = useSelector((state) => state.persistedReducer.auth.user);

	const userRole = currentUser?.role;

	const navigateHandler = () => {
		navigate("/sign-in", { state: { from: location } });
	};

	if (!userRole) {
		return isButtonDisplay ? (
			<button
				onClick={navigateHandler}
				className={buttonClassName}
				style={buttonClassName ? undefined : loginCSSProperties}
			>
				{buttonMessage}
			</button>
		) : null;
	}

	if (!allowedRoles.includes(userRole)) {
		// Handle scenario where user doesn't have access
		return isMessageDisplay ? <NoAccess /> : null;
	}

	return children;
};

export default AccessControl;
