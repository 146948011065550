import React, { useEffect } from "react";
import Productslider from "../Productslider";
import ReUse from "../../services/helpers/reUse";
import ProductReview from "../../pages/Product/ProductReview";
import ShareProduct from "../../pages/Product/ShareProduct";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Rating } from "react-simple-star-rating";
import Slide from "../../Components/Slide";
import ProductVariations from "../../pages/Product/ProductVariations";
import AppService from "../../services/appServices";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { CartActions } from "../../redux/slices/cart.slice";
import ResponseMessage from "../ReUse/ResponseMessage";
import {
  clearResponseMessageAfterDelay,
  errorMessage,
  successMessage,
} from "../../redux/slices/responseMessage.slice";
import { ProductDetailActions } from "../../redux/slices/productDetail.slice";
import AccessControl from "../Auth/AccessControl";
import { ProductDetailHook } from "../../Hooks/ProductDetailHook";
import CustomButton from "../ReUse/Components/Button";

const ProductDetailContent = ({ data, currentIndex }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    product_id,
    product_name,
    slug,
    seller_id,
    seller_name,
    rating,
    review_count,
    reviews,
    description,
    category_id,
    category_name,
    sub_category_id,
    sub_category_name,
    brand_id,
    brand_name,
    images,
    thumbnail,
    unit_price,
    purchase_price,
    discount,
    discount_type,
    current_stock,
    colors,
    attributes,
    choice_options,
    variations,
    moq_selling,
    country_name,
  } = data ?? {};

  const cart = useSelector((state) => state.persistedReducer.cart);
  const { cartData } = cart ?? {};

  const isLoginAuth = useSelector(
    (state) => state.persistedReducer.auth.authenticated
  );

  const [loading, setLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [shippingLoader, setShippingLoader] = useState(false);

  const [matchedVarient, setMatchedVarient] = useState({
    type: "",
    sku: "",
    qty: null,
    price: null,
  });

  const [shippingCharges, setShippingCharges] = useState([]);
  const [productShippingCharges, setProductShippingCharges] = useState(null);
  const [reveiwFeedback, setReveiwFeedback] = useState("");
  const [givenRating, setGivenRating] = useState(null);
  const [reveiwStatusMessage, setReveiwStatusMessage] = useState("");
  const [reveiwLoader, setReveiwLoader] = useState(false);

  const { type, sku, qty, price } = matchedVarient ?? {};

  // const [selectedVarientType, setSelectedVarientType] = useState(["Amethyst", "34"]);

  const selectedVariant = useSelector((state) => state.productDetail);

  const productDetailRedux = useSelector((state) => state.productDetail);
  const { counter } = productDetailRedux ?? {};

  const { selectedVariantType, selectedVariations } = selectedVariant ?? {};

  function matchVarient() {
    const foundVarient = ReUse.foundRecord({
      data: variations,
      property: "type",
      target: selectedVariantType?.join("-"),
    });
    const { type, sku, qty, price } = foundVarient ?? {};

    setMatchedVarient({
      type: type,
      sku: sku,
      qty: parseInt(qty),
      price: parseInt(price),
    });
  }

  useEffect(() => {
    matchVarient();
  }, [selectedVariantType]);

  const customerAddProductWishlist = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (!isLoginAuth) {
      dispatch(errorMessage("Please Login to use whishlist"));
      dispatch(clearResponseMessageAfterDelay());
      setLoading(false);
      return;
    }

    try {
      const resultVariations = {};

      selectedVariations.forEach((variant) => {
        resultVariations[variant?.valueCategory] = variant?.selectedValue;
      });

      const payload = {
        product_id: product_id,
        product_type: "seller",
      };

      const apiResp = await AppService.customerAddProductWishlist(payload);
      const { message, response } = apiResp.data;

      setLoading(false);
      dispatch(successMessage(message));
      setTimeout(() => {
        dispatch(successMessage("Navigating..."));
        setTimeout(() => {
          dispatch(successMessage(""));
          navigate("/wish-list");
        }, 3000);
      }, 1000);
    } catch (err) {
      setLoading(false);
      dispatch(successMessage(""));
      dispatch(errorMessage("Something Went Wrong"));
      dispatch(clearResponseMessageAfterDelay());
    }
  };

  const customerAddProductCart = async (e) => {
    e.preventDefault();

    setCartLoading(true);

    if (ReUse.isFound(cartData, product_id)) {
      dispatch(errorMessage("Already Added"));
      dispatch(clearResponseMessageAfterDelay());
      setCartLoading(false);
      return;
    }

    if (selectedVariantType?.length === 0) {
      dispatch(errorMessage("Please select variant"));
      dispatch(clearResponseMessageAfterDelay());
      setCartLoading(false);
      return;
    }

    if (selectedVariantType?.length < attributes?.length) {
      dispatch(errorMessage("Please select all variants"));
      dispatch(clearResponseMessageAfterDelay());
      setCartLoading(false);
      return;
    }

    if (counter === 0) {
      dispatch(errorMessage("Please select quantity"));
      dispatch(clearResponseMessageAfterDelay());
      setCartLoading(false);
      return;
    }

    try {
      const { price, qty, sku, type } = matchedVarient ?? {};

      const {
        brand_id,
        brand_name,
        category_id,
        category_name,
        current_stock,
        description,
        discount,
        discount_type,
        images,
        product_name,
        thumbnail,
        unit_price,
        product_id,
      } = data ?? {};

      const resultVariations = {};

      selectedVariations.forEach((variant) => {
        resultVariations[variant?.valueCategory] = variant?.selectedValue;
      });

      const payload = {
        brand_name,
        category_id,
        category_name,
        current_stock,
        description,
        discount,
        discount_type,
        images,
        product_id,
        product_name,
        thumbnail,
        unit_price: price,
        qty: counter,
        currentStock: qty,
        calculatedPrice: ReUse.calculateSubtotalPrice(
          price || unit_price,
          counter
        ),
        shippingCharges: productShippingCharges,
        variant: selectedVariantType?.join("-"),
        variations: resultVariations,
        product_type: "standard",
      };

      dispatch(CartActions.Add_To_Cart(payload));
      dispatch(successMessage("Added to cart"));
      setCartLoading(false);
      dispatch(ProductDetailActions.setClearVariants());
      setTimeout(() => {
        dispatch(successMessage("Navigating..."));
        setTimeout(() => {
          dispatch(successMessage(""));
          navigate("/cart");
        }, 1000);
      }, 1000);
    } catch (err) {
      console.error("API Error:", err);
      setCartLoading(false);
      dispatch(successMessage(""));
      dispatch(errorMessage("Something Went Wrong"));
      dispatch(clearResponseMessageAfterDelay());
    }
  };

  const currentUser = useSelector((state) => state.persistedReducer.auth.user);

  const clearVariants = () => {
    dispatch(ProductDetailActions.setClearVariants());
  };

  useEffect(() => {
    const apiShippingCharges = AppService.shippingCharges();
    ReUse.getApiData(apiShippingCharges, setShippingCharges, setShippingLoader);
  }, []);

  useEffect(() => {
    const { country } = currentUser ?? {};
    let foundCharges = 0;
    let shippingType = "";

    if (country?.toLowerCase() === country_name?.toLowerCase()) {
      shippingType = "same-country";
    } else {
      shippingType = "different-country";
    }

    foundCharges = shippingCharges.find(
      (record) => record?.shipping_type === shippingType
    );
    setProductShippingCharges(parseInt(foundCharges?.charges || 0));
  }, [country_name, currentUser, shippingCharges]);

  const productDetailHook = ProductDetailHook(qty);

  const handleIncrement = () => {
    productDetailHook.handleIncrement();
  };

  const handleDecrement = () => {
    productDetailHook.handleDecrement();
  };

  const reveiwSubmitHandler = async (e) => {
    e.preventDefault();

    setReveiwLoader(true);

    if (!givenRating) {
      setReveiwLoader(false);
      dispatch(errorMessage("Add Rating"));
      dispatch(clearResponseMessageAfterDelay());
      return;
    }

    if (!reveiwFeedback) {
      setReveiwLoader(false);
      dispatch(errorMessage("Add feedback"));
      dispatch(clearResponseMessageAfterDelay());

      return;
    }

    try {
      const payload = {
        product_id: product_id,
        comment: reveiwFeedback,
        rating: givenRating,
      };

      const apiResp = await AppService.customerSubmitReview(payload);
      const { message, response } = apiResp.data;
      setReveiwLoader(false);
      dispatch(successMessage(message));
      setTimeout(() => {
        dispatch(successMessage("Processing feedback."));
        setTimeout(() => {
          dispatch(successMessage(""));
        }, 1000);
      }, 1000);
    } catch (err) {
      console.error("API Error:", err);
      setReveiwLoader(false);
      dispatch(successMessage(""));
      dispatch(errorMessage("Something Went Wrong"));
      dispatch(clearResponseMessageAfterDelay());
    }
  };
  const handleRating = (rating) => {
    setGivenRating(rating);
  };

  return (
    <>
      {/* Product Detail Section */}
      <section className="product-detail-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6 only-for-this-product">
              <Slide images={images} thumbnail={thumbnail} />
            </div>
            <div className="col-md-6">
              <div className="custom-fashion-text">
                <h3>{product_name}</h3>
                <div className="name-stars">
                  <h5>
                    {seller_name && "Seller Name:"} <span>{seller_name}</span>
                  </h5>
                  <Rating
                    size={20}
                    allowFraction={true}
                    initialValue={rating}
                    readonly={true}
                  />

                  <p>({review_count} Customer Review)</p>
                  <div className="seller-anchor">
                    <Link
                      to={`/seller-store/${seller_id}`}
                      state={{
                        sellerId: seller_id,
                        currentIndex: currentIndex,
                      }}
                    >
                      Seller Store
                    </Link>
                  </div>
                </div>
                <div className="seller">
                  <p>{ReUse.removeHTMLTags({ unStripped: description })}</p>
                  <div className="seller-price">
                    <h2>
                      $
                      {ReUse.calculateSubtotalPrice({
                        unitPrice: price || unit_price,
                        quantity: counter,
                      })}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="quantity">
                <div className="quantity-head">
                  <h5>Quantity</h5>
                </div>
                <div className="quantity-all-stuff">
                  <input value={counter} min="0" />
                  <button className="btn-qunatity-1" onClick={handleIncrement}>
                    <i class="fa fa-angle-up" aria-hidden="true"></i>
                  </button>
                  <button className="btn-qunatity-2" onClick={handleDecrement}>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              {ReUse.mapItems(false, attributes, ProductVariations)}
              {selectedVariations?.length > 0 && (
                <p onClick={() => clearVariants()}>Clear Selections</p>
              )}
              <div className="whishlist-and-cart-button">
                <AccessControl
                  allowedRoles={["customer"]}
                  isMessageDisplay={false}
                  buttonMessage="Login to use Wishlist"
                  // buttonClassName="button"
                >
                  <div className="whishlist-cart">
                    <CustomButton
                      loading={loading}
                      onClickHandler={customerAddProductWishlist}
                      customClassName="button"
                    >
                      Add to wishlist
                    </CustomButton>
                  </div>
                </AccessControl>
                <AccessControl
                  allowedRoles={["customer"]}
                  isMessageDisplay={false}
                  buttonMessage="Login to use Cart"
                  // buttonClassName="button"
                >
                  <div className="whishlist-cart">
                    <CustomButton
                      loading={cartLoading}
                      onClickHandler={customerAddProductCart}
                      customClassName="button"
                    >
                      Add to Cart
                    </CustomButton>
                  </div>
                </AccessControl>
              </div>
              <ResponseMessage />

              <div className="category-text">
                <h5>
                  CATEGORIES: <span>{category_name}</span>
                </h5>
              </div>

              <div className="product-ul">
                <div className="product-head">
                  <h5>SHARE THIS PRODUCT:</h5>
                </div>
                <div className="product-ul">
                  <ShareProduct />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================= */}

      {/* Tabs section */}
      <section className="reviews-tab-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tabs-alls">
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      DESCRIPTION
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      REVIEWS{" "}
                      <span className="for-number">{`(${review_count})`}</span>
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#customer-write-reveiw"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Write Reveiw{" "}
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="description-para">
                      <p>{ReUse.removeHTMLTags({ unStripped: description })}</p>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="reviews-all">
                      {ReUse.mapItems(false, reviews, ProductReview)}
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="customer-write-reveiw"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <AccessControl
                      allowedRoles={["customer"]}
                      isMessageDisplay={true}
                      buttonMessage="Login to send Reveiw"
                    >
                      <div class="modal-content">
                        <div class="modal-body">
                          <div className="review-modal-all">
                            <div className="reviews-modal-para">
                              <p>Write a Review</p>
                            </div>
                            <div className="reviews-modal-text">
                              <Rating
                                size={20}
                                allowFraction={true}
                                readonly={false}
                                onClick={handleRating}
                                className="mb-3"
                              />
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                placeholder="Add feedback"
                                name="feedback"
                                onChange={(e) =>
                                  setReveiwFeedback(e.target.value)
                                }
                                value={reveiwFeedback}
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <div className="all-seller-btn modal-submit-btn">
                          <CustomButton
                            loading={reveiwLoader}
                            onClickHandler={reveiwSubmitHandler}
                            customClassName="custom-btn"
                          >
                            Submit
                          </CustomButton>
                          <ResponseMessage />
                        </div>
                      </div>
                    </AccessControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =========================================================== */}

      {/* Product Slider */}
      <Productslider catId={category_id} subCatId={sub_category_id} />
      {/* ============ */}
    </>
  );
};

export default ProductDetailContent;
