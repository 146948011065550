import React from "react";
import { productinventoryimg } from "../../Constant/Index";
import ReUse from "../../services/helpers/reUse";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import AppService from "../../services/appServices";
import { toast } from "react-toastify";
import {
	clearResponseMessageAfterDelay,
	errorMessage,
} from "../../redux/slices/responseMessage.slice";

const SellerProductInventoryCard = (propsData) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [loading, setLoading] = useState(false);
	const [statusMessage, setStatusMessage] = useState("");

	const {
		id,
		product_name,
		thumbnail,
		description,
		category_id,
		category_name,
		sub_category_id,
		sub_category_name,
		unit_price,
		stock,
		quantity,
		status,
		discount_price,
	} = propsData ?? {};

	const handleDeleteProduct = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const apiResp = await AppService.sellerDeleteProduct({ productId: id });
			const { message, response } = apiResp.data;

			setStatusMessage(message);
			setLoading(false);
			setTimeout(() => {
				setStatusMessage("Navigating...");
				setTimeout(() => {
					setStatusMessage("");
					navigate("/seller-product-inventory");
				}, 1000);
			}, 1000);
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			setStatusMessage("");
		}
	};

	return (
		<tr>
			<td>
				<h5>{product_name}</h5>
			</td>
			<td className="inventry-img">
				<figure>
					<img
						className="img-fluid"
						alt="product-inventory-img"
						src={productinventoryimg}
					/>
				</figure>
			</td>
			<td className="product-inventory-para">
				<p>{ReUse.removeHTMLTags({ unStripped: description })}</p>
			</td>
			<td>
				<h5>{category_name}</h5>
			</td>
			<td>
				<h5>{sub_category_name}</h5>
			</td>
			<td>
				<h5>${unit_price}</h5>
			</td>
			<td className="product-inventory-btn">
				<div className="product-inventory-all">
					<button className={status !== 1 ? "inactive" : ""}>
						{status === 1 ? "Active" : "Inactive"}
					</button>
				</div>
			</td>
			<td className="stock-img">
				<p>
					{stock ? "In Stock" : "Not In Stock"}
					<span>
						<i class="fa fa-check-circle" aria-hidden="true"></i>
					</span>
				</p>
			</td>
			<td className="delete-trash" onClick={(e) => handleDeleteProduct(e)}>
				<i class="fa fa-trash-o" aria-hidden="true"></i>
			</td>
			<td
				className="edit-screen"
				data-bs-toggle="modal"
				data-bs-target="#exampleModal"
			>
				<i class="fa fa-pencil-square-o" aria-hidden="true"></i>
			</td>
			<td className="price-para">
				<p>{quantity}</p>
			</td>
			<td className="price-para">
				<p>${discount_price}</p>
			</td>
		</tr>
	);
};

export default SellerProductInventoryCard;
