import React, { useEffect, useState } from "react";
import { visaimg } from "../../Constant/Index";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import {
  clearResponseMessageAfterDelay,
  errorMessage,
  successMessage,
} from "../../redux/slices/responseMessage.slice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const PaymentCard = (propsDdata) => {
  const { id } = propsDdata || {};

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [apiData, setApiData] = useState({});
  const [loading, setLoading] = useState(false);
  const [deleteCardloader, setDeleteCardloader] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  useEffect(() => {
    const getSellerCard = AppService.getSellerCard({ cardId: 1 });
    ReUse.getApiData(getSellerCard, setApiData, setLoading);
  }, []);

  const deleteCardHandler = async (e) => {
    e.preventDefault();

    const isConfirmed = await ReUse.showConfirmationDialog({});

    if (!isConfirmed) {
      ReUse.showSuccessDialog({});
      return;
    }

    try {
      setDeleteCardloader(true);
      const apiResp = await AppService.deleteSellerCard({ cardId: id });
      const { message, response } = apiResp.data;
      dispatch(successMessage(message));
      ReUse.showSuccessDialog({ text: message });
      setTimeout(() => {
        dispatch(successMessage("Navigating..."));
        setTimeout(() => {
          dispatch(successMessage(""));
        }, 1000);
      }, 1000);
    } catch (err) {
      setDeleteCardloader(false);
      const errorMessage = err?.response?.data?.message || "An error occurred.";
      dispatch(errorMessage);
      dispatch(clearResponseMessageAfterDelay());
      dispatch(successMessage(""));
      ReUse.showErrorDialog({ text: errorMessage });
    } finally {
      setDeleteCardloader(false);
    }
  };

  return (
    <div className="my-all-address">
      <div className="saved-address">
        {/* <!-- Saved Address --> */}
        <label className="container">
          <input type="radio" defaultChecked name="radio" />
          <span className="checkmark">
            <div className="select-address">
              <div className="seller-delete">
                <span className="selection-delete">
                  {" "}
                  <i
                    class="fa fa-trash-o"
                    aria-hidden="true"
                    onClick={deleteCardHandler}
                  ></i>
                </span>
              </div>
              <div className="selection">
                {/* <div className="seller-check">
									<span className="selection-delete">
										{" "}
										<i class="fa fa-trash-o" aria-hidden="true"></i>
									</span>
								</div> */}
                <div className="seller-default">
                  <button>Default</button>
                </div>
              </div>
              <div className="radio-btn">
                <span></span>
              </div>
              <div className="address-detail">
                <div className="for-flex-the-things">
                  <div className="visa-img">
                    <figure>
                      <img className="img-fluid" alt="visa-img" src={visaimg} />
                    </figure>
                  </div>
                  <div className="visa-para">
                    <h5>My Personal Card</h5>
                    <p>****************1239</p>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </label>
        {/* <!-- Saved Address --> */}
      </div>
    </div>
  );
};

export default PaymentCard;
