import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	homecategories1,
	homecategories2,
	homecategories3,
	homecategories4,
} from "../Constant/Index";
import { useDispatch } from "react-redux";
import { ProductsFilterActions } from "../redux/slices/productsFilter.slice";
import ReUse from "../services/helpers/reUse";

const TopcategoryCard = (propsData) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { product_id, name, slug, thumbnail } = propsData ?? {};

	// const navigator = () => {
	// 	dispatch(
	// 		ProductsFilterActions.setCatSubCatIds({
	// 			categoryId: id,
	// 			subCategoryId: null,
	// 		}),
	// 	);
	// 	navigate("/shop");
	// };



	return (
		<>
			<div className="top-cat-img">
				<div className="categories-main-img">
					<figure>
						<img
							className="img-fluid"
							alt="top-img"
							src={
								thumbnail !== null
									? thumbnail
									: ReUse.defaultThumbnailImage({
											content: "No+Image+Available",
									  })
							}
							onError={ReUse.onImageError}
						/>
					</figure>
					<div className="text-for-movement-in-categories">
						<div className="top-categories-sec">
							<h5>{name}</h5>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default TopcategoryCard;
