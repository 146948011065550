import React, { useEffect, useState } from "react";
import PageHeader from "../Pageheader";
import { man1, man2, sellerstoreimg } from "../../Constant/Index";
import { Link } from "react-router-dom";
import ReUse from "../../services/helpers/reUse";
import ProductBySeller from "../../pages/Sellerstore/ProductBySeller";
import AppService from "../../services/appServices";
import StoreReveiwCard from "../../pages/Sellerstore/StoreReveiwCard";
import { Rating } from "react-simple-star-rating";
import ReveiwForm from "../../pages/Sellerstore/ReveiwForm";
import "../../../src/assets/css/sellerstore.css";
import ProductsSortingFilters from "../../pages/Product/ProductsSortingFilters";
import { useSelector } from "react-redux";
import AccessControl from "../Auth/AccessControl";

const SellerStoreContent = ({ data, currentIndex }) => {
  const [sellerStoreProducts, setSellerStoreProducts] = useState([]);
  const [storeProductsLoader, setStoreProductsLoader] = useState(false);

  const [sellerStoreReviews, setSellerStoreReviews] = useState([]);
  const [storeReviewsLoader, setStoreReviewsLoader] = useState(false);

  const productsFilterData = useSelector((state) => state.productsfilters);

  const {
    categoryId,
    subCategoryId,
    minPrice,
    maxPrice,
    showProducts,
    sortByOrder,
  } = productsFilterData ?? {};

  const { id, name, email, phone, image, about_seller, ratings } = data ?? {};

  useEffect(() => {
    const storeProducts = AppService.storeProducts({ sellerId: id }); //56
    ReUse.getApiData(
      storeProducts,
      setSellerStoreProducts,
      setStoreProductsLoader
    );

    const storeReviews = AppService.storeReviews({ sellerId: id }); //56
    ReUse.getApiData(
      storeReviews,
      setSellerStoreReviews,
      setStoreReviewsLoader
    );
  }, [id]);

  useEffect(() => {
    const sortedData = ReUse.sortData({
      data: sellerStoreProducts?.data,
      order: sortByOrder,
      sortByProperty: "unit_price",
    });
    setSellerStoreProducts({ ...sellerStoreProducts, data: sortedData });
  }, [sortByOrder]);

  return (
    <>
      <PageHeader pagename="seller store" />
      {/* Seller Store Section */}
      <section className="seller-store-sec">
        <div className="container">
          <div className="seller-store-all">
            <div className="row">
              <div className="col-md-6">
                <div className="seller-dv">
                  <div className="seller-logo-img">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="seller-store-img"
                        src={
                          image !== null
                            ? image
                            : ReUse.defaultThumbnailImage({
                                content: "No+Image+Available",
                              })
                        }
                        onError={ReUse.onImageError}
                      />
                    </figure>
                  </div>
                  <div className="seller-names-and-icons">
                    <div className="small-heading">
                      <h5>Store Info</h5>
                      <div className="icon-number">
                        <i class="fa fa-phone" aria-hidden="true"></i>
                        <a href={`tel:${phone}`}>{phone}</a>
                      </div>
                      <div className="icon-number">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                        <a href={`mailto:${email}`}>{email}</a>
                      </div>
                      <div className="seller-stars">
                        <Rating
                          size={20}
                          allowFraction={true}
                          initialValue={ratings}
                          readonly={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="seller-about">
                  <h3>About Seller {name}</h3>
                  <p>{about_seller}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========================= */}

      {/* Seller Store Tabs Section */}
      <section className="seller-tabs-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="all-store-tab">
                <div className="all-nav-flex-stuff">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Home
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Reviews
                      </button>
                    </li>
                  </ul>
                  <div className="seller-search">
                    <input type="text" placeholder="Search" />
                    <div className="seller-search-icon">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>

                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="seller-sort-flex">
                      {/* <div className="sort-flex">
                        <div className="sort-all">
                          <p>Sort By :</p>
                          <span>
                            <div class="dropdown ">
                              <button
                                class="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton2"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Default Sorting
                              </button>
                              <ul
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuButton2"
                              >
                                <li>
                                  <a class="dropdown-item" href="#">
                                    Default Sorting
                                  </a>
                                </li>
                                <li>
                                  <a class="dropdown-item" href="#">
                                    Default Sorting
                                  </a>
                                </li>
                                <li>
                                  <a class="dropdown-item" href="#">
                                    Default Sorting
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </span>
                        </div>
                        <div className="sort-all">
                          <span>
                            <div class="dropdown ">
                              <button
                                class="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton2"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Show 15
                              </button>
                              <ul
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuButton2"
                              >
                                <li>
                                  <a class="dropdown-item" href="#">
                                    Show 15
                                  </a>
                                </li>
                                <li>
                                  <a class="dropdown-item" href="#">
                                    Show 15
                                  </a>
                                </li>
                                <li>
                                  <a class="dropdown-item" href="#">
                                    Show 15
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </span>
                          <span className="two-grid-icons">
                            <Link>
                              <i class="fa fa-th" aria-hidden="true"></i>
                            </Link>
                            <Link>
                              <i class="fa fa-th" aria-hidden="true"></i>
                            </Link>
                          </span>
                        </div>
                      </div> */}
                      <ProductsSortingFilters />
                    </div>
                    <div className="row">
                      {ReUse.mapItems(
                        storeProductsLoader,
                        sellerStoreProducts?.data?.slice(0, showProducts),
                        ProductBySeller
                      )}
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="reviews-seller-store">
                      {ReUse.mapItems(
                        storeReviewsLoader,
                        sellerStoreReviews?.data,
                        StoreReveiwCard
                      )}
                    </div>
                    {/* <AccessControl
                      allowedRoles={["customer"]}
                      isMessageDisplay={false}
                      buttonMessage="Login to send Reveiw"
                    >
                      <ReveiwForm propsData={{ sellerId: id }} />
                    </AccessControl> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =========================== */}

      {/* Seller Review Form */}
      <section className="seller-review-form-sec">
        <div className="container"></div>
      </section>
    </>
  );
};

export default SellerStoreContent;
