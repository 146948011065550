import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import { visaimg } from "../../Constant/Index";
import Footer from "../../Components/Footer";
import AccessControl from "../../Components/Auth/AccessControl";
import PaymentCard from "./PaymentCard";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import { useDispatch } from "react-redux";
import {
  clearResponseMessageAfterDelay,
  errorMessage,
  successMessage,
} from "../../redux/slices/responseMessage.slice";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import FieldError from "../../Components/ReUse/FieldError";
import CustomButton from "../../Components/ReUse/Components/Button";

const publishableKey =
  "pk_test_51I84thLEOr3DyRBMuYv80CHV113DjLWDVdKaWG0vbg4VD1dSvz3FFnCHhZaPbQY62bCXZBeEFZUcIMf35Mf68fzP007oM4TWZy";

function Paymentdetail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const getSellerAllCards = AppService.getSellerAllCards();
    ReUse.getApiData(getSellerAllCards, setApiData, setLoading);
  }, []);

  const [addCardloader, setAddCardloader] = useState(false);

  const [defaultCardloader, setDefaultCardloader] = useState(false);

  const [statusMessage, setStatusMessage] = useState("");

  const [allErrors, setAllErrors] = useState({
    cardNumber: "",
    expiryDate: "",
    cvcNumber: "",
  });
  const [fieldsData, setFieldsData] = useState({
    cardNumber: "",
    expiryDate: "",
    cvcNumber: "",
  });

  function handleChange(e) {
    const resetErrors = ReUse.resetErrors({ fields: allErrors });
    setAllErrors(resetErrors);
    const { value, name } = e.target;
    setFieldsData({
      ...fieldsData,
      [name]: value,
    });
  }

  const addCardHandler = async (token = "") => {
    try {
      setAddCardloader(true);

      const payload = {
        token: token,
      };

      const apiResp = await AppService.createSellerCard(payload);
      const { message, response } = apiResp.data;
      setAddCardloader(false);
      dispatch(successMessage(message));
      setTimeout(() => {
        dispatch(successMessage("Navigating..."));
        setTimeout(() => {
          dispatch(successMessage(""));
          navigate("/seller-dashboard");
        }, 1000);
      }, 1000);

      ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
      ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
    } catch (err) {
      setAddCardloader(false);
      dispatch(errorMessage(err?.response?.data?.message));
      dispatch(clearResponseMessageAfterDelay());
      dispatch(successMessage(""));
      ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
      ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
    }
  };

  const handlePaymentSuccess = (e) => {
    e.preventDefault();
    setAddCardloader(true);

    const { cardNumber, expiryDate, cvcNumber } = fieldsData;

    const fields = {
      cardNumber,
      expiryDate,
      cvcNumber,
    };

    const allErrors = ReUse.validateRequired({ fields: fields });

    // Check if there are any errors
    if (Object.keys(allErrors).length > 0) {
      setAllErrors(allErrors);
      setAddCardloader(false);
      return;
    }

    if (!ReUse.isValidCardNumber({ cardNumber: cardNumber })) {
      allErrors.cardNumber = "Invalid card number";
      setAllErrors(allErrors);
      setAddCardloader(false);
      return;
    }

    if (!ReUse.isValidExpiryDate({ expiryDate: expiryDate })) {
      allErrors.expiryDate =
        "Invalid expiry date. Please use the format MM/YY.";
      setAllErrors(allErrors);
      setAddCardloader(false);
      return;
    }

    if (!ReUse.isValidCVCNumber({ cvcNumber: cvcNumber })) {
      allErrors.cvcNumber = "Invalid CVC number.";
      setAllErrors(allErrors);
      setAddCardloader(false);
      return;
    }

    ReUse.stripePaymentHandler({
      fieldsData: fieldsData,
      stripePublishableKey: publishableKey,
    })
      .then(async (result) => {
        ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
        ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object

        const { isPaymentSuccess, data } = result ?? {};

        if (isPaymentSuccess) {
          await addCardHandler(data?.id);
          dispatch(successMessage("successfully generated token"));
          setAddCardloader(false);
        } else {
          dispatch(errorMessage("sorry, an error occurred"));
          dispatch(clearResponseMessageAfterDelay());
          setAddCardloader(false);
          return;
        }

        setTimeout(() => {
          dispatch(successMessage("Processing..."));
          setTimeout(() => {
            dispatch(successMessage(""));
          }, 1000);
        }, 1000);
      })
      .catch((err) => {
        setAddCardloader(false);
        dispatch(errorMessage(err?.response?.data?.message));
        dispatch(clearResponseMessageAfterDelay());
        ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
        ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
        dispatch(successMessage(""));
      });
  };

  const setDefaultCardHandler = async (e) => {
    e.preventDefault();

    try {
      setDefaultCardloader(true);
      const apiResp = await AppService.customerSignUp();
      const { message, response } = apiResp.data;

      setStatusMessage(message);
      setDefaultCardloader(false);
      setTimeout(() => {
        setStatusMessage("Navigating to Login...");
        setTimeout(() => {
          setStatusMessage("");
          navigate("/sign-in");
        }, 1000);
      }, 1000);
    } catch (err) {
      setDefaultCardloader(false);
      dispatch(errorMessage(err?.response?.data?.message));
      dispatch(clearResponseMessageAfterDelay());
      setStatusMessage("");
    }
  };


  return (
    <>
      <AccessControl allowedRoles={["seller"]}>
        <SellerHeader />
        {/* Seller payment details here */}
        <section className="seller-dashboard">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 ps-0">
                <SellerSidebar />
              </div>
              <div className="col-lg-8">
                <div className="edit-profile-card">
                  <div className="edit-heading-wrapper">
                    <h2>Payment Details</h2>
                  </div>
                  <div className="seller-edit-with-border-dv">
                    <div className="visa-img">
                      {ReUse.mapItems(loading, apiData, PaymentCard)}
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="custom-radio">
                          {/* <p>
														<input
															type="radio"
															id="test1"
															name="radio-group"
															checked
														/>
														<label for="test1">Add New Card</label>
													</p> */}
                        </div>
                      </div>
                    </div>

                    {/* Payment Page Inputs */}
                    <div className="row">
                      <div className="payment-head">
                        <h3>Add New Card</h3>
                      </div>
                      <div className="col-md-6">
                        <div className="payment-input">
                          <input
                            type="text"
                            placeholder="Credit Card Number"
                            onChange={handleChange}
                            name="cardNumber"
                            value={fieldsData?.cardNumber}
                          />
                          <FieldError fieldName={allErrors?.cardNumber} />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="payment-input">
                          <input
                            type="text"
                            placeholder="MM/YY"
                            onChange={handleChange}
                            name="expiryDate"
                            value={fieldsData?.expiryDate}
                          />
                          <FieldError fieldName={allErrors?.expiryDate} />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="payment-input">
                          <input
                            type="text"
                            placeholder="CVV"
                            onChange={handleChange}
                            name="cvcNumber"
                            value={fieldsData?.cvcNumber}
                          />
                          <FieldError fieldName={allErrors?.cvcNumber} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="payment-btn">
                          <CustomButton
                            loading={addCardloader}
                            onClickHandler={handlePaymentSuccess}
                            customClassName="btn"
                          >
                            Submit
                          </CustomButton>

                          <ResponseMessage />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Seller payment details here */}

        {/* Footer section */}
      </AccessControl>
    </>
  );
}

export default Paymentdetail;
