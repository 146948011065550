import React from "react";
import ReUse from "../../services/helpers/reUse";

const BulkVariantAddField = ({ propsData, index }) => {
  const {
    bulkVariantDataIndex,
    setBulkVariantAddFieldsData,
    bulkVariantAddFieldsData,
    bulkVariantData,
    variantMOQsHandler,
  } = propsData || {};

  function handleChange(e, index) {
    const { name, value } = e.target;
    const isNumberOrText = ReUse.isNumberOrText({ stringInput: value });

    setBulkVariantAddFieldsData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [name]: isNumberOrText ? parseInt(value, 10) : value,
      };

      // // Move the variantMOQsHandler call inside the callback of setBulkVariantAddFieldsData
      // variantMOQsHandler({
      //   index: bulkVariantDataIndex,
      //   variantData: bulkVariantData,
      //   MOQsData: newData, // Use the updated data here
      // });

      return newData;
    });
  }

  const currentData = bulkVariantAddFieldsData[index];

  const { moq, price } = currentData || {};

  return (
    <>
      <div className="col-md-6">
        <div className="mcq-dv-input mcq-before for-upper-margin-in-mcq">
          <label>Enter MOQ</label>
          <input
            type="text"
            placeholder="00"
            max="2"
            onChange={(e) => handleChange(e, index)}
            name="moq"
            value={moq}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="mcq-dv-input for-upper-margin-in-mcq">
          <label>Enter Price US $</label>
          <input
            type="text"
            placeholder="00"
            max="2"
            onChange={(e) => handleChange(e, index)}
            name="price"
            value={price}
          />
        </div>
      </div>
    </>
  );
};

export default BulkVariantAddField;
