import React from "react";
import { customerorder1, reviewimg3 } from "../../Constant/Index";
import ReUse from "../../services/helpers/reUse";

const CustomerOrderCard = (propsData) => {
	const {
		customer_id,
		customer_name,
		customer_img,
		order_id,
		order_date,
		payment_status,
	} = propsData ?? {};

	return (
		<tr>
			<td>
				<div className="customer-order-row">
					<img
						className="img-fluid for-extra-border-raduis"
						alt="customer-img"
						src={
							customer_img && customer_img !== null
								? customer_img
								: ReUse.defaultThumbnailImage({
										content: "No+Image+Available",
								  })
						}
						onError={ReUse.onImageError}
					/>
					<p>{customer_name}</p>
				</div>
			</td>
			<td>
				<div className="customer-order-row">
					<img
						className="img-fluid for-slight-border-raduis"
						alt="customer-img"
						src={customerorder1}
					/>
					<p>{customer_name}</p>
				</div>
			</td>
			<td>
				<p className="customer-para">
					{ReUse.dateFormat({ date: order_date })}
				</p>
			</td>
			<td>
				<h5 className="customer-order-paid">
					{payment_status ? "paid" : "unpaid"}
					<span>
						<i class="fa fa-check-circle" aria-hidden="true"></i>
					</span>
				</h5>
			</td>
			<td>
				<h4 className="customer-order-price">$590.00</h4>
			</td>
			<td>
				<button data-bs-toggle="modal" data-bs-target="#exampleModal">
					Order Details
				</button>
			</td>
		</tr>
	);
};

export default CustomerOrderCard;
