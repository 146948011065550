import React from "react";
import { recipedetail } from "../../Constant/Index";
import ReUse from "../../services/helpers/reUse";
import PageHeader from "../Pageheader";

const RecipedetailContent = ({ data }) => {
	const { title, creation_date, detail_description, views } = data ?? {};

	return (
		<>
			<PageHeader pagename="Recipes" />
			{/* Recipe Detail-section */}
			<section className="recipe-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="recipe-detail-all">
								<div className="recipe-detail-img">
									<figure>
										<img
											className="img-fluid"
											alt="recipe-detail"
											src={recipedetail}
										/>
									</figure>
								</div>

								<div className="recipe-detail-para-all">
									<p>{title}</p>
								</div>

								<div className="df-heading">
									<h3>
										<span>df</span> Diallo Faraji
									</h3>
									<div className="for-views for-padding-space">
										<h5>{views} Views </h5>
										<h5>{ReUse.dateFormat({ date: creation_date })}</h5>
									</div>
								</div>

								<div className="recipe-all-text">
									<h5>Recipes</h5>
									{ReUse.dangerouslySetInnerHTML({
										content: detail_description,
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* =========================================== */}
		</>
	);
};

export default RecipedetailContent;
