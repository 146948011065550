import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import { Link } from "react-router-dom";
import Careercompo from "../Careercompo";

const CareerDetailPositionContent = ({ position }) => {
  const [similarJobs, setSimilarJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let getCareers;
    if (position) {
      getCareers = AppService.getCareers({ role: position });
    }

    ReUse.getApiData(getCareers, setSimilarJobs, setLoading);
  }, [position]);

  return (
    <section className="open-job-sec">
      <div className="container">
        <div className="row">
          {ReUse.mapItems(loading, similarJobs, Careercompo)}
        </div>
        <div className="caree-view-more-btn">
          <Link>view more</Link>
        </div>
      </div>
    </section>
  );
};

export default CareerDetailPositionContent;
