import React from 'react'
import { useSelector } from 'react-redux';

const ResponseMessage = () => {
    const response = useSelector(
        (state) => state.responseMessage
    );

    const { responseLoading, responseMessage, error } = response ?? {};

    

    return (
        <>
            {responseLoading ? (
                <p className={`response_${error ? "error" : "success"}`}>{error !== "" ? error : responseMessage}</p>
            ) : null}
        </>
    );
};

export default ResponseMessage