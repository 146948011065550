import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReUse from "../services/helpers/reUse";
import { useDispatch } from "react-redux";

const MembershipCard = (propsData) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const {
		currency,
		id,
		currency_id,
		title,
		slug,
		stripe_product_id,
		stripe_price_id,
		interval,
		description,
		package_description,
		per_item_charge,
		price,
		per_item_price,
		item_selling_limit,
		created_at,
	} = propsData ?? {};

	const handleNavigateClick = () => {
		navigate("/professianal-plan", {
			state: {
				packageId: id,
				packageTitle: title,
				packageCost: price,
				currency: currency?.name,
				description,
				longDescription: package_description,
			},
		});
	};

	return (
		<>
			<div className="seller-center-all-membership-plan">
				<div className="seller-professional-plan">
					<div className="seller-inner-profile-head">
						<h3>{title}</h3>
						<div className="seller-head-profile">
							<h5>limited sales</h5>
							<p>{description}</p>
						</div>
					</div>
					<div className="seller-who">
						<h5>What you will get</h5>
						<div className="seller-who-ul">
							{ReUse.dangerouslySetInnerHTML({
								content: package_description,
							})}
						</div>
					</div>
					<div className="seller-buy-now">
						<a>
							<button onClick={handleNavigateClick}>Buy Now</button>
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default MembershipCard;
