import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../assets/css/professianalplan.css";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import AppService from "../../services/appServices";

import StripeCheckout from "react-stripe-checkout";
import ReUse from "../../services/helpers/reUse";
import Errors from "../../Components/ReUse/Errors";
import {
	ResponseMessageActions,
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import CustomButton from "../../Components/ReUse/Components/Button";

const publishableKey =
	"pk_test_51I84thLEOr3DyRBMuYv80CHV113DjLWDVdKaWG0vbg4VD1dSvz3FFnCHhZaPbQY62bCXZBeEFZUcIMf35Mf68fzP007oM4TWZy";

function Professionalplan() {
	const [paymentSuccessful, setPaymentSuccessful] = useState(false);
	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const [isPasswordVisible, setIsPasswordVisible] = useState(true);
	const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(
		true,
	);

	const {
		packageId,
		packageTitle,
		packageCost,
		currency,
		description,
		longDescription,
	} = location?.state ?? {};

	const [allErrors, setAllErrors] = useState({
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		store_name: "",
		password: "",
		password_confirmation: "",
		address: "",
		country_id: "",
		state: "",
	});
	const [fieldsData, setFieldsData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		store_name: "",
		password: "",
		password_confirmation: "",
		address: "",
		country_id: "",
		state: "",
	});

	function handleChange(e) {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		const { value, name } = e.target;
		setFieldsData({
			...fieldsData,
			[name]: value,
		});
	}

	const passwordVisibilityHandler = (e) => {
		e.preventDefault();
		setIsPasswordVisible(!isPasswordVisible);
	};

	const confirmPasswordVisibilityHandler = (e) => {
		e.preventDefault();
		setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
	};

	const PasswordVisibility = () => {
		if (isPasswordVisible) {
			return (
				<i
					class="fa fa-eye-slash"
					aria-hidden="true"
					onClick={passwordVisibilityHandler}
				></i>
			);
		}
		return (
			<i
				class="fa fa-eye"
				aria-hidden="true"
				onClick={passwordVisibilityHandler}
			></i>
		);
	};

	const ConfirmPasswordVisibility = () => {
		if (isConfirmPasswordVisible) {
			return (
				<i
					class="fa fa-eye-slash"
					aria-hidden="true"
					onClick={confirmPasswordVisibilityHandler}
				></i>
			);
		}
		return (
			<i
				class="fa fa-eye"
				aria-hidden="true"
				onClick={confirmPasswordVisibilityHandler}
			></i>
		);
	};

	const onSubmitHandler = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);

			const {
				first_name,
				last_name,
				email,
				phone,
				store_name,
				password,
				password_confirmation,
			} = fieldsData;

			const fields = {
				first_name,
				last_name,
				email,
				phone,
				store_name,
				password,
				password_confirmation,
			};

			const allErrors = ReUse.validateRequired({ fields: fields });

			// Check if there are any errors
			if (Object.keys(allErrors).length > 0) {
				setAllErrors(allErrors);
				setLoading(false);
				ReUse.scrollToTop();
				return;
			}

			setLoading(false);
			dispatch(successMessage("Details successfully created"));
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/payment", {
						state: {
							packageId,
							packageTitle,
							packageCost,
							currency,
							first_name,
							last_name,
							email,
							phone,
							store_name,
							password,
							password_confirmation,
							address: "New York, USA",
							country_id: 166,
							state: "New York",
							description,
						},
					});
				}, 1000);
			}, 1000);

			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
			dispatch(successMessage(""));
		}
	};

	const {
		first_name,
		last_name,
		email,
		phone,
		store_name,
		password,
		password_confirmation,
	} = fieldsData ?? {};

	return (
		<>
			<PageHeader pagename="professional plan" />
			{/* Professianol Section */}
			<Errors allErrors={allErrors} />
			<section className="professianal-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="professional-plan-form">
								<div className="professianol-plan-dv">
									<p>
										Lorem Ipsum is simply dummy text of the printing and
										typesetting industry. Lorem Ipsum has been the industry's
										standard dummy text ever since the 1500s,
									</p>
								</div>
								<form>
									<div className="row">
										<div className="col-md-6">
											<div className="professional-input-form">
												<input
													type="text"
													placeholder="First Name"
													onChange={handleChange}
													name="first_name"
													value={first_name}
												/>
												{/* <p style={{ color: "red" }}>{allErrors?.first_name}</p> */}
											</div>
										</div>
										<div className="col-md-6">
											<div className="professional-input-form">
												<input
													type="text"
													placeholder="Last Name"
													onChange={handleChange}
													name="last_name"
													value={last_name}
												/>
												{/* <p style={{ color: "red" }}>{allErrors?.last_name}</p> */}
											</div>
										</div>
										<div className="col-md-6">
											<div className="professional-input-form">
												<input
													type="text"
													placeholder="Email Address"
													onChange={handleChange}
													name="email"
													value={email}
												/>
												{/* <p style={{ color: "red" }}>{allErrors?.email}</p> */}
											</div>
										</div>
										<div className="col-md-6">
											<div className="professional-input-form">
												<input
													type="text"
													placeholder="Contact Number"
													onChange={handleChange}
													name="phone"
													value={phone}
												/>
												{/* <p style={{ color: "red" }}>{allErrors?.phone}</p> */}
											</div>
										</div>
										<div className="col-md-12">
											<div className="professional-input-form">
												<input
													type="text"
													placeholder="Store Name"
													onChange={handleChange}
													name="store_name"
													value={store_name}
												/>
												{/* <p style={{ color: "red" }}>{allErrors?.store_name}</p> */}
											</div>
										</div>
										<div className="col-md-6">
											<div className="professional-input-form">
												<input
													type={isPasswordVisible ? "password" : "text"}
													placeholder="Password"
													onChange={handleChange}
													name="password"
													value={password}
												/>
												{/* <p style={{ color: "red" }}>{allErrors?.password}</p> */}
												<div className="icon-for-show-password">
													<PasswordVisibility />
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="professional-input-form">
												<input
													type={isConfirmPasswordVisible ? "password" : "text"}
													placeholder="Confirm Password"
													onChange={handleChange}
													name="password_confirmation"
													value={password_confirmation}
												/>
												{/* <p style={{ color: "red" }}>
													{allErrors?.password_confirmation}
												</p> */}
												<div className="icon-for-show-password">
													<ConfirmPasswordVisibility />
												</div>
											</div>
										</div>
										<div className="col-md-12">
											<div className="professional-plan-input-form-btn">
												<a>
													<CustomButton
														loading={loading}
														onClickHandler={onSubmitHandler}
														customClassName="custom-btn"
													>
														Submit
													</CustomButton>
												</a>
												<ResponseMessage />
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ======================== */}
		</>
	);
}

export default Professionalplan;
