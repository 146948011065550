import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { wishlist1, wishlist2, wishlist3 } from "../../Constant/Index";
import "../../assets/css/checkout.css";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import Footer from "../../Components/Footer";
import { useDispatch, useSelector } from "react-redux";
import CheckoutProduct from "./CheckoutProduct";
import ReUse from "../../services/helpers/reUse";
import AppService from "../../services/appServices";
import { toast } from "react-toastify";
import { AuthActions } from "../../redux/slices/auth.slice";
import Errors from "../../Components/ReUse/Errors";
import CardUI from "./CardUI";
import AddressCard from "../Profile/AddressCard";
import {
	ResponseMessageActions,
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import AccessControl from "../../Components/Auth/AccessControl";
import CustomButton from "../../Components/ReUse/Components/Button";
import { CartActions } from "../../redux/slices/cart.slice";

const publishableKey =
	"pk_test_51I84thLEOr3DyRBMuYv80CHV113DjLWDVdKaWG0vbg4VD1dSvz3FFnCHhZaPbQY62bCXZBeEFZUcIMf35Mf68fzP007oM4TWZy";

function Checkout() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const cart = useSelector((state) => state.persistedReducer.cart);
	const { cartData, error, loading } = cart ?? {};

	const [loadingState, setLoadingState] = useState(false);
	const [paymentLoader, setPaymentLoader] = useState(false);
	const [stripeToken, setStripeToken] = useState("");
	const [isShowBtn, setIsShowBtn] = useState(false);
	const [selectedAddress, setSelectedAddress] = useState(null);
	const [addresses, setAddresses] = useState([]);
	const [addressesLoader, setAddressesLoader] = useState(false);
	const [defaultAddressId, setDefaultAddressId] = useState(null);
	const [isTermsAccepted, setIsTermsAccepted] = useState(false);
	const [statusMessage, setStatusMessage] = useState("");

	const [allPaymnetErrors, setAllPaymnetErrors] = useState({
		cardNumber: "",
		expiryDate: "",
		cvcNumber: "",
	});
	const [fieldsData, setFieldsData] = useState({
		cardNumber: "",
		expiryDate: "",
		cvcNumber: "",
	});

	const handlePaymentSuccess = (e) => {
		e.preventDefault();
		setPaymentLoader(true);
		

		const { cardNumber, expiryDate, cvcNumber } = fieldsData;

		const fields = {
			cardNumber,
			expiryDate,
			cvcNumber,
		};

		const validateAllErrors = ReUse.validateRequired({ fields: fields });

		// Check if there are any errors
		if (Object.keys(validateAllErrors).length > 0) {
			setAllPaymnetErrors(validateAllErrors);
			setPaymentLoader(false);
			ReUse.scrollToTop();
			return;
		}

		if (!ReUse.isValidCardNumber({ cardNumber: cardNumber })) {
			const updatedPaymentErrors = { ...allPaymnetErrors };
			updatedPaymentErrors.cardNumber = "Invalid card number";
			setAllPaymnetErrors(updatedPaymentErrors);
			setPaymentLoader(false);
			ReUse.scrollToTop();
			return;
		}

		if (!ReUse.isValidExpiryDate({ expiryDate: expiryDate })) {
			const updatedPaymentErrors = { ...allPaymnetErrors };
			updatedPaymentErrors.expiryDate =
				"Invalid expiry date. Please use the format MM/YY.";
			setAllPaymnetErrors(updatedPaymentErrors);
			setPaymentLoader(false);
			ReUse.scrollToTop();
			return;
		}

		if (!ReUse.isValidCVCNumber({ cvcNumber: cvcNumber })) {
			const updatedPaymentErrors = { ...allPaymnetErrors };
			updatedPaymentErrors.cvcNumber =
				"CVC number must be exactly 3 digits long";
			setAllPaymnetErrors(updatedPaymentErrors);
			setPaymentLoader(false);
			ReUse.scrollToTop();
			return;
		}

		if (!isTermsAccepted) {
			dispatch(errorMessage("Please accept terms and conditions"));
			dispatch(clearResponseMessageAfterDelay());
			setPaymentLoader(false);
			return;
		}

		ReUse.stripePaymentHandler({
			fieldsData: fieldsData,
			stripePublishableKey: publishableKey,
		})
			.then(async (stripeResponse) => {
				ReUse.resetErrors({ fields: allPaymnetErrors }); // allErrors is an object
				ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
				const { isPaymentSuccess, data } = stripeResponse ?? {};
				await onSubmitHandler(data?.id);
				setStripeToken(data?.id);
				setPaymentLoader(false);
				setIsShowBtn(true);
			})
			.catch((err) => {
				console.error(err);
				setPaymentLoader(false);
				setIsShowBtn(false);
				dispatch(errorMessage(err?.response?.data?.message));
				ReUse.resetErrors({ fields: allPaymnetErrors }); // allErrors is an object
				dispatch(clearResponseMessageAfterDelay());
				ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
			});
	};

	const onSubmitHandler = async (token = "") => {
		try {
			setLoadingState(true);

			const extractedCartData = cartData?.map(
				({
					product_id,
					calculatedPrice,
					currentStock,
					variant,
					variations,
					discount,
				}) => {
					return {
						id: product_id,
						price: calculatedPrice,
						quantity: currentStock,
						tax: 0,
						discount: discount,
						variant: variant,
						variations: variations,
					};
				},
			);

			const payload = {
				discount: 0,
				cart: extractedCartData,
				address_id: defaultAddressId,
				token: token,
			};

			const apiResp = await AppService.customerPlaceOrder(payload);
			const { message, response } = apiResp.data;

			dispatch(successMessage(message));
			dispatch(CartActions.Clear_Cart());
			setLoadingState(false);
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/my-orders");
				}, 1000);
			}, 1000);
		} catch (err) {
			setLoadingState(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			dispatch(successMessage(""));
		}
	};

	const handlePaymnetChange = async (e) => {
		const resetErrors = ReUse.resetErrors({ fields: allPaymnetErrors });
		setAllPaymnetErrors(resetErrors);
		await ReUse.getInputFieldsData(e, setFieldsData, fieldsData);
	};

	const { cardNumber, expiryDate, cvcNumber } = fieldsData ?? {};

	const selectAddressHandler = (e, index) => {
		e.preventDefault();
		setSelectedAddress(index);
	};

	const calculateTotalShipping = ReUse.calculateTotalShipping({
		data: cartData,
	});

	useEffect(() => {
		const customerGetAllAddresses = AppService.customerGetAllAddresses();
		ReUse.getApiData(customerGetAllAddresses, setAddresses, setAddressesLoader);
	}, []);

	useEffect(() => {
		const defaultAddress = addresses?.find(
			(address) => address?.default_status === 1,
		);
		const defaultAddressId = defaultAddress?.id || null;

		setDefaultAddressId(defaultAddressId);
	}, [addresses]);

	
	return (
		<>
			<PageHeader pagename="Check Out" />
			<AccessControl allowedRoles={["customer"]} isMessageDisplay={false}>
				<Errors allErrors={allPaymnetErrors} />
				<section className="check-out-sec">
					<div className="container">
						<div className="row">
							<div className="col-md-7">
								<div className="all-checkout">
									<div className="billin-dv">
										<h4>billing details </h4>
									</div>
									<div className="row">
										<p>
											Your default address has been added. If you wish to change
											it, please navigate to your profile and set another
											address as the default.{" "}
										</p>

										<h5>Add Payment Details </h5>
										<div className="row">
											<div className="col-md-12">
												<div className="payment-all-input">
													<input
														type="text"
														placeholder="Card Number"
														onChange={handlePaymnetChange}
														name="cardNumber"
														value={cardNumber}
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="payment-all-input">
													<input
														type="text"
														placeholder="Expiry"
														onChange={handlePaymnetChange}
														name="expiryDate"
														value={expiryDate}
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="payment-all-input">
													<input
														type="text"
														placeholder="CVC"
														onChange={handlePaymnetChange}
														name="cvcNumber"
														value={cvcNumber}
													/>
												</div>
											</div>
											<div className="col-md-12">
												<input
													type="checkbox"
													id="bottom-check"
													onChange={(e) => setIsTermsAccepted(e.target.checked)}
												/>
												<label for="bottom-check">
													Proident, sunt in culpa qui officia deserunt mollit
													anim id est laborum.
												</label>
											</div>
										</div>

										<div className="col-md-12">
											<div className="checkout-btn">
												<CustomButton
													loading={paymentLoader}
													onClickHandler={handlePaymentSuccess}
												>
													Pay Now
												</CustomButton>
												<ResponseMessage />
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-md-5">
								<div className="summary-dv">
									<h4>product summary</h4>
								</div>

								<div className="product-detail-all check-out-detail">
									<div className="product-head">
										<h5>PRODUCT DETAILS</h5>
									</div>
									<div className="for-over-flow">
										{ReUse.mapItems(loading, cartData, CheckoutProduct)}
									</div>

									<div className="pament-details">
										<h5>PAYMENT DETAILS</h5>
									</div>

									<div className="all-total">
										<div className="total">
											<p className="for-total-css">Total :</p>
											<p className="for-total-green">
												${ReUse.calculateTotalSubtotalPrice({ data: cartData })}
											</p>
										</div>
										<div className="total">
											<p className="for-total-css">Shipping Charges : </p>
											<p className="for-total-green">
												${calculateTotalShipping}
											</p>
										</div>
										<div className="total">
											<p className="for-total-css">Total Cost : </p>
											<p className="for-total-green">
												$
												{ReUse.calculateTotalCost({
													data: cartData,
													shippingCost: calculateTotalShipping,
												})}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* =============================================== */}
			</AccessControl>
		</>
	);
}

export default Checkout;
