import React from "react";
import { Link } from "react-router-dom";

import ReUse from "./../services/helpers/reUse";

const BlogCard = (propsData) => {
	const {
		id,
		slug,
		title,
		created_at,
		content,
		blog_category_id,
		image,
		imageurl,
	} = propsData;

	return (
		<>
			<div className="col-md-4">
				<div className="blog-compo-all-dv">
					<div className="blog-compo-img">
						<figure>
							<img
								className="img-fluid"
								alt="blog-main-img"
								src={
									image !== null
										? imageurl
										: ReUse.defaultThumbnailImage({
												content: "No+Image+Available",
										  })
								}
								onError={ReUse.onImageError}
							/>
						</figure>
					</div>
					<div className="blog-compo-text-date">
						<h5>Category : Lorem Ipsum is simply</h5>
						<h5>
							<span>{created_at && created_at}</span>
						</h5>
					</div>
					<div className="only-blog-text">
						<h4>{title && title} </h4>
						<p>{ReUse.removeHTMLTags({ unStripped: content })}</p>
					</div>
					<div className="blog-main-btn">
						<Link to={`/blog-detail/${slug}`} state={slug}>
							read more
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default BlogCard;
