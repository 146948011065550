import React from "react";
import PageHeader from "../Pageheader";
import ReUse from "../../services/helpers/reUse";

const BlogDetailContent = ({ data }) => {
	const { title, image, imageurl, content } = data?.blogdetail ?? {};

	return (
		<>
			<PageHeader pagename="Blogs" />
			{/* Blog Detail Section */}
			<section className="blog-detail-sec">
				<div className="container">
					<div className="blog-detail-img">
						<h4>{title}</h4>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="blog-detail-all">
								<div className="blog-detail-img">
									<figure>
										<img
											className="img-fluid"
											alt="blog-detail-img"
											src={
												image !== null
													? imageurl
													: ReUse.defaultThumbnailImage({
															content: "No+Image+Available",
													  })
											}
											onError={ReUse.onImageError}
										/>
									</figure>
								</div>
								<div className="blog-detail-text">
									{/* <div
                                   
                                    ></div> */}
									{ReUse.dangerouslySetInnerHTML({
										content: content,
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ===================== */}
		</>
	);
};

export default BlogDetailContent;
