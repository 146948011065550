import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../assets/css/product.css";
import {
	organic1,
	organic2,
	organic3,
	product1,
	slider1,
	slider4,
} from "../../Constant/Index";
import Blogsmain from "../../Components/Blogsmain";
import Newsletter from "../../Components/Newsletter";
import Sliderforproduct from "../../Components/Sliderforproduct";
import ShopProduct from "./ShopProduct";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import CatsSubcats from "./CatsSubcats";
import ShowNoOfProducts from "./ShowNoOfProducts";
import ShowProductsFilter from "./ShowProductsFilter";
import SortByProducts from "./SortByProducts";
import ReactPaginate from "react-paginate";
import Pagination from "./Pagination";
function Fashionproduct() {
	const [apiData, setApiData] = useState([]);

	const [cats, setCats] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [sortValue, setSortValue] = useState(1);
	const [showProducts, setShowProducts] = useState(12);

	const [showNoOfProducts, setShowNoOfProducts] = useState([12, 18, 24, 1]);
	const [pageCount, setPageCount] = useState();
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		const getAllProducts = AppService.getAllProducts({
			name: "",
			categoryId: null,
			subCategoryId: null,
			minPrice: null,
			maxPrice: null,
			currentPage: currentPage,
		});

		const getAllCats = AppService.getAllCats();

		ReUse.getApiData(getAllProducts, setApiData, setLoading);

		ReUse.getApiData(getAllCats, setCats, setLoading2);
	}, [sortValue, showProducts]);

	const sortLowToHigh = (e, value) => {
		e.preventDefault();
		setSortValue(value);
		const result = ReUse.sortData({
			data: apiData?.data,
			order: sortValue,
			sortByProperty: "unit_price",
		});
		setApiData(result);
	};

	const sortHighToLow = (e, value) => {
		e.preventDefault();
		setSortValue(value);
		const result = ReUse.sortData({
			data: apiData?.data,
			order: sortValue,
			sortByProperty: "unit_price",
		});
		setApiData(result);
	};

	const productShowHandler = (e, value) => {
		e.preventDefault();
		setShowProducts(value);
	};

	const paginationProps = {
		apiData: apiData,
		setPageCount: setPageCount,
		pageCount: pageCount,
		setCurrentPage: setCurrentPage,
		currentPage: currentPage,
	};

	

	return (
		<>
			{/* Product Section */}
			<section className="fashion-product-sec-banner">
				<div className="container">
					<div className="col-md-12"></div>
					<Sliderforproduct />
				</div>
			</section>

			{/* ============================ */}

			{/* Product All Seciton */}
			<section className="product-sec">
				<div className="container">
					<div className="row sec-first-row">
						<div className="col-md-3">
							<div className="product-filter">
								<p>Filter :</p>
								<p>
									<span>Clean All</span>
								</p>
							</div>
						</div>
						<div className="col-md-9">
							<div className="sort-flex">
								<SortByProducts
									sortLowToHigh={sortLowToHigh}
									sortHighToLow={sortHighToLow}
								/>
								<ShowProductsFilter
									showNoOfProducts={showNoOfProducts}
									productShowHandler={productShowHandler}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3">
							<div className="all-product-accordian">
								<div class="accordion" id="accordionExample">
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingOne">
											<button
												class="accordion-button"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseOne"
												aria-expanded="true"
												aria-controls="collapseOne"
											>
												All Categories
											</button>
										</h2>
										<div
											id="collapseOne"
											class="accordion-collapse collapse show"
											aria-labelledby="headingOne"
											data-bs-parent="#accordionExample"
										>
											<div class="accordion-body">
												<div className="inner-accordian">
													{/* ============== */}

													{ReUse.mapItems(loading, cats, CatsSubcats)}

													{/* ==================== */}
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingTwo">
											<button
												class="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseTwo"
												aria-expanded="false"
												aria-controls="collapseTwo"
											>
												Price
											</button>
										</h2>
										<div
											id="collapseTwo"
											class="accordion-collapse collapse"
											aria-labelledby="headingTwo"
											data-bs-parent="#accordionExample"
										>
											<div class="accordion-body">
												<div className="all-price-li">
													<ul>
														<li>$0.00 - $100.00</li>
														<li>$100.00 - $200.00</li>
														<li>$200.00 - $300.00</li>
														<li>$300.00 - $500.00</li>
														<li>$500.00+</li>
													</ul>
												</div>
												<div className="price-input">
													<div className="input-1">
														<input type="number" placeholder="$Min" />
													</div>
													<div className="input-1">
														<input type="number" placeholder="$Max" />
													</div>
													<div className="input-1-btn">
														<button>Go</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingThree">
											<button
												class="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseThree"
												aria-expanded="false"
												aria-controls="collapseThree"
											>
												Weight
											</button>
										</h2>
										<div
											id="collapseThree"
											class="accordion-collapse collapse"
											aria-labelledby="headingThree"
											data-bs-parent="#accordionExample"
										>
											<div class="accordion-body">
												<div className="check-box-sizes">
													<div className="check-and">
														<div class="form-group">
															<input type="checkbox" id="html" />
															<label for="html">1kg</label>
														</div>
													</div>
													<div className="check-and">
														<div class="form-group">
															<input type="checkbox" id="Large" />
															<label for="Large">2kg</label>
														</div>
													</div>
													<div className="check-and">
														<div class="form-group">
															<input type="checkbox" id="Medium" />
															<label for="Medium">3kg</label>
														</div>
													</div>
													<div className="check-and">
														<div class="form-group">
															<input type="checkbox" id="Small" />
															<label for="Small">4kg</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingfifth">
											<button
												class="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapsefifth"
												aria-expanded="false"
												aria-controls="collapsefifth"
											>
												Colors
											</button>
										</h2>
										<div
											id="collapsefifth"
											class="accordion-collapse collapse"
											aria-labelledby="headingfifth"
											data-bs-parent="#accordionExample"
										>
											<div class="accordion-body">
												<div className="check-box-sizes">
													<div className="check-and">
														<div class="form-group">
															<input type="checkbox" id="Black" />
															<label for="Black">Black</label>
														</div>
													</div>
													<div className="check-and">
														<div class="form-group">
															<input type="checkbox" id="Blue" />
															<label for="Blue">Large</label>
														</div>
													</div>
													<div className="check-and">
														<div class="form-group">
															<input type="checkbox" id="Brown" />
															<label for="Brown">Brown</label>
														</div>
													</div>
													<div className="check-and">
														<div class="form-group">
															<input type="checkbox" id="Green" />
															<label for="Green">Green</label>
														</div>
													</div>
													<div className="check-and">
														<div class="form-group">
															<input type="checkbox" id="Grey" />
															<label for="Grey">Grey</label>
														</div>
													</div>

													<div className="check-and">
														<div class="form-group">
															<input
																type="checkbox"
																id="Orange
"
															/>
															<label
																for="Orange
"
															>
																Orange
															</label>
														</div>
													</div>
													<div className="check-and">
														<div class="form-group">
															<input type="checkbox" id="Yellow" />
															<label for="Yellow">Yellow</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-9">
							<div className="row all-class-for-padding">
								{ReUse.mapItems(
									loading2,
									apiData?.data?.slice(0, showProducts),
									ShopProduct,
								)}
							</div>
						</div>
					</div>

					<Pagination paginationProps={paginationProps} />
				</div>
			</section>
			{/* ============================== */}
			{/* Blog component */}
			<Blogsmain />
			{/* ============ */}

			{/* News Letter Componenet */}
			<Newsletter />
			{/* ================ */}
		</>
	);
}

export default Fashionproduct;
