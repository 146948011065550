import React from "react";
import { wishlist1 } from "../../Constant/Index";
import ReUse from "../../services/helpers/reUse";

const CheckoutProduct = ({ currentIndex, ...otherProps }) => {
	const {
		brand_id,
		brand_name,
		category_id,
		category_name,
		current_stock,
		description,
		discount,
		discount_type,
		images,
		product_name,
		thumbnail,
		unit_price,
		product_id,
		qty,
	} = otherProps ?? {};
	return (
		<>
			<div className="pro-first-dv">
				<div className="pro-img">
					<img
						className="img-fluid"
						src={
							thumbnail && thumbnail !== null
								? thumbnail
								: ReUse.defaultThumbnailImage({
										content: "No+Image+Available",
								  })
						}
						alt={product_name}
						onError={ReUse.onImageError}
					/>
					<div className="pro-text">
						<p>{product_name}</p>
						<p className="for-font-change">{`(${qty})x`}</p>
					</div>
				</div>

				<div className="pro-price">
					<p>${unit_price}</p>
				</div>
			</div>
		</>
	);
};

export default CheckoutProduct;
