import React, { useState } from "react";
import "../../assets/css/contact.css";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReUse from "../../services/helpers/reUse";
import AppService from "../../services/appServices";
import {
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import CustomButton from "../../Components/ReUse/Components/Button";
import FieldError from "../../Components/ReUse/FieldError";

function Contact() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [statusMessage, setStatusMessage] = useState("");

	const [allErrors, setAllErrors] = useState({
		first_name: "",
		last_name: "",
		email: "",
		mobile_number: "",
		message: "",
	});
	const [fieldsData, setFieldsData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		mobile_number: "",
		message: "",
	});

	function handleChange(e) {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		const { value, name } = e.target;
		setFieldsData({
			...fieldsData,
			[name]: value,
		});
	}

	const onSubmitHandler = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const {
				first_name,
				last_name,
				email,
				mobile_number,
				message,
			} = fieldsData;

			const fields = {
				first_name,
				last_name,
				email,
				mobile_number,
				message,
			};
			const allErrors = ReUse.validateRequired({ fields: fields });

			// Check if there are any errors
			if (Object.keys(allErrors).length > 0) {
				setAllErrors(allErrors);
				setLoading(false);
				ReUse.scrollToTop();
				return;
			}

			const payLoad = {
				first_name,
				last_name,
				email,
				mobile_number,
				message,
			};

			const apiResp = await AppService.contactUs(payLoad);
			setLoading(false);
			dispatch(successMessage(apiResp?.data?.message));
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/");
				}, 1000);
			}, 1000);

			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
			dispatch(successMessage(""));
		}
	};

	return (
		<>
			<PageHeader pagename="Contact Us" />
			<section className="contact-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="contact-form">
								<form>
									<div className="row">
										<div className="contact-form-para">
											<p>Let Us Answer All Your Questions </p>
										</div>
										<div className="col-md-6">
											<div className="contact-form-input">
												<input
													type="text"
													placeholder="First Name"
													onChange={handleChange}
													name="first_name"
													value={fieldsData?.first_name}
												/>
												<FieldError fieldName={allErrors?.first_name} />
											</div>
										</div>
										<div className="col-md-6">
											<div className="contact-form-input">
												<input
													type="text"
													placeholder="Last Name"
													onChange={handleChange}
													name="last_name"
													value={fieldsData?.last_name}
												/>
												<FieldError fieldName={allErrors?.last_name} />
											</div>
										</div>
										<div className="col-md-6">
											<div className="contact-form-input">
												<input
													type="email"
													placeholder="Email Address"
													onChange={handleChange}
													name="email"
													value={fieldsData?.email}
												/>

												<FieldError fieldName={allErrors?.email} />
											</div>
										</div>
										<div className="col-md-6">
											<div className="contact-form-input">
												<input
													type="text"
													placeholder="Contact Number"
													onChange={handleChange}
													name="mobile_number"
													value={fieldsData?.mobile_number}
												/>
												<FieldError fieldName={allErrors?.mobile_number} />
											</div>
										</div>
										<div className="col-md-12">
											<div className="contact-form-input">
												<textarea
													class="form-control"
													id="exampleFormControlTextarea1"
													rows="3"
													placeholder="Message"
													onChange={handleChange}
													name="message"
													value={fieldsData?.message}
												></textarea>
												<FieldError fieldName={allErrors?.message} />
											</div>
										</div>
										<div className="col-md-12">
											<div className="contact-btn">
												<CustomButton
													loading={loading}
													onClickHandler={onSubmitHandler}
													customClassName="custom-btn"
												>
													Submit
												</CustomButton>

												<ResponseMessage />
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>

					<div className="contact-icon-text-all">
						<div className="row">
							<div className="col-md-4">
								<div className="contact-icon-text contact-for-right">
									<div className="contact-icon">
										<i class="fa fa-phone" aria-hidden="true"></i>
									</div>
									<div className="contact-text">
										<a href="tel:1-234-5678-901">1-234-5678-901</a>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="contact-icon-text contact-for-left">
									<div className="contact-icon">
										<i class="fa fa-map-marker" aria-hidden="true"></i>
									</div>
									<div className="contact-text">
										<p>
											7468 Mrakus St <br /> Gotham, MI 54654
										</p>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="contact-icon-text">
									<div className="contact-icon">
										<i class="fa fa-envelope" aria-hidden="true"></i>
									</div>
									<div className="contact-text">
										<a href="mailto:Info@sharaeagle.com">Info@sharaeagle.com</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ====================== */}
		</>
	);
}

export default Contact;
