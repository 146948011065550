import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ProductsFilterActions } from "../../redux/slices/productsFilter.slice";
import ReUse from "../../services/helpers/reUse";

const ProductsSortingFilters = () => {
	const dispatch = useDispatch();

	const sortLowToHighandler = (e, order) => {
		e.preventDefault();
		try {
			dispatch(
				ProductsFilterActions.setSortOrder({
					sortOrder: parseInt(order),
				}),
			);
		} catch (err) {

		}
	};

	const sortHighToLowHandler = (e, order) => {
		e.preventDefault();
		try {
			dispatch(
				ProductsFilterActions.setSortOrder({
					sortOrder: parseInt(order),
				}),
			);
		} catch (err) {
			
		}
	};

	const showProductsHandler = (e, productsToShow) => {
		e.preventDefault();
		try {
			dispatch(
				ProductsFilterActions.setShowProducts({
					noOfProducts: parseInt(productsToShow),
				}),
			);
		} catch (err) {
			
		}
	};

	const showGridHandler = (e, colValue) => {
		e.preventDefault();
		try {
			dispatch(
				ProductsFilterActions.setGridCol({
					eachCol: parseInt(colValue),
				}),
			);
		} catch (err) {
			
		}
	};

	const productsFilterData = useSelector((state) => state.productsfilters);

	const { showProducts, sortByOrder } = productsFilterData ?? {};

	const generateId = ReUse.makeId();

	const ShowNoOfProductsComponent = () => {
		return [12, 18, 24, 1].map((item, index) => {
			return (
				<li key={item} onClick={(e) => showProductsHandler(e, item)}>
					<a class="dropdown-item" href="#">
						Show {item}
					</a>
				</li>
			);
		});
	};

	return (
		<>
			<div className="row sec-first-row">
				<div className="col-md-3">
					{/* <div className="product-filter">
						<p>Filter :</p>
						<p>
							<span>Clean All</span>
						</p>
					</div> */}
				</div>
				<div className="col-md-12">
					<div className="sort-flex">
						<div className="sort-all">
							<p>Sort By :</p>
							<span>
								<div class="dropdown ">
									<button
										class="btn btn-secondary dropdown-toggle"
										type="button"
										id="dropdownMenuButton2"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										{sortByOrder === 1 ? "Low To High" : " High to Low"}
									</button>
									<ul
										class="dropdown-menu"
										aria-labelledby="dropdownMenuButton2"
									>
										<li onClick={(e) => sortHighToLowHandler(e, 2)}>
											<a class="dropdown-item" href="#">
												High to Low
											</a>
										</li>
										<li onClick={(e) => sortLowToHighandler(e, 1)}>
											<a class="dropdown-item" href="#">
												Low To High
											</a>
										</li>
									</ul>
								</div>
							</span>
						</div>
						<div className="sort-all">
							<span>
								<div class="dropdown ">
									<button
										class="btn btn-secondary dropdown-toggle"
										type="button"
										id="dropdownMenuButton2"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										{showProducts}
									</button>
									<ul
										class="dropdown-menu"
										aria-labelledby="dropdownMenuButton2"
									>
										<ShowNoOfProductsComponent />
									</ul>
								</div>
							</span>
							<span className="two-grid-icons">
								<a
									onClick={(e) => {
										showGridHandler(e, 4);
									}}
								>
									<i class="fa fa-th" aria-hidden="true"></i>
								</a>
								<a
									onClick={(e) => {
										showGridHandler(e, 12);
									}}
								>
									<i class="fa fa-th-list" aria-hidden="true"></i>
								</a>
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProductsSortingFilters;
