import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import { zipicon } from "../../Constant/Index";
import "../../assets/css/profile.css";
import Siderbar from "./Siderbar";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import AccessControl from "../../Components/Auth/AccessControl";
import ProfileDetailContent from "../../Components/PID/ProfileDetailContent";
import ObjBasedApiDataLoader from "../../Components/ReUse/ReUsableHOC/ObjBasedApiDataLoader";

function Profile() {

	function PIDDetail({ loading, data }) {
		return <ProfileDetailContent data={data} />;
	}

	const EnhancedPIDDetail = ObjBasedApiDataLoader(PIDDetail);

	const [apiData, setApiData] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const customerProfile = AppService.customerProfile();
		ReUse.getApiData(customerProfile, setApiData, setLoading);
	}, []);


	return <EnhancedPIDDetail data={apiData} loading={loading} />
}

export default Profile;
