import React from "react";
import ProductCard from "./ProductCard";
import { organic } from "../../Constant/Index";
import ReUse from "../../services/helpers/reUse";
import HomeCategoriesBody from "./HomeCategoriesBody";

export const HomeCategoriesSec = ({ currentIndex, ...otherProps }) => {
  const {
    category_id,
    category_img,
    category_name,
    sub_category_id,
    sub_category_name,
    color,
    title,
    products,
  } = otherProps ?? {};

  return (
    products?.length > 0 && (
      <HomeCategoriesBody currentIndex={currentIndex} otherProps={otherProps} />
    )
  );
};
