import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import AccessControl from "../../Components/Auth/AccessControl";
import { useEffect } from "react";
import {
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import AppService from "../../services/appServices";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import ReUse from "../../services/helpers/reUse";
import CustomButton from "../../Components/ReUse/Components/Button";

function Sellerotp() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	// const { email } = location?.state && location?.state;

	const [loading, setLoading] = useState(false);
	const [OTP, setOTP] = useState([]);
	const [OTPBoxes, setOTPBoxes] = useState([1, 2, 3, 4, 5, 6]);

	const [allErrors, setAllErrors] = useState({
		otp: "",
	});

	const [fieldsData, setFieldsData] = useState({
		otp: "",
	});

	const [isResendOTPDisabled, setResendOTPDisabled] = useState(false);
	const [email, setEmail] = useState("");

	const OTPHandler = (e, index) => {
		// const resetErrors = ReUse.resetErrors(allErrors);
		// setAllErrors(resetErrors);
		const newOTP = [...OTP];
		const { value } = e.target;
		newOTP[index] = value;
		setOTP(newOTP);
	};

	const onSubmitHandler = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const { otp } = fieldsData;

			const fields = {
				otp,
			};

			const arrayToStringOtp = OTP?.join("")?.toString();
			const stringToNumberOtp = parseInt(arrayToStringOtp);

			const payLoad = {
				otp: stringToNumberOtp,
			};

			const apiResp = await AppService.sellerVerifyOTP(payLoad);
			const { message, response } = apiResp.data;
			setLoading(false);
			dispatch(successMessage(message));
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/create-new-password", { state: { email: email } });
				}, 1000);
			}, 1000);
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			dispatch(successMessage(""));
		}
	};

	const resendOTPHandler = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const data = {
				otp: email,
			};

			setTimeout(async () => {
				const apiResp = await AppService.sellerResendEmailVerification(data);
				const { message, response } = apiResp.data;
				toast.success(message);
				setLoading(false);
			}, 600);

			setResendOTPDisabled(true);
			const timer = setTimeout(() => {
				setResendOTPDisabled(false);
				clearTimeout(timer);
			}, 5000);
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
		}
	};

	useEffect(() => {
		setEmail(location?.state?.email);
	}, [location?.state?.email]);

	const generateId = ReUse.makeId();

	return (
		<>
			{/* <AccessControl allowedRoles={["seller"]}> */}
			{/* Seller Forget Sec */}
			<section className="seller-forget-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="seller-forget-form">
								<div className="seller-forget-head">
									<h3>enter your OTP</h3>
									<p>
										Please Enter The Confirmation Code, sent on{" "}
										{location?.state?.email}
									</p>
								</div>
								<form>
									<div className="row">
										{OTPBoxes?.map((item, index) => {
											return (
												<div className="col-md-2">
													<div className="seller-forget-input">
														<input
															key={generateId}
															type="number"
															name="otp"
															value={OTP[index] || ""}
															onChange={(e) => OTPHandler(e, index)}
														/>
														<p style={{ color: "red" }}>{allErrors?.otp}</p>
													</div>
												</div>
											);
										})}

										<div className="seller-forget-btn">
											{/* <Link to="/change-password-for-seller">send</Link> */}

											<a>
												<CustomButton
													loading={loading}
													onClickHandler={onSubmitHandler}
													customClassName="btn"
												>
													Submit
												</CustomButton>
											</a>
											<ResponseMessage />
										</div>
										<div className="seller-again">
											{/* <Link>send again</Link> */}
											<button
												className="btn-Resend"
												disabled={isResendOTPDisabled}
												onClick={(e) => resendOTPHandler(e)}
											>
												{isResendOTPDisabled
													? `You can resend the OTP after 50 seconds`
													: "Resend Code"}
											</button>
											<ResponseMessage />
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ================== */}
			{/* </AccessControl> */}
		</>
	);
}

export default Sellerotp;
