import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import { recipedetail } from "../../Constant/Index";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import { BounceLoader } from "react-spinners";
import NoRecord from "../../Components/ReUse/NoRecord";
import RecipedetailContent from "../../Components/PID/RecipeDetailContent";
import ObjBasedApiDataLoader from "../../Components/ReUse/ReUsableHOC/ObjBasedApiDataLoader";
import { useLocation } from "react-router-dom";

function Recipedetail() {
	const location = useLocation();
	const getId = location?.state || null;

	const currentSlug = ReUse.extractIdFromCurrentUrl();

	function Recipedetail({ loading, data }) {
		return <RecipedetailContent data={data} />;
	}

	const EnhancedRecipedetail = ObjBasedApiDataLoader(Recipedetail);

	const [apiData, setApiData] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const showRecipe = AppService.showRecipe({ recipeId: currentSlug });
		ReUse.getApiData(showRecipe, setApiData, setLoading);
	}, [currentSlug]);

	return <EnhancedRecipedetail data={apiData} loading={loading} />;
}

export default Recipedetail;
