import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import Siderbar from "./Siderbar";
import ReUse from "../../services/helpers/reUse";
import { toast } from "react-toastify";
import AppService from "../../services/appServices";
import { useDispatch } from "react-redux";
import AccessControl from "../../Components/Auth/AccessControl";
import AddressCard from "./AddressCard";

function MyAddresses() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [apiData, setApiData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const customerGetAllAddresses = AppService.customerGetAllAddresses();
		ReUse.getApiData(customerGetAllAddresses, setApiData, setLoading);
	}, []);

	return (
		<>
			<div className="col-md-12 mt-3">
				<h2>My Addresses</h2>
				<div className="all-edit-screen">
					<div className="row">
						{ReUse.mapItems(loading, apiData, AddressCard)}
					</div>
				</div>
			</div>
		</>
	);
}

export default MyAddresses;
