import React from "react";
import { Link } from "react-router-dom";
import HeaderSubCats from "./HeaderSubCats";
import ReUse from "../services/helpers/reUse";

const HeaderCats = (propsData) => {
  const { id, name, slug, icon, icon_path, childes } = propsData;
  return (
    <>
      <li className="inner-dropdown">
        <a
          className="dropdown-item sherwood-dropdown"
          // href="#"
        >
          <Link>{name && name}</Link>
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </a>
        <ul className="inner-dropdown-menu for-position-up">
          {ReUse.mapItems(false, childes, HeaderSubCats)}

          {/* <HeaderSubCats /> */}
        </ul>
      </li>
    </>
  );
};

export default HeaderCats;
