import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { reviewimg } from "../../Constant/Index";
import ReUse from "../../services/helpers/reUse";
import { Rating } from "react-simple-star-rating";
import GlobalModal from "../../Components/ReUse/Modals/GlobalModal";
import { useDispatch } from "react-redux";
import {
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import AppService from "../../services/appServices";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import CustomButton from "../../Components/ReUse/Components/Button";
import FieldError from "../../Components/ReUse/FieldError";

const SellerReviewCard = (propsData) => {
	const {
		review_id,
		product_id,
		comment,
		rating,
		review_date,
		customer_name,
		customer_img,
		customer_desgination,
	} = propsData ?? {};

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [modalIsOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [statusMessage, setStatusMessage] = useState("");
	const [feedback, setFeedback] = useState("");

	const [allErrors, setAllErrors] = useState({
		feedback: "",
	});
	const [fieldsData, setFieldsData] = useState({
		feedback: "",
	});

	function openModal() {
		setIsOpen(true);
	}
	function closeModal() {
		setIsOpen(false);
	}

	function handleChange(e) {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		const { value, name } = e.target;
		setFieldsData({
			...fieldsData,
			[name]: value,
		});
	}

	const onSubmitHandler = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const { feedback } = fieldsData ?? {};

			const fields = {
				feedback,
			};
			const allErrors = ReUse.validateRequired({ fields: fields });

			// Check if there are any errors
			if (Object.keys(allErrors).length > 0) {
				setAllErrors(allErrors);
				setLoading(false);
				ReUse.scrollToTop();
				return;
			}

			const payLoad = {
				review_id,
				feedback,
			};

			const apiResp = await AppService.sellerSendFeedback(payLoad);
			const { message, response } = apiResp.data;
			setLoading(false);
			dispatch(successMessage(message));
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/seller-review");
				}, 1000);
			}, 1000);

			setIsOpen(false);
			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
			dispatch(successMessage(""));
		}
	};

	return (
		<div className="reviews-all-stuff">
			<div className="row">
				<div className="col-md-3">
					<div className="edit-review-all">
						<div className="review-flex">
							<div className="review-img seller-review-img">
								<figure>
									<img
										className="img-fluid"
										alt="review-img-all"
										src={
											customer_img && customer_img !== null
												? customer_img
												: ReUse.defaultThumbnailImage({
														content: "No+Image+Available",
												  })
										}
										onError={ReUse.onImageError}
									/>
								</figure>
								<div className="review-text">
									<h5>{customer_name}</h5>
									<p>{customer_desgination}</p>
									<Rating
										size={20}
										allowFraction={true}
										initialValue={rating}
										readonly={true}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-9">
					<div className="reviews-">
						<div className="reviews-para">
							<p>{comment}</p>
						</div>
					</div>
				</div>
				<div className="col-md-12">
					<div className="all-seller-date-btn">
						<div className="all-seller-date">
							<p>{ReUse.dateFormat({ date: review_date })}</p>
						</div>
						<div className="all-seller-btn">
							<button onClick={openModal}>Send Feedback</button>
						</div>
					</div>
				</div>
			</div>
			<GlobalModal isOpen={modalIsOpen} onRequestClose={closeModal}>
				<div class="modal-content">
					<div class="modal-body">
						<div className="review-modal-all">
							<div className="reviews-modal-para">
								<p>Write a Review Reply Message for {customer_name}</p>
							</div>
							<div className="reviews-modal-text">
								<textarea
									class="form-control"
									id="exampleFormControlTextarea1"
									rows="3"
									placeholder="Reviews"
									onChange={handleChange}
									name="feedback"
									value={fieldsData?.feedback}
								></textarea>
								<FieldError fieldName={allErrors?.feedback} />
							</div>
						</div>
					</div>

					<div className="all-seller-btn modal-submit-btn">
						<CustomButton
							loading={loading}
							onClickHandler={onSubmitHandler}
							customClassName="custom-btn"
						>
							Submit
						</CustomButton>

						<ResponseMessage />
					</div>
				</div>
			</GlobalModal>
		</div>
	);
};

export default SellerReviewCard;
