import React, { useEffect, useState } from "react";
import BulkVariantAddField from "./BulkVariantAddField";
import ReUse from "../../services/helpers/reUse";

const BulkVariantMeta = ({
  variation,
  index,
  bulkVariantDataHandler,
  bulkVariantData,
  variantMOQsHandler,
}) => {
  const variantTitle = variation[`variation${index + 1}`];

  const currentVariantData = bulkVariantData[index];

  const { qty, sku, moqs } = currentVariantData ?? {};

  const [bulkVariantFields, setBulkVariantFields] = useState([1]);
  const [bulkVariantAddFieldsData, setBulkVariantAddFieldsData] = useState([]);

  const addMoreVariantHanlder = (e) => {
    e.preventDefault();
    setBulkVariantFields((prev) => [...prev, prev + 1]);
  };

  const AddMoreButton = () => {
    return (
      <>
        {bulkVariantFields?.length <= 3 && (
          <div className="col-md-12">
            <div className="check-box-button" onClick={addMoreVariantHanlder}>
              <button>Add More (Max 4)</button>
            </div>
          </div>
        )}
      </>
    );
  };

  const BulkVariantFieldsProps = {
    bulkVariantDataIndex: index,
    setBulkVariantAddFieldsData,
    bulkVariantAddFieldsData,
    bulkVariantData,
	variantMOQsHandler
  };

  const BulkVariantFieldsComponent = () => {
    return (
      bulkVariantFields?.length > 0 &&
      bulkVariantFields.map((field, index) => {
        return (
          <BulkVariantAddField
            propsData={BulkVariantFieldsProps}
            index={index}
          />
        );
      })
    );
  };





  return (
    <>
      <div className="col-md-12">
        <div className="row checkbox-border">
          <div className="show-all-dv">
            <div className="row">
              <div className="col-md-12">
                <div className="all-name-and-things">
                  <h3>Variant: {variantTitle}</h3>
                </div>
              </div>
              <div className="col-md-6">
                <div className="check-quantity label-in-adding-prdoct">
                  <label>qty</label>
                  <input
                    type="text"
                    name="qty"
                    onChange={(e) => {
                      bulkVariantDataHandler(e, index);
                    }}
                    value={qty}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="check-quantity label-in-adding-prdoct">
                  <label>SKU</label>
                  <input
                    type="text"
                    name="sku"
                    onChange={(e) => {
                      bulkVariantDataHandler(e, index);
                    }}
                    value={sku}
                  />
                </div>
              </div>
              <BulkVariantFieldsComponent />
            </div>

            <AddMoreButton />
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkVariantMeta;
