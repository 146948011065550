import HttpServices from "../httpServices";
import { ENDPOINTS } from "../helpers/endpoints";
import { header } from "../helpers/constants";

class AppService extends HttpServices {
  /*Customer/Auth */
  static customerSignUp(payload) {
    return this.post(ENDPOINTS.signUp, header, { ...payload });
  }
  static customerSignIn(payload) {
    return this.post(ENDPOINTS.login, header, { ...payload });
  }
  static customerResendEmailVerification(payload) {
    return this.post(ENDPOINTS.resendEmailVerification, header, { ...payload });
  }
  static customerForgotPassword(payload) {
    return this.post(ENDPOINTS.forgotPassword, header, { ...payload });
  }
  static customerVerifyOTP(payload) {
    return this.post(ENDPOINTS.verifyOTP, header, { ...payload });
  }
  static customerForgotPasswordReset(payload) {
    return this.post(ENDPOINTS.forgotPasswordReset, header, { ...payload });
  }
  static customerResetPassword(payload) {
    return this.post(ENDPOINTS.resetPassword, header, { ...payload });
  }
  static customerLogout() {
    return this.post(ENDPOINTS.logout, header);
  }

  /*Customer/Profile */
  static customerProfile() {
    return this.get(ENDPOINTS.profile, header);
  }
  static customerUpdateProfile(payload) {
    return this.post(ENDPOINTS.updateProfile, header, { ...payload });
  }

  /*Customer/Order/Product Order */
  static customerPlaceOrder(payload) {
    return this.post(ENDPOINTS.placeOrder, header, { ...payload });
  }
  static customerSubmitReview(payload) {
    return this.post(ENDPOINTS.submitReview, header, { ...payload });
  }

  /*Customer/Order/Customizable Product Order */
  static customerAddCustomizableOrder(payload) {
    return this.post(ENDPOINTS.addCustomizableOrder, header, { ...payload });
  }
  static customerCustomizableSubmitReview(payload) {
    return this.post(ENDPOINTS.customizableSubmitReview, header, {
      ...payload,
    });
  }

  /*Customer/Order */
  static customerOrderDetail({ orderId = "", productType = "" }) {
    return this.get(
      `${ENDPOINTS.orderDetail}?order_id=${orderId}&product_type=${productType}`,
      header
    );
  }
  static customerGetAllOrders({ pageNumber = 1 }) {
    return this.get(`${ENDPOINTS.getAllOrders}?page=${pageNumber}`, header);
  }

  /*Customer/Order/Wishlist */
  static customerAddProductWishlist(payload) {
    return this.post(`${ENDPOINTS.addProductWishlist}`, header, { ...payload });
  }
  static customerRemoveProductWishlist(payload) {
    return this.delete(`${ENDPOINTS.removeProductWishlist}`, header, {
      ...payload,
    });
  }
  static customerGetAllProductsWishlist({ pageNumber = 1 }) {
    return this.get(
      `${ENDPOINTS.getAllProductsWishlist}?page=${pageNumber}`,
      header
    );
  }

  /*Customer/Order/Get a Quote */
  static customerAddQuote(payload) {
    return this.post(ENDPOINTS.addQuote, header, {
      ...payload,
    });
  }
  static customerGetAllQuotes() {
    return this.get(ENDPOINTS.getAllQuotes, header);
  }
  static customerShowQuote({ quoteId }) {
    return this.get(`${ENDPOINTS.showQuote}/${quoteId}`, header);
  }

  /*Customer/Order/Checkout*/
  static customerOrderCheckout(payload) {
    return this.post(ENDPOINTS.orderCheckout, header, {
      ...payload,
    });
  }

  /*Customer/Address*/
  static customerGetAllAddresses() {
    return this.get(`${ENDPOINTS.getAllAddresses}`, header);
  }

  static customerAddAddress(payload) {
    return this.post(ENDPOINTS.addAddress, header, {
      ...payload,
    });
  }

  static customerUpdateAddress(payload, { adressId = null }) {
    return this.post(`${ENDPOINTS.updateAddress}/${adressId}`, header, {
      ...payload,
    });
  }

  static customerShowAddress({ adressId }) {
    return this.get(`${ENDPOINTS.showAddress}/${adressId}`, header);
  }

  static customerDeleteAddress({ adressId }) {
    return this.get(`${ENDPOINTS.deleteAddress}/${adressId}`, header);
  }

  /*Seller/Auth*/
  static sellerSignIn(payload) {
    return this.post(ENDPOINTS.sellerLogin, header, { ...payload });
  }
  static sellerResendEmailVerification(payload) {
    return this.post(ENDPOINTS.sellerResendEmailVerification, header, {
      ...payload,
    });
  }
  static sellerForgotPassword(payload) {
    return this.post(ENDPOINTS.sellerForgotPassword, header, { ...payload });
  }
  static sellerVerifyOTP(payload) {
    return this.post(ENDPOINTS.sellerVerifyOTP, header, { ...payload });
  }
  static sellerForgotPasswordReset(payload) {
    return this.post(ENDPOINTS.sellerForgotPasswordReset, header, {
      ...payload,
    });
  }
  static sellerResetPassword(payload) {
    return this.post(ENDPOINTS.sellerResetPassword, header, { ...payload });
  }
  static sellerLogout() {
    return this.post(ENDPOINTS.sellerLogout, header);
  }

  /*Seller/Dashboard*/
  static sellerGetOrderActivity() {
    return this.get(ENDPOINTS.orderActivity, header);
  }

  /*Seller/Dashboard/Graph*/
  static sellerGetGraphData() {
    return this.get(ENDPOINTS.sellerGraphData, header);
  }

  /*Seller/Profile*/
  static sellerGetProfileInfo() {
    return this.get(ENDPOINTS.profileInfo, header);
  }
  static sellerUpdatePersonalDetails(payload) {
    return this.post(ENDPOINTS.updatePersonalDetails, header, { ...payload });
  }
  static sellerUpdateBusinessDetails(payload) {
    return this.post(ENDPOINTS.updateBusinessDetails, header, { ...payload });
  }

  /*Seller/Customers & Orders*/
  static sellerGetorders({ pageNumber = 1 }) {
    return this.get(`${ENDPOINTS.orders}?page=${pageNumber}`, header);
  }
  static sellerGetOrdersDetails({ orderId }) {
    return this.get(`${ENDPOINTS.ordersDetails}/${orderId}`, header);
  }

  /*Seller/Reports*/
  static sellerGetOrderReport({ dateFrom = "", dateTo = "" }) {
    return this.get(
      `${ENDPOINTS.orderReport}?date_from=${dateFrom}&date_to=${dateTo}`,
      header
    );
  }
  static sellerGetEarningReport({ dateFrom = "", dateTo = "" }) {
    return this.get(
      `${ENDPOINTS.earningReport}?date_from=${dateFrom}&date_to=${dateTo}`,
      header
    );
  }

  /*Seller/Reviews & Feedback*/
  static sellerGetReviews() {
    return this.get(ENDPOINTS.reviews, header);
  }
  static sellerSendFeedback(payload) {
    return this.post(ENDPOINTS.sendFeedback, header, { ...payload });
  }

  /*Seller/Product Inventory*/
  static sellerGetProducts({ pageNumber = 1 }) {
    return this.get(`${ENDPOINTS.products}/?page=${pageNumber}`, header);
  }
  static sellerGetcategories() {
    return this.get(ENDPOINTS.categories, header);
  }
  static sellerGetsubCategories({ categoryId = null }) {
    return this.get(`${ENDPOINTS.subCategories}/${categoryId}`, header);
  }
  static sellerGetunits() {
    return this.get(ENDPOINTS.units, header);
  }
  static sellerGetcolors() {
    return this.get(ENDPOINTS.colors, header);
  }
  static sellerGetAttributes() {
    return this.get(ENDPOINTS.attributes, header);
  }
  static sellerSkuCombination(payload) {
    return this.post(ENDPOINTS.skuCombination, header, { ...payload });
  }
  static sellerAllowAddProduct(payload) {
    return this.post(ENDPOINTS.allowAddProduct, header, { ...payload });
  }
  static sellerAddProduct(payload) {
    return this.post(ENDPOINTS.addProduct, header, { ...payload });
  }
  static sellerUpdateProduct(payload, { productId }) {
    return this.post(`${ENDPOINTS.updateProduct}/${productId}`, header, {
      ...payload,
    });
  }
  static sellerUpdateProductStatus(payload) {
    return this.post(ENDPOINTS.updateProductStatus, header, { ...payload });
  }
  static sellerDeleteProduct({ productId }) {
    return this.delete(`${ENDPOINTS.delete}/${productId}`, header, {});
  }

  /*Seller/Membeship Plan*/
  static sellerGetMembership() {
    return this.get(ENDPOINTS.membership, header);
  }
  static sellerCancelMembership() {
    return this.post(ENDPOINTS.cancelMembership, header);
  }
  static sellerUpgradeMembership(payload) {
    return this.post(ENDPOINTS.upgradeMembership, header, { ...payload });
  }

  /*Seller/Seller Center Page*/
  static sellerGetsellerCenter() {
    return this.get(ENDPOINTS.sellerCenter, header);
  }
  static sellerGetpackages() {
    return this.get(ENDPOINTS.packages, header);
  }
  static sellerRegistrationwithPlan(payload) {
    return this.post(ENDPOINTS.sellerRegistrationwithPlan, header, {
      ...payload,
    });
  }

  /*Seller/Seller Public Apis*/
  static storeInfo({ sellerId }) {
    return this.get(`${ENDPOINTS.storeInfo}/${sellerId}`, header);
  }
  static storeProducts({ sellerId }) {
    return this.get(`${ENDPOINTS.storeProducts}/${sellerId}`, header);
  }
  static storeReviews({ sellerId }) {
    return this.get(`${ENDPOINTS.storeReviews}/${sellerId}`, header);
  }

  /*Seller/Seller Payment Card*/
  static getSellerAllCards() {
    return this.get(`${ENDPOINTS.getSellerAllCards}`, header);
  }
  static getSellerCard({ cardId }) {
    return this.get(`${ENDPOINTS.getSellerCard}/${cardId}`, header);
  }
  static updateSellerCard({ cardId }, payload) {
    return this.put(`${ENDPOINTS.updateSellerCard}/${cardId}`, header, {
      ...payload,
    });
  }
  static createSellerCard(payload) {
    return this.post(`${ENDPOINTS.createSellerCard}`, header, {
      ...payload,
    });
  }
  static deleteSellerCard({ cardId }) {
    return this.delete(`${ENDPOINTS.deleteSellerCard}/${cardId}`, header);
  }

  /*Categories*/
  static getProductByCategory({ categoryId = null, subCategoryId = null }) {
    return this.get(
      `${ENDPOINTS.productByCategory}?category_id=${categoryId}&sub_category_id=${subCategoryId}`,
      header
    );
  }
  static getAllCats() {
    return this.get(ENDPOINTS.all, header);
  }

  /*Notifications*/
  static getAllNotifications() {
    return this.get(ENDPOINTS.allNotifications, header);
  }

  /*Products */
  static getAllProducts({
    name = "",
    categoryId = null,
    subCategoryId = null,
    minPrice = null,
    maxPrice = null,
    currentPage = 1,
  }) {
    return this.get(
      `${ENDPOINTS.allProducts}?name=${name}&min_price=${minPrice}&max_price=${maxPrice}&page_number=${currentPage}&category_id=${categoryId}&sub_category_id=${subCategoryId}`,
      header
    );
  }
  static getProductDetail({ productId = null }) {
    return this.get(`${ENDPOINTS.productDetail}/${productId}`, header);
  }

  /*Pages */
  static getPage({ pageName = "" }) {
    return this.get(`${ENDPOINTS.page}?type=${pageName}`, header);
  }

  /*FAQs */
  static getAllFAQs() {
    return this.get(ENDPOINTS.allFAQs, header);
  }

  /*Contact Us */
  static contactUs(payload) {
    return this.post(ENDPOINTS.contactUs, header, { ...payload });
  }

  /*Careers */
  static getCareers({ role = "" }) {
    return this.get(`${ENDPOINTS.careers}${`?name=${role}`}`, header);
  }
  static getCareerDetails({ jobId }) {
    return this.get(`${ENDPOINTS.careerDetails}/${jobId}`, header);
  }
  static applyForJob(payload) {
    return this.post(ENDPOINTS.applyForJob, header, { ...payload });
  }

  /*Recipes */
  static allRecipes({ pageNumber = 1 }) {
    return this.get(`${ENDPOINTS.allRecipes}?page=${pageNumber}`, header);
  }
  static showRecipe({ recipeId = null }) {
    return this.get(`${ENDPOINTS.showRecipe}/${recipeId}`, header);
  }

  /*Blogs */
  static allBlogs() {
    return this.get(ENDPOINTS.allBlogs, header);
  }
  static ShowBlog({ blogId = null }) {
    return this.get(`${ENDPOINTS.ShowBlog}/${blogId}`, header);
  }

  /*HomePage */
  static newsletter(payload) {
    return this.post(ENDPOINTS.newsletter, header, { ...payload });
  }
  static getAllBanners(bannerType = "main_banner") {
    return this.get(
      `${ENDPOINTS.getAllBanners}?banner_type=${bannerType}`,
      header
    );
  }
  static topSellingProducts() {
    return this.get(ENDPOINTS.topSellingProducts, header);
  }
  static socialMedia() {
    return this.get(ENDPOINTS.socialMedia, header);
  }

  /*Customizable Products */
  static getAllCustomizableProducts({
    categoryId = null,
    subCategoryId = null,
    minPrice = null,
    maxPrice = null,
    pageNumber = 1,
  }) {
    return this.get(
      `${ENDPOINTS.getAllCustomizableProducts}?category_id=${categoryId}&sub_category_id=${subCategoryId}&min_price=${minPrice}&max_price=${maxPrice}&page_number=${pageNumber}`,
      header
    );
  }
  static getCustomizableProductDetails({ productId = null }) {
    return this.get(
      `${ENDPOINTS.getCustomizableProductDetails}/${productId}`,
      header
    );
  }

  /*Homepage Categories */
  static homepageCategories() {
    return this.get(ENDPOINTS.homepageCategories, header);
  }

  /*Settings */
  static settings() {
    return this.get(ENDPOINTS.settings, header);
  }

  /* Region Info */
  static countryList() {
    return this.get(ENDPOINTS.countryList, header);
  }

  static countryStatesList({ countryId }) {
    return this.get(`${ENDPOINTS.countryStatesList}/${countryId}`, header);
  }

  static stateCitieslist({ stateId }) {
    return this.get(`${ENDPOINTS.statecitieslist}/${stateId}`, header);
  }

  /* Shipping Charges */
  static shippingCharges() {
    return this.get(`${ENDPOINTS.shippingCharges}`, header);
  }
}

export default AppService;
