// import React from "react";
// import { useSelector } from "react-redux";
// import { Navigate, Outlet } from "react-router-dom";

// const PrivateRoute = () => {
//   const isLoginAuth = useSelector(
//     (state) => state.persistedReducer.auth.authenticated
//   );

//   return <>{isLoginAuth ? <Outlet /> : <Navigate to="/sign-in" />}</>;
// };

// export default PrivateRoute;

import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

const PrivateRoute = () => {
	const navigate = useNavigate();

	const isLoginAuth = useSelector(
		(state) => state.persistedReducer.auth.authenticated,
	);

	const location = useLocation();

	return (
		<>
			{isLoginAuth ? (
				<Outlet />
			) : (
				// <Navigate to={{ pathname: "/sign-in", state: { from: "/" } }} />
				// navigate({ pathname: "/sign-in", state: { from: "/" } })
				navigate("/sign-in", { state: { from: location } })
			)}
		</>
	);
};

export default PrivateRoute;
