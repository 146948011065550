import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReUse from "../../services/helpers/reUse";
import { toast } from "react-toastify";
import { AuthActions } from "../../redux/slices/auth.slice";
import AppService from "../../services/appServices";
import { useDispatch } from "react-redux";
import {
  ResponseMessageActions,
  clearResponseMessageAfterDelay,
  errorMessage,
  successMessage,
} from "../../redux/slices/responseMessage.slice";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import CustomButton from "../../Components/ReUse/Components/Button";
import FieldError from "../../Components/ReUse/FieldError";

const SellerLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(true);
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [allErrors, setAllErrors] = useState({
    email: "",
    password: "",
  });
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const handleChange = async (e) => {
    const resetErrors = ReUse.resetErrors({ fields: allErrors });
    setAllErrors(resetErrors);
    await ReUse.getInputFieldsData({
      e: e,
      setFieldsData: setUserData,
      fieldsData: userData,
    });
  };

  const passwordVisibilityHandler = (e) => {
    e.preventDefault();
    setIsPasswordVisible(!isPasswordVisible);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const { email, password } = userData;

      const fields = { email, password };
      const allErrors = ReUse.validateRequired({ fields: fields });

      // Check if there are any errors
      if (Object.keys(allErrors).length > 0) {
        setAllErrors(allErrors);
        setLoading(false);
        return;
      }

      const payLoad = {
        email,
        password,
        remember_me: isRememberMe ? 1 : 0,
      };

      const apiResp = await AppService.sellerSignIn(payLoad);
      const { message, response } = apiResp?.data;
      setLoading(false);
      dispatch(successMessage(message));

      const { token, user } = response?.data ?? {};

      const { role, personal_details, business_details } = user ?? {};

      const dataForRedux = {
        token: token,
        user: {
          ...personal_details,
          role,
        },
      };

      dispatch(AuthActions.userAuthentication(dataForRedux));
      setTimeout(() => {
        dispatch(successMessage("Navigating..."));
        setTimeout(() => {
          dispatch(successMessage(""));
          navigate("/seller-profile");
        }, 3000);
      }, 1000);

      ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
      ReUse.resetForm({ fields: userData }); // userData is an object
    } catch (err) {
      setLoading(false);
      dispatch(errorMessage(err?.response?.data?.message));
      dispatch(clearResponseMessageAfterDelay());
      ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
      ReUse.resetForm({ fields: userData }); // userData is an object
      dispatch(successMessage(""));
    }
  };

  const PasswordVisiblity = () => {
    if (isPasswordVisible) {
      return (
        <i
          class="fa fa-eye-slash"
          aria-hidden="true"
          onClick={passwordVisibilityHandler}
        ></i>
      );
    } else {
      return (
        <i
          class="fa fa-eye"
          aria-hidden="true"
          onClick={passwordVisibilityHandler}
        ></i>
      );
    }
  };

  return (
    <>
      <div class="modal-content">
        <div class="modal-body ">
          <div className="seller-center-signin">
            <div className="seller-signin-btn">
              <h3>Seller Sign In</h3>
            </div>
            <div className="seller-sigbin-form-all">
              <form action="/seller-dashboard">
                <div className="row">
                  <div className="col-md-12">
                    <div className="seller-signin-input">
                      <input
                        type="text"
                        placeholder="Email "
                        onChange={handleChange}
                        name="email"
                        value={userData?.email}
                      />
                      <FieldError fieldName={allErrors?.email} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="seller-signin-input">
                      <input
                        type={isPasswordVisible ? "password" : "text"}
                        placeholder="Password"
                        onChange={handleChange}
                        name="password"
                        value={userData?.password}
                      />
                      <FieldError fieldName={allErrors?.password} />

                      <div className="password-icon seller-center-password-icon">
                        <PasswordVisiblity />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="check-and-forget">
                      <div class="form-group">
                        <input
                          type="checkbox"
                          onChange={(e) => setIsRememberMe(!isRememberMe)}
                          value={isRememberMe}
                          name="remember_me"
                        />
                        <label for="check-remeber">Remember Me</label>
                      </div>
                      <div className="modal-forget-link">
                        <Link to="/seller-forget-password">
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                    <div className="check-and-forget-btn">
                      <CustomButton
                        loading={loading}
                        onClickHandler={onSubmitHandler}
                        customClassName="custom-btn"
                      >
                        Submit
                      </CustomButton>

                      <ResponseMessage />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SellerLogin;
