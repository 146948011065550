import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AppService from "../../services/appServices";
import {
  clearResponseMessageAfterDelay,
  errorMessage,
  successMessage,
} from "../../redux/slices/responseMessage.slice";
import MeasurementField from "../../pages/Product/MeasurementField";
import PageHeader from "../Pageheader";
import {
  chestpocket,
  chestpocket2,
  customizenew1,
  customizesleves1,
  customizesleves2,
  mancustomize,
} from "../../Constant/Index";
import ReUse from "../../services/helpers/reUse";
import ProductVariations from "../../pages/Product/ProductVariations";
import { ProductDetailHook } from "../../Hooks/ProductDetailHook";
import { ProductDetailActions } from "../../redux/slices/productDetail.slice";
import ResponseMessage from "../ReUse/ResponseMessage";
import { CartActions } from "../../redux/slices/cart.slice";
import GlobalModal from "../ReUse/Modals/GlobalModal";
import NoRecord from "../ReUse/NoRecord";
import CustomButton from "../ReUse/Components/Button";

function CustomizeDesignProductDetailContent({ data }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [matchedVarient, setMatchedVarient] = useState({
    type: "",
    sku: "",
    qty: null,
    price: null,
  });
  const [cartLoading, setCartLoading] = useState(false);
  const [shippingLoader, setShippingLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [measurements, setMeasurements] = useState({});
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [shippingCharges, setShippingCharges] = useState([]);
  const [productShippingCharges, setProductShippingCharges] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const {
    product_id,
    product_name,
    slug,
    seller_id,
    seller_name,
    rating,
    review_count,
    reviews,
    description,
    category_id,
    category_name,
    sub_category_id,
    sub_category_name,
    brand_id,
    brand_name,
    images,
    thumbnail,
    unit_price,
    purchase_price,
    discount,
    discount_type,
    current_stock,
    colors,
    attributes,
    choice_options,
    variations,
    moq_selling,
    country_name,
    admin_id,
    admin_name,
  } = data ?? {};

  const currentUser = useSelector((state) => state.persistedReducer.auth.user);
  const productDetailRedux = useSelector((state) => state.productDetail);
  const selectedVariant = useSelector((state) => state.productDetail);
  const isLoginAuth = useSelector(
    (state) => state.persistedReducer.auth.authenticated
  );
  const cart = useSelector((state) => state.persistedReducer.cart);

  const { counter } = productDetailRedux ?? {};
  const { selectedVariantType, selectedVariations } = selectedVariant ?? {};
  const { cartData } = cart ?? {};

  const { state } = location ?? {};
  const { productMeasurements, productId } = state ?? {};
  const { type, sku, qty, price } = matchedVarient ?? {};

  const productDetailHook = ProductDetailHook(qty, selectedVariations);

  const isProductInCart = ReUse.isFound(cartData, product_id);
  const initialStatusMessage = isProductInCart ? "Already in Cart" : "";

  // const onSubmitHandler = async (e) => {
  // 	e.preventDefault();

  // 	try {
  // 		setLoading(true);

  // 		const data = {
  // 			product_id: productId,
  // 			measurements: measurements,
  // 		};

  // 		const apiResp = await AppService.customerAddQuote(data);
  // 		const { message, response } = apiResp.data;
  // 		setStatusMessage(message);
  // 		setLoading(false);
  // 		setTimeout(() => {
  // 			setStatusMessage("Navigating to Home...");
  // 			setTimeout(() => {
  // 				setStatusMessage("");
  // 				navigate("/");
  // 			}, 1000);
  // 		}, 1000);
  // 	} catch (err) {
  // 		setLoading(false);
  // 		dispatch(errorMessage(err?.response?.data?.message));
  // 		dispatch(clearResponseMessageAfterDelay());
  // 		setStatusMessage("");
  // 	}
  // };

  const handleIncrement = () => {
    productDetailHook.handleIncrement();
  };

  const handleDecrement = () => {
    productDetailHook.handleDecrement();
  };

  function matchVarient() {
    const foundVarient = ReUse.foundRecord({
      data: variations,
      property: "type",
      target: selectedVariantType?.join("-"),
    });
    const { type, sku, qty, price } = foundVarient ?? {};

    setMatchedVarient({
      type: type,
      sku: sku,
      qty: parseInt(qty),
      price: parseInt(price),
    });
  }

  useEffect(() => {
    matchVarient();
  }, [selectedVariantType]);

  const clearVariants = () => {
    dispatch(ProductDetailActions.setClearVariants());
  };

  const customerAddProductCart = async (e) => {
    e.preventDefault();

    setCartLoading(true);

    if (ReUse.isFound({ data: cartData, target: product_id })) {
      dispatch(errorMessage("Already in cart"));
      dispatch(clearResponseMessageAfterDelay());
      setCartLoading(false);
      return;
    }

    if (selectedVariantType?.length === 0) {
      dispatch(errorMessage("Please select variant"));
      dispatch(clearResponseMessageAfterDelay());
      setCartLoading(false);
      return;
    }

    if (selectedVariantType?.length < attributes?.length) {
      dispatch(errorMessage("Please select all variants"));
      dispatch(clearResponseMessageAfterDelay());
      setCartLoading(false);
      return;
    }

    if (counter === 0) {
      dispatch(errorMessage("Please select quantity"));
      dispatch(clearResponseMessageAfterDelay());
      setCartLoading(false);
      return;
    }

    try {
      const { price, qty, sku, type } = matchedVarient ?? {};

      const {
        brand_id,
        brand_name,
        category_id,
        category_name,
        current_stock,
        description,
        discount,
        discount_type,
        images,
        product_name,
        thumbnail,
        unit_price,
        product_id,
      } = data ?? {};

      const resultVariations = {};

      selectedVariations.forEach((variant) => {
        resultVariations[variant?.valueCategory] = variant?.selectedValue;
      });

      const payload = {
        brand_name,
        category_id,
        category_name,
        current_stock,
        description,
        discount,
        discount_type,
        images,
        product_id,
        product_name,
        thumbnail,
        unit_price: price,
        qty: counter,
        currentStock: qty,
        calculatedPrice: ReUse.calculateSubtotalPrice(
          price || unit_price,
          counter
        ),
        shippingCharges: productShippingCharges,
        variant: selectedVariantType?.join("-"),
        variations: resultVariations,
        measurements: measurements,
      };
      dispatch(CartActions.Add_To_Cart(payload));
      setCartLoading(false);
      dispatch(successMessage("Added to cart"));
      setTimeout(() => {
        dispatch(successMessage("Navigating..."));
        setTimeout(() => {
          dispatch(successMessage(""));
          navigate("/cart");
        }, 1000);
      }, 1000);
    } catch (err) {
      console.error("API Error:", err);
      setCartLoading(false);
      dispatch(successMessage(""));
      dispatch(errorMessage("Something Went Wrong"));
      dispatch(clearResponseMessageAfterDelay());
    }
  };

  useEffect(() => {
    const apiShippingCharges = AppService.shippingCharges();
    ReUse.getApiData(apiShippingCharges, setShippingCharges, setShippingLoader);
  }, []);

  useEffect(() => {
    const { country } = currentUser ?? {};
    let foundCharges = 0;
    let shippingType = "";

    if (country?.toLowerCase() === country_name?.toLowerCase()) {
      shippingType = "same-country";
    } else {
      shippingType = "different-country";
    }

    foundCharges = shippingCharges.find(
      (record) => record?.shipping_type === shippingType
    );
    setProductShippingCharges(parseInt(foundCharges?.charges || 0));
  }, [country_name, currentUser, shippingCharges]);

  // Function to open the modal
  function openModal() {
    setModalIsOpen(true);
  }

  // Function to close the modal
  function closeModal() {
    setModalIsOpen(false);
  }

  const measurementFieldProps = {
    productMeasurements,
    setMeasurements,
    measurements,
  };

  const MeasurementFieldComponent = () => {
    return (
      <>
        {productMeasurements?.map((currentMeasurement, i) => (
          <MeasurementField
            key={i}
            measurementFieldProps={{
              ...measurementFieldProps,
              currentMeasurement,
              currentIndex: i,
            }}
          />
        ))}
      </>
    );
  };

  const ClearSelectionComponent = () => {
    return (
      selectedVariations?.length > 0 && (
        <p onClick={() => clearVariants()}>Clear Selections</p>
      )
    );
  };

  if (productMeasurements?.length === 0 || !productMeasurements) {
    return <NoRecord />;
  }



  return (
    <>
      {/* Customize Design Section */}
      <section className="customize-design-sec">
        <div className="container">
          <div className="row">
            <PageHeader pagename="customization" />
            <div className="custom-design-tabs">
              <div class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne"
                    >
                      Suit
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne"
                  >
                    <div class="accordion-body">
                      <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="panelsStayOpen-headingTwo"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseTwo"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseTwo"
                          >
                            Collar and Cuff Construction
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingTwo"
                        >
                          <div class="accordion-body">
                            <div className="customer-tab-all-stuff">
                              <p>
                                Standard collars and cuffs are fused to maintain
                                their rigidity and work well for business or
                                dress attire. Soft collars and cuffs contain no
                                fusing for a more casual look and feel.
                              </p>

                              <div className="customize-all-imgs">
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={customizenew1}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Fused (Standard)</p>
                                  </div>
                                </div>
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={customizenew1}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Fused (Standard)</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="panelsStayOpen-headingTwo"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapsesleeves"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapsesleeves"
                          >
                            Sleeves Length
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapsesleeves"
                          class="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingsleeves"
                        >
                          <div class="accordion-body">
                            <div className="customer-tab-all-stuff">
                              <p>
                                Standard collars and cuffs are fused to maintain
                                their rigidity and work well for business or
                                dress attire. Soft collars and cuffs contain no
                                fusing for a more casual look and feel.
                              </p>

                              <div className="customize-all-imgs">
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={customizesleves1}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Short Sleeves</p>
                                  </div>
                                </div>
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={customizesleves2}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Long Sleeves</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="panelsStayOpen-headingpocket"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapsepocket"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapsepocket"
                          >
                            Chest Pocket
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapsepocket"
                          class="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingpocket"
                        >
                          <div class="accordion-body">
                            <div className="customer-tab-all-stuff">
                              <p>
                                Add a chest pocket of your choice to your shirt
                              </p>

                              <div className="customize-all-imgs">
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={chestpocket}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Patch Pocket</p>
                                  </div>
                                </div>
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={chestpocket2}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>No Pocket</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseThree"
                    >
                      Jacket
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingThree"
                  >
                    <div class="accordion-body">
                      <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="panelsStayOpen-headingTwo"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapsefour"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapsefour"
                          >
                            Collar and Cuff Construction
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapsefour"
                          class="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingTwo"
                        >
                          <div class="accordion-body">
                            <div className="customer-tab-all-stuff">
                              <p>
                                Standard collars and cuffs are fused to maintain
                                their rigidity and work well for business or
                                dress attire. Soft collars and cuffs contain no
                                fusing for a more casual look and feel.
                              </p>

                              <div className="customize-all-imgs">
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={customizenew1}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Fused (Standard)</p>
                                  </div>
                                </div>
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={customizenew1}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Fused (Standard)</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="panelsStayOpen-headingTwo"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapsefive"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapsefive"
                          >
                            Sleeves Length
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapsefive"
                          class="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingsleeves"
                        >
                          <div class="accordion-body">
                            <div className="customer-tab-all-stuff">
                              <p>
                                Standard collars and cuffs are fused to maintain
                                their rigidity and work well for business or
                                dress attire. Soft collars and cuffs contain no
                                fusing for a more casual look and feel.
                              </p>

                              <div className="customize-all-imgs">
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={customizesleves1}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Short Sleeves</p>
                                  </div>
                                </div>
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={customizesleves2}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Long Sleeves</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="panelsStayOpen-headingpocket"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapsesix"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapsesix"
                          >
                            Chest Pocket
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapsesix"
                          class="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingpocket"
                        >
                          <div class="accordion-body">
                            <div className="customer-tab-all-stuff">
                              <p>
                                Add a chest pocket of your choice to your shirt
                              </p>

                              <div className="customize-all-imgs">
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={chestpocket}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Patch Pocket</p>
                                  </div>
                                </div>
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={chestpocket2}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>No Pocket</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="get-quote-head">
                    <h5>Provide measurements</h5>
                  </div>
                </div>

                <MeasurementFieldComponent />
              </div>
            </div>

            <div className="col-md-12">
              <div className="customize-text-area">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="Additional Information "
                  onChange={(e) => setAdditionalInfo(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="col-md-12">
              <div className="customize-btn">
                <button onClick={openModal}>Save Customiazations</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <GlobalModal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <div className="customization-all-things">
          <div className="customization-dv">
            <div className="row">
              <div className="col-md-4">
                <div className="customzation-image">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="customization-img"
                      src={mancustomize}
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-8">
                <div className="traditional-dress">
                  <h4>{product_name}</h4>
                  <h5>
                    USD $
                    {ReUse.calculateSubtotalPrice({
                      unitPrice: price || unit_price,
                      quantity: counter,
                    })}
                  </h5>
                  <div className="quantity">
                    <div className="quantity-head">
                      <h5>Quantity</h5>
                    </div>
                    <div className="quantity-all-stuff">
                      <input value={counter} min="0" />
                      <button
                        className="btn-qunatity-1"
                        onClick={handleIncrement}
                      >
                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                      </button>
                      <button
                        className="btn-qunatity-2"
                        onClick={handleDecrement}
                      >
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>

                  {ReUse.mapItems(false, attributes, ProductVariations)}
                  <ClearSelectionComponent />
                  <ResponseMessage />
                </div>
              </div>
              <div className="col-md-4">
                <div className="you-shop-allow"></div>
                <div className="col-md-12 mt-3">
                  <div className="traditional-button">
                    <CustomButton
                      loading={cartLoading}
                      onClickHandler={customerAddProductCart}
                      customClassName="custom-btn"
                    >
                      Add In cart
                    </CustomButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </GlobalModal>
    </>
  );
}

export default CustomizeDesignProductDetailContent;
