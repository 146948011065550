import React from "react";
import { productslider1 } from "../Constant/Index";
import ReUse from "../services/helpers/reUse";
import { Rating } from "react-simple-star-rating";

const RelatedProductcCard = (propsData) => {
	const {
		categories,
		product_id,
		product_name,
		rating,
		seller_id,
		slug,
		thumbnail,
		unit_price,
	} = propsData;

	return (
		<>
			<div>
				<div className="slide-dv organic-food for-space-in-slider">
					<figure>
						{/* <img className="img-fluid" alt="slid-1" src={productslider1} /> */}
						<img
							className="img-fluid"
							alt="slid-1"
							src={
								thumbnail !== null
									? thumbnail
									: ReUse.defaultThumbnailImage({
											content: "No+Image+Available",
									  })
							}
							onError={ReUse.onImageError}
						/>
					</figure>
					<div className="slide-text">
						<p>{ReUse.sliceText({ content: product_name })}</p>

						<div className="slide-for-flex">
							<h5>${unit_price}</h5>
							<div className="slide-star">
								<Rating
									allowFraction={true}
									initialValue={rating}
									readonly={true}
									size={20}
								/>
							</div>
						</div>
					</div>
					<div className="product-heading">
						<div className="pro-head">
							<h5>Food</h5>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default RelatedProductcCard;
