import React from "react";

const FaqAccordion = ({ id, answer, question }) => {
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`faq-${id}-heading`}>
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#faq-${id}-collapse`}
          aria-expanded="false"
          aria-controls={`faq-${id}-collapse`}
        >
          <h5>{question}</h5>
        </button>
      </h2>
      <div
        id={`faq-${id}-collapse`}
        className="accordion-collapse collapse"
        aria-labelledby={`faq-${id}-heading`}
      >
        <div className="accordion-body">
          <p>{answer}</p>
        </div>
      </div>
    </div>
  );
};

export default FaqAccordion;
