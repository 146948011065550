import React, { useState } from "react";
import MyAddresses from "./MyAddresses";
import AddressAddModal from "./AddressAddModal";
import PageHeader from "../../Components/Pageheader";
import AccessControl from "../../Components/Auth/AccessControl";
import Siderbar from "./Siderbar";

const MyAddressesPage = () => {
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);

  function openModal() {
    setAddModalIsOpen(true);
  }

  function closeModal() {
    setAddModalIsOpen(false);
  }

  const addressAddModalProps = {
    addModalIsOpen,
    closeModal,
  };

  return (
    <>
      <PageHeader pagename="my orders" />

      <AccessControl allowedRoles={["customer"]}>
        <section className="my-orders-sec my-profile-all">
          <div className="container">
            <div className="row">
              <div className="col-md-2">
                <Siderbar />
              </div>
              <div className="col-md-10">
                <MyAddresses />
                <div className="profile-edit-btn">
                  <a>
                    <button className="btn" onClick={openModal}>
                      Add New Address
                    </button>
                  </a>
                </div>
                <AddressAddModal addressAddModalProps={addressAddModalProps} />
              </div>
            </div>
          </div>
        </section>
      </AccessControl>
    </>
  );
};

export default MyAddressesPage;
