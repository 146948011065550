import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageHeader from "../../Components/Pageheader";
import { useSelector } from "react-redux";
import ProductCart from "./ProductCart";
import ReUse from "../../services/helpers/reUse";

function Cart() {
	const cart = useSelector((state) => state.persistedReducer.cart);
	const { cartData, error, loading } = cart ?? {};

	const calculateTotalShipping = ReUse.calculateTotalShipping({
		data: cartData,
	});

	return (
		<>
			<PageHeader pagename="Cart" />
			{/* Wishlist Section */}
			<section className="wish-list-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="wish-list-all">
								<table className="wish-list-table">
									<thead>
										<th>Items</th>
										<th>Product Name</th>
										<th>Unit Price</th>
										<th>Quantity</th>
										<th>Subtotal</th>
										<th>Actionsds</th>
									</thead>
									<tbody>
										{ReUse.mapItems(loading, cartData, ProductCart)}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					{cartData?.length > 0 && (
						<div className="row for-upper-space">
							<div className="col-md-6">
								<div className="continue-or-check-out-btn">
									<Link>Continue Shopping</Link>
									<Link to="/check-out">Checkout</Link>
								</div>
							</div>

							<div className="col-md-6">
								<div className="for-back-all">
									<div className="row">
										<div className="col-md-6">
											<div className="cart-text">
												<p>Subtotal</p>
												<p className="for-responsive-cart3">
													Estimated Shipping Cost
												</p>
											</div>
										</div>
										<div className="col-md-6">
											<div className="cart-text cart-text-two">
												<p className="for-responsive-movment-in-cart">
													${" "}
													{ReUse.calculateTotalSubtotalPrice({
														data: cartData,
													})}
												</p>
												<p>${calculateTotalShipping}</p>
											</div>
										</div>
									</div>

									<div className="row for-borders-row">
										<div className="col-md-6">
											<div className="total-all-cost">
												<h5>TOTAL COST </h5>
											</div>
										</div>
										<div className="col-md-6">
											<div className="total-all-cost for-total-text">
												<h5>
													${" "}
													{ReUse.calculateTotalCost({
														data: cartData,
														shippingCost: calculateTotalShipping,
													})}
												</h5>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											<div className="continue-check-out">
												<Link to="/check-out">Continue To Checkout</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</section>
			{/* ==================== */}
		</>
	);
}

export default Cart;
