import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import { check } from "../../Constant/Index";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import axios from "axios";
import {
	ResponseMessageActions,
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import CustomButton from "../../Components/ReUse/Components/Button";
import FieldError from "../../Components/ReUse/FieldError";

const publishableKey =
	"pk_test_51I84thLEOr3DyRBMuYv80CHV113DjLWDVdKaWG0vbg4VD1dSvz3FFnCHhZaPbQY62bCXZBeEFZUcIMf35Mf68fzP007oM4TWZy";

function Payment() {
	const [paymentSuccessful, setPaymentSuccessful] = useState(false);
	const [loading, setLoading] = useState(false);

	const [allErrors, setAllErrors] = useState({
		cardNumber: "",
		expiryDate: "",
		cvcNumber: "",
	});
	const [fieldsData, setFieldsData] = useState({
		cardNumber: "",
		expiryDate: "",
		cvcNumber: "",
	});

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const {
		address,
		country_id,
		currency,
		email,
		first_name,
		last_name,
		packageCost,
		packageId,
		packageTitle,
		password,
		password_confirmation,
		phone,
		state,
		store_name,
		description,
	} = location?.state ?? {};

	const onSubmitHandler = async (paymentToken) => {
		try {
			setLoading(true);

			const payLoad = {
				package_id: packageId,
				seller: {
					first_name,
					last_name,
					email,
					phone,
					store_name,
					password,
					password_confirmation,
					address: "New York, USA",
					country_id: 166,
					state: "New York",
				},

				token: paymentToken,
			};

			// const toJSON = JSON.stringify(payLoad);

			const apiResp = await AppService.sellerRegistrationwithPlan(payLoad);
			const { message, response } = apiResp.data;
			setLoading(false);
			dispatch(successMessage(message));
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/");
				}, 1000);
			}, 1000);
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			dispatch(successMessage(""));
		}
	};

	const handlePaymentSuccess = (e) => {
		e.preventDefault();
		setLoading(true);

		const { cardNumber, expiryDate, cvcNumber } = fieldsData;

		const fields = {
			cardNumber,
			expiryDate,
			cvcNumber,
		};

		const allErrors = ReUse.validateRequired({ fields: fields });

		// Check if there are any errors
		if (Object.keys(allErrors).length > 0) {
			setAllErrors(allErrors);
			setLoading(false);
			return;
		}

		if (!ReUse.isValidCardNumber({ cardNumber: cardNumber })) {
			allErrors.cardNumber = "Invalid card number";
			setAllErrors(allErrors);
			setLoading(false);
			return;
		}

		if (!ReUse.isValidExpiryDate({ expiryDate: expiryDate })) {
			allErrors.expiryDate =
				"Invalid expiry date. Please use the format MM/YY.";
			setAllErrors(allErrors);
			setLoading(false);
			return;
		}

		if (!ReUse.isValidCVCNumber({ cvcNumber: cvcNumber })) {
			allErrors.cvcNumber = "Invalid CVC number.";
			setAllErrors(allErrors);
			setLoading(false);
			return;
		}

		ReUse.stripePaymentHandler({
			fieldsData: fieldsData,
			stripePublishableKey: publishableKey,
		})
			.then(async (result) => {
				ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
				ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object

				const { isPaymentSuccess, data } = result ?? {};


				if (isPaymentSuccess) {
					await onSubmitHandler(data?.id);
					dispatch(successMessage("seller registration successfully"));
					setLoading(false);
				} else {
					dispatch(errorMessage("Payment Failed"));
					dispatch(clearResponseMessageAfterDelay());
					setLoading(false);
					return;
				}

				setTimeout(() => {
					dispatch(successMessage("Processing..."));
					setTimeout(() => {
						dispatch(successMessage(""));
					}, 1000);
				}, 1000);
			})
			.catch((err) => {
				setLoading(false);
				dispatch(errorMessage(err?.response?.data?.message));
				dispatch(clearResponseMessageAfterDelay());
				ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
				ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
				dispatch(successMessage(""));
			});
	};

	function handleChange(e) {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		const { value, name } = e.target;
		setFieldsData({
			...fieldsData,
			[name]: value,
		});
	}

	const { cardNumber, expiryDate, cvcNumber } = fieldsData ?? {};

	return (
		<>
			<PageHeader pagename="payment details" />
			<div className="payment-top-para">
				<p>
					Lorem Ipsum is simply dummy text of the printing and typesetting
					industry.
				</p>
			</div>

			{/* Payment Seciton */}
			<section className="payment-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-8">
							<div className="payment-all">
								<div className="payment-head-all">
									<h3>add credit/debit card</h3>
								</div>

								<div className="payment-all-input-things">
									<form>
										<div className="row">
											<div className="col-md-12">
												<div className="payment-all-input">
													<input
														type="text"
														placeholder="Card Number"
														onChange={handleChange}
														name="cardNumber"
														value={cardNumber}
													/>
													<FieldError fieldName={allErrors?.cardNumber} />
												</div>
											</div>
											<div className="col-md-6">
												<div className="payment-all-input">
													<input
														type="text"
														placeholder="Expiry"
														onChange={handleChange}
														name="expiryDate"
														value={expiryDate}
													/>
													<FieldError fieldName={allErrors?.expiryDate} />
												</div>
											</div>
											<div className="col-md-6">
												<div className="payment-all-input">
													<input
														type="text"
														placeholder="CVC"
														onChange={handleChange}
														name="cvcNumber"
														value={cvcNumber}
													/>
													<FieldError fieldName={allErrors?.cvcNumber} />
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>

						<div className="col-md-4">
							<div className="payment-default-all-thnks">
								<div className="payment-head-all">
									<h3>Order Summary</h3>
								</div>
								<div className="payemnt-all-stuff">
									<div className="payment-all-first-heading">
										<h4>Payment Details</h4>
									</div>
									<div className="payment-professinal-plan">
										<div className="payment-all-the-text">
											<h4>{packageTitle}</h4>
											<div className="pay-para">
												<p>{packageTitle}</p>
												<p>
													<span>{description}</span>
												</p>
											</div>
										</div>
										<div className="payment-tag">
											<h5>${packageCost}</h5>
										</div>
									</div>

									<div className="payment-total-dv">
										<div className="payment-total-head">
											<h3>Total Payable</h3>
										</div>
										<div className="payment-total-tag">
											<h5>${packageCost}</h5>
										</div>
									</div>
								</div>
								<div className="payment-total-btn">
									<CustomButton
										loading={loading}
										onClickHandler={handlePaymentSuccess}
										customClassName="custom-btn"
									>
										Submit
									</CustomButton>
									<ResponseMessage />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ======================= */}

			{/* Payment Thankyou Modal  */}
			<div className="payment-thankyou">
				<div
					class="modal fade"
					id="exampleModal"
					tabindex="-1"
					aria-labelledby="exampleModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog for-modal-in-middle">
						<div class="modal-content">
							<div class="modal-body">
								<div className="thank-you-modal">
									<h3>Seller Successfully Registered</h3>
									<p>
										Lorem Ipsum is simply dummy text of the printing and
										typesetting industry.
									</p>
									<div className="payment-thank-you-icon">
										<figure>
											<img
												className="img-fluid"
												alt="payment-icon"
												src={check}
											/>
										</figure>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* ============================ */}
		</>
	);
}

export default Payment;
