import React from "react";
import { man1, man2 } from "../../Constant/Index";
import ReUse from "../../services/helpers/reUse";

const StoreReveiwCard = (propsData) => {
	const {
		review_id,
		product_id,
		comment,
		rating,
		review_date,
		customer_name,
		customer_img,
		customer_desgination,
	} = propsData ?? {};

	return (
		<>
			{/* =========================== */}
			{/* Reviews-Row   */}
			<div className="row for-margin sellerstore-review">
				<div className="col-md-1">
					<div className="review-img seller-store-review-img">
						<img
							className="img-fluid"
							alt="review-img"
							src={
								customer_img !== null
									? customer_img
									: ReUse.defaultThumbnailImage({
											content: "No+Image+Available",
									  })
							}
							onError={ReUse.onImageError}
						/>
					</div>
				</div>
				<div className="col-md-11">
					<div className="review-tab-all">
						<div className="tab-text">
							<h5>
								{customer_name}
								<span> {ReUse.dateFormat({ date: review_date })}</span>
							</h5>
							<p>{comment}</p>
						</div>
					</div>
				</div>
			</div>
			{/* =========================== */}
		</>
	);
};

export default StoreReveiwCard;
