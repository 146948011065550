import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import { blog1, blog2, blog3, blog4, blog5, blog6 } from "../../Constant/Index";
import { useState } from "react";
import { useEffect } from "react";

import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import BlogCard from "../../Components/BlogCard";

function Blog() {
	const [apiData, setApiData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const allBlogs = AppService.allBlogs();
		ReUse.getApiData(allBlogs, setApiData, setLoading);
	}, []);

	return (
		<>
			<PageHeader pagename="Blogs" />
			{/* Blog Section */}
			<section className="blog-sec">
				<div className="container">
					<div className="blogs-head">
						<h5>Read Our Most recent Blogs</h5>
						<p>
							{" "}
							Nisl vel pretium lectus quam id leo. Odio ut enim blandit volutpat
							maecenas volutpat blandit. Nunc vel risus commodo viverra
							maecenas. Lacus luctus accumsan tortor posuere ac ut consequat.{" "}
						</p>
					</div>
					<div className="row">
						{ReUse.mapItems(loading, apiData, BlogCard)}
					</div>
				</div>
			</section>
			{/* ======================== */}
		</>
	);
}

export default Blog;
