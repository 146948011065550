import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { carticon, flag, logo } from "../Constant/Index";
import Footer from "./Footer";
import HeaderCats from "./HeaderCats";
import AppService from "../services/appServices";
import ReUse from "../services/helpers/reUse";
import AccessControl from "./Auth/AccessControl";
import { useSelector } from "react-redux";

function Header() {
	const navigate = useNavigate();

	const [view, setView] = useState(false);

	const [apiData, setApiData] = useState([]);
	const [loading, setLoading] = useState(false);

	const [wishlists, setWishlists] = useState([]);
	const [loading2, setLoading2] = useState(false);

	const [footerSettings, setFooterSettings] = useState({});
	const [settingloader, setSettingloader] = useState(false);

	const [searchQuery, setSearchQuery] = useState("");

	useEffect(() => {
		const getAllCats = AppService.getAllCats();
		ReUse.getApiData(getAllCats, setApiData, setLoading);

		const wishlists = AppService.customerGetAllProductsWishlist({
			pageNumber: 1,
		});
		ReUse.getApiData(wishlists, setWishlists, setLoading2);

		const settings = AppService.settings();
		ReUse.getApiData(settings, setFooterSettings, setSettingloader);
	}, []);

	const currentUser = useSelector((state) => state.persistedReducer.auth.user);

	const cart = useSelector((state) => state.persistedReducer.cart);
	const { cartData } = cart ?? {};

	const isLoginAuth = useSelector(
		(state) => state.persistedReducer.auth.authenticated,
	);

	const { country, role } = currentUser ?? {};

	const getRouteForRole = ({ role, customerRoute, sellerRoute }) => {
		if (role?.toLowerCase() === "customer") {
			return customerRoute;
		} else if (role?.toLowerCase() === "seller") {
			return sellerRoute;
		} else {
			return "/";
		}
	};

	const navigator = {
		myProfile: () =>
			navigate(
				getRouteForRole({
					role: role,
					customerRoute: "/my-profile",
					sellerRoute: "/seller-profile",
				}),
			),
		changePassword: () =>
			navigate(
				getRouteForRole({
					role: role,
					customerRoute: "/change-password",
					sellerRoute: "/seller-change-password",
				}),
			),
		myOrders: () =>
			navigate(
				getRouteForRole({
					role: role,
					customerRoute: "/my-orders",
					sellerRoute: "/seller-dashboard",
				}),
			),
	};

	const {
		name,
		phone,
		web_logo,
		mobile_logo,
		email,
		colors,
		footer_logo,
		copyright_text,
		download_app_apple_stroe,
		download_app_google_stroe,
		fav_icon,
		language,
	} = footerSettings ?? {};

	const { primary, secondary } = colors ?? {};

	const navigateToSearch = (e) => {
		const { target, key, keyCode, which } = e ?? {};

		const { value } = target ?? {};

		if (key === "Enter" || keyCode === 13 || which === 13) {
			navigate(`/shop`, { state: { searchQuery } });
		}

		setSearchQuery(value);
	};

	const HeaderLanguagesComponent = (propsData) => {
		const { id, name, code, status } = propsData ?? {};

		return (
			<li>
				<button class="dropdown-item" type="button">
					<img className="img-fluid" src={flag} />
				</button>
			</li>
		);
	};

	return (
		<>
			{/* Top Nav Bar */}
			<section className="top-nav-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-1">
							<div className="delivery-dv">
								<p>
									<Link>Free Delivery</Link>
								</p>
							</div>
						</div>
						<div className="col-md-6">
							<div className="delivery-dv">
								<p>
									<Link>Returns Policy</Link>
								</p>
							</div>
						</div>
						<div className="col-md-5">
							<div className="call-and-btn">
								<div className="call-us-now-dv">
									<h5>
										CALL US NOW:{" "}
										<span className="color-change">
											<a href="tel:+1 234 567-0000">{phone}</a>
										</span>
									</h5>
								</div>
								<div className="top-header-btn">
									{!isLoginAuth ? <Link to="/sign-up">sign up</Link> : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ======================= */}

			{/* Middle Nab Bar */}
			<section className="middle-nav-bar">
				<div className="container">
					<div className="row">
						<div className="col-md-2">
							<div className="website-logo">
								<Link to="/">
									<figure>
										<img
											className="img-fluid"
											alt="web-logo"
											src={
												web_logo && web_logo !== null
													? web_logo
													: ReUse.defaultThumbnailImage({
															content: "No+Image+Available",
													  })
											}
											onError={ReUse.onImageError}
										/>
									</figure>
								</Link>
							</div>
						</div>
						<div className="col-md-1">
							<div className="middle-col">
								<AccessControl
									allowedRoles={["customer", "seller"]}
									isMessageDisplay={false}
								>
									<div className="icon-text">
										<i class="fa fa-map-marker" aria-hidden="true"></i>
										<div className="dv-text">
											<p>{country && "Deliver to"} </p>
											<h5>{country}</h5>
										</div>
									</div>
								</AccessControl>
							</div>
						</div>
						<div className="col-md-6">
							<div className="search-input">
								<input
									type="text"
									value={searchQuery}
									onChange={navigateToSearch}
								/>
								<div className="input-icon">
									<i class="fa fa-search" aria-hidden="true"></i>
								</div>
							</div>
						</div>

						<div className="col-md-3">
							<div className="all-stuff">
								<div className="flag-dropdown">
									<div class="dropdown">
										<button
											class="btn dropdown-toggle"
											type="button"
											id="dropdownMenu2"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											<img className="img-fluid" src={flag} />
										</button>
										<ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
											{ReUse.mapItems(
												false,
												language,
												HeaderLanguagesComponent,
											)}
										</ul>
									</div>
								</div>
								<AccessControl
									allowedRoles={["customer", "seller"]}
									isMessageDisplay={false}
								>
									<div className="lable-drop-down">
										<label>
											Hello, {currentUser?.first_name || currentUser?.name}
										</label>
										<div className="dv-for-drop-down">
											<div class="dropdown">
												<a
													class=" dropdown-toggle"
													href="#"
													role="button"
													id="dropdownMenuLink"
													data-bs-toggle="dropdown"
													aria-expanded="false"
												>
													Account & Lists
												</a>

												<ul
													class="dropdown-menu"
													aria-labelledby="dropdownMenuLink"
												>
													<li>
														<a
															class="dropdown-item"
															onClick={navigator?.myProfile}
														>
															my profile
														</a>
													</li>
													<li>
														<a class="dropdown-item" href="change-password">
															change password
														</a>
													</li>
													<li>
														<a class="dropdown-item" href="my-orders">
															my orders
														</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</AccessControl>

								<div className="heart-icon">
									<Link to="/wish-list">
										{" "}
										<i class="fa fa-heart-o" aria-hidden="true" />
									</Link>
									<p>{wishlists?.data?.length || 0}</p>
								</div>
								<div className="cart-img">
									<Link to="/cart">
										<span>
											<img
												className="img-fluid"
												alt="cart-icon"
												src={carticon}
											/>{" "}
											cart
										</span>
									</Link>
									<p>{cartData?.length}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ======================== */}

			{/* Bottom Nav Bar */}
			<section className="bottom-nav-bar">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<div className="all-select">
								<div className="dropdown">
									<button
										className="btn btn-secondary dropdown-toggle"
										type="button"
										id="dropdownMenuButton2"
										data-bs-toggle="dropdown"
										aria-expanded="false"
										onClick={() => navigator("/services")}
									>
										Shop By Category
									</button>
									<ul
										className="dropdown-menu"
										aria-labelledby="dropdownMenuButton2"
									>
										{ReUse.mapItems(loading, apiData, HeaderCats)}

										{/* <HeaderCats /> */}
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-9">
							<div className="nav-ul">
								<ul className="all-ul-nav">
									<li>
										<Link to="/">Home</Link>
									</li>
									<li>
										<Link to="/shop">Shop</Link>
									</li>
									<li>
										<Link to="/customize-product">customizable products</Link>
									</li>
									<li>
										<Link to="/recipes">recipes</Link>
									</li>
									<li>
										<Link to="/blogs">blog</Link>
									</li>
									<li>
										<Link to="/seller-center">seller center</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ======================== */}
			<div className="container">
				<div className="canvas_btn " onClick={() => setView(true)}>
					<Link to="/">
						<img className="img-fluid" src={logo}></img>
					</Link>
					<i className="fa fa-bars" aria-hidden="true"></i>
				</div>
			</div>
			<div className="container header-shop-by-category">
				<div className="row">
					<div className="col-md-12">
						<div className="mobile-header-menu">
							<div class="accordion" id="accordionExample">
								<div class="accordion-item">
									<h2 class="accordion-header" id="headingOne">
										<button
											class="accordion-button"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseOne"
											aria-expanded="true"
											aria-controls="collapseOne"
										>
											shop by category
										</button>
									</h2>
									<div
										id="collapseOne"
										class="accordion-collapse collapse"
										aria-labelledby="headingOne"
										data-bs-parent="#accordionExample"
									>
										<div class="accordion-body">
											{/* ================== */}
											<div class="accordion" id="accordionExample1">
												<div class="accordion-item for-items-css">
													<h2 class="accordion-header" id="headingInnerOne">
														<button
															class="accordion-button "
															type="button"
															data-bs-toggle="collapse"
															data-bs-target="#collapseInnerOne"
															aria-expanded="true"
															aria-controls="collapseInnerOne"
														>
															Food Items
														</button>
													</h2>
													<div
														id="collapseInnerOne"
														class="accordion-collapse collapse"
														aria-labelledby="headingInnerOne"
														data-bs-parent="#accordionExample1"
													>
														<div class="accordion-body ">
															<ul className="inner-dropdown-menu for-position-up inner-no-padding">
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>

											<div class="accordion" id="accordionExample2">
												<div class="accordion-item for-items-css">
													<h2 class="accordion-header" id="headingInnertwo">
														<button
															class="accordion-button "
															type="button"
															data-bs-toggle="collapse"
															data-bs-target="#collapseInnertwo"
															aria-expanded="true"
															aria-controls="collapseInnertwo"
														>
															Arts & Crafts
														</button>
													</h2>
													<div
														id="collapseInnertwo"
														class="accordion-collapse collapse "
														aria-labelledby="headingInnertwo"
														data-bs-parent="#accordionExample2"
													>
														<div class="accordion-body ">
															<ul className="inner-dropdown-menu for-position-up inner-no-padding">
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>

											<div class="accordion" id="accordionExample3">
												<div class="accordion-item for-items-css">
													<h2 class="accordion-header" id="headingInnerthree">
														<button
															class="accordion-button "
															type="button"
															data-bs-toggle="collapse"
															data-bs-target="#collapseInnerthree"
															aria-expanded="true"
															aria-controls="collapseInnerthree"
														>
															Fashion & Accessories
														</button>
													</h2>
													<div
														id="collapseInnerthree"
														class="accordion-collapse collapse"
														aria-labelledby="headingInnerthree"
														data-bs-parent="#accordionExample3"
													>
														<div class="accordion-body ">
															<ul className="inner-dropdown-menu for-position-up inner-no-padding">
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
																<li className="dropdown-item">
																	<Link>Loreum Ipsum</Link>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
											{/* ==================== */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* ========================================= */}

			{/* <!-- Mobile Header Start Here --> */}
			<div className={view ? "mobile_header show" : "mobile_header "}>
				<div className="cancel" onClick={() => setView(false)}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="25"
						height="25"
						fill="#000"
						className="bi bi-x"
						viewBox="0 0 16 16"
					>
						<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
					</svg>
				</div>

				<div className="middle-col ">
					<div className="icon-text responsive-middle-col">
						<i class="fa fa-map-marker" aria-hidden="true"></i>
						<div className="dv-text">
							<p>Deliver to</p>
							<h5>canada</h5>
						</div>
					</div>
				</div>

				<div className="search-input">
					<input type="text" />
					<div className="input-icon">
						<i class="fa fa-search" aria-hidden="true"></i>
					</div>
				</div>

				<ul className="mobile_menus">
					<li>
						<Link to="/">home</Link>
					</li>
					<li>
						<Link to="/customize-product">customizable products</Link>
					</li>
					<li>
						<Link to="/recipes">recipes</Link>
					</li>
					<li>
						<Link to="/blogs">blog</Link>
					</li>
					<li>
						<Link to="/seller-center">seller center</Link>
					</li>
					<li>
						{" "}
						<div className="heart-icon mobile-responsive-heart-icon">
							<Link to="/wish-list">
								<span>
									{" "}
									<i class="fa fa-heart-o" aria-hidden="true" />
									wishlist
								</span>
							</Link>
							<p>0</p>
						</div>
					</li>
					<li>
						<div className="cart-img mobile-responsive-cart-img">
							<Link to="/cart">
								<span>
									{/* <img className="img-fluid" alt="cart-icon" src={carticon} />{" "} */}
									<i class="fa fa-shopping-cart" aria-hidden="true"></i>
									cart
								</span>
							</Link>
							<p>0</p>
						</div>
					</li>
					<li>
						{" "}
						<div className="flag-dropdown responsive-flag-dropdown">
							<div class="dropdown">
								<button
									class="btn dropdown-toggle"
									type="button"
									id="dropdownMenu2"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									<img className="img-fluid" src={flag} />
								</button>
								<ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
									<li>
										<button class="dropdown-item" type="button">
											<img className="img-fluid" src={flag} />
										</button>
									</li>
									<li>
										<button class="dropdown-item" type="button">
											<img className="img-fluid" src={flag} />
										</button>
									</li>
									<li>
										<button class="dropdown-item" type="button">
											<img className="img-fluid" src={flag} />
										</button>
									</li>
								</ul>
							</div>
						</div>
					</li>
					<li>
						{" "}
						<div className="call-and-btn call-responsive">
							<div className="call-us-now-dv call-us-all-responsive">
								<h5>
									CALL US NOW:{" "}
									<span className="color-change">
										<a href="tel:+1 234 567-0000">+1 234 567-0000</a>
									</span>
								</h5>
							</div>
						</div>
					</li>
					<li>
						<div className="delivery-dv">
							<p>
								<Link>Free Delivery</Link>
							</p>
						</div>
					</li>
					<li>
						{" "}
						<div className="delivery-dv">
							<p>
								<Link>Returns Policy</Link>
							</p>
						</div>
					</li>
				</ul>
				<div className="lable-drop-down mobile-menu-drop-header">
					<label>Hello, Sign in</label>
					<div className="dv-for-drop-down">
						<div class="dropdown">
							<a
								class=" dropdown-toggle"
								href="#"
								role="button"
								id="dropdownMenuLink"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								Account & Lists
							</a>

							<ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
								<li>
									<a class="dropdown-item" href="my-profile">
										my profile
									</a>
								</li>
								<li>
									<a class="dropdown-item" href="change-password">
										change password
									</a>
								</li>
								<li>
									<a class="dropdown-item" href="my-orders">
										my orders
									</a>
								</li>
								<li>
									<a class="dropdown-item" href="#">
										log out
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className="call-and-btn-res-menu">
					<div className="top-res-btn">
						<Link to="/sign-up">sign up</Link>
					</div>
				</div>
			</div>
			{/* =================================== */}
		</>
	);
}

export default Header;
