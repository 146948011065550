import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReUse from "../services/helpers/reUse";
import AppService from "../services/appServices";

const AutoLogout = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const navigate = useNavigate();

  const Token = useSelector((state) => state.persistedReducer.auth.token);
  const currentUser = useSelector((state) => state.persistedReducer.auth.user);
  const userRole = currentUser?.role || "";
  const userRememberMe = currentUser?.remember_me || 0;

  useEffect(() => {
    if (Token) {
      setIsLoggedIn(true);
    } else setIsLoggedIn(false);
  }, [Token]);

  // // Function to simulate user login
  // const handleLogin = () => {
  // 	setIsLoggedIn(true);
  // };

  // Function to simulate user logout
  const handleLogout = async () => {
    setIsLoggedIn(false);
    await logOutUser();
  };

  const logOutUser = async () => {
    try {
      if (userRole.toLowerCase() === "seller") {
        const apiResp = await AppService.sellerLogout();
        const { message, response } = apiResp?.data;
        await ReUse.logOutUser();
      } else {
        const apiResp = await AppService.customerLogout();
        const { message, response } = apiResp?.data;
        await ReUse.logOutUser();
      }
    } catch (err) {}
  };

  // Set a timer for automatic logout after 10 minutes of inactivity
  useEffect(() => {
    let logoutTimer;

    const resetTimer = () => {
      clearTimeout(logoutTimer);
      const timeValues = {
        1: { days: 7, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 },
        0: { days: 0, hours: 24, minutes: 0, seconds: 0, milliseconds: 0 },
      };

      const { days, hours, minutes, seconds, milliseconds } =
        timeValues[userRememberMe];

      const sessionTime =
        days * 24 * 60 * 60 * 1000 +
        hours * 60 * 60 * 1000 +
        minutes * 60 * 1000 +
        seconds * 1000 +
        milliseconds;

      logoutTimer = setTimeout(handleLogout, sessionTime);
    };

    if (isLoggedIn) {
      resetTimer();

      // Add event listeners to detect user activity and reset the timer
      const onActivity = () => {
        resetTimer();
      };

      document.addEventListener("mousemove", onActivity);
      document.addEventListener("keydown", onActivity);

      // Clean up event listeners when component unmounts or user logs out
      return () => {
        clearTimeout(logoutTimer);
        document.removeEventListener("mousemove", onActivity);
        document.removeEventListener("keydown", onActivity);
      };
    }
  }, [isLoggedIn]);

  return (
    <div>
      {/* {isLoggedIn ? (
				<div>
					<h2>Welcome, User!</h2>
					<button onClick={handleLogout}>Logout</button>
				</div>
			) : (
				<button onClick={handleLogin}>Login</button>
			)} */}
    </div>
  );
};

export default AutoLogout;
