import React from "react";
import ReUse from "../../services/helpers/reUse";
import ProductCard from "./ProductCard";
import { useState } from "react";

const HomeCategoriesBody = ({ currentIndex, otherProps }) => {
  const [hover, setHover] = useState(false);

  const {
    category_id,
    category_img,
    category_name,
    sub_category_id,
    sub_category_name,
    color,
    title,
    products,
  } = otherProps ?? {};

  //   const hoverStyle = {
  //     content: "",
  //     width: "100%",
  //     height: "100%",
  //     position: "absolute",
  //     display: "block",
  //     background: "#000000",
  //     top: "0px",
  //     transform: "translateY(100%)",
  //     transition: "all 0.4s ease-in-out",
  //   };

  const hoverStyle = {
    backgroundColor: "aquamarine",
  };

  const normalStyle = {
    backgroundColor: "red",
  };

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  const sectionStyles = {
    backgroundColor: color !== null && color !== "" ? color : "#7dab3c",
  };

  return (
    <>
      {" "}
      <section className="oraganic-food-sec">
        <div className="container">
          <div className="organic-all">
            <div className="row">
              <div
                className="col-md-4 for-back-color-and-raduis"
                style={sectionStyles}
              >
                <div className="background-head">
                  <h3>{title}</h3>
                </div>
                <div className="organic-img">
                  <figure
                  >
                    <img
                      className="img=fluid"
                      alt="organic-pic"
                      src={
                        category_img && category_img !== null
                          ? category_img
                          : ReUse.defaultThumbnailImage({
                              content: "No+Image+Available",
                            })
                      }
                      onError={ReUse.onImageError}
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row all-class-for-padding">
                  {ReUse.mapItems(false, products, ProductCard)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeCategoriesBody;
