import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import Siderbar from "./Siderbar";
import ReUse from "../../services/helpers/reUse";
import { toast } from "react-toastify";
import AppService from "../../services/appServices";
import { useDispatch } from "react-redux";
import AccessControl from "../../Components/Auth/AccessControl";
import {
	ResponseMessageActions,
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import CustomButton from "../../Components/ReUse/Components/Button";
import FieldError from "../../Components/ReUse/FieldError";

function Newpassword() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [loading, setLoading] = useState(false);

	const [allErrors, setAllErrors] = useState({
		current_password: "",
		password: "",
		confirm_password: "",
	});

	const [fieldsData, setFieldsData] = useState({
		current_password: "",
		password: "",
		confirm_password: "",
	});

	function handleChange(e) {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		const { value, name } = e.target;
		setFieldsData({
			...fieldsData,
			[name]: value,
		});
	}

	const onSubmitHandler = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const { current_password, password, confirm_password } = fieldsData;

			const fields = {
				current_password,
				password,
				confirm_password,
			};
			const allErrors = ReUse.validateRequired({ fields: fields });

			// Check if there are any errors
			if (Object.keys(allErrors).length > 0) {
				setAllErrors(allErrors);
				setLoading(false);
				return;
			}

			if (password !== confirm_password) {
				setLoading(false);
				dispatch(errorMessage("Passwords do not match"));
				dispatch(clearResponseMessageAfterDelay());
				return;
			}

			const payLoad = {
				password,
				confirm_password,
				current_password,
			};

			const apiResp = await AppService.customerResetPassword(payLoad);
			const { message, response } = apiResp.data;
			setLoading(false);
			dispatch(successMessage(message));
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/my-profile");
				}, 1000);
			}, 1000);

			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
			dispatch(successMessage(""));
		}
	};



	return (
		<>
			<PageHeader pagename="change password" />
			{/* New Password Section */}
			<AccessControl allowedRoles={["customer"]}>
				<section className="edit-profile-sec my-profile-all">
					<div className="container">
						<div className="row">
							<div className="col-md-2">
								<Siderbar />
							</div>
							<div className="col-md-10">
								<div className="all-edit-screen">
									<div className="row">
										<div className="col-md-10">
											<form>
												<div className="row">
													<div className="col-md-6">
														<div className="edit-profile-input">
															<input
																type="password"
																placeholder="Enter Your Current Password"
																onChange={handleChange}
																name="current_password"
																value={fieldsData?.current_password}
															/>
															<FieldError
																fieldName={allErrors?.current_password}
															/>
															<div className="icon-of-edit-profile">
																<i class="fa fa-key" aria-hidden="true"></i>
															</div>
															<div className="icon-eye">
																<i
																	class="fa fa-eye-slash"
																	aria-hidden="true"
																></i>
															</div>
														</div>
													</div>
													<div className="col-md-6 hide-on-responsive"></div>
													<div className="col-md-6">
														<div className="edit-profile-input">
															<input
																type="password"
																placeholder="Enter Your New Password"
																onChange={handleChange}
																name="password"
																value={fieldsData?.password}
															/>
															<FieldError fieldName={allErrors?.password} />
															<div className="icon-of-edit-profile">
																<i class="fa fa-key" aria-hidden="true"></i>
															</div>
															<div className="icon-eye">
																<i
																	class="fa fa-eye-slash"
																	aria-hidden="true"
																></i>
															</div>
														</div>
													</div>
													<div className="col-md-6 hide-on-responsive"></div>
													<div className="col-md-6">
														<div className="edit-profile-input">
															<input
																type="password"
																placeholder="Enter Your Confirm New Password"
																onChange={handleChange}
																name="confirm_password"
																value={fieldsData?.confirm_password}
															/>
															<FieldError
																fieldName={allErrors?.confirm_password}
															/>
															<div className="icon-of-edit-profile">
																<i class="fa fa-key" aria-hidden="true"></i>
															</div>
															<div className="icon-eye">
																<i
																	class="fa fa-eye-slash"
																	aria-hidden="true"
																></i>
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>
										<div className="col-md-2">
											<div className="profile-edit-btn">
												{/* <Link>Save</Link> */}
												<a>
													<CustomButton
														loading={loading}
														onClickHandler={onSubmitHandler}
														customClassName="btn"
													>
														Submit
													</CustomButton>
												</a>
												<ResponseMessage />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</AccessControl>

			{/* ======================== */}
		</>
	);
}

export default Newpassword;
