import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import ReUse from "../../services/helpers/reUse";

const ProductCard = ({ currentIndex, ...otherProps }) => {
  const [hover, setHover] = useState(false);

  const {
    category_id,
    category_name,
    sub_category_id,
    sub_category_name,
    product_id,
    product_name,
    unit_price,
    rating,
    thumbnail,
    slug,
    color,
  } = otherProps ?? {};

  const hoverStyle = {
    content: '""',
    width: "100%",
    height: "100%",
    // position: "absolute",
    display: "block",
    background: "#000",
    top: "0px",
    transition: "all 0.4s ease-in-out",
    transform: "translateY(0%)",
  };

  const normalStyle = {
    content: '""',
    width: "100%",
    height: "100%",
    // position: "absolute",
    display: "block",
    background: "#7dab3c47",
    top: "0px",
    transform: "translateY(100%)",
    transition: "all 0.4s ease-in-out",
  };

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  const styleToApply = hover ? hoverStyle : normalStyle;

  return (
    <>
      <div className="col-md-4">
        <Link
          to={`/product-detail/${slug}`}
          state={{
            product_id: slug,
            currentIndex: currentIndex,
          }}
        >
          <div
            className="slide-dv organic-food"
            // onMouseEnter={onMouseEnter}
            // onMouseLeave={onMouseLeave}
          >
            <figure>
              <div
              //   style={styleToApply}
              >
                <img
                  className="img-fluid"
                  alt="slid-1"
                  src={
                    thumbnail && thumbnail !== null
                      ? thumbnail
                      : ReUse.defaultThumbnailImage({
                          content: "No+Image+Available",
                        })
                  }
                  onError={ReUse.onImageError}
                />
              </div>
            </figure>
            <div className="slide-text">
              <p>{ReUse.sliceText({ content: product_name })}</p>
              <div className="slide-for-flex">
                <h5>${unit_price && unit_price}</h5>
                <div className="slide-star">
                  <Rating
                    allowFraction={true}
                    initialValue={rating}
                    readonly={true}
                    size={20}
                  />
                </div>
              </div>
            </div>
            <div className="product-heading">
              <div className="pro-head">
                <h5>
                  {ReUse.sliceText({ content: category_name, noOfWords: 1 })}
                </h5>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default ProductCard;
