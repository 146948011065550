import React from "react";
import PropTypes from "prop-types";
import LoaderForContent from "../../LoaderForContent";
import LoaderForButton from "../../LoaderForButton";
import { useSelector } from "react-redux";

const CustomButton = ({
	children,
	customClassName,
	loading,
	onClickHandler,
}) => {
	const response = useSelector((state) => state.responseMessage);
	const { responseLoading, responseMessage, responseSucessMessage, error } =
		response ?? {};

	return (
		<button
			className={customClassName}
			disabled={loading}
			onClick={onClickHandler}
		>
			{loading ? <LoaderForButton /> : responseSucessMessage || children}
		</button>
	);
};

CustomButton.propTypes = {
	children: PropTypes.node.isRequired,
	customClassName: PropTypes.string,
	loading: PropTypes.bool,
	onClickHandler: PropTypes.func,
};

CustomButton.defaultProps = {
	customClassName: "Default_btn_style",
	loading: false,
	onClickHandler: () => {},
};

export default CustomButton;
