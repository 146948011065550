import React from "react";
import {
  sellerstore1,
  sellerstore10,
  sellerstore11,
  sellerstore12,
  sellerstore2,
  sellerstore3,
  sellerstore4,
  sellerstore5,
  sellerstore6,
  sellerstore7,
  sellerstore8,
} from "../../Constant/Index";
import ReUse from "../../services/helpers/reUse";
import { Rating } from "react-simple-star-rating";
import { useSelector } from "react-redux";

const ProductBySeller = (propsData) => {
  const {
    product_id,
    seller_id,
    product_name,
    slug,
    thumbnail,
    unit_price,
    rating,
    category_id,
    category_name,
    sub_category_id,
    sub_category_name,
  } = propsData ?? {};

  const productsFilterData = useSelector((state) => state.productsfilters);

  const { showGridCols } = productsFilterData ?? {};

  return (
    <>
      <div className={`col-md-${showGridCols}`}>
        <div className="slide-dv organic-food fashion-product-all">
          <figure>
            <img
              className="img-fluid"
              alt="slid-1"
              src={
                thumbnail !== null
                  ? thumbnail && thumbnail
                  : ReUse.defaultThumbnailImage({
                      content: "No+Image+Available",
                    })
              }
              onError={ReUse.onImageError}
            />
          </figure>
          <div className="slide-text">
            <p>{ReUse.sliceText({ content: product_name })} </p>
            <div className="slide-for-flex">
              <h5>${unit_price}</h5>
              <div className="slide-star">
                <Rating
                  size={20}
                  allowFraction={true}
                  initialValue={rating}
                  readonly={true}
                />
              </div>
            </div>
          </div>
          <div className="product-heading">
            <div className="pro-head">
              <h5>{category_name}</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductBySeller;
