import React from "react";
import Footer from "../../Components/Footer";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import "../../assets/css/seller-dashboard.css";
import Barchart from "../../Components/Barchart";
import AccessControl from "../../Components/Auth/AccessControl";
import { useLocation } from "react-router-dom";
import SellerDashboardContent from "../../Components/PID/SellerDashboardContent";
import ObjBasedApiDataLoader from "../../Components/ReUse/ReUsableHOC/ObjBasedApiDataLoader";
import { useState } from "react";
import { useEffect } from "react";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";

function SellerDashboard() {
	function PIDDetail({ loading, data }) {
		return <SellerDashboardContent data={data} />;
	}

	const EnhancedPIDDetail = ObjBasedApiDataLoader(PIDDetail);

	const [apiData, setApiData] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const sellerGetOrderActivity = AppService.sellerGetOrderActivity();
		ReUse.getApiData(sellerGetOrderActivity, setApiData, setLoading);
	}, []);

	return (
		<>
			<EnhancedPIDDetail data={apiData} loading={loading} />
		</>
	);
}

export default SellerDashboard;
