import React from "react";
import { vegiorder1 } from "../../Constant/Index";
import ReUse from "../../services/helpers/reUse";

const CustomerOrderProduct = (propsData) => {
	const { name, price, quantity, thumbnail } = propsData ?? {};
	return (
		<>
			<div className="all-img-flex">
				<div className="img-one">
					<figure>
						<img
							className="img-fluid"
							alt="img-one"
							src={
								thumbnail && thumbnail !== null
									? thumbnail
									: ReUse.defaultThumbnailImage({
											content: "No+Image+Available",
									  })
							}
							onError={ReUse.onImageError}
						/>
					</figure>
					<div className="img-text-all">
						<h4>{name}</h4>
						<h5>Qty: {quantity || 0}</h5>
					</div>
				</div>

				<div className="order-price">
					<h5>${price || 0} </h5>
				</div>
			</div>
		</>
	);
};

export default CustomerOrderProduct;
