import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
	organic1,
	organic2,
	organic3,
	slider1,
	slider4,
} from "../../Constant/Index";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import { Rating } from "react-simple-star-rating";
import { useSelector } from "react-redux";

const ShopProduct = ({ currentIndex, ...otherProps }) => {
	const location = useLocation();

	const [productNavigate, setProductNavigate] = useState();

	const {
		id,
		product_id,
		seller_id,
		product_name,
		category_id,
		category_name,
		sub_category_id,
		sub_category_name,
		slug,
		thumbnail,
		unit_price,
		rating,
		categories,
	} = otherProps ?? {};

	const productsFilterData = useSelector((state) => state.productsfilters);

	const { showGridCols } = productsFilterData ?? {};

	const { pathname } = location ?? {};

	useEffect(() => {
		const pathToProductMap = {
			"/customize-product": `/product-customize-detail/${slug}`,
			"/shop": `/product-detail/${slug}`,
		};

		const newPath = pathToProductMap[pathname];

		if (newPath) {
			setProductNavigate(newPath);
		}
	}, [pathname]);

	return (
		<>
			<div className={`col-md-${showGridCols}`}>
				<Link
					to={`${productNavigate}`}
					state={{
						product_id: slug,
						currentIndex: currentIndex,
					}}
				>
					<div className="slide-dv organic-food fashion-product-all">
						<figure>
							<img
								className="img-fluid"
								alt="slid-1"
								src={
									thumbnail !== null
										? thumbnail && thumbnail
										: ReUse.defaultThumbnailImage({
												content: "No+Image+Available",
										  })
								}
								onError={ReUse.onImageError}
							/>
						</figure>
						<div className="slide-text">
							<p>{ReUse.sliceText({ content: product_name })} </p>
							<div className="slide-for-flex">
								<h5>${unit_price && unit_price}</h5>
								<div className="slide-star">
									<Rating
										allowFraction={true}
										initialValue={rating && rating}
										readonly={true}
										size={20}
									/>
								</div>
							</div>
						</div>
						<div className="product-heading">
							<div className="pro-head">
								<h5>{sub_category_name && sub_category_name}</h5>
							</div>
						</div>
					</div>
				</Link>
			</div>
		</>
	);
};

export default ShopProduct;
