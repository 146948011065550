import React from "react";
import { BounceLoader } from "react-spinners";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CSSProperties = {
	display: "block",
	margin: "0 auto",
	borderColor: "red",
};

const LoaderForApi = ({ lines = 10 }) => {
	return (
		// <BounceLoader
		//   color="#7dab3c"
		//   cssOverride={CSSProperties}
		//   size={150}
		//   aria-label="Loading Spinner"
		//   data-testid="loader"
		// />
		<Skeleton count={lines} />
	);
};

export default LoaderForApi;
