import React from "react";

const MeasurementField = ({ measurementFieldProps }) => {
  const {
    productMeasurements,
    setMeasurements,
    measurements,
    currentIndex,
    currentMeasurement,
  } = measurementFieldProps ?? {};

  const { title, key } = productMeasurements ?? {};

  const onMeasurementChange = (e) => {
    const { value, name } = e.target;
    setMeasurements((prevMeasurements) => ({
      ...prevMeasurements,
      [name]: parseInt(value),
    }));
  };



  return (
    <div className="col-md-4">
      <div className="mcq-dv-input get-quote-mcq-dv-input">
        <label>{currentMeasurement}</label>
        <input
          type="text"
          placeholder="00"
          max="2"
          name={currentMeasurement}
          value={measurements[currentMeasurement]}
          onChange={onMeasurementChange}
        />
      </div>
    </div>
  );
};

export default MeasurementField;
