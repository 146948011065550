import React from "react";
import Header from "../../Components/Header";
import "../../assets/css/about.css";
import { about1 } from "../../Constant/Index";
import Footer from "../../Components/Footer";
import Pageheader from "../../Components/Pageheader";

function About() {
	return (
		<>
			{/* About Section */}
			<Pageheader pagename="About Us" />
			<section className="about-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="about-all">
								<h3>Read Our Most Recent Blogs</h3>
								<p>
									Lorem Ipsum is simply dummy text of the printing and
									typesetting industry. Lorem Ipsum has been the industry's
									standard dummy text ever since the 1500s, when an unknown
									printer took a galley of type and scrambled it to make a type
									specimen book
								</p>
								<p>
									{" "}
									It has survived not only five centuries, but also the leap
									into electronic typesetting, remaining essentially unchanged.
									It was popularised in the 1960s with the release of Letraset
									sheets containing Lorem Ipsum passages,and more recently with
									desktop publishing software like Aldus PageMaker including
									versions of Lorem Ipsum.
								</p>
								<p>
									Lorem Ipsum is simply dummy text of the printing and
									typesetting industry. Lorem Ipsum has been the industry's
									standard dummy text ever since the 1500s, when an unknown
									printer took a galley of type and scrambled it to make a type
									specimen book.
								</p>
							</div>
						</div>
						<div className="col-md-6">
							<div className="about-img">
								<figure>
									<img className="img-fluid" alt="about-img" src={about1} />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ====================== */}

			{/* Best Product Section */}
			<section className="best-product-sec">
				<div className="container">
					<div className="best-product-all">
						<div className="row">
							<div className="col-md-12">
								<div className="best-pro-all">
									<h4>we help you find best Product</h4>
									<p>
										Lorem Ipsum is simply dummy text of the printing and
										typesetting industry. Lorem Ipsum has been the industry's
										standard dummy text ever since the 1500s,
									</p>
									<p className="best-pro-all">
										when an unknown printer took a galley of type and scrambled
										it to make a type specimen book. It has survived not only
										five centuries, but also the leap into electronic
										typesetting, remaining essentially unchanged.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ====================================== */}

			{/* Help Customer Section */}
			<section className="help-customer-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="all-help-customer">
								<h4>how we help Customer?</h4>
								<p>
									Lorem Ipsum is simply dummy text of the printing and
									typesetting industry. Lorem Ipsum has been the industry's
									standard dummy text ever since the 1500s, when an unknown
									printer took a galley of type and scrambled it to make a type
									specimen book.
								</p>
								<p>
									{" "}
									It has survived not only five centuries, but also the leap
									into electronic typesetting, remaining essentially unchanged.
									It was popularised in the 1960s with the release of
								</p>
								<p>
									Letraset sheets containing Lorem Ipsum passages,and more
									recently with desktop publishing software like Aldus PageMaker
									including versions of Lorem Ipsum.
								</p>
							</div>
						</div>

						<div className="col-md-6">
							<div className="all-help-customer">
								<h4>how we help merchants?</h4>
								<p>
									Lorem Ipsum is simply dummy text of the printing and
									typesetting industry. Lorem Ipsum has been the industry's
									standard dummy text ever since the 1500s, when an unknown
									printer took a galley of type and scrambled it to make a type
									specimen book.
								</p>
								<p>
									{" "}
									It has survived not only five centuries, but also the leap
									into electronic typesetting, remaining essentially unchanged.
									It was popularised in the 1960s with the release of
								</p>
								<p>
									Letraset sheets containing Lorem Ipsum passages,and more
									recently with desktop publishing software like Aldus PageMaker
									including versions of Lorem Ipsum.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ======================================== */}
		</>
	);
}
export default About;
