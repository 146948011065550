import React from "react";
import Footer from "../../Components/Footer";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import { key } from "../../Constant/Index";
import AccessControl from "../../Components/Auth/AccessControl";
import {
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import ReUse from "../../services/helpers/reUse";
import { toast } from "react-toastify";
import AppService from "../../services/appServices";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import CustomButton from "../../Components/ReUse/Components/Button";
import FieldError from "../../Components/ReUse/FieldError";

function Sellerchangepassword() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [loading, setLoading] = useState(false);

	const [allErrors, setAllErrors] = useState({
		current_password: "",
		password: "",
		confirm_password: "",
	});

	const [fieldsData, setFieldsData] = useState({
		current_password: "",
		password: "",
		confirm_password: "",
	});

	function handleChange(e) {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		const { value, name } = e.target;
		setFieldsData({
			...fieldsData,
			[name]: value,
		});
	}

	const onSubmitHandler = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const { current_password, password, confirm_password } = fieldsData;

			const fields = {
				current_password,
				password,
				confirm_password,
			};
			const allErrors = ReUse.validateRequired({ fields: fields });

			// Check if there are any errors
			if (Object.keys(allErrors).length > 0) {
				setAllErrors(allErrors);
				setLoading(false);
				return;
			}

			const payLoad = {
				password,
				confirm_password,
				current_password,
			};

			const apiResp = await AppService.sellerResetPassword(payLoad);
			const { message, response } = apiResp.data;
			setLoading(false);
			dispatch(successMessage(message));
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/my-profile");
				}, 1000);
			}, 1000);

			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
			dispatch(successMessage(""));
		}
	};

	return (
		<>
			<AccessControl allowedRoles={["seller"]}>
				<SellerHeader />
				{/* Seller profile starts here */}
				<section className="seller-dashboard">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-3 ps-0">
								<SellerSidebar />
							</div>
							<div className="col-lg-8">
								<div className="edit-profile-card edit-no-padding-right">
									<div className="edit-heading-wrapper">
										<h2>Change Password</h2>
									</div>

									<div className="seller-edit-with-border-dv">
										<div className="row">
											<div className="col-md-10">
												<div className="edit-details-form">
													<div className="seller-all-form">
														<form>
															<div className="row">
																<div className="col-md-6">
																	<div className="seller-edit-form-input">
																		<input
																			type="password"
																			placeholder="Enter Your Current Password"
																			onChange={handleChange}
																			name="current_password"
																			value={fieldsData?.current_password}
																		/>
																		<FieldError
																			fieldName={allErrors?.current_password}
																		/>
																		<div className="seller-dashboard-icon">
																			<figure>
																				<img
																					className="img-fluid"
																					alt="seller-key-img"
																					src={key}
																				/>
																			</figure>
																		</div>
																		<div className="eyes-icon">
																			<i
																				class="fa fa-eye-slash"
																				aria-hidden="true"
																			></i>
																		</div>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="seller-edit-form-input">
																		<input
																			type="password"
																			placeholder="Enter Your New Password"
																			onChange={handleChange}
																			name="password"
																			value={fieldsData?.password}
																		/>
																		<FieldError
																			fieldName={allErrors?.password}
																		/>
																		<div className="seller-dashboard-icon">
																			<figure>
																				<img
																					className="img-fluid"
																					alt="seller-key-img"
																					src={key}
																				/>
																			</figure>
																		</div>
																		<div className="eyes-icon">
																			<i
																				class="fa fa-eye-slash"
																				aria-hidden="true"
																			></i>
																		</div>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="seller-edit-form-input">
																		<input
																			type="password"
																			placeholder="Confirm New Password"
																			onChange={handleChange}
																			name="confirm_password"
																			value={fieldsData?.confirm_password}
																		/>
																		<FieldError
																			fieldName={allErrors?.confirm_password}
																		/>
																		<div className="seller-dashboard-icon">
																			<figure>
																				<img
																					className="img-fluid"
																					alt="seller-key-img"
																					src={key}
																				/>
																			</figure>
																		</div>
																		<div className="eyes-icon">
																			<i
																				class="fa fa-eye-slash"
																				aria-hidden="true"
																			></i>
																		</div>
																	</div>
																</div>
															</div>
														</form>
													</div>
												</div>
											</div>
											<div className="col-md-2">
												<div className="seller-save-btn">
													<CustomButton
														loading={loading}
														onClickHandler={onSubmitHandler}
														customClassName="btn"
													>
														Submit
													</CustomButton>

													<ResponseMessage />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* Seller profile ends here */}
			</AccessControl>
		</>
	);
}

export default Sellerchangepassword;
