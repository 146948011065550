import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const CartSlice = createSlice({
	name: "cart",
	initialState: {
		loading: false,
		cartData: [],
		error: null,
	},
	reducers: {
		Add_To_Cart: (state, action) => {
			state.cartData = [...state.cartData, { ...action.payload }];
		},

		Remove_Cart_Item: (state, action) => {
			state.cartData = [
				...state.cartData.filter((val, index) => index !== action.payload),
			];
		},

		Clear_Cart: (state, action) => {
			state.cartData = [];
		},

		UPDATE_CART_ITEM: (state, action) => {
			let newItems = [...state.cartData];
			let index = newItems.findIndex(
				(value) => value?.u_Id === action?.payload?.u_Id,
			);
			if (index >= 0) {
				let item = newItems.find(
					(value) => value?.u_Id === action.payload?.u_Id,
				);
				newItems[index] = {
					...item,
					data: {
						...item.data,
						order_detail_image_id: action?.payload?.response?.id,
					},
					imageUrl: action?.payload?.response?.imageUrl,
				};
			}
			state.cartData = newItems;
		},

		REMOVE_PRODUCT_IMAGE: (state, action) => {
			let newItems = [...state.cartData];
			let index = action.payload?.index;
			if (index >= 0) {
				let item = newItems.find(
					(value) => value?.u_Id === action.payload?.u_Id,
				);
				newItems[index] = {
					...item,
					data: {
						...item.data,
						order_detail_image_id: 0,
					},
					imageUrl: "",
				};
			}
			state.cartData = newItems;
		},

		INCREMENT_PRODUCT_QTY: (state, action) => {
			let UpdateCart = state.cartData.map((curElem, index) => {
				if (index === action.payload) {
					return { ...curElem, qty: curElem.qty >= 1 ? curElem.qty + 1 : 1 };
				}
				return curElem;
			});

			return { ...state, cartData: UpdateCart };
		},

		DECREMENT_PRODUCT_QTY: (state, action) => {
			let UpdateCart = state.cartData
				.map((curElem, index) => {
					if (index === action.payload) {
						return { ...curElem, qty: curElem.qty > 1 ? curElem.qty - 1 : 1 };
					}
					return curElem;
				})
				.filter((curElem) => {
					return curElem?.qty !== 0;
				});
			return { ...state, cartData: UpdateCart };
		},
	},
});

export const CartActions = CartSlice.actions;
export default CartSlice.reducer;

// export default CartSlice.reducer;
