import React from "react";
import Footer from "../../Components/Footer";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import {
	business,
	des,
	svg1,
	svg2,
	svg3,
	svg4,
	svg5,
	svg6,
} from "../../Constant/Index";
import AccessControl from "../../Components/Auth/AccessControl";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import ReUse from "../../services/helpers/reUse";
import AppService from "../../services/appServices";
import { toast } from "react-toastify";
import {
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import CustomButton from "../../Components/ReUse/Components/Button";
import FieldError from "../../Components/ReUse/FieldError";

function Sellerbuisnesseditprofile() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState();
	const [fileupload, setFileupload] = useState();
	const [addModalIsOpen, setAddModalIsOpen] = useState(false);

	const [allErrors, setAllErrors] = useState({
		business_name: "",
		street_address: "",
		company_address_1: "",
		company_address_2: "",
		city: "",
		state: "",
		zipcode: "",
		country_id: "",
		company_email: "",
		description: "",
		business_website: "",
	});

	const [fieldsData, setFieldsData] = useState({
		business_name: "",
		street_address: "",
		company_address_1: "",
		company_address_2: "",
		city: "",
		state: "",
		zipcode: "",
		country_id: "",
		company_email: "",
		description: "",
		business_website: "",
	});

	function handleChange(e) {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		const { value, name } = e.target;
		setFieldsData({
			...fieldsData,
			[name]: value,
		});
	}

	const onSubmitHandler = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const {
				business_name,
				street_address,
				company_address_1,
				company_address_2,
				city,
				state,
				zipcode,
				country_id,
				company_email,
				description,
				business_website,
			} = fieldsData;

			const fields = {
				business_name,
				street_address,
				company_address_1,
				company_address_2,
				city,
				state,
				zipcode,
				country_id,
				company_email,
				description,
				business_website,
			};
			const allErrors = ReUse.validateRequired({ fields: fields });

			// Check if there are any errors
			if (Object.keys(allErrors).length > 0) {
				setAllErrors(allErrors);
				setLoading(false);
				return;
			}

			const payLoad = {
				business_name,
				street_address,
				company_address_1,
				company_address_2,
				city,
				state,
				zipcode,
				country_id,
				company_email,
				description,
				business_website,
			};

			const apiResp = await AppService.sellerUpdatePersonalDetails(payLoad);
			const { message, response } = apiResp.data;
			setLoading(false);
			dispatch(successMessage(message));
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/my-profile");
				}, 1000);
			}, 1000);

			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
		} catch (err) {
			setLoading(false);
			// toast.error(err?.response?.data?.message);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
		}
	};

	const {
		business_name,
		street_address,
		company_address_1,
		company_address_2,
		city,
		state,
		zipcode,
		country_id,
		company_email,
		description,
		business_website,
	} = fieldsData ?? {};

	return (
		<>
			<AccessControl allowedRoles={["seller"]}>
				<SellerHeader />
				{/* Seller Edit Business starts here */}
				<section className="seller-dashboard">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-3 ps-0">
								<SellerSidebar />
							</div>
							<div className="col-lg-8">
								<div className="edit-profile-card edit-no-padding-right adding-seller-business-profile">
									<div className="edit-heading-wrapper">
										<h2>Edit Business Profile</h2>
									</div>
									<div className="seller-edit-with-border-dv">
										<div className="row">
											<div className="col-md-10">
												<div className="edit-details-form">
													<div className="seller-all-form">
														<form>
															<div className="row">
																<div className="col-md-12">
																	<div className="seller-edit-form-input">
																		<input
																			type="text"
																			placeholder="Business Name"
																			onChange={handleChange}
																			name="business_name"
																			value={business_name}
																		/>
																		<FieldError
																			fieldName={allErrors?.business_name}
																		/>

																		<div className="seller-dashboard-icon">
																			<figure>
																				<img
																					className="img-fluid"
																					alt="icon-svg"
																					src={svg1}
																				/>
																			</figure>
																		</div>
																	</div>
																</div>
																<div className="col-md-12">
																	<div className="seller-edit-form-input">
																		<input
																			type="text"
																			placeholder="Address 1"
																			onChange={handleChange}
																			name="company_address_1"
																			value={company_address_1}
																		/>
																		<FieldError
																			fieldName={allErrors?.company_address_1}
																		/>

																		<div className="seller-dashboard-icon">
																			<figure>
																				<img
																					className="img-fluid"
																					alt="icon-svg"
																					src={svg2}
																				/>
																			</figure>
																		</div>
																	</div>
																</div>
																<div className="col-md-12">
																	<div className="seller-edit-form-input">
																		<input
																			type="text"
																			placeholder="Address 2"
																			onChange={handleChange}
																			name="company_address_2"
																			value={company_address_2}
																		/>
																		<FieldError
																			fieldName={allErrors?.company_address_2}
																		/>

																		<div className="seller-dashboard-icon">
																			<figure>
																				<img
																					className="img-fluid"
																					alt="icon-svg"
																					src={svg2}
																				/>
																			</figure>
																		</div>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="seller-edit-form-input">
																		<input
																			type="text"
																			placeholder="Street Address"
																			onChange={handleChange}
																			name="street_address"
																			value={street_address}
																		/>
																		<FieldError
																			fieldName={allErrors?.street_address}
																		/>

																		<div className="seller-dashboard-icon">
																			<figure>
																				<img
																					className="img-fluid"
																					alt="icon-svg"
																					src={svg3}
																				/>
																			</figure>
																		</div>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="seller-edit-form-input">
																		<input
																			type="text"
																			placeholder="City"
																			onChange={handleChange}
																			name="city"
																			value={city}
																		/>
																		<FieldError fieldName={allErrors?.city} />

																		<div className="seller-dashboard-icon">
																			<figure>
																				<img
																					className="img-fluid"
																					alt="icon-svg"
																					src={svg3}
																				/>
																			</figure>
																		</div>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="seller-edit-form-input">
																		<input
																			type="text"
																			placeholder="Zipcode"
																			onChange={handleChange}
																			name="zipcode"
																			value={zipcode}
																		/>
																		<FieldError
																			fieldName={allErrors?.zipcode}
																		/>

																		<div className="seller-dashboard-icon">
																			<figure>
																				<img
																					className="img-fluid"
																					alt="icon-svg"
																					src={svg4}
																				/>
																			</figure>
																		</div>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="seller-edit-form-input">
																		<input
																			type="text"
																			placeholder="State"
																			onChange={handleChange}
																			name="state"
																			value={state}
																		/>
																		<FieldError fieldName={allErrors?.state} />

																		<div className="seller-dashboard-icon">
																			<figure>
																				<img
																					className="img-fluid"
																					alt="icon-svg"
																					src={svg4}
																				/>
																			</figure>
																		</div>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="seller-edit-form-input">
																		<input type="text" placeholder="dob" />
																		<FieldError fieldName={allErrors?.dob} />

																		<div className="seller-dashboard-icon">
																			<i
																				class="fa fa-phone"
																				aria-hidden="true"
																			></i>
																		</div>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="seller-edit-form-input">
																		<input
																			type="text"
																			placeholder="Website"
																			onChange={handleChange}
																			name="business_website"
																			value={business_website}
																		/>
																		<FieldError
																			fieldName={allErrors?.business_website}
																		/>

																		<div className="seller-dashboard-icon">
																			<i
																				class="fa fa-envelope"
																				aria-hidden="true"
																			></i>
																		</div>
																	</div>
																</div>
																<div className="col-md-12">
																	<div className="seller-edit-form-input">
																		<input
																			type="text"
																			placeholder="Business Email"
																			onChange={handleChange}
																			name="company_email"
																			value={company_email}
																		/>
																		<FieldError
																			fieldName={allErrors?.company_email}
																		/>

																		<div className="seller-dashboard-icon">
																			<figure>
																				<img
																					className="img-fluid"
																					alt="icon-svg"
																					src={svg5}
																				/>
																			</figure>
																		</div>
																	</div>
																</div>
																<div className="col-md-12">
																	<div className="check-seller">
																		<div class="form-group">
																			<input
																				type="checkbox"
																				id="bottom-check"
																			/>
																			<label for="bottom-check">
																				Send emails to me at lorem
																				ipsum@business.com instead of the email
																				address above.
																			</label>
																		</div>
																	</div>
																</div>
																<div className="col-md-12">
																	<div className="seller-edit-form-input">
																		<textarea
																			class="form-control"
																			id="exampleFormControlTextarea1"
																			rows="3"
																			placeholder="Description"
																			onChange={handleChange}
																			name="description"
																			value={description}
																		></textarea>
																		<FieldError
																			fieldName={allErrors?.description}
																		/>

																		<div className="seller-dashboard-icon">
																			<figure>
																				<img
																					className="img-fluid"
																					alt="icon-svg"
																					src={des}
																				/>
																			</figure>
																		</div>
																	</div>
																</div>
															</div>
														</form>
													</div>
												</div>
											</div>
											<div className="col-md-2">
												<div className="seller-save-btn">
													<CustomButton
														loading={loading}
														onClickHandler={onSubmitHandler}
														customClassName="custom-btn"
													>
														Submit
													</CustomButton>

													<ResponseMessage />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* Seller Edit Business starts here  */}
			</AccessControl>
		</>
	);
}

export default Sellerbuisnesseditprofile;
