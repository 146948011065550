import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";

const AddressVeiwModal = ({ veiwModalIsOpen, closeModal, propsData }) => {
	const {
		id,
		first_name,
		last_name,
		phone,
		email,
		address,
		country_id,
		country_name,
		state_id,
		state_name,
		city_id,
		city_name,
		zip_code,
		default_status,
	} = propsData ?? {};

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
		},
	};

	return (
		<>
			<Modal
				isOpen={veiwModalIsOpen}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Example Modal"
			>
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="adress_modal_wrapper">
								<p>
									<b>First Name:</b> {first_name}
								</p>
								<p>
									<b>Last Name:</b> {last_name}
								</p>
								<p>
									<b>phone:</b> {phone}
								</p>
								<p>
									<b>email:</b> {email}
								</p>
								<p>
									<b>address:</b> {address}
								</p>
								<p>
									<b>Country Name: </b>
									{country_name}
								</p>
								<p>
									<b>State Name:</b> {state_name}
								</p>
								<p>
									<b>City Name: </b>
									{city_name}
								</p>
								<p>
									<b>Zip Code:</b> {zip_code}
								</p>
								<p>
									<b>Is Default:</b> {default_status === 1 ? "Yes" : "No"}
								</p>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default AddressVeiwModal;
