import { createSlice } from "@reduxjs/toolkit";

const ResponseMessageSlice = createSlice({
	name: "responseMessage",
	initialState: {
		responseLoading: false,
		responseMessage: "",
		responseSucessMessage: "",
		error: "",
	},
	reducers: {
		successMessage: (state, action) => {
			state.responseSucessMessage = action.payload;
			state.responseLoading = true;
		},

		errorMessage: (state, action) => {
			state.responseMessage = action.payload;
			state.responseLoading = true;
			state.error = action.payload || "Network Error, Please Try Again.";
		},

		clearResponseMessage: (state) => {
			state.responseMessage = "";
			state.responseSucessMessage = "";
			state.error = "";
			state.responseLoading = false;
		},
	},
});

export const {
	successMessage,
	errorMessage,
	clearResponseMessage,
} = ResponseMessageSlice.actions;

// Add a thunk action to clear the responseMessage after 5 seconds
export const clearResponseMessageAfterDelay = () => (dispatch) => {
	setTimeout(() => {
		dispatch(clearResponseMessage());
	}, 5000); // 5000 milliseconds (5 seconds)
};

export default ResponseMessageSlice.reducer;
