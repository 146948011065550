import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ReUse from "../../services/helpers/reUse";
import AppService from "../../services/appServices";
import { toast } from "react-toastify";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import Select from "react-select";
import {
  ResponseMessageActions,
  clearResponseMessageAfterDelay,
  errorMessage,
  successMessage,
} from "../../redux/slices/responseMessage.slice";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import CustomButton from "../../Components/ReUse/Components/Button";
import FieldError from "../../Components/ReUse/FieldError";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const AddressAddModal = ({ addressAddModalProps }) => {
  const { addModalIsOpen, closeModal } = addressAddModalProps ?? {};

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const [countriesLoader, setCountriesLoader] = useState(false);
  const [statesLoader, setStatesLoader] = useState(false);
  const [citiesLoader, setCitiesLoader] = useState(false);

  const [allCountries, setAllCountries] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [allCities, setAllCities] = useState([]);

  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);

  const [allErrors, setAllErrors] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    address: "",
    suite_apartment: "",
    country: "",
    state: "",
    city: "",
    zip_code: "",
    default_status: "",
  });

  const [fieldsData, setFieldsData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    address: "",
    suite_apartment: "",
    country: selectedCountryId,
    state: selectedStateId,
    city: selectedCityId,
    zip_code: "",
    default_status: "",
  });

  function handleChange(e) {
    const resetErrors = ReUse.resetErrors({ fields: allErrors });
    setAllErrors(resetErrors);
    const { value, name } = e.target;
    const isNumberOrText = ReUse.isNumberOrText({ stringInput: value });
    setFieldsData({
      ...fieldsData,
      [name]: isNumberOrText ? parseInt(value) : value,
    });
  }

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const {
        first_name,
        last_name,
        phone,
        email,
        address,
        suite_apartment,
        country,
        state,
        city,
        zip_code,
        default_status,
      } = fieldsData;

      const fields = {
        first_name,
        last_name,
        phone,
        email,
        address,
        suite_apartment,
        country,
        state,
        city,
        zip_code,
        default_status,
      };
      const allErrors = ReUse.validateRequired({ fields: fields });

      // Check if there are any errors
      if (Object.keys(allErrors).length > 0) {
        setAllErrors(allErrors);
        setLoading(false);
        return;
      }

      const payLoad = {
        first_name,
        last_name,
        phone,
        email,
        address,
        suite_apartment,
        zip_code,
        default_status,
        country_id: selectedCountryId,
        state_id: selectedStateId,
        city_id: selectedStateId,
      };

      const apiResp = await AppService.customerAddAddress(payLoad);
      const { message, response } = apiResp.data;
      setLoading(false);
      dispatch(successMessage(message));
      setTimeout(() => {
        dispatch(successMessage("Processing..."));
        setTimeout(() => {
          dispatch(successMessage(""));
        }, 1000);
      }, 1000);

      ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
      ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
      closeModal();
    } catch (err) {
      setLoading(false);
      dispatch(errorMessage(err?.response?.data?.message));
      dispatch(clearResponseMessageAfterDelay());
      ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
      ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
      dispatch(successMessage(""));
    }
  };

  const {
    first_name,
    last_name,
    phone,
    email,
    address,
    suite_apartment,
    country,
    state,
    city,
    zip_code,
    default_status,
  } = fieldsData ?? {};

  useEffect(() => {
    const countryList = AppService.countryList();
    ReUse.getApiData(countryList, setAllCountries, setCountriesLoader);
  }, []);

  useEffect(() => {
    const countryStatesList = AppService.countryStatesList({
      countryId: selectedCountryId,
    });
    ReUse.getApiData(countryStatesList, setAllStates, setStatesLoader);
  }, [selectedCountryId]);

  useEffect(() => {
    const stateCitieslist = AppService.stateCitieslist({
      stateId: selectedStateId,
    });
    ReUse.getApiData(stateCitieslist, setAllCities, setCitiesLoader);
  }, [selectedStateId]);

  const minifiedCountries = ReUse.minifyArrayOfObjects({
    data: allCountries,
  });
  const minifiedStates = ReUse.minifyArrayOfObjects({
    data: allStates,
  });
  const minifiedCities = ReUse.minifyArrayOfObjects({
    data: allCities,
  });

  return (
    <>
      <Modal
        isOpen={addModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="adress_modal_wrapper">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <input
                          type="text"
                          placeholder="first name"
                          onChange={handleChange}
                          name="first_name"
                          value={first_name}
                        />
                        <FieldError fieldName={allErrors?.first_name} />

                        <div className="icon-of-edit-profile">
                          <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <input
                          type="text"
                          placeholder="last name"
                          onChange={handleChange}
                          name="last_name"
                          value={last_name}
                        />
                        <FieldError fieldName={allErrors?.last_name} />

                        <div className="icon-of-edit-profile">
                          <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <input
                          type="email"
                          placeholder="Email "
                          name="email"
                          value={email}
                          onChange={handleChange}
                        />
                        <FieldError fieldName={allErrors?.email} />

                        <div className="icon-of-edit-profile">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <input
                          type="text"
                          placeholder="phone"
                          name="phone"
                          value={phone}
                          onChange={handleChange}
                        />
                        <FieldError fieldName={allErrors?.phone} />

                        <div className="icon-of-edit-profile">
                          <i class="fa fa-phone" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <input
                          type="text"
                          placeholder="address"
                          name="address"
                          value={address}
                          onChange={handleChange}
                        />
                        <FieldError fieldName={allErrors?.address} />

                        <div className="icon-of-edit-profile">
                          <i
                            class="fa fa-calendar-minus-o"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <input
                          type="text"
                          placeholder="suite_apartment"
                          name="suite_apartment"
                          onChange={handleChange}
                        />
                        <FieldError fieldName={allErrors?.suite_apartment} />

                        <div className="icon-of-edit-profile">
                          <i
                            class="fa fa-calendar-minus-o"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="edit-profile-input">
                        <Select
                          isMulti={false}
                          options={minifiedCountries}
                          closeMenuOnSelect={true}
                          // value={allCountries?.find(
                          // 	(option) => option?.value === selectedCountryId,
                          // )}
                          onChange={(selectedOption) => {
                            setSelectedCountryId(selectedOption?.value);
                          }}
                        />
                        <FieldError fieldName={allErrors?.country} />

                        {/* <div className="icon-of-edit-profile">
													<i
														class="fa fa-calendar-minus-o"
														aria-hidden="true"
													></i>
												</div> */}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <Select
                          isMulti={false}
                          options={minifiedStates}
                          closeMenuOnSelect={true}
                          // value={allStates?.find(
                          // 	(option) => option?.value === selectedStateId,
                          // )}
                          onChange={(selectedOption) => {
                            setSelectedStateId(selectedOption?.value);
                          }}
                        />
                        <FieldError fieldName={allErrors?.state} />

                        {/* <div className="icon-of-edit-profile">
													<i
														class="fa fa-calendar-minus-o"
														aria-hidden="true"
													></i>
												</div> */}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <Select
                          isMulti={false}
                          options={minifiedCities}
                          closeMenuOnSelect={true}
                          // value={allCities?.find(
                          // 	(option) => option?.value === selectedCityId,
                          // )}
                          onChange={(selectedOption) => {
                            setSelectedCityId(selectedOption?.value);
                          }}
                        />
                        <FieldError fieldName={allErrors?.city} />

                        {/* <div className="icon-of-edit-profile">
													<i
														class="fa fa-calendar-minus-o"
														aria-hidden="true"
													></i>
												</div> */}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <input
                          type="text"
                          placeholder="zip_code"
                          name="zip_code"
                          onChange={handleChange}
                          value={zip_code}
                        />
                        <FieldError fieldName={allErrors?.zip_code} />

                        <div className="icon-of-edit-profile">
                          <i
                            class="fa fa-calendar-minus-o"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="edit-profile-input">
                        <input
                          type="number"
                          placeholder="default_status"
                          name="default_status"
                          value={default_status}
                          onChange={handleChange}
                        />
                        <FieldError fieldName={allErrors?.default_status} />

                        <div className="icon-of-edit-profile">
                          <i
                            class="fa fa-calendar-minus-o"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="profile-edit-btn">
                        <a>
                          <CustomButton
                            loading={loading}
                            onClickHandler={onSubmitHandler}
                            customClassName="btn"
                          >
                            Submit
                          </CustomButton>
                        </a>
                        <ResponseMessage />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddressAddModal;
