import React, { useEffect, useState } from "react";
import Careercompo from "../../Components/Careercompo";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import { attach, careerdetail } from "../../Constant/Index";
import { Link, useLocation } from "react-router-dom";
import AppService from "../../services/appServices";
import JobApplicationForm from "./JobApplicationForm";
import ReUse from "../../services/helpers/reUse";
import CareerDetailContent from "../../Components/PID/CareerDetailContent";
import ObjBasedApiDataLoader from "../../Components/ReUse/ReUsableHOC/ObjBasedApiDataLoader";

function Careerdetail() {
	const location = useLocation();
	const getId = location?.state || null;

	const currentSlug = ReUse.extractIdFromCurrentUrl();

	const [apiData, setApiData] = useState({});
	const [loading, setLoading] = useState(false);

	function PIDdetail({ loading, data }) {
		return <CareerDetailContent data={data} />;
	}

	const EnhancedPIDDetail = ObjBasedApiDataLoader(PIDdetail);

	useEffect(() => {
		const getCareerDetails = AppService.getCareerDetails({
			jobId: currentSlug,
		});
		ReUse.getApiData(getCareerDetails, setApiData, setLoading);
	}, [currentSlug]);

	return <EnhancedPIDDetail data={apiData} loading={loading} />;
}

export default Careerdetail;
