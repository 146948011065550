import React from "react";
import { product1 } from "../Constant/Index";
import { Link } from "react-router-dom";
import ReUse from "../services/helpers/reUse";

const BannerSlide = (propsData) => {
	const { id, photo, title, sub_title, description, url } = propsData ?? {};

	return (
		<div className="slider-for-pro">
			<div className="all-banenr-back">
				<div className="row">
					<div className="col-md-5">
						<div className="banenr-text">
							<h5>{title}</h5>
							<h2>{sub_title}</h2>
							<div className="discover-btn">
								<Link to={url || "/"}>Discover Now</Link>
							</div>
						</div>
					</div>
					<div className="col-md-7">
						<div className="banner-image">
							<figure>
								<img
									className="img-fluid"
									alt="banner-img"
									src={
										photo && photo !== null
											? photo
											: ReUse.defaultThumbnailImage({
													content: "No+Image+Available",
											  })
									}
									onError={ReUse.onImageError}
								/>
							</figure>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BannerSlide;
