import React from "react";
import {
  ordersmap,
  vegiorder1,
  vegiorder2,
  vegiorder3,
} from "../../Constant/Index";
import { useEffect } from "react";
import ReUse from "../../services/helpers/reUse";
import AppService from "../../services/appServices";
import { useState } from "react";
import GlobalModal from "../../Components/ReUse/Modals/GlobalModal";
import LoaderForContent from "../../Components/ReUse/LoaderForContent";
import NoRecord from "../../Components/ReUse/NoRecord";
import CustomerOrderProduct from "./CustomerOrderProduct";

const CustomerOrderDetail = ({
  modalIsOpen,
  closeModal,
  orderId,
  productType,
}) => {
  const [apiData, setApiData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const customerOrderDetail = AppService.customerOrderDetail({
      orderId: orderId,
      productType: productType,
    });
    ReUse.getApiData(customerOrderDetail, setApiData, setLoading);
  }, [orderId, productType]);

  if (loading)
    return (
      <GlobalModal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <LoaderForContent />
      </GlobalModal>
    );

  if (Object.keys(apiData)?.length === 0)
    return (
      <GlobalModal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <NoRecord />
      </GlobalModal>
    );

  const {
    coupon_discount,
    delivery_charges,
    order_amount,
    order_id,
    products,
  } = apiData ?? {};

  return (
    <>
      <GlobalModal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <div class="modal-body">
          <div className="all-orders-detail-modal">
            <div className="order-head">
              <h2>Order #{order_id}</h2>
              <p>Delivered on 18 Jan, 20:54</p>
            </div>
            <div className="all-order-img">
              <div className="all-img-order">
                <figure>
                  <img className="img-fluid" alt="order-img" src={ordersmap} />
                </figure>
              </div>
              <div className="all-img-order-text">
                <div className="order-text">
                  <h4>Order From</h4>
                  <p>Eat & Bite</p>
                </div>
                <div className="order-text order-move-up-down">
                  <h4>Delivery Address</h4>
                  <p className="for-para-width">
                    Lorem Ipsum is simply dummy text of 123ssd2 the printing{" "}
                  </p>
                </div>
              </div>
            </div>

            {ReUse.mapItems(false, products, CustomerOrderProduct)}

            <div className="item-total">
              <div className="order-total">
                <h5>Delivery Charges </h5>
                <h5>${delivery_charges || 0}</h5>
              </div>
              <div className="order-total">
                <h5>Promo Code Applied :</h5>
                <h5>${coupon_discount || 0}</h5>
              </div>
            </div>

            <div className="order-sum">
              <h3>Order Total</h3>
              <h4>${order_amount || 0}</h4>
            </div>
          </div>
        </div>
      </GlobalModal>
    </>
  );
};

export default CustomerOrderDetail;
