import { CChart } from "@coreui/react-chartjs";
import React, { useEffect, useState } from "react";
import ReUse from "../services/helpers/reUse";
import AppService from "../services/appServices";
import NoRecord from "./ReUse/NoRecord";
import LoaderForApi from "./ReUse/LoaderForApi";

function Barchart() {
	const [chartData, setChartData] = useState([]);
	const [chartLabels, setChartLabels] = useState([]);

	const [apiData, setApiData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const sellerGetGraphData = AppService.sellerGetGraphData();
		ReUse.getApiData(sellerGetGraphData, setApiData, setLoading);
	}, []);

	useEffect(() => {
		const extractChartData = ReUse.extractDataByProperty({
			data: apiData,
			propertyName: "total_orders",
		});

		const extractChartLabels = ReUse.extractDataByProperty({
			data: apiData,
			propertyName: "month",
		});

		setChartData(extractChartData);
		setChartLabels(extractChartLabels);
	}, [apiData]);

	if (loading) return <LoaderForApi />;
	if (apiData?.length === 0) return <NoRecord />;

	return (
		<>
			<CChart
				type="bar"
				data={{
					labels: chartLabels,
					datasets: [
						{
							label: "Sahara-eagle",
							backgroundColor: "#7DAB3C",
							data: chartData,
						},
					],
				}}
				labels="months"
			/>
		</>
	);
}

export default Barchart;
