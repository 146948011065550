import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import {
  recipes,
  recipes2,
  recipes3,
  recipes4,
  recipes5,
} from "../../Constant/Index";
import "../../assets/css/recipes.css";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import Recipe from "./Recipe";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import Pagination from "../Product/Pagination";

function Recipes() {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const allRecipes = AppService.allRecipes({ pageNumber: currentPage });
    ReUse.getApiData(allRecipes, setApiData, setLoading);
  }, []);

  const paginationProps = {
    apiData: apiData,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };

  return (
    <>
      <PageHeader pagename="Recipes" />
      {/* Recipe Section */}
      <section className="recipe-sec">
        <div className="container">
          <div className="row">
            {ReUse.mapItems(loading, apiData?.data, Recipe)}
          </div>
        </div>

        <Pagination paginationProps={paginationProps} />
      </section>
      {/* ============================= */}
    </>
  );
}

export default Recipes;
