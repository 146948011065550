import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import AccessControl from "../../Components/Auth/AccessControl";
import { useEffect } from "react";
import ReUse from "../../services/helpers/reUse";
import {
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import { toast } from "react-toastify";
import AppService from "../../services/appServices";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import CustomButton from "../../Components/ReUse/Components/Button";

function Sellercreatepassword() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [loading, setLoading] = useState(false);

	const [allErrors, setAllErrors] = useState({
		password: "",
		confirm_password: "",
	});

	const [fieldsData, setFieldsData] = useState({
		password: "",
		"Confirm Password": "",
	});

	const [email, setEmail] = useState("");

	function handleChange(e) {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		const { value, name } = e.target;
		setFieldsData({
			...fieldsData,
			[name]: value,
		});
	}

	const onSubmitHandler = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const { password, confirm_password } = fieldsData;

			const fields = {
				password,
				"Confirm Password": confirm_password,
			};
			const allErrors = ReUse.validateRequired({ fields: fields });

			// Check if there are any errors
			if (Object.keys(allErrors).length > 0) {
				setAllErrors(allErrors);
				setLoading(false);
				return;
			}

			const payLoad = {
				password,
				confirm_password,
				email,
			};

			const apiResp = await AppService.sellerForgotPasswordReset(payLoad);
			const { message, response } = apiResp.data;
			setLoading(false);
			dispatch(successMessage(message));
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/seller-dashboard");
				}, 1000);
			}, 1000);

			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
			dispatch(successMessage(""));
		}
	};

	useEffect(() => {
		setEmail(location?.state?.email);
	}, [location?.state?.email]);

	return (
		<>
			<AccessControl allowedRoles={["seller"]}>
				{/* Seller Forget Sec */}
				<section className="seller-forget-sec">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="seller-forget-form">
									<div className="seller-forget-head">
										<h3>Create New Password</h3>
									</div>
									<form>
										<div className="row">
											<div className="col-md-12">
												<div className="seller-forget-input">
													<input
														type="password"
														onChange={handleChange}
														name="password"
														value={fieldsData?.password}
													/>
													<div className="password-icon sell-pass">
														<i class="fa fa-eye-slash" aria-hidden="true"></i>
													</div>
												</div>
											</div>
											<div className="col-md-12">
												<div className="seller-forget-input">
													<input
														type="password"
														onChange={handleChange}
														name="confirm_password"
														value={fieldsData?.confirm_password}
													/>
													<div className="password-icon sell-pass">
														<i class="fa fa-eye-slash" aria-hidden="true"></i>
													</div>
												</div>
											</div>

											<div className="seller-forget-btn">
												{/* <Link>send</Link> */}
												<a>
													<CustomButton
														loading={loading}
														onClickHandler={onSubmitHandler}
														customClassName="btn"
													>
														Submit
													</CustomButton>
												</a>
												<ResponseMessage />
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* ================== */}
			</AccessControl>
		</>
	);
}

export default Sellercreatepassword;
