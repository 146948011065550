import { useLocation } from "react-router-dom";
import CustomizeDesignProductDetailContent from "../../Components/PID/CustomizeDesignProductDetailContent";
import ObjBasedApiDataLoader from "../../Components/ReUse/ReUsableHOC/ObjBasedApiDataLoader";
import { useEffect, useState } from "react";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";

function Productdetail() {
  const location = useLocation();
  const { state } = location ?? {};
  const { productId } = state ?? {};

  function PIDDetail({ loading, data }) {
    return <CustomizeDesignProductDetailContent data={data} currentIndex={0} />;
  }

  const EnhancedPIDDetail = ObjBasedApiDataLoader(PIDDetail);

  const [apiData, setApiData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getProductDetail = AppService.getCustomizableProductDetails({
      productId: productId,
    }); //1299
    ReUse.getApiData(getProductDetail, setApiData, setLoading);
  }, [productId]);

  return (
    <>
      <EnhancedPIDDetail data={apiData} loading={loading} />
    </>
  );
}

export default Productdetail;
