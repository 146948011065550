import React from "react";
import Select from "react-select";
import SelectedAttributeValues from "./SelectedAttributeValues";
import VariantMeta from "./VariantMeta";
import ReUse from "../../services/helpers/reUse";

const IndividualVariant = ({ inventoryVariantProps }) => {
  const {
    extractAttributeOptions,
    setSelectedAttributes,
    selectedAttributes,
    attributeOptions,
    delimiters,
    handleAttributeOptionDelete,
    handleAttributeOptionAddition,
    handleAttributeOptionDrag,
    handleAttributeOptionClick,
    generatedVariations,
    variantDataHandler,
    variantData,
  } = inventoryVariantProps ?? {};

  const generateId = ReUse.makeId();

  return (
    <>
      <div className="col-md-12">
        <div className="all-select-dv">
          <div className="mcq-dv-all">
            <h5>Add Varaitions / Attribute</h5>
          </div>

          <Select
            isMulti
            name="colors"
            options={extractAttributeOptions}
            classNamePrefix="select"
            onChange={(selectedOptions) =>
              setSelectedAttributes(selectedOptions)
            }
            value={selectedAttributes}
          />
        </div>
      </div>
      {selectedAttributes?.map((selectedAttribute, index) => (
        <SelectedAttributeValues
          attributeOptions={attributeOptions}
          delimiters={delimiters}
          handleAttributeOptionDelete={handleAttributeOptionDelete}
          handleAttributeOptionAddition={handleAttributeOptionAddition}
          handleAttributeOptionDrag={handleAttributeOptionDrag}
          handleAttributeOptionClick={handleAttributeOptionClick}
          selectedAttribute={selectedAttribute}
          currentIndex={index || 0}
          key={selectedAttribute?.value || index}
        />
      ))}

      <div className="col-md-12">
        <div className="mcq-dv-all">
          <h5>Variant, Price, and Stock</h5>
        </div>
        <div className="all-select-product-table">
          <table>
            <thead>
              <th>variant</th>
              <th>variant price</th>
              <th>discount price</th>
              <th>quantity</th>
              <th>SKU</th>
            </thead>
            <tbody>
              {generatedVariations?.map((variation, index) => {
                return (
                  <VariantMeta
                    key={generateId}
                    variation={variation}
                    index={index}
                    variantDataHandler={variantDataHandler}
                    variantData={variantData}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default IndividualVariant;
