import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Blogsmain from "../../Components/Blogsmain";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Newsletter from "../../Components/Newsletter";
import Slidecompo from "../../Components/Slidecompo";
import Topcategories from "../../Components/Topcategories";
import { icon1, icon2, icon3, icon4, icon5 } from "../../Constant/Index";

import { useState } from "react";
import { useEffect } from "react";

import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import BlogCard from "../../Components/BlogCard";

import OFFoodCard from "./ProductCard";
import { HomeCategoriesSec } from "./HomeCategoriesSec";
import { CustomizableProducts } from "./CustomizableProducts";
import { useSelector } from "react-redux";
import AccessControl from "../../Components/Auth/AccessControl";
import Hero from "./Hero";
import GlobalModal from "../../Components/ReUse/Modals/GlobalModal";
import { UseModal } from "../../Hooks/modals/UseModal";
import Benefits from "./Benefits";

function Home() {
  const [productsByCats, setProductsByCats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const homepageCategories = AppService.homepageCategories();
    ReUse.getApiData(homepageCategories, setProductsByCats, setLoading);

    const result =
      productsByCats?.length > 3 ? productsByCats?.slice(0, 4) : productsByCats;
    setProductsByCats(result);
  }, []);

  const currentUser = useSelector((state) => state.persistedReducer.auth.user);

  return (
    <>
      <Hero />
      <Benefits />
      <Topcategories />
      {ReUse.mapItems(loading, productsByCats, HomeCategoriesSec)}
      <CustomizableProducts />
      <Blogsmain />
      <Newsletter />
    </>
  );
}

export default Home;
