import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { wishlist1 } from "../../Constant/Index";
import ReUse from "../../services/helpers/reUse";
import { useDispatch, useSelector } from "react-redux";
import { CartActions } from "../../redux/slices/cart.slice";
import AppService from "../../services/appServices";

const WishlistCard = (propsData) => {
	const { product, in_stock } = propsData ?? {};
	const {
		name,
		id,
		slug,
		thumbnail,
		unit_price,
		current_stock,
		details,
		discount,
		discount_type,
		images,
		quantity,
		country_name,
		attributes,
		price,
	} = product ?? {};

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [statusMessage, setStatusMessage] = useState("");

	const [loading2, setLoading2] = useState(false);
	const [statusMessage2, setStatusMessage2] = useState("");

	const [productShippingCharges, setProductShippingCharges] = useState(null);
	const [shippingCharges, setShippingCharges] = useState([]);

	const [shippingLoader, setShippingLoader] = useState(false);

	const customerAddProductCart = async (e) => {
		e.preventDefault();

		setLoading(true);

		try {
			const parseAttributes = JSON.parse(attributes);

			const reduceAttributes = parseAttributes
				.map((item) => {
					return Object.values(item);
				})
				.flat();

			const resultVariations = {};

			parseAttributes.forEach((variant) => {
				resultVariations[variant?.valueCategory] = variant?.selectedValue;
			});

			const payload = {
				product_id: id,
				current_stock,
				description: details,
				discount,
				discount_type,
				images,
				product_id: id,
				product_name: name,
				thumbnail,
				unit_price: unit_price,
				qty: quantity,
				currentStock: quantity,
				calculatedPrice: ReUse.calculateSubtotalPrice({
					unitPrice: price || unit_price,
					quantity: quantity,
				}),
				shippingCharges: productShippingCharges,
				variant: reduceAttributes?.join("-"),
				variations: resultVariations,
			};

			dispatch(CartActions.Add_To_Cart(product));
			setLoading(false);
			setStatusMessage("Added to cart");

			setTimeout(() => {
				setStatusMessage("Navigating...");
				setTimeout(() => {
					setStatusMessage("");
					navigate("/cart");
				}, 1000);
			}, 1000);
		} catch (err) {
			console.error("API Error:", err);
			setLoading(false);
			setStatusMessage("Error");
		}
	};

	const customerRemoveProductWishlist = async (e) => {
		e.preventDefault();
		try {
			const payload = {
				product_id: id,
				product_type: "seller",
			};
			const apiResp = await AppService.customerRemoveProductWishlist(payload);
			const { message, response } = apiResp.data;
		} catch (err) {
			console.error("API Error:", err);
		}
	};

	useEffect(() => {
		const apiShippingCharges = AppService.shippingCharges();
		ReUse.getApiData(apiShippingCharges, setShippingCharges, setShippingLoader);
	}, []);

	const currentUser = useSelector((state) => state.persistedReducer.auth.user);

	useEffect(() => {
		const { country } = currentUser ?? {};
		let foundCharges = 0;
		let shippingType = "";

		if (country?.toLowerCase() === country_name?.toLowerCase()) {
			shippingType = "same-country";
		} else {
			shippingType = "different-country";
		}

		foundCharges = shippingCharges.find(
			(record) => record?.shipping_type === shippingType,
		);
		setProductShippingCharges(parseInt(foundCharges?.charges || 0));
	}, [country_name, currentUser, shippingCharges]);

	const navigateToProductDetail = (e) => {
		e.preventDefault();
		navigate(`/product-detail/${slug}`, { state: { product_id: slug } });
	};

	return (
		<tr className="no-border-in-bottom">
			<td>
				<div className="img-icon">
					<i
						class="fa fa-times"
						aria-hidden="true"
						onClick={customerRemoveProductWishlist}
					></i>

					<figure>
						<img
							className="img-fluid"
							alt="wish-list-img"
							src={
								thumbnail !== null
									? thumbnail
									: ReUse.defaultThumbnailImage({
											content: "No+Image+Available",
									  })
							}
							onError={ReUse.onImageError}
						/>
					</figure>
				</div>
			</td>
			<td>
				<p>{name}</p>
			</td>
			<td>
				<p>
					<span>${unit_price}</span>
				</p>
			</td>
			<td>
				<p>{in_stock ? "In-Stock" : "Not In Stock"}</p>
			</td>
			<td>
				<a>
					<button
						className="button"
						onClick={navigateToProductDetail}
						disabled={in_stock ? loading : !in_stock}
					>
						Product Detail
					</button>
				</a>
			</td>
		</tr>
	);
};

export default WishlistCard;
