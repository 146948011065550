import React from "react";
import { homebanner3 } from "../../../Constant/Index";
import ReUse from "../../../services/helpers/reUse";

const RightSecond = ({ allBanners }) => {
	const isArrayHasIndex = ReUse.isArrayHasIndex({
		arrayInput: allBanners,
		index: 2,
	});

	const banner = isArrayHasIndex ? allBanners[2] : {};

	const {
		id,
		photo,
		banner_type,
		published,
		url,
		resource_type,
		title,
		sub_title,
		description,
	} = banner ?? {};
	return (
		isArrayHasIndex && (
			<div className="banner-first-img third-banner-img">
				<figure>
					<img
						className="img-fluid"
						alt="first-img"
						src={
							photo && photo !== null
								? photo
								: ReUse.defaultThumbnailImage({
										content: "No+Image+Available",
								  })
						}
						onError={ReUse.onImageError}
					/>
				</figure>
				<div className="second-banner-text third-banner-text">
					<div className="second-all-text wow animate__animated animate__fadeInRightBig">
						<h4>{title}</h4>
						<h4>{sub_title} </h4>
					</div>
				</div>
			</div>
		)
	);
};

export default RightSecond;
