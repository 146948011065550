import React from "react";
import ReUse from "../../services/helpers/reUse";
import ProductVariationOptions from "./ProductVariationOptions";

const ProductSubVariations = (data) => {
	const filteredKeys = ["extraData"];

	const filteredObject = ReUse.filteredObj({
		objectInput: data,
		filteredKeys: filteredKeys,
	});

	const minifiedObject = ReUse.getStringProperties({ object: filteredObject });

	const { extraData } = data ?? {};

	return (
		<div>
			{ReUse.mapObject({
				loading: false,
				data: minifiedObject,
				Component: ProductVariationOptions,
				extraData: extraData,
			})}
		</div>
	);
};

export default ProductSubVariations;
