import React from "react";
import CustomerOrderDetail from "./CustomerOrderDetail";
import { useState } from "react";

const MyOrder = (propsData) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const {
    order_amount,
    order_date,
    order_id,
    order_status = "Unknown",
    payment_status,
    product_type,
  } = propsData ?? {};

  function openModal() {
    setModalIsOpen(true);
  }

  // Function to close the modal
  function closeModal() {
    setModalIsOpen(false);
  }

  return (
    <>
      <tr className="for-only-bor">
        <td className="order-td-left">
          {" "}
          <div className="for-border-raduis">{order_id}</div>
        </td>
        <td>{order_date}</td>
        <td>{order_status}</td>
        <td className="for-different-color">
          {payment_status} <i class="fa fa-check-circle" aria-hidden="true"></i>
        </td>
        <td>${order_amount}</td>
        <td className="end-td-borde-raduis">
          <button onClick={openModal}>Order Details</button>
        </td>
      </tr>
      <CustomerOrderDetail
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        orderId={order_id}
        productType={product_type}
      />
    </>
  );
};

export default MyOrder;
