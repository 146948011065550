import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import { zipcodeimg } from "../../Constant/Index";
import AccessControl from "../../Components/Auth/AccessControl";
import ReUse from "../../services/helpers/reUse";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import AppService from "../../services/appServices";
import { toast } from "react-toastify";
import {
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import FieldError from "../../Components/ReUse/FieldError";

function Sellereditprofie() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState();
	const [fileupload, setFileupload] = useState();
	const [addModalIsOpen, setAddModalIsOpen] = useState(false);

	const [allErrors, setAllErrors] = useState({
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		dob: "",
	});

	const [fieldsData, setFieldsData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		dob: "",
	});

	function handleChange(e) {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		const { value, name } = e.target;
		setFieldsData({
			...fieldsData,
			[name]: value,
		});
	}

	const onSubmitHandler = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const { first_name, last_name, phone, dob, email } = fieldsData;

			const fields = {
				first_name,
				last_name,
				phone,
				dob,
				email,
			};
			const allErrors = ReUse.validateRequired({ fields: fields });

			// Check if there are any errors
			if (Object.keys(allErrors).length > 0) {
				setAllErrors(allErrors);
				setLoading(false);
				return;
			}

			const payLoad = {
				first_name,
				last_name,
				phone,
				dob,
				email,
			};

			const apiResp = await AppService.sellerUpdatePersonalDetails(payLoad);
			const { message, response } = apiResp.data;
			setLoading(false);
			dispatch(successMessage(message));
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/my-profile");
				}, 1000);
			}, 1000);

			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
			dispatch(successMessage(""));
		}
	};

	const { first_name, last_name, email, phone, dob } = fieldsData ?? {};

	return (
		<>
			<AccessControl allowedRoles={["seller"]}>
				<SellerHeader />
				{/* Seller profile starts here */}
				<section className="seller-dashboard">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-3 ps-0">
								<SellerSidebar />
							</div>
							<div className="col-lg-8">
								<div className="edit-profile-card edit-no-padding-right">
									<div className="edit-heading-wrapper">
										<h2>Edit Personal Details</h2>
									</div>

									<div className="seller-edit-with-border-dv">
										<div className="row">
											<div className="col-md-10">
												<div className="edit-details-form">
													<div className="seller-all-form">
														<form>
															<div className="row">
																<div className="col-md-6">
																	<div className="seller-edit-form-input">
																		<input
																			type="text"
																			placeholder="First Name"
																			onChange={handleChange}
																			name="first_name"
																			value={first_name}
																		/>
																		<FieldError
																			fieldName={allErrors?.first_name}
																		/>

																		<div className="seller-dashboard-icon">
																			<i
																				class="fa fa-user"
																				aria-hidden="true"
																			></i>
																		</div>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="seller-edit-form-input">
																		<input
																			type="text"
																			placeholder="Last Name"
																			onChange={handleChange}
																			name="last_name"
																			value={last_name}
																		/>
																		<FieldError
																			fieldName={allErrors?.last_name}
																		/>

																		<div className="seller-dashboard-icon">
																			<i
																				class="fa fa-user"
																				aria-hidden="true"
																			></i>
																		</div>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="seller-edit-form-input">
																		<input
																			type="text"
																			placeholder="Email"
																			onChange={handleChange}
																			name="email"
																			value={email}
																		/>
																		<FieldError fieldName={allErrors?.email} />

																		<div className="seller-dashboard-icon">
																			<i
																				class="fa fa-envelope"
																				aria-hidden="true"
																			></i>
																		</div>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="seller-edit-form-input">
																		<input
																			type="text"
																			placeholder="Phone"
																			onChange={handleChange}
																			name="phone"
																			value={phone}
																		/>
																		<FieldError fieldName={allErrors?.phone} />

																		<div className="seller-dashboard-icon">
																			<i
																				class="fa fa-phone"
																				aria-hidden="true"
																			></i>
																		</div>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="seller-edit-form-input">
																		<input
																			type="text"
																			placeholder="DOB"
																			onChange={handleChange}
																			name="dob"
																			value={dob}
																		/>
																		<FieldError fieldName={allErrors?.dob} />

																		<div className="seller-dashboard-icon">
																			<i
																				class="fa fa-calendar-minus-o"
																				aria-hidden="true"
																			></i>
																		</div>
																	</div>
																</div>
															</div>
														</form>
													</div>
												</div>
											</div>
											<div className="col-md-2">
												<div className="seller-save-btn">
													<button
														onClick={(e) => onSubmitHandler(e)}
														disabled={loading}
													>
														{loading ? "loading" : "Save"}
													</button>

													<ResponseMessage />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* Seller profile ends here */}
			</AccessControl>
		</>
	);
}

export default Sellereditprofie;
