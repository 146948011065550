import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Blogsmain from "../../Components/Blogsmain";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Newsletter from "../../Components/Newsletter";
import Productslider from "../../Components/Productslider";
import Sliderforproduct from "../../Components/Sliderforproduct";
import {
	customize1,
	customize2,
	customize3,
	customize4,
	customize5,
	customize6,
	customize7,
	customize8,
} from "../../Constant/Index";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import CatsSubcats from "./CatsSubcats";
import ShopProduct from "./ShopProduct";
import ProductsParamsFilter from "./ProductsParamsFilter";
import ProductsSortingFilters from "./ProductsSortingFilters";
import { useSelector } from "react-redux";
import Pagination from "./Pagination";

function Customizeproduct() {
	const [customizedProducts, setCustomizedProducts] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pageCount, setPageCount] = useState();
	const [currentPage, setCurrentPage] = useState(1);

	const productsFilterData = useSelector((state) => state.productsfilters);

	const {
		categoryId,
		subCategoryId,
		minPrice,
		maxPrice,
		showProducts,
		sortByOrder,
	} = productsFilterData ?? {};

	useEffect(() => {
		const getAllCustomizableProducts = AppService.getAllCustomizableProducts({
			categoryId,
			subCategoryId,
			minPrice,
			maxPrice,
			pageNumber: currentPage,
		});

		ReUse.getApiData(
			getAllCustomizableProducts,
			setCustomizedProducts,
			setLoading,
		);
	}, [categoryId, subCategoryId, minPrice, maxPrice, currentPage]);

	useEffect(() => {
		const sortedData = ReUse.sortData({
			data: customizedProducts?.data,
			order: sortByOrder,
			sortByProperty: "unit_price",
		});
		setCustomizedProducts({ ...customizedProducts, data: sortedData });
	}, [sortByOrder]);

	const paginationProps = {
		apiData: customizedProducts,
		setPageCount: setPageCount,
		pageCount: pageCount,
		setCurrentPage: setCurrentPage,
		currentPage: currentPage,
	};

	return (
		<>
			<section className="fashion-product-sec-banner">
				<div className="container">
					<div className="col-md-12"></div>
					<Sliderforproduct />
				</div>
			</section>

			<section className="product-sec">
				<div className="container">
					<ProductsSortingFilters />
					<div className="row">
						<ProductsParamsFilter />
						<div className="col-md-9">
							<div className="row all-class-for-padding">
								{ReUse.mapItems(
									loading,
									customizedProducts?.data?.slice(0, showProducts),
									ShopProduct,
								)}
							</div>
						</div>
					</div>

					<Pagination paginationProps={paginationProps} />
				</div>
			</section>
			<Blogsmain />
			<Newsletter />
		</>
	);
}

export default Customizeproduct;
