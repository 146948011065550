import React from "react";
import Footer from "../../Components/Footer";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import SellerProfile from "./SellerProfile";
import AccessControl from "../../Components/Auth/AccessControl";
import { useState } from "react";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import { useEffect } from "react";

import Select from "react-select";
import {
  clearResponseMessageAfterDelay,
  errorMessage,
  successMessage,
} from "../../redux/slices/responseMessage.slice";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import CustomButton from "../../Components/ReUse/Components/Button";

function Membershipplan() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [apiData, setApiData] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [memberShips, setMemberShips] = useState([]);
  const [selectedMemberShip, setSelectedMemberShip] = useState(null);
  const [memberShipPrice, setMemberShipPrice] = useState(null);

  const [memberShipsLoader, setMemberShipsLoader] = useState(false);
  const [updgradeMemberShipLoader, setUpdgradeMemberShipLoader] =
    useState(false);

  const [cancelMemberShipLoader, setCancelMemberShipLoader] = useState(false);

  const [statusMessage, setStatusMessage] = useState("");

  useEffect(() => {
    const sellerGetMembership = AppService.sellerGetMembership();
    ReUse.getApiData(sellerGetMembership, setApiData, setLoading);

    const getPublicMemberships = AppService.sellerGetpackages();
    ReUse.getApiData(
      getPublicMemberships,
      setMemberShips,
      setMemberShipsLoader
    );
  }, []);

  const {
    id,
    package_id,
    package_name,
    amount_paid,
    expiry,
    status,
    description,
    long_description,
  } = apiData ?? {};

  const upgradeMembership = async (e) => {
    e.preventDefault();
    setUpdgradeMemberShipLoader(true);

    try {
      if (!selectedMemberShip) {
        dispatch(errorMessage("Please select a membership to upgrade"));
        dispatch(clearResponseMessageAfterDelay());
		setUpdgradeMemberShipLoader(false);
        return;
      }

      const payload = {
        package_id: selectedMemberShip?.value,
      };

      const apiResp = await AppService.sellerUpgradeMembership(payload);
      const { message, response } = apiResp.data;
      setUpdgradeMemberShipLoader(false);
      dispatch(successMessage(message));
      setTimeout(() => {
        dispatch(successMessage("Navigating..."));
        setTimeout(() => {
          dispatch(successMessage(""));
          navigate("/seller-dashboard");
        }, 1000);
      }, 1000);
    } catch (err) {
      setUpdgradeMemberShipLoader(false);
      dispatch(errorMessage(err?.response?.data?.message));
      dispatch(clearResponseMessageAfterDelay());
      dispatch(successMessage(""));
    }
  };

  const cancelMembership = async (e) => {
    e.preventDefault();

    const isConfirmed = await ReUse.showConfirmationDialog({
      text: "Are you sure you want to cancel your membership?",
      confirmButtonText: "Yes, Cancel Membership",
    });

    if (!isConfirmed) {
      ReUse.showSuccessDialog({});
      setCancelMemberShipLoader(false);
      return;
    }

    try {
      setCancelMemberShipLoader(true);
      const apiResp = await AppService.sellerCancelMembership();
      const { message, response } = apiResp.data;
      setCancelMemberShipLoader(false);
      dispatch(successMessage(message));
      ReUse.showSuccessDialog({ text: message });
      setTimeout(() => {
        dispatch(successMessage("Navigating..."));
        setTimeout(() => {
          dispatch(successMessage(""));
          navigate("/seller-dashboard");
        }, 1000);
      }, 1000);
    } catch (err) {
      setCancelMemberShipLoader(false);
      const errorMessage = err?.response?.data?.message || "An error occurred.";
      dispatch(errorMessage);
      dispatch(clearResponseMessageAfterDelay());
      dispatch(successMessage(""));
      ReUse.showErrorDialog({ text: errorMessage });
    }
  };

  const extractOptions = ReUse.minifyArrayOfObjects({
    data: memberShips,
    key: "title",
  });

  useEffect(() => {
    const selectedMemberShipPrice = memberShips?.find(
      (item) => item?.id === selectedMemberShip?.value
    );

    const { price } = selectedMemberShipPrice || {};

    setMemberShipPrice(price);
  }, [selectedMemberShip]);


  return (
    <>
      <AccessControl allowedRoles={["seller"]}>
        <SellerHeader />
        {/* Seller payment details here */}
        <section className="seller-dashboard">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 ps-0">
                <SellerSidebar />
              </div>
              <div className="col-lg-8">
                <div className="edit-profile-card">
                  <div className="edit-heading-wrapper">
                    <h2>Membership Plan</h2>
                  </div>
                  <div className="row membership-plan">
                    <div className="col-md-6">
                      <div className="all-subscription-plan">
                        <div className="all-subscription-head">
                          <h3>My Subscribtion</h3>
                        </div>
                        <div className="all-amount-paid all-amout-outer-paid">
                          <span>
                            {" "}
                            <h5>My Membership </h5>
                          </span>
                          <span>
                            {" "}
                            <h5>Amount Paid</h5>
                          </span>
                        </div>

                        <div className="all-professional-plan">
                          <span className="for-color-and-text-captalize">
                            <p>{package_name}</p>
                          </span>
                          <span>
                            <p>${amount_paid}/Per Month</p>
                          </span>
                        </div>

                        <div className="all-professioanl-cancel">
                          <span className="cancel-btn">
                            {" "}
                            <p>
                              {" "}
                              <i class="fa fa-times" aria-hidden="true"></i>
                            </p>
                            <p onClick={(e) => cancelMembership(e)}>
                              {cancelMemberShipLoader
                                ? "loading"
                                : "Cancel Membership"}
                            </p>
                          </span>
                          <span>
                            {" "}
                            <p>
                              Your Membership Will Expire on{" "}
                              {ReUse.dateFormat({ date: expiry })}
                            </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="upgrade-btn">
                          <button
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            Upgrade
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="getting-dv">
                        <div className="getting-dv-head">
                          <h3>What you are getting</h3>
                        </div>

                        <div className="getting-ul">
                          {ReUse.dangerouslySetInnerHTML({
                            content: long_description,
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Seller payment details here */}

        {/* Membership Modal */}

        <div className="membership-modal">
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div className="responsive-close-icon">
                  <i
                    class="fa fa-times"
                    aria-hidden="true"
                    data-bs-dismiss="modal"
                  ></i>
                </div>
                <div class="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="membership-modal-head">
                        <h2>Choose Membership</h2>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="all-subscription-plan">
                        <div className="all-subscription-head">
                          <h3>My Subscribtion</h3>
                        </div>
                        <div className="all-amount-paid">
                          <span>
                            {" "}
                            <h5>My Membership </h5>
                          </span>
                          <span className="for-modal-amount">
                            {" "}
                            <h5>Amount Paid</h5>
                          </span>
                        </div>

                        <div className="all-pro-plan2">
                          <div className="row">
                            <div className="col-md-6 col-show-right-border">
                              <span className="for-color-and-text-captalize">
                                <Select
                                  isMulti={false}
                                  name="colors"
                                  options={extractOptions}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={setSelectedMemberShip}
                                />
                              </span>
                            </div>

                            <div className="col-md-6">
                              <span>
                                <div className="per-month-dv">
                                  {memberShipPrice && (
                                    <p>${memberShipPrice}/Per Month</p>
                                  )}
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="all-professioanl-cancel">
                          <span className="cancel-btn for-member-modal-css">
                            {" "}
                            <p>
                              {" "}
                              <i class="fa fa-times" aria-hidden="true"></i>
                            </p>
                            <p onClick={(e) => cancelMembership(e)}>
                              {cancelMemberShipLoader
                                ? "loading"
                                : "Cancel Membership"}
                            </p>
                            <ResponseMessage />
                          </span>
                          <span className="for-member-modal-css">
                            {" "}
                            <p>
                              Your Membership Will Expire On{" "}
                              {ReUse.dateFormat({ date: expiry })}
                            </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="upgrade-btn save-upgrade-btn">
                          <CustomButton
                            loading={updgradeMemberShipLoader}
                            onClickHandler={upgradeMembership}
                            customClassName="custom-btn"
                          >
                            Upgrade
                          </CustomButton>
                          <ResponseMessage />
                          {/* <button data-bs-dismiss="modal">Save Upgrade</button> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="getting-dv">
                        <div className="getting-dv-head">
                          <h3>What you are getting</h3>
                        </div>

                        <div className="getting-ul">
                          {ReUse.dangerouslySetInnerHTML({
                            content: long_description,
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ============================== */}
      </AccessControl>
    </>
  );
}

export default Membershipplan;
