import React, { useState } from "react";
import Footer from "../../Components/Footer";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import "../../assets/css/Seller-myprofile.css";
import { business, des, street, zipcodeimg } from "../../Constant/Index";
import { Link } from "react-router-dom";
import AccessControl from "../../Components/Auth/AccessControl";
import ReUse from "../../services/helpers/reUse";
import { useEffect } from "react";
import AppService from "../../services/appServices";
import ObjBasedApiDataLoader from "../../Components/ReUse/ReUsableHOC/ObjBasedApiDataLoader";
import SellerProfileContent from "../../Components/PID/SellerProfileContent";

function SellerProfile() {
	function PIDDetail({ loading, data }) {
		return <SellerProfileContent data={data} />;
	}

	const EnhancedPIDDetail = ObjBasedApiDataLoader(PIDDetail);

	const [apiData, setApiData] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const getProductDetail = AppService.sellerGetProfileInfo();
		ReUse.getApiData(getProductDetail, setApiData, setLoading);
	}, []);

	return (
		<>
			<EnhancedPIDDetail data={apiData} loading={loading} />
		</>
	);
}

export default SellerProfile;
