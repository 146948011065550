import React from "react";
import { Link } from "react-router-dom";

import {
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";

const ShareProduct = () => {
  let currentUrl = window.location.href;
  return (
    <>
      <ul className="product-li">
        <li>
          <FacebookShareButton url={currentUrl && currentUrl}>
            <Link>
              <i className="fa fa-facebook" aria-hidden="true"></i>
            </Link>
          </FacebookShareButton>
        </li>

        <li>
          <FacebookShareButton url={currentUrl && currentUrl}>
            <Link>
              <i className="fa fa-youtube-play" aria-hidden="true"></i>
            </Link>
          </FacebookShareButton>
        </li>

        <li>
          <LinkedinShareButton url={currentUrl && currentUrl}>
            <Link>
              <i className="fa fa-linkedin" aria-hidden="true"></i>
            </Link>
          </LinkedinShareButton>
        </li>

        <li>
          <TwitterShareButton url={currentUrl && currentUrl}>
            <Link>
              <i className="fa fa-twitter" aria-hidden="true"></i>
            </Link>
          </TwitterShareButton>
        </li>
      </ul>
    </>
  );
};

export default ShareProduct;
