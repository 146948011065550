import React, { useState } from "react";
import Header from "../../Components/Header";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import { WithContext as ReactTags } from "react-tag-input";

import Select from "react-select";
import {
  sketch,
  svg1,
  svg2,
  svg3,
  svg4,
  svg5,
  upperarrow,
} from "../../Constant/Index";
import Footer from "../../Components/Footer";
import AccessControl from "../../Components/Auth/AccessControl";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReUse from "../../services/helpers/reUse";
import AppService from "../../services/appServices";
import { toast } from "react-toastify";
import {
  clearResponseMessageAfterDelay,
  errorMessage,
  successMessage,
} from "../../redux/slices/responseMessage.slice";
import { SellerAddProduct } from "../../Hooks/productInventory/SellerAddProduct";
import VariantMeta from "./VariantMeta";
import { useEffect } from "react";
import SelectedAttributeValues from "./SelectedAttributeValues";
import RenderUploadedImage from "./RenderUploadedImage";
import BulkVariant from "./BulkVariant";
import IndividualVariant from "./IndividualVariant";
import CustomButton from "../../Components/ReUse/Components/Button";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import FieldError from "../../Components/ReUse/FieldError";
import RenderImage from "../../Components/ReUse/RenderImage";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function Addingproduct() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [fileupload, setFileupload] = useState();
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [attributesLoader, setAttributesLoader] = useState(false);

  const [productCategories, setProductCategories] = useState([]);
  const [productSubCategories, setProductSubCategories] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState(null);
  const [selectedProductSubCategory, setSelectedProductSubCategory] =
    useState(null);
  const [categoriesLoader, setCategoriesLoader] = useState(false);
  const [subCategoriesLoader, setSubCategoriesLoader] = useState(false);

  const [allErrors, setAllErrors] = useState({
    name: "",
    category_id: selectedProductCategory,
    sub_category_id: selectedProductSubCategory,
    unit_price: "",
    discount: "0",
    discount_type: "amount",
    current_stock: "",
    details: "",
    purchase_price: "",
  });

  const [fieldsData, setFieldsData] = useState({
    name: "",
    unit_price: "",
    current_stock: "",
    details: "",
    purchase_price: "",
  });

  const [selectedAttributes, setSelectedAttributes] = useState([]);

  const [selectedAttributeValues, setSelectedAttributeValues] = useState([]);

  const [uploadedImages, setUploadedImages] = useState([]);

  const [inventoryTypes, setInventoryTypes] = useState([
    {
      value: 1,
      label: "Normal",
    },
    {
      value: 2,
      label: "Bulk",
    },
  ]);

  const [selectedInventoryType, setSelectedInventoryType] = useState(
    inventoryTypes[0]
  );

  const { value, label } = selectedInventoryType ?? {};

  useEffect(() => {
    const sellerGetAttributes = AppService.sellerGetAttributes();
    ReUse.getApiData(sellerGetAttributes, setAttributes, setAttributesLoader);

    const sellerGetcategories = AppService.sellerGetcategories();
    ReUse.getApiData(
      sellerGetcategories,
      setProductCategories,
      setCategoriesLoader
    );
  }, []);

  useEffect(() => {
    const sellerGetsubCategories = AppService.sellerGetsubCategories({
      categoryId: selectedProductCategory?.value,
    });
    ReUse.getApiData(
      sellerGetsubCategories,
      setProductSubCategories,
      setSubCategoriesLoader
    );
  }, [selectedProductCategory]);

  const extractAttributeOptions = ReUse.minifyArrayOfObjects({
    data: attributes,
  });

  const handleAttributeOptionAddition = ({
    attributeOption,
    attributeIndex,
    attributeType,
  }) => {
    const updatedAttributeOptions = [...attributeOptions];
    const { label, id } = attributeOption ?? {};
    if (updatedAttributeOptions[attributeIndex]) {
      updatedAttributeOptions[attributeIndex].push({
        id: id,
        text: id,
      });
    } else {
      updatedAttributeOptions[attributeIndex] = [
        {
          id: id,
          text: id,
        },
      ];
    }

    setAttributeOptions(updatedAttributeOptions);

    const updatedSelectedAttributeValues = [...selectedAttributeValues];
    if (updatedSelectedAttributeValues[attributeIndex]) {
      updatedSelectedAttributeValues[attributeIndex]?.values?.push(
        attributeOption
      );
    } else {
      updatedSelectedAttributeValues[attributeIndex] = {
        selectedAttribute: attributeType,
        values: [attributeOption],
      };
    }

    setSelectedAttributeValues(updatedSelectedAttributeValues);
  };

  const handleAttributeOptionDrag = (attributeOption, currPos, newPos) => {
    const newAttributeOptions = attributeOptions?.slice();
    newAttributeOptions?.splice(currPos, 1);
    newAttributeOptions?.splice(newPos, 0, attributeOption);
    setAttributeOptions(newAttributeOptions);
  };

  const handleAttributeOptionDelete = ({
    attributeOption,
    attributeIndex,
    attributeType,
  }) => {
    console.log(attributeOption, "currentAttributeOptions");

    const updatedAttributeOptions = [...attributeOptions];
    const currentAttributeOptions = updatedAttributeOptions[attributeIndex];

    if (currentAttributeOptions) {
      const updatedValues = currentAttributeOptions?.filter(
        (option) => option?.id !== attributeOption?.id
      );

      updatedAttributeOptions[attributeIndex] = updatedValues;

      setAttributeOptions(updatedAttributeOptions);
    }
  };

  // console.log(attributeOptions, "currentAttributeOptions");

  const handleAttributeOptionClick = (index) => {};

  function handleChange(e) {
    const resetErrors = ReUse.resetErrors({ fields: allErrors });
    setAllErrors(resetErrors);
    const { value, name } = e.target;
    setFieldsData({
      ...fieldsData,
      [name]: value,
    });
  }

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const { name, unit_price, current_stock, details, purchase_price } =
        fieldsData ?? {};

      const fields = {
        name,
        unit_price,
        current_stock,
        details,
        purchase_price,
        category_id: selectedProductCategory,
        sub_category_id: selectedProductSubCategory,
        discount: "0",
        discount_type: "amount",
        thumbnail: file,
      };
      const allErrors = ReUse.validateRequired({ fields: fields });

      // Check if there are any errors
      if (Object.keys(allErrors).length > 0) {
        setAllErrors(allErrors);
        setLoading(false);
        return;
      }

      if (uploadedImages?.length < 3) {
        setLoading(false);
        dispatch(errorMessage("Please  Upload Atleast 3 images"));
        dispatch(clearResponseMessageAfterDelay());
        return;
      }

      if (variantData?.length < 1) {
        setLoading(false);
        dispatch(errorMessage("Please Add atleast 1 variant"));
        dispatch(clearResponseMessageAfterDelay());
        return;
      }

      const payLoad = {
        name,
        category_id: selectedProductCategory,
        sub_category_id: selectedProductSubCategory,
        unit_price: parseInt(unit_price),
        discount: 0,
        discount_type: "amount",
        current_stock: parseInt(current_stock),
        details,
        purchase_price: parseInt(purchase_price),
        ...(selectedInventoryType?.value === 2 && { "moq_selling[]": [] }),
        ...(selectedInventoryType?.value === 1 && { variations: variantData }),
        "images[]": uploadedImages,
        thumbnail: file,
        selectedInventoryType,
      };

      const apiResp = await AppService.sellerAddProduct(payLoad);
      const { message, response } = apiResp.data;
      setLoading(false);
      dispatch(successMessage(message));
      setTimeout(() => {
        dispatch(successMessage("Navigating..."));
        setTimeout(() => {
          dispatch(successMessage(""));
          navigate("/sign-in");
        }, 1000);
      }, 1000);

      ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
      ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
    } catch (err) {
      setLoading(false);
      dispatch(errorMessage(err?.response?.data?.message));
      dispatch(clearResponseMessageAfterDelay());
      ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
      ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
      dispatch(successMessage(""));
    }
  };

  const handleThumbnailUpload = (e) => {
    const resetErrors = ReUse.resetErrors({ fields: allErrors });
    setAllErrors(resetErrors);
    ReUse.handleFileUploads(e).then((result) => {
      const { fileOutput, fileUpload } = result;
      setFile(fileOutput);
      setFileupload(fileUpload);
    });
  };

  const handleMultipleImagesUpload = async (e) => {
    const resetErrors = ReUse.resetErrors({ fields: allErrors });
    setAllErrors(resetErrors);
    try {
      const newImages = await ReUse.handleMultipleImagesUpload({
        e: e,
        prevImages: uploadedImages,
      });
      setUploadedImages(newImages);
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const extractAttributeValues = () => {
    try {
      if (!selectedAttributeValues || !Array.isArray(selectedAttributeValues)) {
        return [];
      }

      const attributeValues = selectedAttributeValues.map(
        ({ selectedAttribute, values }) => {
          if (!selectedAttribute || !values || !Array.isArray(values)) {
            return [];
          }

          const labels = values?.map(({ text }) => text);
          return { [selectedAttribute]: labels };
        }
      );

      const mergedAttributes = Object.assign({}, ...attributeValues);
      return mergedAttributes;
    } catch (error) {
      return [];
    }
  };

  const extractCategoriesOptions = ReUse.minifyArrayOfObjects({
    data: productCategories,
  });

  const extractSubCategoriesOptions = ReUse.minifyArrayOfObjects({
    data: productSubCategories,
  });

  const SellerAddProductHook = SellerAddProduct(extractAttributeValues());

  const { generatedVariations, useVariantDataHandler } = SellerAddProductHook;

  const [
    variantData,
    variantDataHandler,
    bulkVariantData,
    bulkVariantDataHandler,
    bulkVariantMOQs,
    variantMOQsHandler,
  ] = useVariantDataHandler();

  const IndividualVariantProps = {
    variantData,
    variantDataHandler,
    extractAttributeOptions,
    setSelectedAttributes,
    selectedAttributes,
    attributeOptions,
    delimiters,
    handleAttributeOptionDelete,
    handleAttributeOptionAddition,
    handleAttributeOptionDrag,
    handleAttributeOptionClick,
    generatedVariations,
  };

  const BulkVariantProps = {
    bulkVariantData,
    bulkVariantDataHandler,
    bulkVariantMOQs,
    variantMOQsHandler,
    extractAttributeOptions,
    setSelectedAttributes,
    selectedAttributes,
    attributeOptions,
    delimiters,
    handleAttributeOptionDelete,
    handleAttributeOptionAddition,
    handleAttributeOptionDrag,
    handleAttributeOptionClick,
    generatedVariations,
  };

  const SelectedInventoryType = () => {
    if (value === 1) {
      return (
        <>
          <IndividualVariant inventoryVariantProps={IndividualVariantProps} />
        </>
      );
    } else if (value === 2) {
      return (
        <>
          <BulkVariant inventoryVariantProps={BulkVariantProps} />
        </>
      );
    } else {
      return null;
    }
  };

  const UploadedImages = () => {
    if (uploadedImages?.length > 0) {
      return (
        <>
          <div className="uploaded-images-wrapper">
            {uploadedImages?.map((image) => (
              <RenderUploadedImage imageURL={image} />
            ))}
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  console.log(selectedAttributeValues, "extractAttributeOptions");

  return (
    <>
      <AccessControl allowedRoles={["seller"]}>
        <SellerHeader />
        {/* Seller Adding Product starts here */}
        <section className="seller-dashboard">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 ps-0">
                <SellerSidebar />
              </div>
              <div className="col-lg-8">
                <div className="edit-profile-card edit-no-padding-right adding-main-product-scroll">
                  <div className="edit-heading-wrapper">
                    <h2>Adding product</h2>
                  </div>

                  <div className="seller-edit-with-border-dv">
                    <div className="row">
                      <div className="col-md-10">
                        <div className="edit-">
                          <div className="seller-all-form">
                            <form>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="seller-edit-form-dropdown">
                                    <Select
                                      isMulti={false}
                                      name="categories"
                                      options={extractCategoriesOptions}
                                      // className="form-select"
                                      classNamePrefix="select"
                                      onChange={(option) => {
                                        setSelectedProductCategory(
                                          option?.value
                                        );
                                        const resetErrors = ReUse.resetErrors({
                                          fields: allErrors,
                                        });
                                        setAllErrors(resetErrors);
                                      }}
                                    />
                                    <FieldError
                                      fieldName={allErrors?.category_id}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="seller-edit-form-dropdown">
                                    <Select
                                      isMulti={false}
                                      name="categories"
                                      options={extractSubCategoriesOptions}
                                      // class="form-select"
                                      classNamePrefix="select"
                                      onChange={(option) => {
                                        setSelectedProductSubCategory(
                                          option?.value
                                        );
                                        const resetErrors = ReUse.resetErrors({
                                          fields: allErrors,
                                        });
                                        setAllErrors(resetErrors);
                                      }}
                                    />
                                    <FieldError
                                      fieldName={allErrors?.sub_category_id}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="seller-edit-form-input">
                                    <input
                                      type="text"
                                      placeholder="Product Name"
                                      onChange={handleChange}
                                      name="name"
                                      value={fieldsData?.name}
                                    />
                                    <FieldError fieldName={allErrors?.name} />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="seller-edit-form-input">
                                    <input
                                      type="text"
                                      placeholder="Stock Quantity"
                                      onChange={handleChange}
                                      name="current_stock"
                                      value={fieldsData?.current_stock}
                                    />
                                    <FieldError
                                      fieldName={allErrors?.current_stock}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="seller-edit-form-input">
                                    <input
                                      type="text"
                                      placeholder="Unit Price"
                                      onChange={handleChange}
                                      name="unit_price"
                                      value={fieldsData?.unit_price}
                                    />
                                    <FieldError
                                      fieldName={allErrors?.unit_price}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="seller-edit-form-input">
                                    <input
                                      type="text"
                                      placeholder="Purchase Price"
                                      onChange={handleChange}
                                      name="purchase_price"
                                      value={fieldsData?.purchase_price}
                                    />
                                    <FieldError
                                      fieldName={allErrors?.purchase_price}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="seller-edit-form-input">
                                    <textarea
                                      class="form-control"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      placeholder="Description"
                                      name="details"
                                      value={fieldsData?.details}
                                      onChange={handleChange}
                                    ></textarea>
                                    <FieldError
                                      fieldName={allErrors?.details}
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>

                            <div className="row">
                              <div className="col-md-12">
                                <div className="label-and-upload-product-btn">
                                  <label>Select Product Images</label>
                                  <div className="upload-btn">
                                    <button>
                                      <span>
                                        <img
                                          className="img-fluid"
                                          alt="upper-arrow"
                                          src={upperarrow}
                                        />{" "}
                                        Upload Pictures
                                      </span>
                                      <input
                                        type="file"
                                        accept="image/*"
                                        multiple
                                        onChange={handleMultipleImagesUpload}
                                      />
                                    </button>
                                  </div>

                                  <div className="upload-btn">
                                    <button>
                                      <span>
                                        <img
                                          className="img-fluid"
                                          alt="upper-arrow"
                                          src={upperarrow}
                                        />{" "}
                                        Upload Thumbnail
                                      </span>
                                      <input
                                        type="file"
                                        accept="image/*"
                                        multiple={false}
                                        onChange={handleThumbnailUpload}
                                      />
                                    </button>
                                    <FieldError
                                      fieldName={allErrors?.thumbnail}
                                    />
                                  </div>
                                </div>
                                <div className="label-and-upload-box-shadow">
                                  <UploadedImages />
                                  <RenderImage file={fileupload} />
                                </div>
                              </div>
                              <div className="mcq-dv-all">
                                <h5>Select Inventory Type</h5>
                              </div>
                              <Select
                                isMulti={false}
                                options={inventoryTypes}
                                class="form-select mt-2 mb-2"
                                classNamePrefix="select Inventory Type"
                                onChange={setSelectedInventoryType}
                                value={selectedInventoryType}
                              />
                              <SelectedInventoryType />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="seller-save-btn">
                          <CustomButton
                            loading={loading}
                            onClickHandler={onSubmitHandler}
                            customClassName="custom-btn"
                          >
                            Create
                          </CustomButton>
                          <ResponseMessage />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Seller Adding Product ends here */}
      </AccessControl>
    </>
  );
}

export default Addingproduct;
