import React from "react";
import Footer from "../../Components/Footer";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import {
  customerorder1,
  ordersmap,
  productinventoryimg,
  reviewimg3,
  vegiorder1,
  vegiorder2,
  vegiorder3,
} from "../../Constant/Index";
import AccessControl from "../../Components/Auth/AccessControl";
import { useState } from "react";
import { useEffect } from "react";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import CustomerOrderCard from "./CustomerOrderCard";
import Pagination from "../Product/Pagination";

function Customerorder() {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const sellerGetorders = AppService.sellerGetorders({
      pageNumber: currentPage,
    });
    ReUse.getApiData(sellerGetorders, setApiData, setLoading);
  }, []);

  const paginationProps = {
    apiData: apiData,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };

  return (
    <>
      <AccessControl allowedRoles={["seller"]}>
        <SellerHeader />
        {/* Seller Customer Order here */}
        <section className="seller-dashboard">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 ps-0">
                <SellerSidebar />
              </div>
              <div className="col-lg-8">
                <div className="edit-profile-card edit-no-padding-right">
                  <div className="edit-heading-wrapper">
                    <h2>Customers Orders</h2>
                  </div>

                  <div className="seller-prdoduct-inventory-table seller-prdouct-customer-table">
                    <table>
                      <thead>
                        <th className="for-border-all-raduis">Customers</th>
                        <th>Products </th>
                        <th>Order Date</th>
                        <th>Payment Status</th>
                        <th>Total</th>
                        <th className="for-border-raduis-all-things"></th>
                      </thead>

                      <tbody>
                        {ReUse.mapItems(
                          loading,
                          apiData?.data,
                          CustomerOrderCard
                        )}
                      </tbody>
                    </table>
                  </div>

                  <Pagination paginationProps={paginationProps} />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Seller Product Inventory ends here */}

        {/* Customer Order Detail */}
        <div className="order-total-modal">
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div className="responsive-close-icon">
                  <i
                    class="fa fa-times"
                    aria-hidden="true"
                    data-bs-dismiss="modal"
                  ></i>
                </div>
                <div class="modal-body">
                  <div className="all-orders-detail-modal">
                    <div className="order-head">
                      <h2>Order #s0tq-vrp6</h2>
                      <p>Delivered on 18 Jan, 20:54</p>
                    </div>
                    <div className="all-order-img">
                      <div className="all-img-order">
                        <figure>
                          <img
                            className="img-fluid"
                            alt="order-img"
                            src={ordersmap}
                          />
                        </figure>
                      </div>
                      <div className="all-img-order-text">
                        <div className="order-text">
                          <h4>Order From</h4>
                          <p>Eat & Bite</p>
                        </div>
                        <div className="order-text order-move-up-down">
                          <h4>Delivery Address</h4>
                          <p className="for-para-width">
                            Lorem Ipsum is simply dummy text of 123ssd2 the
                            printing{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="all-img-flex">
                      <div className="img-one">
                        <figure>
                          <img
                            className="img-fluid"
                            alt="img-one"
                            src={vegiorder1}
                          />
                        </figure>
                        <div className="img-text-all">
                          <h4>Cabbage Soup</h4>
                          <h5>Qty: 2</h5>
                        </div>
                      </div>

                      <div className="order-price">
                        <h5>$158.07 </h5>
                      </div>
                    </div>
                    <div className="all-img-flex">
                      <div className="img-one">
                        <figure>
                          <img
                            className="img-fluid"
                            alt="img-one"
                            src={vegiorder2}
                          />
                        </figure>
                        <div className="img-text-all">
                          <h4>Cabbage Soup</h4>
                          <h5>Qty: 2</h5>
                        </div>
                      </div>

                      <div className="order-price">
                        <h5>$158.07 </h5>
                      </div>
                    </div>
                    <div className="all-img-flex">
                      <div className="img-one">
                        <figure>
                          <img
                            className="img-fluid"
                            alt="img-one"
                            src={vegiorder3}
                          />
                        </figure>
                        <div className="img-text-all">
                          <h4>Cabbage Soup</h4>
                          <h5>Qty: 2</h5>
                        </div>
                      </div>

                      <div className="order-price">
                        <h5>$158.07 </h5>
                      </div>
                    </div>

                    <div className="item-total">
                      <div className="order-total">
                        <h5>Item Total :</h5>
                        <h5>$50</h5>
                      </div>
                      <div className="order-total">
                        <h5>Delivery Charges </h5>
                        <h5>$0</h5>
                      </div>
                      <div className="order-total">
                        <h5>Promo Code Applied :</h5>
                        <h5>-$5</h5>
                      </div>
                    </div>

                    <div className="order-sum">
                      <h3>Order Total</h3>
                      <h4>$45</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ============== */}
      </AccessControl>
    </>
  );
}

export default Customerorder;
