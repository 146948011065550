import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./slices/auth.slice";
import UiSlice from "./slices/ui.slice";
import CartSlice from "./slices/cart.slice";
import ProductsFilters from "./slices/productsFilter.slice";
import ResponseMessage from "./slices/responseMessage.slice";
import ProductDetailSlice from "./slices/productDetail.slice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
	key: "root",
	storage,
};

const rootReducer = combineReducers({
	auth: AuthReducer,
	cart: CartSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: { persistedReducer, ui: UiSlice, productsfilters: ProductsFilters, responseMessage: ResponseMessage, productDetail: ProductDetailSlice },
	devTools: process.env.NODE_ENV !== "production",
	middleware: [thunk],
});
export const persistor = persistStore(store);

// import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import AuthReducer from "./slices/auth.slice";
// import UiSlice from "./slices/ui.slice";
// import ProductsFilters from "./slices/productsFilter.slice";
// import storage from "redux-persist/lib/storage";
// import { persistReducer, persistStore } from "redux-persist";
// import thunk from "redux-thunk";

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const rootReducer = combineReducers({
//   auth: AuthReducer,
//   ui: UiSlice,
//   filters: ProductsFilters,
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//   reducer: persistedReducer, // Use the combined rootReducer directly here
//   devTools: process.env.NODE_ENV !== "production",
//   middleware: [thunk],
// });
// export const persistor = persistStore(store);
