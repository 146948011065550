import React, { useEffect, useState } from "react";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import ObjBasedApiDataLoader from "../../Components/ReUse/ReUsableHOC/ObjBasedApiDataLoader";
import ProductDetailContent from "../../Components/PID/ProductDetailContent";
import { useLocation } from "react-router-dom";
import SellerStoreContent from "../../Components/PID/SellerStoreContent";

function Productdetail() {
  const location = useLocation();
  const { sellerId, currentIndex } = location?.state ?? {};

  const currentId = ReUse.extractIdFromCurrentUrl();

  function PIDDetail({ loading, data }) {
    return <SellerStoreContent data={data} />;
  }

  const EnhancedPIDDetail = ObjBasedApiDataLoader(PIDDetail);

  const [apiData, setApiData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storeInfo = AppService.storeInfo({ sellerId: currentId }); //56
    ReUse.getApiData(storeInfo, setApiData, setLoading);
  }, [currentId]);

  return (
    <>
      <EnhancedPIDDetail data={apiData} loading={loading} />
    </>
  );
}

export default Productdetail;
