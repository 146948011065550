import { createSlice } from "@reduxjs/toolkit";
import { Store } from "react-notifications-component";

const initialUIState = {
  isLoading: false,
  showErrorNotification: false,
  showSuccessNotification: false,
};

const UISlice = createSlice({
  name: "ui",
  initialState: initialUIState,
  reducers: {
    setGlobalLoader: (state) => {
      state.isLoading = !state.isLoading;
    },
    setErrorNotification: (state, action) => {
      // debugger;
      if (Object.keys(action.payload.errors).length > 0) {
        for (const key in action.payload.errors) {
          Store.addNotification({
            title: "Invalid " + key,
            message: action.payload.errors[key][0],
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      }
      if (action.payload.errors.length === 0) {
        Store.addNotification({
          title: "Invalid",
          message: action.payload.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    },
    setSuccessNotification: (state, action) => {
      Store.addNotification({
        title: "Success",
        message: action.payload,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    },
  },
});

export const UIActions = UISlice.actions;
export default UISlice.reducer;
