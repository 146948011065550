import React from "react";
import CategoryChild from "./CategoryChild";
import ReUse from "../../services/helpers/reUse";

const CatsSubcats = (propsData) => {
  const { id, name, slug, icon, icon_path, childes } = propsData;

  const test = 1;

  return (
    <>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingOne">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            {name && name}
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <ul className="accessories-body">
              {ReUse.mapItems(false, childes, CategoryChild)}
              {/* <CategoryChild /> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CatsSubcats;
