import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import {
  wishlist1,
  wishlist2,
  wishlist3,
  wishlist4,
} from "../../Constant/Index";
import "../../assets/css/wishlist.css";
import PageHeader from "../../Components/Pageheader";
import { Link } from "react-router-dom";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import WishlistCard from "./WishlistCard";
import Pagination from "./Pagination";

function Wishlist() {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const wishlists = AppService.customerGetAllProductsWishlist({
      pageNumber: currentPage,
    });
    ReUse.getApiData(wishlists, setApiData, setLoading);
  }, [currentPage]);

  const paginationProps = {
    apiData,
    setPageCount,
    pageCount,
    setCurrentPage,
    currentPage,
  };

  return (
    <>
      <PageHeader pagename="Wishlist" />
      {/* Wishlist Section */}
      <section className="wish-list-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="wish-list-all">
                <div className="table-responsive">
                  <table className="wish-list-table table">
                    <thead>
                      <th>
                        <span>Items </span>
                      </th>
                      <th>
                        <span>Product Name </span>
                      </th>
                      <th>
                        <span>Unit Price </span>
                      </th>
                      <th>
                        <span>Stock Status </span>
                      </th>
                      <th>
                        <span>Action </span>
                      </th>
                    </thead>
                    <tbody>
                      {ReUse.mapItems(loading, apiData?.data, WishlistCard)}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Pagination paginationProps={paginationProps} />
      </section>
      {/* ==================== */}
    </>
  );
}

export default Wishlist;
