import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  organic1,
  productslider1,
  productslider2,
  productslider3,
  productslider4,
  productslider5,
  slider1,
  slider2,
  slider3,
  slider4,
  slider5,
} from "../Constant/Index";
import AppService from "../services/appServices";
import ReUse from "../services/helpers/reUse";
import { useEffect } from "react";
import RelatedProductcCard from "./RelatedProductcCard";

function Productslider({ productsliderProps }) {
  const { catId, subCatId } = productsliderProps ?? {};
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const relatedProducts = AppService.getProductByCategory({
      categoryId: catId,
      subCategoryId: subCatId,
    });
    ReUse.getApiData(relatedProducts, setApiData, setLoading);
  }, [catId, subCatId]);

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      {/* Product Slider */}
      <section className=" prdocut-slide-sec">
        <div className="container">
          <div className="slide-head">
            <h4>Related Products</h4>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider {...settings}>
                {ReUse.mapItems(loading, apiData, RelatedProductcCard)}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* =========================== */}
    </>
  );
}

export default Productslider;
