import React from 'react';
import Modal from 'react-modal';
import { UseModal } from '../../../Hooks/modals/UseModal';

const defaultStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const GlobalModal = ({ children, propsStyles, isOpen, onRequestClose }) => {
    // const { modalIsOpen, openModal, closeModal } = UseModal(); 



    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                style={propsStyles || defaultStyles}
                contentLabel="Modal"
            >
                {children}
            </Modal>
        </>
    );
};

export default GlobalModal;
