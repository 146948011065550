import React from "react";
import PageHeader from "../Pageheader";
import { careerdetail } from "../../Constant/Index";
import JobApplicationForm from "../../pages/Career/JobApplicationForm";
import { Link } from "react-router-dom";
import CareerDetailPositionContent from "./CareerDetailPositionContent";
import AccessControl from "../Auth/AccessControl";
import ReUse from "../../services/helpers/reUse";

const CareerDetailContent = ({ data }) => {
	const { position, description, experience_required } = data ?? {};

	return (
		<>
			<PageHeader pagename="Careers" />
			{/* Job description */}
			<section className="job-description">
				<div className="container">
					<div className="right-job-head">
						<h4>{position}</h4>
					</div>
					<div className="row">
						<div className="col-md-8">
							<div className="job-all-descrciption-dv">
								<div className="job-head-text">
									<h3>Job Description</h3>
									{ReUse.dangerouslySetInnerHTML({
										content: description,
									})}
								</div>

								<div className="job-head-text resposibility-text">
									<h3>Responsibilities</h3>
									{ReUse.dangerouslySetInnerHTML({
										content: experience_required,
									})}
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="job-description-img">
								<img className="img-fluid" alt="job-img" src={careerdetail} />
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ================================ */}

			{/* Job Application Form */}
			<section className="simple-job-application-form-sec">
				<div className="container">
					<div className="work-with-head">
						<h3>Fill Out a Job Application form</h3>
					</div>

					<AccessControl allowedRoles={["customer"]}>
						<JobApplicationForm />
					</AccessControl>
				</div>
			</section>
			{/* =============== */}

			{/* Similar Opening Section */}
			<PageHeader pagename="Similar Openings" />
			{/* Open Job Section */}
			<CareerDetailPositionContent position={position} />
			{/* ========================= */}
			{/* ================= */}
		</>
	);
};

export default CareerDetailContent;
