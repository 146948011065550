import React from "react";
import { Link } from "react-router-dom";
import ReUse from "../services/helpers/reUse";

function Careercompo(propsData) {
	const {
		id,
		slug,
		position,
		location,
		experience,
		experience_required,
		description,
		salary,
	} = propsData ?? {};
	return (
		<>
			<div className="col-md-4">
				<div className="open-job-all">
					<div className="open-dv">
						<div className="open-first-dv">
							<h3>{position}</h3>
							<p>{location}</p>
						</div>
						<div className="full-time-btn">
							<Link>Full Time</Link>
						</div>
					</div>

					<div className="open-all-job-para">
						<p>{ReUse.removeHTMLTags({ unStripped: description })}</p>
						<Link to={`/career-detail/${slug}`} state={slug}>
							View More
						</Link>
					</div>
				</div>
			</div>
		</>
	);
}

export default Careercompo;
