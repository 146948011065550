import React from "react";
import { Link } from "react-router-dom";
import ProductSubVariations from "./ProductSubVariations";
import ReUse from "../../services/helpers/reUse";

const ProductVariations = (propsData) => {
	const { options, title } = propsData ?? {};

	return (
		<>
			<div className="all-radio-btn">
				<div className="radio-name quantity-head">
					<h5>{title}</h5>
				</div>
				{ReUse.mapItems(false, options, ProductSubVariations, title)}
				<div className="size-chart-anchor">
					<Link>Size Chart</Link>
				</div>
			</div>
		</>
	);
};

export default ProductVariations;
