export const ENDPOINTS = {
  /*Customer/Auth */
  signUp: "/auth/register",
  login: "/auth/login",
  resendEmailVerification: "/auth/email-verification-link",
  forgotPassword: "/auth/forgot-password",
  verifyOTP: "/auth/verify-otp",
  forgotPasswordReset: "/auth/forgot-password-reset",
  resetPassword: "/auth/reset-password",
  logout: "/customer/logout",

  /*Customer/Profile */
  profile: "/customer/info",
  updateProfile: "/customer/update-profile",

  /*Customer/Ordere/Poduct Order */
  placeOrder: "/customer/order/place",
  submitReview: "/products/reviews/submit",

  /*Customer/Order/Customizable Product Order */
  addCustomizableOrder: "/customer/product/product-customization",
  customizableSubmitReview: "/customer/product/customization/review",

  /*Customer/Order */
  orderDetail: "/customer/order/details",
  getAllOrders: "/customer/order/list",

  /*Customer/Order/Wishlist */
  addProductWishlist: "/customer/product/wishlist",
  removeProductWishlist: "/customer/product/wishlist",
  getAllProductsWishlist: "/customer/product/wishlist",

  /*Customer/Get a Quote */
  addQuote: "/customer/product/quote",
  getAllQuotes: "/customer/product/quote",
  showQuote: "/customer/product/quote",

  /*Customer/Checkout*/
  orderCheckout: "/stripe/checkout",

  /*Customer/Addresses*/
  getAllAddresses: "/customer/address",
  addAddress: "/customer/address",
  updateAddress: "/customer/address",
  showAddress: "/customer/address",
  deleteAddress: "/customer/address",

  /*Seller/Auth*/
  sellerLogin: "/seller/auth/login",
  sellerResendEmailVerification: "/seller/auth/email-verification-link",
  sellerForgotPassword: "/seller/auth/forgot-password",
  sellerVerifyOTP: "/seller/auth/verify-otp",
  sellerForgotPasswordReset: "/seller/auth/forgot-password-reset",
  sellerResetPassword: "/seller/auth/reset-password",
  sellerLogout: "/seller/auth/logout",

  /*Seller/Dashboard*/
  orderActivity: "/seller/dashboard/order-activity",

  /*Seller/Dashboard/Graph*/
  sellerGraphData: "/seller/graph",

  /*Seller/Profile*/
  profileInfo: "/seller/info",
  updatePersonalDetails: "/seller/update-personal-details",
  updateBusinessDetails: "/seller/update-business-details",

  /*Seller/Customers & Orders*/
  orders: "/seller/orders",
  ordersDetails: "/seller/orders/details",

  /*Seller/Reports*/
  orderReport: "/seller/reports/order",
  earningReport: "/seller/reports/earning",

  /*Seller/Reviews & Feedback*/
  reviews: "/seller/reviews",
  sendFeedback: "/seller/reviews/send-feedback",

  /*Seller/Product Inventory*/
  products: "/seller/productss",
  categories: "/seller/products/categories",
  subCategories: "/seller/products/sub-categories",
  units: "/seller/products/units",
  colors: "/seller/products/colors",
  attributes: "/seller/products/attributes",
  skuCombination: "/seller/products/sku-combinations",
  allowAddProduct: "/seller/allow-add-product",
  addProduct: "/seller/products/create",
  updateProduct: "/seller/products/update",
  updateProductStatus: "/seller/update-product-status",
  delete: "/seller/products/delete",

  /*Seller/Membeship Plan*/
  membership: "/seller/membership",
  cancelMembership: "/seller/membership/cancel",
  upgradeMembership: "/seller/membership/upgrade",

  /*Seller/Seller Center Page*/
  sellerCenter: "/sellercenters",
  packages: "/package",
  sellerRegistrationwithPlan: "/seller/plan",

  /*Seller/Seller Public Apis*/
  storeInfo: "/seller/store-info",
  storeProducts: "/seller/store-products",
  storeReviews: "/seller/store-reviews",

  /*Seller/Seller Payment Card*/
  getSellerAllCards: "/seller/card ",
  getSellerCard: "/seller/card",
  updateSellerCard: "/seller/card",
  createSellerCard: "/seller/card",
  deleteSellerCard: "/seller/card/",

  /*Categories*/
  productByCategory: "/categories/products",
  all: "/categories",

  /*Notifications*/
  allNotifications: "/notifications",

  /*Products */
  allProducts: "/products",
  productDetail: "/products/details",

  /*Pages */
  page: "/pages/",

  /*FAQs */
  allFAQs: "/faqs",

  /*Contact Us */
  contactUs: "/contact-us/store",

  /*Careers */
  careers: "/careers",
  careerDetails: "/careers/detail",
  applyForJob: "/careers/apply",

  /*Recipes */
  allRecipes: "/recipe",
  showRecipe: "/recipe/show",

  /*Blogs */
  allBlogs: "/blogs",
  ShowBlog: "/blogs/show",

  /*HomePage */
  newsletter: "/subscribe/newsletter",
  getAllBanners: "/banners",
  topSellingProducts: "/top-selling-products/",
  socialMedia: "/social-media",

  /*Customizable Products */
  getAllCustomizableProducts: "/customized-product",
  getCustomizableProductDetails: "/customized-product/details",

  /*Homepage Categories */
  homepageCategories: "/market-place",

  /*Settings */
  settings: "/settings",

  /* Region Info*/
  countryList: "/country/list",
  countryStatesList: "/country/states",
  statecitieslist: "/state/cities",

  /* Shipping Charges */
  shippingCharges: "/shipping-charges",
};
