import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ProductsFilterActions } from "../redux/slices/productsFilter.slice";

const HeaderSubCats = (propsData) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { id, name, slug, icon, icon_path, parent_id } = propsData;

	const catSubCatIdHandles = () => {
		dispatch(
			ProductsFilterActions.setCatSubCatIds({
				categoryId: parent_id,
				subCategoryId: id,
			}),
		);
		navigate("/shop");
	};

	return (
		<>
			<li
				className="dropdown-item"
				onClick={() => {
					catSubCatIdHandles();
				}}
			>
				<Link>{name && name}</Link>
			</li>
		</>
	);
};

export default HeaderSubCats;
