import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import "../../assets/css/terms.css";
import TermsConditionContent from "../../Components/PID/TermsConditionContent";
import ObjBasedApiDataLoader from "../../Components/ReUse/ReUsableHOC/ObjBasedApiDataLoader";
import { useState } from "react";
import { useEffect } from "react";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import { useLocation } from "react-router-dom";
import StaticPageContent from "../../Components/PID/StaticPageContent";

function Termscondition() {
  const location = useLocation();

  const { pathname } = location ?? {};

  function PIDDetail({ loading, data }) {
    return <StaticPageContent data={data} />;
  }

  const EnhancedPIDDetail = ObjBasedApiDataLoader(PIDDetail);

  const [apiData, setApiData] = useState({});
  const [loading, setLoading] = useState(false);

  const pageName = ReUse.removeSpecialCharacterFromString(pathname, "/");

  useEffect(() => {
    const getPage = AppService.getPage({ pageName: pageName });
    ReUse.getApiData(getPage, setApiData, setLoading);
  }, [pathname]);

  return (
    <>
      <EnhancedPIDDetail data={apiData} loading={loading} />
    </>
  );
}

export default Termscondition;
