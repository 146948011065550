import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { secondlogo } from "../../Constant/Index";
import ReUse from "../../services/helpers/reUse";
import { toast } from "react-toastify";
import { AuthActions } from "../../redux/slices/auth.slice";
import AppService from "../../services/appServices";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import CustomButton from "../../Components/ReUse/Components/Button";
import FieldError from "../../Components/ReUse/FieldError";

function Enterotp() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	// const { email } = location?.state && location?.state;

	const [loading, setLoading] = useState(false);
	const [OTP, setOTP] = useState([]);
	const [OTPBoxes, setOTPBoxes] = useState([1, 2, 3, 4, 5, 6]);

	const [allErrors, setAllErrors] = useState({
		otp: "",
	});

	const [fieldsData, setFieldsData] = useState({
		otp: "",
	});

	const [isResendOTPDisabled, setResendOTPDisabled] = useState(false);
	const [email, setEmail] = useState("");

	const OTPHandler = (e, index) => {
		// const resetErrors = ReUse.resetErrors(allErrors);
		// setAllErrors(resetErrors);
		const newOTP = [...OTP];
		const { value } = e.target;
		newOTP[index] = value;
		setOTP(newOTP);
	};

	const onSubmitHandler = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const { otp } = fieldsData;

			const fields = {
				otp,
			};

			const arrayToStringOtp = OTP?.join("")?.toString();
			const stringToNumberOtp = parseInt(arrayToStringOtp);

			const payLoad = {
				otp: stringToNumberOtp,
			};

			const apiResp = await AppService.customerVerifyOTP(payLoad);
			const { message, response } = apiResp.data;
			setLoading(false);
			dispatch(successMessage(message));
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/create-new-password", { state: { email: email } });
				}, 1000);
			}, 1000);
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			dispatch(successMessage(""));
		}
	};

	const resendOTPHandler = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const data = {
				otp: email,
			};

			setTimeout(async () => {
				const apiResp = await AppService.customerResendEmailVerification(data);
				const { message, response } = apiResp.data;
				toast.success(message);
				setLoading(false);
			}, 600);

			setResendOTPDisabled(true);
			const timer = setTimeout(() => {
				setResendOTPDisabled(false);
				clearTimeout(timer);
			}, 5000);
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
		}
	};

	useEffect(() => {
		setEmail(location?.state?.email);
	}, [location?.state?.email]);

	

	const generateId = ReUse.makeId();

	return (
		<>
			{/* Enter Email Section */}
			<section className="login-sec sign-in-sec enter-otp-sec">
				<div className="container">
					<div className="row">
						<div className="login-page-logo">
							<Link to="/">
								<figure>
									<img
										className="img-fluid"
										alt="login-page-logo"
										src={secondlogo}
									/>
								</figure>
							</Link>
						</div>
						<div className="col-md-6">
							<div className="div-for-all-movement">
								<div className="enter-email-movement enter-otp-res">
									<div className="welcome-back-sec">
										<h3>Welcome Back!</h3>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit.
											Fusce laoreet mauris sit amet venenatis cursus. Fusce quis
											libero orci. Donec posuere lacus id felis hendrerit
											dapibus. Mauris
										</p>
										<div className="login-signin-btn">
											<Link to="/sign-in">Sign in</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="login-form">
								<form>
									<div className="row">
										<div className="form-head">
											<h3>Enter Your OTP</h3>
											<p>Please Enter The Confirmation Code</p>
										</div>

										{OTPBoxes?.map((item, index) => {
											return (
												<div className="col-md-2" key={index + item}>
													<div className="form-login-input">
														<input
															type="number"
															name="otp"
															value={OTP[index] || ""}
															onChange={(e) => OTPHandler(e, index)}
														/>
														<FieldError fieldName={allErrors?.otp} />
													</div>
												</div>
											);
										})}
										<div className="col-md-12">
											<div className="enter-email-para">
												<p>
													Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing
													Elit. Fusce Laoreet Mauris Sit Amet Venenatis Cursus.
												</p>
											</div>
										</div>

										<div className="login-signup-btn">
											<a>
												<CustomButton
													loading={loading}
													onClickHandler={onSubmitHandler}
													customClassName="btn"
												>
													Submit
												</CustomButton>
											</a>
											<ResponseMessage />
										</div>
										<div className="send-again-btn">
											<button
												className="btn-Resend"
												disabled={isResendOTPDisabled}
												onClick={(e) => resendOTPHandler(e)}
											>
												{isResendOTPDisabled
													? `You can resend the OTP after 50 seconds`
													: "Resend Code"}
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ================ */}
		</>
	);
}

export default Enterotp;
