import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ProductsFilterActions } from "../../redux/slices/productsFilter.slice";
import ReUse from "../../services/helpers/reUse";

const CategoryChild = (propsData) => {
  const { id, name, slug, icon, parent_id, icon_path } = propsData;

  const [catSubCatIds, setCatSubCatIds] = useState({
    categoryId: null,
    subCategoryId: null,
  });

  const dispatch = useDispatch();

  const catSubCatIdsHandler = () => {
    dispatch(
      ProductsFilterActions.setCatSubCatIds({
        categoryId: parent_id,
        subCategoryId: id,
      })
    );
    ReUse.scrollToTop();
  };

  return (
    <>
      <li onClick={catSubCatIdsHandler}> {name && name}</li>
    </>
  );
};

export default CategoryChild;
