import React from "react";
import ProductsSortingFilters from "./ProductsSortingFilters";
import ProductsParamsFilter from "./ProductsParamsFilter";
import ReUse from "../../services/helpers/reUse";
import Pagination from "./Pagination";
import ShopProduct from "./ShopProduct";

const AllProductsSection = ({ allProductsSectionProps }) => {
	const { loading, allProducts, showProducts, setCurrentPage, currentPage } =
		allProductsSectionProps ?? {};

	const paginationProps = {
		apiData: allProducts,
		setCurrentPage: setCurrentPage,
		currentPage: currentPage,
	};

	return (
		<>
			<section className="product-sec">
				<div className="container">
					<ProductsSortingFilters />
					<div className="row">
						<ProductsParamsFilter />
						<div className="col-md-9">
							<div className="row all-class-for-padding">
								{ReUse.mapItems(
									loading,
									allProducts?.data?.slice(0, showProducts),
									ShopProduct,
								)}
							</div>
						</div>
					</div>

					<Pagination paginationProps={paginationProps} />
				</div>
			</section>
		</>
	);
};

export default AllProductsSection;
