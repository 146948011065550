import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import MembershipCard from "./MembershipCard";
import AppService from "../services/appServices";
import ReUse from "../services/helpers/reUse";

var settings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 4,
	slidesToScroll: 1,
	arrows: false,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
				dots: false,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
};

function Membershipslider() {
	const [loading, setLoading] = useState(false);
	const [apiData, setApiData] = useState([]);

	useEffect(() => {
		const getPublicMemberships = AppService.sellerGetpackages();

		ReUse.getApiData(getPublicMemberships, setApiData, setLoading);
	}, []);

	return (
		<>
			<section className="memeber-ship=slider-sec">
				<div className="container">
					<Slider {...settings}>
						{ReUse.mapItems(loading, apiData, MembershipCard)}
						{/* {[1, 2, 3, 4].map(() => {
							return <MembershipCard />;
						})} */}
					</Slider>
				</div>
			</section>
		</>
	);
}

export default Membershipslider;
