import React from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import ReUse from "../services/helpers/reUse";
import { useState } from "react";
import AppService from "../services/appServices";
import { toast } from "react-toastify";
import {
  clearResponseMessageAfterDelay,
  errorMessage,
} from "../redux/slices/responseMessage.slice";
import { useDispatch } from "react-redux";
import ResponseMessage from "./ReUse/ResponseMessage";

const SellerSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const logOutSeller = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const apiResp = await AppService.sellerLogout();
      const { message, response } = apiResp?.data;
    //   toast.success(message);
      await ReUse.logOutUser();
      setLoading(false);
      navigate("/");
    } catch (err) {
      setLoading(false);
      dispatch(errorMessage(err?.response?.data?.message));
      dispatch(clearResponseMessageAfterDelay());
    }
  };

  return (
    <>
      {/* sidebar starts here */}
      <div className="seller-sidebar-sec">
        <div className="sidebar-links-wrapper">
          <ul>
            <li className="sidebar-links">
              <NavLink to="/seller-dashboard">
                <span className="icon">
                  <svg
                    id="Group_14882"
                    data-name="Group 14882"
                    xmlns="http://www.w3.org/2000/svg"
                    width="21.935"
                    height="24.167"
                    viewBox="0 0 21.935 24.167"
                  >
                    <path
                      id="Path_30141"
                      data-name="Path 30141"
                      d="M291.327,1655.234H298.7c.01.01.019.026.031.03a1.754,1.754,0,0,1,1.3,1.918q0,5.121,0,10.241a1.7,1.7,0,0,1-1.719,1.91q-3.309,0-6.619,0a1.913,1.913,0,0,1-.508-.062,1.744,1.744,0,0,1-1.21-1.8q0-3.292,0-6.584c0-1.266,0-2.533,0-3.8A1.78,1.78,0,0,1,291,1655.35C291.11,1655.306,291.219,1655.272,291.327,1655.234Z"
                      transform="translate(-278.09 -1655.234)"
                      fill="#7dab3c"
                    />
                    <path
                      id="Path_30142"
                      data-name="Path 30142"
                      d="M13.277,1880.7v-11.139a.215.215,0,0,0,.021-.041,1.656,1.656,0,0,1,1.767-1.458q3.256,0,6.511,0a2.107,2.107,0,0,1,.551.063,1.742,1.742,0,0,1,1.206,1.8q0,3.882,0,7.763c0,.881.006,1.762,0,2.643a1.687,1.687,0,0,1-1.6,1.821q-3.427.01-6.854,0a1.625,1.625,0,0,1-1.539-1.289C13.319,1880.813,13.3,1880.756,13.277,1880.7Z"
                      transform="translate(-13.277 -1857.997)"
                      fill="#7dab3c"
                    />
                    <path
                      id="Path_30143"
                      data-name="Path 30143"
                      d="M21.974,1655.234a4.541,4.541,0,0,1,.792.437,1.7,1.7,0,0,1,.567,1.366c0,1.486.01,2.973,0,4.459a1.672,1.672,0,0,1-1.628,1.794q-3.394.017-6.788,0a1.63,1.63,0,0,1-1.6-1.387.987.987,0,0,0-.039-.109v-5.1c.026-.079.054-.158.079-.238a1.666,1.666,0,0,1,.836-1.049c.133-.069.275-.118.413-.177Z"
                      transform="translate(-13.277 -1655.234)"
                      fill="#7dab3c"
                    />
                    <path
                      id="Path_30144"
                      data-name="Path 30144"
                      d="M294.98,2003.845q-1.659,0-3.318,0a1.693,1.693,0,0,1-1.709-1.872q0-2.158,0-4.316a1.7,1.7,0,0,1,1.692-1.87q3.34,0,6.679,0a1.692,1.692,0,0,1,1.685,1.874q0,2.158,0,4.316a1.689,1.689,0,0,1-1.689,1.867Q296.65,2003.847,294.98,2003.845Z"
                      transform="translate(-278.074 -1979.679)"
                      fill="#7dab3c"
                    />
                  </svg>
                </span>
                <span className="text">Dashboard</span>
              </NavLink>
            </li>
            <li className="sidebar-links">
              <NavLink to="/seller-profile">
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.739"
                    height="21"
                    viewBox="0 0 16.739 21"
                  >
                    <g
                      id="Group_14881"
                      data-name="Group 14881"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Path_30139"
                        data-name="Path 30139"
                        d="M-1232.773,1911.234c.261.055.524.1.783.165a5.018,5.018,0,0,1,3.538,4.11,5.094,5.094,0,0,1-.691,3.488,4.7,4.7,0,0,1-3.778,2.337,4.833,4.833,0,0,1-4.731-2.972,5.307,5.307,0,0,1,.734-5.341,4.773,4.773,0,0,1,2.688-1.683c.184-.039.371-.07.556-.1Z"
                        transform="translate(1241.475 -1911.234)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30140"
                        data-name="Path 30140"
                        d="M-1299.131,2119.687h-4.764a3.633,3.633,0,0,1-2.259-.672,3.139,3.139,0,0,1-1.3-2.333,11.394,11.394,0,0,1,.675-5.065,4.249,4.249,0,0,1,1.842-2.314,3.551,3.551,0,0,1,1.826-.462,1.352,1.352,0,0,1,.6.213c.3.172.584.388.879.577a4.3,4.3,0,0,0,5.022-.009c.233-.151.471-.3.69-.468a1.617,1.617,0,0,1,1.32-.285,3.736,3.736,0,0,1,2.845,2.134,9.091,9.091,0,0,1,.916,3.405,17.4,17.4,0,0,1,.077,1.821,3.3,3.3,0,0,1-3,3.421,6.058,6.058,0,0,1-.645.036Q-1296.769,2119.69-1299.131,2119.687Z"
                        transform="translate(1307.497 -2098.688)"
                        fill="#7dab3c"
                      />
                    </g>
                  </svg>
                </span>
                <span className="text">My Profile</span>
              </NavLink>
            </li>
            <li className="sidebar-links">
              <NavLink to="/seller-product-inventory">
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21.111"
                    height="23.177"
                    viewBox="0 0 21.111 23.177"
                  >
                    <g
                      id="Group_14887"
                      data-name="Group 14887"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Path_30161"
                        data-name="Path 30161"
                        d="M-2213.663,621.713h-1.456v-5.6h-1.835l2.045-3.61h-1.656V610.51h15.991v16.814h-.709V612.9h-5.825v-.794h-6.554Z"
                        transform="translate(2220.937 -604.153)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30162"
                        data-name="Path 30162"
                        d="M-2210.821,521.13c0-.413-.008-.813.008-1.213,0-.068.1-.156.165-.193q2.013-1.073,4.032-2.135,1.812-.958,3.623-1.92a.335.335,0,0,1,.358,0q4.184,2.221,8.374,4.428a.309.309,0,0,1,.192.34c-.013.224,0,.45,0,.7Z"
                        transform="translate(2215.179 -515.617)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30163"
                        data-name="Path 30163"
                        d="M-2273.312,540.579h1.074c-.042.089-.069.153-.1.213q-.722,1.275-1.446,2.548c-.341.6-.891.6-1.23,0-.515-.9-1.027-1.811-1.562-2.755h1.082v-8.022h2.184Z"
                        transform="translate(2276.936 -531.432)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30164"
                        data-name="Path 30164"
                        d="M-2145.566,727.817H-2147v-5.6h-3.629v-4h1.433v1.576h2.185v-1.576h1.446Z"
                        transform="translate(2158.646 -704.64)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30165"
                        data-name="Path 30165"
                        d="M-2277.309,711.029h-5.064v-4.791h1.433v1.576h2.185v-1.576h1.446Z"
                        transform="translate(2282.378 -693.468)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30166"
                        data-name="Path 30166"
                        d="M-2278.837,791.691v-1.57h1.449V794.9h-5.074v-4.779h1.44v1.57Z"
                        transform="translate(2282.462 -771.732)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30167"
                        data-name="Path 30167"
                        d="M-2186.663,794.852v-4.786h1.44v1.577h2.185v-1.577h1.445v4.787Z"
                        transform="translate(2192.488 -771.68)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30168"
                        data-name="Path 30168"
                        d="M-2145.566,650.348h-5.063v-3.989h1.433v1.576h2.185v-1.576h1.446Z"
                        transform="translate(2158.646 -637.601)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30169"
                        data-name="Path 30169"
                        d="M-2049.752,662.326h-5.063v-3.989h1.433v1.576h2.185v-1.576h1.446Z"
                        transform="translate(2068.657 -648.776)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30170"
                        data-name="Path 30170"
                        d="M-2049.752,734.181h-5.063v-3.989h1.433v1.576h2.185v-1.576h1.446Z"
                        transform="translate(2068.658 -715.818)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30171"
                        data-name="Path 30171"
                        d="M-2051.263,802.029h1.45v3.98h-5.071v-3.973h1.436V803.6h2.185Z"
                        transform="translate(2068.722 -782.843)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30172"
                        data-name="Path 30172"
                        d="M-2114.7,646.262h.694v.78h-.694Z"
                        transform="translate(2124.903 -637.51)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30173"
                        data-name="Path 30173"
                        d="M-2018.951,658.3h.7v.771h-.7Z"
                        transform="translate(2034.973 -648.741)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30174"
                        data-name="Path 30174"
                        d="M-2114,719h-.693v-.78h.693Z"
                        transform="translate(2124.896 -704.653)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30175"
                        data-name="Path 30175"
                        d="M-2018.215,730.951h-.7v-.776h.7Z"
                        transform="translate(2034.936 -715.802)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30176"
                        data-name="Path 30176"
                        d="M-2245.783,790.167v.761h-.707v-.761Z"
                        transform="translate(2248.677 -771.775)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30177"
                        data-name="Path 30177"
                        d="M-2149.945,790.068v.775h-.7v-.775Z"
                        transform="translate(2158.659 -771.683)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30178"
                        data-name="Path 30178"
                        d="M-2018.9,802.044h.7v.775h-.7Z"
                        transform="translate(2034.926 -782.857)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30179"
                        data-name="Path 30179"
                        d="M-2246.578,712.109v-.4h.705v.4Z"
                        transform="translate(2248.76 -698.574)"
                        fill="#7dab3c"
                      />
                    </g>
                  </svg>
                </span>
                <span className="text">Product Inventory</span>
              </NavLink>
            </li>
            <li className="sidebar-links">
              <NavLink to="/seller-customer-order">
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21.218"
                    height="23.402"
                    viewBox="0 0 21.218 23.402"
                  >
                    <g
                      id="Group_14886"
                      data-name="Group 14886"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_30154"
                        data-name="Path 30154"
                        d="M-2567,2082.757v-4.793h6.181v5.3a5.3,5.3,0,0,0-6.58,1.828,6.694,6.694,0,0,0-.505,7.466h-11.466v-14.6h6.168v4.778c.085-.052.141-.082.192-.119.485-.355.971-.708,1.451-1.07a.189.189,0,0,1,.272,0c.345.264.7.512,1.044.776a.2.2,0,0,0,.291,0c.332-.255.677-.491,1.007-.749a.238.238,0,0,1,.346,0C-2568.086,2081.969-2567.563,2082.345-2567,2082.757Zm-10.608,7.848h6.166v-6.794h-6.166Z"
                        transform="translate(2579.372 -2071.119)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30155"
                        data-name="Path 30155"
                        d="M-2380.159,2167.561a5.132,5.132,0,0,1,4.873,5.355,5.136,5.136,0,0,1-4.859,5.37,5.135,5.135,0,0,1-4.873-5.355A5.137,5.137,0,0,1-2380.159,2167.561Zm-.908,7.467,2.923-3.22-.6-.693-2.347,2.593-.912-1.076-.64.659Z"
                        transform="translate(2396.504 -2154.883)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30156"
                        data-name="Path 30156"
                        d="M-2571.62,1972.884l-.909,4.014c-.125.551-.254,1.1-.374,1.652-.026.121-.062.177-.187.177q-3.019-.006-6.038,0a.431.431,0,0,1-.08-.019c.035-.062.062-.116.094-.166q1.757-2.747,3.52-5.491a.37.37,0,0,1,.257-.165c1.194-.011,2.388-.007,3.583-.007C-2571.719,1972.874-2571.683,1972.879-2571.62,1972.884Z"
                        transform="translate(2579.218 -1972.871)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30157"
                        data-name="Path 30157"
                        d="M-2385.273,1978.7l-.881-5.83h.822c.8,0,1.607,0,2.41.006a.339.339,0,0,1,.249.132q1.8,2.789,3.584,5.588c.016.026.03.054.057.1Z"
                        transform="translate(2397.573 -1972.865)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30158"
                        data-name="Path 30158"
                        d="M-2457.712,1978.672c.26-1.151.516-2.283.772-3.415.169-.747.343-1.493.509-2.241.026-.115.052-.185.182-.183.611.007,1.222,0,1.867,0l.884,5.837Z"
                        transform="translate(2464.902 -1972.833)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30159"
                        data-name="Path 30159"
                        d="M-2455.143,2077.956v2.966c-.256-.188-.494-.352-.72-.534a.212.212,0,0,0-.309,0c-.331.256-.677.491-1.005.75a.236.236,0,0,1-.346-.006c-.336-.262-.687-.5-1.026-.761a.174.174,0,0,0-.252,0c-.234.182-.477.352-.739.544v-2.957Z"
                        transform="translate(2466.622 -2071.112)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30160"
                        data-name="Path 30160"
                        d="M-2530.057,2182.8v4.845h-4.4V2182.8Zm-3.52,1.915h2.627v-.945h-2.627Zm0,1.95h2.627v-.945h-2.627Z"
                        transform="translate(2537.108 -2169.128)"
                        fill="#7dab3c"
                      />
                    </g>
                  </svg>
                </span>
                <span className="text">Customers & Orders</span>
              </NavLink>
            </li>
            <li className="sidebar-links">
              <NavLink to="/seller-review">
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.878"
                    height="22.357"
                    viewBox="0 0 19.878 22.357"
                  >
                    <g
                      id="Group_14883"
                      data-name="Group 14883"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Path_30145"
                        data-name="Path 30145"
                        d="M-1553.175,1527.234a.8.8,0,0,1,.421.45c.2.459.425.91.629,1.369a.286.286,0,0,0,.274.2c.536.048,1.07.116,1.605.175a.547.547,0,0,1,.509.358.533.533,0,0,1-.187.606c-.389.346-.766.7-1.161,1.043a.365.365,0,0,0-.13.414c.115.5.207,1.011.317,1.515a.52.52,0,0,1-.2.579.524.524,0,0,1-.6.014c-.481-.269-.965-.532-1.443-.806a.282.282,0,0,0-.322,0c-.465.266-.939.519-1.4.783a.55.55,0,0,1-.642.005.532.532,0,0,1-.2-.6c.113-.5.206-1.011.317-1.515a.335.335,0,0,0-.118-.378c-.4-.343-.783-.708-1.178-1.058a.533.533,0,0,1-.186-.606.545.545,0,0,1,.509-.358c.542-.059,1.084-.125,1.627-.176a.259.259,0,0,0,.243-.176c.209-.465.433-.923.638-1.389a.792.792,0,0,1,.421-.45Z"
                        transform="translate(1563.248 -1527.234)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30146"
                        data-name="Path 30146"
                        d="M-1540.617,1653.031h.562c1.47,0,2.939-.006,4.408,0a1.518,1.518,0,0,1,1.535,1.639,1.735,1.735,0,0,1-.416.944,1.559,1.559,0,0,1-.227,2.093,1.548,1.548,0,0,1-.361,2.055,1.575,1.575,0,0,1,.189,1.018,1.541,1.541,0,0,1-1.578,1.286q-2.8.007-5.6,0l-2.26,0c-.044,0-.089,0-.133,0-.5-.018-.574-.093-.574-.577q0-4.551,0-9.1a.6.6,0,0,1,.162-.429,8.249,8.249,0,0,0,1.99-4.4.914.914,0,0,1,1.385-.761,2.62,2.62,0,0,1,1.534,2.172,8.115,8.115,0,0,1-.414,3.416C-1540.479,1652.6-1540.545,1652.795-1540.617,1653.031Z"
                        transform="translate(1552.115 -1639.719)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30147"
                        data-name="Path 30147"
                        d="M-1651.945,1753.5v3.84a.79.79,0,0,1-.865.861c-.332.009-.664,0-1,0q-1.307,0-2.613,0a1.038,1.038,0,0,1-1.121-1.105q0-3.611,0-7.222a1.037,1.037,0,0,1,1.123-1.1c1.174,0,2.348-.007,3.521.006a1.509,1.509,0,0,1,.591.143.6.6,0,0,1,.368.608C-1651.955,1750.848-1651.945,1752.172-1651.945,1753.5Zm-3.524-2.281c.066,0,.133,0,.2,0a.519.519,0,0,0,.526-.508.517.517,0,0,0-.5-.534,2.729,2.729,0,0,0-.463,0,.514.514,0,0,0-.48.549.516.516,0,0,0,.519.491C-1655.6,1751.217-1655.536,1751.214-1655.469,1751.214Z"
                        transform="translate(1657.922 -1735.844)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30148"
                        data-name="Path 30148"
                        d="M-1428.974,1573.357c.076-.37.158-.8.254-1.224a.3.3,0,0,0-.108-.342c-.3-.257-.588-.53-.883-.794a.525.525,0,0,1-.19-.585.534.534,0,0,1,.5-.372c.41-.048.82-.1,1.232-.135a.254.254,0,0,0,.24-.177c.163-.366.339-.726.5-1.09a.536.536,0,0,1,.524-.364.537.537,0,0,1,.5.363c.163.358.335.712.493,1.072a.283.283,0,0,0,.273.2c.4.03.792.086,1.188.13a.545.545,0,0,1,.518.377.532.532,0,0,1-.191.587c-.3.262-.582.537-.885.793a.3.3,0,0,0-.107.339c.087.383.156.769.241,1.152a.519.519,0,0,1-.2.58.526.526,0,0,1-.6.017c-.359-.2-.722-.4-1.078-.6a.281.281,0,0,0-.323,0c-.368.214-.739.426-1.117.621A.531.531,0,0,1-1428.974,1573.357Z"
                        transform="translate(1443.783 -1565.881)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30149"
                        data-name="Path 30149"
                        d="M-1660.85,1568.257a.5.5,0,0,1,.425.33c.177.375.354.75.523,1.13a.253.253,0,0,0,.243.172c.4.034.807.087,1.21.133a.543.543,0,0,1,.514.38.529.529,0,0,1-.195.584c-.294.265-.582.536-.883.795a.289.289,0,0,0-.109.322c.087.375.16.753.239,1.13a.545.545,0,0,1-.184.609.563.563,0,0,1-.662,0c-.338-.193-.684-.373-1.019-.572a.292.292,0,0,0-.342,0c-.341.2-.693.387-1.038.582a.548.548,0,0,1-.642,0,.535.535,0,0,1-.19-.6q.135-.584.234-1.176a.334.334,0,0,0-.084-.255c-.284-.275-.579-.54-.879-.8a.551.551,0,0,1-.217-.618.575.575,0,0,1,.566-.377c.389-.038.778-.078,1.165-.135a.342.342,0,0,0,.223-.156c.187-.371.352-.752.53-1.127A.553.553,0,0,1-1660.85,1568.257Z"
                        transform="translate(1663.933 -1565.864)"
                        fill="#7dab3c"
                      />
                    </g>
                  </svg>
                </span>
                <span className="text">Reviews & Feedback</span>
              </NavLink>
            </li>
            <li className="sidebar-links">
              <NavLink to="/seller-payment-detail">
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21.147"
                    height="23.057"
                    viewBox="0 0 21.147 23.057"
                  >
                    <g
                      id="Group_14884"
                      data-name="Group 14884"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Path_30150"
                        data-name="Path 30150"
                        d="M-779.01,2382.436a.72.72,0,0,1,.792-.533c.357.016.716,0,1.073,0a1.954,1.954,0,0,1,1.892,2.1c0,1.1,0,2.195,0,3.293q0,2.311,0,4.623c0,.559-.23.81-.743.811-.736,0-1.473-.015-2.209.007a.751.751,0,0,1-.807-.509Z"
                        transform="translate(779.01 -2370.999)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30151"
                        data-name="Path 30151"
                        d="M-688.539,2404.9c0-1.3,0-2.6,0-3.9,0-.152.033-.228.173-.269.4-.118.8-.272,1.209-.373a1.9,1.9,0,0,1,1.387.2q2.642,1.432,5.274,2.886a1.384,1.384,0,0,1,.495,1.773,1.163,1.163,0,0,1-1.614.619c-.746-.384-1.481-.793-2.219-1.2a1.877,1.877,0,0,0-1.011-.266,1.772,1.772,0,0,0-1.366.731.7.7,0,0,0,.035.932.577.577,0,0,0,.873-.023.634.634,0,0,1,.943-.156l2.044,1.128a2.13,2.13,0,0,0,1.774.208c2.146-.7,4.3-1.379,6.449-2.069a1.2,1.2,0,0,1,1.422.406,1.384,1.384,0,0,1-.5,2.092c-.9.4-1.8.785-2.7,1.178q-2.49,1.085-4.979,2.17a4.875,4.875,0,0,1-1.708.371,5.617,5.617,0,0,1-3.207-.831c-.843-.491-1.705-.942-2.564-1.4a.353.353,0,0,1-.216-.371C-688.535,2407.461-688.539,2406.182-688.539,2404.9Z"
                        transform="translate(693.537 -2388.293)"
                        fill="#7dab3c"
                      />
                      <path
                        id="Path_30152"
                        data-name="Path 30152"
                        d="M-637.95,2200.994c3.061.02,5.583,2.83,5.56,6.2a5.85,5.85,0,0,1-5.593,6.063,5.851,5.851,0,0,1-5.555-6.144C-643.513,2203.75-640.978,2200.975-637.95,2200.994Zm-1.868,7.7a1.745,1.745,0,0,0,.79,1.454.848.848,0,0,1,.446.974.488.488,0,0,0,0,.068.648.648,0,0,0,.6.708.65.65,0,0,0,.634-.7c0-.188.01-.377,0-.564a.256.256,0,0,1,.163-.285,2.058,2.058,0,0,0,.952-2.534,1.856,1.856,0,0,0-1.753-1.327.635.635,0,0,1-.6-.691.651.651,0,0,1,.6-.653.654.654,0,0,1,.641.654c.047.471.32.738.7.686a.7.7,0,0,0,.527-.849,1.99,1.99,0,0,0-1.093-1.655.217.217,0,0,1-.137-.237c.009-.21.009-.421,0-.631a.649.649,0,0,0-.621-.673.651.651,0,0,0-.614.7c0,.173-.01.346,0,.518a.317.317,0,0,1-.2.355,2.035,2.035,0,0,0-1.034,2.051,1.968,1.968,0,0,0,1.447,1.732c.167.04.342.038.51.071a.663.663,0,0,1,.495.781.622.622,0,0,1-.659.546.649.649,0,0,1-.564-.66c-.036-.423-.311-.71-.656-.684S-639.824,2208.175-639.818,2208.7Z"
                        transform="translate(651.013 -2200.994)"
                        fill="#7dab3c"
                      />
                    </g>
                  </svg>
                </span>
                <span className="text">payment methods</span>
              </NavLink>
            </li>
            <li className="sidebar-links">
              <NavLink to="/seller-membership-plan">
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.561"
                    height="18.255"
                    viewBox="0 0 25.561 18.255"
                  >
                    <path
                      id="Path_30153"
                      data-name="Path 30153"
                      d="M-2233.911,1429.243h9.868a2.744,2.744,0,0,1,2.913,2.909q0,6.217,0,12.433a2.745,2.745,0,0,1-2.91,2.912h-19.736a2.747,2.747,0,0,1-2.913-2.909q0-6.217,0-12.433a2.746,2.746,0,0,1,2.911-2.912Zm-4.955,9.122c-.121.025-.2.047-.284.058a4.57,4.57,0,0,0-3.888,4.432.915.915,0,0,0,.969.991q3.594,0,7.188,0a.913.913,0,0,0,.97-.99,4.572,4.572,0,0,0-3.887-4.433c-.081-.011-.159-.034-.239-.051a2.811,2.811,0,0,0,2.294-2.935,2.75,2.75,0,0,0-2.589-2.538,2.741,2.741,0,0,0-2.855,2.368A2.8,2.8,0,0,0-2238.866,1438.365Zm9.949-.907c1.026,0,2.053,0,3.079,0a1.325,1.325,0,0,0,.472-.077.894.894,0,0,0,.531-1.026.916.916,0,0,0-.935-.718q-3.136,0-6.272,0a.909.909,0,0,0-.953.919.918.918,0,0,0,.971.9C-2230.989,1437.46-2229.953,1437.457-2228.917,1437.458Zm-.871,1.826c-.732,0-1.464,0-2.2,0a.922.922,0,0,0-1.011.919.923.923,0,0,0,1,.905q2.2,0,4.392,0a.922.922,0,0,0,1-.906.925.925,0,0,0-1.013-.919C-2228.343,1439.282-2229.066,1439.283-2229.788,1439.283Z"
                      transform="translate(2246.69 -1429.243)"
                      fill="#7dab3c"
                    />
                  </svg>
                </span>
                <span className="text">Membership Plan</span>
              </NavLink>
            </li>
            <li className="sidebar-links">
              <NavLink to="/seller-center">
                <span className="icon">
                  <svg
                    id="Group_14880"
                    data-name="Group 14880"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.878"
                    height="23"
                    viewBox="0 0 20.878 23"
                  >
                    <path
                      id="Path_30137"
                      data-name="Path 30137"
                      d="M-822.492,1392.532h-.53c-.121-.042-.243-.083-.364-.127-1.7-.621-3.392-1.239-5.086-1.867a1.843,1.843,0,0,1-1.2-1.454.732.732,0,0,0-.037-.1v-17.832c.019-.062.041-.123.057-.186a1.787,1.787,0,0,1,1.638-1.429q1.192,0,2.384,0c2.419,0,4.837-.006,7.256,0a2.7,2.7,0,0,1,2.564,2.629c.035,1.054.017,2.111.009,3.166a.876.876,0,0,1-.547.841.867.867,0,0,1-1.176-.874c-.012-.92,0-1.841,0-2.762,0-.7-.344-1.082-.981-1.083h-3.8a2.224,2.224,0,0,1,1.3,2.381c-.025,4.529-.01,9.057-.01,13.586v.316h2.481c.666,0,1-.371,1-1.1,0-1.183,0-2.365,0-3.548a1.724,1.724,0,0,1,.033-.4.869.869,0,0,1,.911-.691.9.9,0,0,1,.783.929q.012,1.92,0,3.84a2.736,2.736,0,0,1-2.645,2.893c-.781,0-1.563,0-2.344,0h-.231c0,.371.009.707,0,1.042a1.8,1.8,0,0,1-.688,1.433A4.589,4.589,0,0,1-822.492,1392.532Z"
                      transform="translate(829.708 -1369.532)"
                      fill="#7dab3c"
                    />
                    <path
                      id="Path_30138"
                      data-name="Path 30138"
                      d="M-548.511,1479.816v-1.885c0-.322,0-.644,0-.966a.926.926,0,0,1,.533-.9.786.786,0,0,1,.973.226q1.105,1.211,2.206,2.429.6.659,1.2,1.319a1.026,1.026,0,0,1,0,1.482q-1.677,1.852-3.357,3.7a.823.823,0,0,1-1,.274.977.977,0,0,1-.546-.983c0-.913,0-1.825,0-2.774h-.244c-1.053,0-2.106,0-3.159,0a.9.9,0,0,1-.924-.767.926.926,0,0,1,.875-1.15c1.067,0,2.133,0,3.2,0Z"
                      transform="translate(564.167 -1471.19)"
                      fill="#7dab3c"
                    />
                  </svg>
                </span>

                <span className="text" onClick={(e) => logOutSeller(e)}>
                  {/* <button onClick={(e) => logOutSeller(e)} disabled={loading}>
										{loading ? "loading" : "Log Out"}
									</button> */}
                  {loading ? "loading" : "Log Out"}
                </span>
                <ResponseMessage />
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      {/* sidebar ends here */}
    </>
  );
};

export default SellerSidebar;
