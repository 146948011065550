import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { useState } from "react";
import AddressVeiwModal from "./AddressVeiwModal";
import AddressEditModal from "./AddressEditModal";
import { toast } from "react-toastify";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import {
  ResponseMessageActions,
  clearResponseMessageAfterDelay,
  errorMessage,
  successMessage,
} from "../../redux/slices/responseMessage.slice";
import { useDispatch } from "react-redux";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";

const AddressCard = (propsData) => {
  const dispatch = useDispatch();

  const {
    id,
    first_name,
    last_name,
    phone,
    email,
    address,
    country_id,
    country_name,
    state_id,
    state_name,
    city_id,
    city_name,
    zip_code,
    default_status,
  } = propsData ?? {};

  const [veiwModalIsOpen, setVeiwModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loading2, addressUpdateLoader] = useState(false);

  const [allCountries, setAllCountries] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [allCities, setAllCities] = useState([]);

  const [countriesLoader, setCountriesLoader] = useState(false);
  const [statesLoader, setStatesLoader] = useState(false);
  const [citiesLoader, setCitiesLoader] = useState(false);

  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);

  function openModal() {
    setVeiwModalIsOpen(true);
  }

  function closeModal() {
    setVeiwModalIsOpen(false);
  }

  function openEditModal() {
    setEditModalIsOpen(true);
  }

  function closeEditModal() {
    setEditModalIsOpen(false);
  }

  const addressDeleteHandler = async (e) => {
    e.preventDefault();

    const isConfirmed = await ReUse.showConfirmationDialog({});

    if (!isConfirmed) {
      ReUse.showSuccessDialog({});
      return;
    }

    try {
      setLoading(true);
      const apiResp = await AppService.customerDeleteAddress({ adressId: id });
      const { message, response } = apiResp.data;
      dispatch(successMessage(message));
      ReUse.showSuccessDialog({ text: "Address deleted successfully." });
      setTimeout(() => {
        dispatch(successMessage("Navigating..."));
        setTimeout(() => {
          dispatch(successMessage(""));
        }, 1000);
      }, 1000);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const errorMessage = err?.response?.data?.message || "An error occurred.";
      dispatch(errorMessage);
      dispatch(clearResponseMessageAfterDelay());
      dispatch(successMessage(""));
      ReUse.showErrorDialog({ text: errorMessage });
    }
  };

  const [allErrors, setAllErrors] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    address: "",
    suite_apartment: "",
    country: "",
    state: "",
    city: "",
    zip_code: "",
    default_status: "",
  });

  const [fieldsData, setFieldsData] = useState({
    first_name: first_name,
    last_name: last_name,
    phone: phone,
    email: email,
    address: address,
    suite_apartment: address,
    country: country_name,
    state: state_name,
    city: city_name,
    zip_code: zip_code,
    default_status: "0",
  });

  function handleChange(e) {
    const resetErrors = ReUse.resetErrors({ fields: allErrors });
    setAllErrors(resetErrors);
    const { value, name } = e.target;
    setFieldsData({
      ...fieldsData,
      [name]: value,
    });
  }

  const addressUpdateHandler = async (e) => {
    e.preventDefault();

    try {
      addressUpdateLoader(true);

      const {
        first_name,
        last_name,
        phone,
        email,
        address,
        suite_apartment,
        country,
        state,
        city,
        zip_code,
        default_status,
      } = fieldsData;

      const fields = {
        first_name,
        last_name,
        phone,
        email,
        address,
        suite_apartment,
        country,
        state,
        city,
        zip_code,
        default_status,
      };
      const allErrors = ReUse.validateRequired({ fields: fields });

      // Check if there are any errors
      if (Object.keys(allErrors).length > 0) {
        setAllErrors(allErrors);
        setLoading(false);
        return;
      }

      const payLoad = {
        first_name,
        last_name,
        phone,
        email,
        address,
        suite_apartment,
        zip_code,
        default_status,
        country_id: selectedCountryId,
        state_id: selectedStateId,
        city_id: selectedStateId,
      };

      const apiResp = await AppService.customerUpdateAddress(payLoad, {
        adressId: id,
      });

      const { message, response } = apiResp.data;
      addressUpdateLoader(false);
      dispatch(successMessage(message));
      setTimeout(() => {
        dispatch(successMessage("Processing..."));
        setTimeout(() => {
          dispatch(successMessage(""));
        }, 1000);
      }, 1000);

      ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
      ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
    } catch (err) {
      addressUpdateLoader(false);
      dispatch(errorMessage(err?.response?.data?.message));
      dispatch(clearResponseMessageAfterDelay());
      ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
      ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
      dispatch(successMessage(""));
    }
  };

  useEffect(() => {
    const countryList = AppService.countryList();
    ReUse.getApiData(countryList, setAllCountries, setCountriesLoader);
  }, []);

  useEffect(() => {
    const countryStatesList = AppService.countryStatesList({
      countryId: selectedCountryId,
    });
    ReUse.getApiData(countryStatesList, setAllStates, setStatesLoader);
  }, [selectedCountryId]);

  useEffect(() => {
    const stateCitieslist = AppService.stateCitieslist({
      stateId: selectedStateId,
    });
    ReUse.getApiData(stateCitieslist, setAllCities, setCitiesLoader);
  }, [selectedStateId]);

  const minifiedCountries = ReUse.minifyArrayOfObjects({
    data: allCountries,
  });
  const minifiedStates = ReUse.minifyArrayOfObjects({
    data: allStates,
  });
  const minifiedCities = ReUse.minifyArrayOfObjects({
    data: allCities,
  });

  return (
    <>
      <div className="col-md-6">
        <div
          className="open-job-all"
          style={{
            backgroundColor: default_status === 1 ? "#7dab3c" : "",
          }}
        >
          <div className="open-dv">
            <div className="open-first-dv">
              <h3>{address}</h3>
              <p>
                {country_name}, {city_name}
              </p>
            </div>
          </div>

          <div className="open-all-job-para mt-4">
            <div className="full-time-btn">
              <a className="m-2">
                <AiOutlineDelete onClick={addressDeleteHandler} />
              </a>
              <a className="m-2" onClick={openEditModal}>
                <AiOutlineEdit />
              </a>
              <a className="m-2" onClick={openModal}>
                <AiOutlineEye />
              </a>
            </div>
            <ResponseMessage />
          </div>
          <AddressVeiwModal
            veiwModalIsOpen={veiwModalIsOpen}
            closeModal={closeModal}
            propsData={propsData}
          />
          <AddressEditModal
            editModalIsOpen={editModalIsOpen}
            closeEditModal={closeEditModal}
            propsData={propsData}
            handleChange={handleChange}
            allErrors={allErrors}
            addressUpdateHandler={addressUpdateHandler}
            AddressEditLoader={loading2}
            minifiedCountries={minifiedCountries}
            minifiedCities={minifiedCities}
            minifiedStates={minifiedStates}
            setSelectedCityId={setSelectedCityId}
            setSelectedCountryId={setSelectedCountryId}
            setSelectedStateId={setSelectedStateId}
            selectedCountryId={selectedCountryId}
            selectedCityId={selectedCityId}
            selectedStateId={selectedStateId}
          />
        </div>
      </div>
    </>
  );
};

export default AddressCard;
