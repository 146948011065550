import React from "react";
import {
	recipes,
	recipes2,
	recipes3,
	recipes4,
	recipes5,
} from "../../Constant/Index";
import { Link } from "react-router-dom";
import ReUse from "../../services/helpers/reUse";

const Recipe = (propsData) => {
	const {
		id,
		slug,
		title,
		short_description,
		views,
		creation_date,
		thumbnail,
	} = propsData ?? {};
	return (
		<>
			<div className="col-md-3">
				<Link to={`/recipes-detail/${slug}`} state={slug}>
					<div className="recipe-all">
						<div className="recipe-img">
							<figure>
								<img
									className="img-fluid"
									alt="recipe-img-all"
									src={
										thumbnail !== null
											? thumbnail && thumbnail
											: ReUse.defaultThumbnailImage({
													content: "No+Image+Available",
											  })
									}
									onError={ReUse.onImageError}
								/>
							</figure>
						</div>
						<div className="recipe-para">
							<h4>{title && title}</h4>
							<div className="recipe-flex">
								<p>{short_description && short_description}</p>
								<div className="for-views">
									<h5>{views && views} Views </h5>
									<h5>{ReUse.dateFormat({ date: creation_date })} </h5>
								</div>
							</div>
						</div>
					</div>
				</Link>
			</div>
		</>
	);
};

export default Recipe;
