import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import {
  ordersmap,
  vegiorder1,
  vegiorder2,
  vegiorder3,
} from "../../Constant/Index";
import Siderbar from "./Siderbar";

import { useDispatch } from "react-redux";
import { UIActions } from "../../redux/slices/ui.slice";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import MyOrder from "./MyOrder";
import AccessControl from "../../Components/Auth/AccessControl";
import Pagination from "../Product/Pagination";
import CustomerOrderDetail from "./CustomerOrderDetail";

function Myorders() {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const customerGetAllOrders = AppService.customerGetAllOrders({
      currentPage: currentPage,
    });
    ReUse.getApiData(customerGetAllOrders, setApiData, setLoading);
  }, []);

  const paginationProps = {
    apiData: apiData,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };

  return (
    <>
      <PageHeader pagename="my orders" />

      <AccessControl allowedRoles={["customer"]}>
        <section className="my-orders-sec my-profile-all">
          <div className="container">
            <div className="row">
              <div className="col-md-2">
                <Siderbar />
              </div>
              <div className="col-md-10">
                <div className="all-table-order">
                  <table>
                    <thead>
                      <th className="for-border-all-raduis">OrderNo</th>
                      <th>Order Date</th>
                      <th>Status</th>
                      <th>Payment Status</th>
                      <th>Total</th>
                      <th className="for-border-raduis-all-things">
                        Order Details
                      </th>
                    </thead>

                    <tbody>
                      {ReUse.mapItems(loading, apiData?.data, MyOrder)}
                    </tbody>
                  </table>

                  <Pagination paginationProps={paginationProps} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </AccessControl>
    </>
  );
}

export default Myorders;
