import React, { useEffect, useState } from "react";
import Blogsmain from "../../Components/Blogsmain";
import Newsletter from "../../Components/Newsletter";
import Sliderforproduct from "../../Components/Sliderforproduct";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import ShopProduct from "./ShopProduct";
import ProductsParamsFilter from "./ProductsParamsFilter";
import ProductsSortingFilters from "./ProductsSortingFilters";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "./Pagination";
import { useLocation } from "react-router-dom";
import { ProductsFilterActions } from "../../redux/slices/productsFilter.slice";
import AllProductsSection from "./AllProductsSection";
import NoRecord from "../../Components/ReUse/NoRecord";

function ProductsPage() {
	const location = useLocation();
	const dispatch = useDispatch();

	const [allProducts, setAllProducts] = useState([]);
	const [allCategories, setAllCategories] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [pageCount, setPageCount] = useState();
	const [currentPage, setCurrentPage] = useState(1);

	const pathName = location?.pathname || "";
	const formatedPath = ReUse.removeSpecialCharacterFromString({
		stringInput: pathName,
		characterToMatch: "/",
	});

	const searchQuery = location?.state?.searchQuery || "";

	useEffect(() => {
		const getAllCats = AppService.getAllCats();
		ReUse.getApiData(getAllCats, setAllCategories, setLoading2);
	}, [pathName]);

	useEffect(() => {
		const validPaths = [
			"new-fashion-collection",
			"product-fashion",
			"art-craft-product",
		];

		const matchingPath = validPaths.includes(formatedPath);

		if (matchingPath) {
			const matchingCategory = allCategories.find(
				(category) => category.slug === formatedPath,
			);

			if (matchingCategory) {
				const { id } = matchingCategory;
				dispatch(
					ProductsFilterActions.setCatSubCatIds({
						categoryId: id,
						subCategoryId: null,
					}),
				);
			}
		}
	}, [formatedPath, allCategories, dispatch]);

	const productsFilterData = useSelector((state) => state.productsfilters);

	const {
		categoryId,
		subCategoryId,
		minPrice,
		maxPrice,
		showProducts,
		sortByOrder,
	} = productsFilterData ?? {};

	useEffect(() => {
		const getAllProducts = AppService.getAllProducts({
			name: "",
			categoryId,
			subCategoryId,
			minPrice,
			maxPrice,
			currentPage,
		});

		ReUse.getApiData(getAllProducts, setAllProducts, setLoading);
	}, [categoryId, subCategoryId, minPrice, maxPrice, currentPage]);

	useEffect(() => {
		const sortedData = ReUse.sortData({
			data: allProducts?.data,
			order: sortByOrder,
			sortByProperty: "unit_price",
		});
		setAllProducts({ ...allProducts, data: sortedData });
	}, [sortByOrder]);

	const allProductsSectionProps = {
		loading,
		allProducts,
		showProducts,
		setCurrentPage,
		currentPage,
	};

	const AllProductsSectionComponent = () => {
		return allProducts?.data?.length > 0 ? (
			<AllProductsSection allProductsSectionProps={allProductsSectionProps} />
		) : (
			<NoRecord />
		);
	};

	

	return (
		<>
			<section className="fashion-product-sec-banner">
				<div className="container">
					<div className="col-md-12"></div>
					<Sliderforproduct />
				</div>
			</section>
			<AllProductsSectionComponent />
			<Blogsmain />
			<Newsletter />
		</>
	);
}

export default ProductsPage;
