import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { artcarftpic, newfashionbanner, product1 } from "../Constant/Index";
import BannerSlide from "./BannerSlide";
import AppService from "../services/appServices";
import ReUse from "../services/helpers/reUse";

var settings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
};

function Sliderforproduct() {
	const [apiData, setApiData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const getAllBanners = AppService.getAllBanners("main_banner");
		ReUse.getApiData(getAllBanners, setApiData, setLoading);
	}, []);

	return (
		<>
			<Slider {...settings}>
				{ReUse.mapItems(loading, apiData, BannerSlide)}
			</Slider>
		</>
	);
}

export default Sliderforproduct;
