import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import "../../assets/css/career.css";
import { career1, career2, career3 } from "../../Constant/Index";
import { Link } from "react-router-dom";
import Careercompo from "../../Components/Careercompo";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";

function Career() {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getCareers = AppService.getCareers({ role: "" });
    ReUse.getApiData(getCareers, setApiData, setLoading);
  }, []);

  return (
    <>
      <PageHeader pagename="Careers" />
      {/* Right Job Heading Seciton */}
      <section className="right-job-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="right-job-head">
                <h4>Get the right job in seconds with Sahara Eagle</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================================ */}

      {/* Work with Section */}
      <section className="work-with-sec">
        <div className="container">
          <div className="work-with-head">
            <h3>Why work with us?</h3>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="career-all">
                <div className="career-img">
                  <figure>
                    <img className="img-fluid" src={career1} alt="career-img" />
                  </figure>
                </div>
                <div className="career-text">
                  <h3>Catered Meals</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="career-all">
                <div className="career-img">
                  <figure>
                    <img className="img-fluid" src={career2} alt="career-img" />
                  </figure>
                </div>
                <div className="career-text">
                  <h3>Health Care</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="career-all">
                <div className="career-img">
                  <figure>
                    <img className="img-fluid" src={career3} alt="career-img" />
                  </figure>
                </div>
                <div className="career-text">
                  <h3>Flexible Enviroment</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ====================================== */}

      {/* Open Job Section */}
      <PageHeader pagename="Open Job" />
      {/* Open Job Section */}
      <section className="open-job-sec">
        <div className="container">
          <div className="row">
            {ReUse.mapItems(loading, apiData, Careercompo)}

            {/* <Careercompo /> */}
          </div>
          <div className="caree-view-more-btn">
            <Link>view more</Link>
          </div>
        </div>
      </section>
      {/* ========================= */}
      {/* =============== */}
    </>
  );
}

export default Career;
