import React, { useEffect, useState } from "react";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import ObjBasedApiDataLoader from "../../Components/ReUse/ReUsableHOC/ObjBasedApiDataLoader";
import ProductDetailContent from "../../Components/PID/ProductDetailContent";
import { useLocation } from "react-router-dom";

function Productdetail() {
	const location = useLocation();
	const { product_id, currentIndex } = location?.state ?? {};

	const currentSlug = ReUse.extractIdFromCurrentUrl();

	function PIDDetail({ loading, data }) {
		return <ProductDetailContent data={data} currentIndex={currentIndex} />;
	}

	const EnhancedPIDDetail = ObjBasedApiDataLoader(PIDDetail);

	const [apiData, setApiData] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const getProductDetail = AppService.getProductDetail({
			productId: currentSlug,
		}); //1299
		ReUse.getApiData(getProductDetail, setApiData, setLoading);
	}, [currentSlug]);

	return (
		<>
			<EnhancedPIDDetail data={apiData} loading={loading} />
		</>
	);
}

export default Productdetail;
