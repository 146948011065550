import { useDispatch, useSelector } from "react-redux";
import { clearResponseMessageAfterDelay, errorMessage } from "../redux/slices/responseMessage.slice";
import { ProductDetailActions } from "../redux/slices/productDetail.slice";

export const ProductDetailHook = (qty = null) => {
    const dispatch = useDispatch();

    const productDetail = useSelector((state) => state.productDetail);
    const selectedVariant = useSelector((state) => state.productDetail);

    const { counter } = productDetail ?? {};
    const { selectedVariations } = selectedVariant ?? {};

    const handleIncrement = () => {
        if (!isNaN(qty) && qty > counter) {
            dispatch(ProductDetailActions.setIncrementCounter());
        } else if (selectedVariations.length === 0) {
            dispatch(errorMessage("Please select a variant first"));
        } else if (isNaN(qty)) {
            dispatch(errorMessage("Stock is not available for this variant"));
        } else {
            dispatch(errorMessage(`Sorry, current stock is ${qty}`));
        }
        dispatch(clearResponseMessageAfterDelay());
    };

    const handleDecrement = () => {
        dispatch(ProductDetailActions.setDecrementCounter());
    };

    return {
        handleIncrement,
        handleDecrement,
    };
};
