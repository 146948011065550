import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/login.css";
import { secondlogo } from "../../Constant/Index";

import { UIActions } from "../../redux/slices/ui.slice";
import AppService from "../../services/appServices";
import { AuthActions } from "../../redux/slices/auth.slice";
import { useDispatch, useSelector } from "react-redux";
import { emailRegExp, resetForm } from "../../services/helpers/constants";

import ReUse from "../../services/helpers/reUse";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Errors from "../../Components/ReUse/Errors";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import {
	ResponseMessageActions,
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import CustomButton from "../../Components/ReUse/Components/Button";
import FieldError from "../../Components/ReUse/FieldError";

function Login() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [statusMessage, setStatusMessage] = useState("");
	const [isPasswordVisible, setIsPasswordVisible] = useState(true);
	const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(
		true,
	);

	const [allErrors, setAllErrors] = useState({
		first_name: "",
		last_name: "",
		email: "",
		password: "",
		password_confirmation: "",
		phone: "",
	});
	const [fieldsData, setFieldsData] = useState({
		f_name: "",
		l_name: "",
		email: "",
		password: "",
		password_confirmation: "",
		phone: "",
	});

	function handleChange(e) {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		const { value, name } = e.target;
		setFieldsData({
			...fieldsData,
			[name]: value,
		});
	}

	const passwordVisibilityHandler = (e) => {
		e.preventDefault();
		setIsPasswordVisible(!isPasswordVisible);
	};

	const confirmPasswordVisibilityHandler = (e) => {
		e.preventDefault();
		setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
	};

	const onSubmitHandler = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const {
				f_name,
				l_name,
				email,
				password,
				password_confirmation,
				phone,
			} = fieldsData;

			const fields = {
				first_name: f_name,
				last_name: l_name,
				email,
				password,
				password_confirmation,
				phone,
			};
			const allErrors = ReUse.validateRequired({ fields: fields });

			// Check if there are any errors
			if (Object.keys(allErrors).length > 0) {
				setAllErrors(allErrors);
				setLoading(false);
				ReUse.scrollToTop();
				return;
			}

			const payload = {
				f_name,
				l_name,
				email,
				password,
				password_confirmation,
				phone,
				address: "New York, USA",
				country_id: 166,
				state: "New York",
			};

			const apiResp = await AppService.customerSignUp(payload);
			const { message, response } = apiResp.data;
			setLoading(false);
			dispatch(successMessage(message));
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/sign-in");
				}, 1000);
			}, 1000);

			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			dispatch(successMessage(""));
			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
		}
	};

	const PasswordVisibility = () => {
		if (isPasswordVisible) {
			return (
				<i
					class="fa fa-eye-slash"
					aria-hidden="true"
					onClick={passwordVisibilityHandler}
				></i>
			);
		}
		return (
			<i
				class="fa fa-eye"
				aria-hidden="true"
				onClick={passwordVisibilityHandler}
			></i>
		);
	};

	const ConfirmPasswordVisibility = () => {
		if (isConfirmPasswordVisible) {
			return (
				<i
					class="fa fa-eye-slash"
					aria-hidden="true"
					onClick={confirmPasswordVisibilityHandler}
				></i>
			);
		}
		return (
			<i
				class="fa fa-eye"
				aria-hidden="true"
				onClick={confirmPasswordVisibilityHandler}
			></i>
		);
	};

	return (
		<>
			{/* Login Section */}
			{/* <Errors allErrors={allErrors} /> */}
			<section className="login-sec sign-up-sec">
				<div className="container">
					<div className="row">
						<div className="login-page-logo">
							<Link to="/">
								<figure>
									<img
										className="img-fluid"
										alt="login-page-logo"
										src={secondlogo}
									/>
								</figure>
							</Link>
						</div>
						<div className="col-md-6">
							<div className="div-for-all-movement">
								<div className="welcome-back-movement sign-up-all">
									<div className="welcome-back-sec">
										<h3>Welcome Back!</h3>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit.
											Fusce laoreet mauris sit amet venenatis cursus. Fusce quis
											libero orci. Donec posuere lacus id felis hendrerit
											dapibus. Mauris
										</p>
										<div className="login-signin-btn">
											<Link to="/sign-in">Sign in</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="login-form">
								<form>
									<div className="row">
										<div className="form-head">
											<h3>User Sign Up</h3>
										</div>
										<div className="col-md-6">
											<div className="form-login-input">
												<input
													type="text"
													placeholder="first name"
													onChange={handleChange}
													name="f_name"
													value={fieldsData?.f_name}
												/>
												<FieldError fieldName={allErrors?.first_name} />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-login-input">
												<input
													type="text"
													placeholder="last_name"
													onChange={handleChange}
													name="l_name"
													value={fieldsData?.l_name}
												/>
												<FieldError fieldName={allErrors?.last_name} />
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-login-input">
												<input
													type="email"
													placeholder="Email"
													onChange={handleChange}
													name="email"
													value={fieldsData?.email}
												/>
												<FieldError fieldName={allErrors?.email} />
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-login-input">
												<input
													type="text"
													placeholder="Phone"
													onChange={handleChange}
													name="phone"
													value={fieldsData?.phone}
												/>
												<FieldError fieldName={allErrors?.phone} />
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-login-input">
												<input
													type={isPasswordVisible ? "password" : "text"}
													placeholder="Password"
													onChange={handleChange}
													name="password"
													value={fieldsData?.password}
												/>
												<FieldError fieldName={allErrors?.password} />

												<div className="password-icon">
													<PasswordVisibility />
												</div>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-login-input">
												<input
													type={isConfirmPasswordVisible ? "password" : "text"}
													placeholder="Confirm Password"
													onChange={handleChange}
													name="password_confirmation"
													value={fieldsData?.password_confirmation}
												/>
												<FieldError
													fieldName={allErrors?.password_confirmation}
												/>
												<div className="password-icon">
													<ConfirmPasswordVisibility />
												</div>
											</div>
										</div>
										<div className="login-signup-btn">
											<a>
												<CustomButton
													loading={loading}
													onClickHandler={onSubmitHandler}
													customClassName="btn"
												>
													Sign Up
												</CustomButton>
											</a>

											<ResponseMessage />
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="col-md-12"></div>
					</div>
				</div>
			</section>
			{/* ================ */}
		</>
	);
}

export default Login;
