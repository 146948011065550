import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { slider1, slider2, slider3, slider4, slider5 } from "../Constant/Index";

function Slidecompo() {
	var settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 1,
		arrows: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 3,
					infinite: true,
					dots: false,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	return (
		<>
			<section className="slide-sec">
				<div className="container">
					<div className="slide-head">
						<h4>just for you</h4>
						<h4>
							<Link>view all</Link>
						</h4>
					</div>
					<div className="row">
						<div className="col-md-12">
							<Slider {...settings}>
								<div>
									<div className="slide-dv">
										<figure>
											<img className="img-fluid" alt="slid-1" src={slider1} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="slide-dv">
										<figure>
											<img className="img-fluid" alt="slid-1" src={slider2} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="slide-dv">
										<figure>
											<img className="img-fluid" alt="slid-1" src={slider3} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="slide-dv">
										<figure>
											<img className="img-fluid" alt="slid-1" src={slider4} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="slide-dv">
										<figure>
											<img className="img-fluid" alt="slid-1" src={slider5} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="slide-dv">
										<figure>
											<img className="img-fluid" alt="slid-1" src={slider1} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Slider>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Slidecompo;
