import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import { about1, sellercenterimg } from "../../Constant/Index";
import Membershipslider from "../../Components/Membershipslider";
import SellerLogin from "./SellerLogin";
import AppService from "../../services/appServices";
import ReUse from "../../services/helpers/reUse";
import { useSelector } from "react-redux";
import GlobalModal from "../../Components/ReUse/Modals/GlobalModal";

function Sellercenter() {
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const sellerGetsellerCenter = AppService.sellerGetsellerCenter();

    ReUse.getApiData(sellerGetsellerCenter, setApiData, setLoading);
  }, []);

  const { seller_center, membership_plans } = apiData ?? {};

  const { title, description, image_url, sub_description } =
    seller_center ?? {};

  const isAuth = useSelector(
    (state) => state.persistedReducer.auth.authenticated
  );

  const SellerLoginBtnComponent = () => {
    return (
      !isAuth && (
        <div className="about-btn">
          <a onClick={openModal}>Seller Login</a>
        </div>
      )
    );
  };

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      {/* About Section */}
      <PageHeader
        pagename={ReUse.loadObjBasedApiData({ loading: loading, data: title })}
      />
      <section className="about-sec seller-center-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="about-all">
                <h3>{seller_center?.sub_title}</h3>

                {ReUse.loadObjBasedApiData({
                  loading: loading,
                  data: ReUse.dangerouslySetInnerHTML({ content: description }),
                })}
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-img seller-img-postion-relative">
                <SellerLoginBtnComponent />

                <figure>
                  {ReUse.loadObjBasedApiData({
                    loading: loading,
                    data: (
                      <img
                        className="img-fluid"
                        alt="about-img"
                        src={
                          image_url !== null
                            ? image_url
                            : ReUse.defaultThumbnailImage({
                                content: "No+Image+Available",
                              })
                        }
                        onError={ReUse.onImageError}
                      />
                    ),
                  })}
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ====================== */}

      {/* Best Product Section */}
      <section className="best-product-sec">
        <div className="container">
          <div className="best-product-all">
            <div className="row">
              <div className="col-md-12">
                <div className="best-pro-all">
                  <h4>we help you find best Product</h4>
                  <p>
                    {ReUse.loadObjBasedApiData({
                      loading: loading,
                      data: sub_description,
                    })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ====================================== */}

      {/* Member Ship Plan Section */}
      <section className="membership-plan-sec">
        <div className="container">
          <div className="professional-plan-heading">
            <h3>Membership Plan</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
          <div className="row">
            <Membershipslider />
          </div>
        </div>
      </section>

      {/* Seller Sign in Modal Section */}

      <div className="seller-center-modal-for-signin">
        <GlobalModal isOpen={modalIsOpen} onRequestClose={closeModal}>
          <SellerLogin />
        </GlobalModal>
      </div>
      {/* ========================== */}
    </>
  );
}

export default Sellercenter;
