import React, { useState } from "react";
import { attach } from "../../Constant/Index";
import ReUse from "../../services/helpers/reUse";
import AppService from "../../services/appServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessMessage from "../../Components/ReUse/ResponseMessage";
import ResponseError from "../../Components/ReUse/ResponseMessage";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import { useDispatch, useSelector } from "react-redux";
import {
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import Errors from "../../Components/ReUse/Errors";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Components/ReUse/Components/Button";
import FieldError from "../../Components/ReUse/FieldError";

const JobApplicationForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [allErrors, setAllErrors] = useState({
		"First Name": "",
		"Last Name": "",
		phone: "",
		email: "",
		attachment: "",
		description: "",
		career_id: "",
	});
	const [fieldsData, setFieldsData] = useState({
		first_name: "",
		last_name: "",
		phone: "",
		email: "",
		attachment: "",
		description: "",
		career_id: "",
	});

	const [file, setFile] = useState();
	const [fileupload, setFileupload] = useState();

	function handleChange(e) {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		const { value, name } = e.target;
		setFieldsData({
			...fieldsData,
			[name]: value,
		});
	}

	const onSubmitHandler = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const {
				first_name,
				last_name,
				phone,
				email,
				description,
				career_id,
			} = fieldsData;

			const fields = {
				"First Name": first_name,
				"Last Name": last_name,
				phone,
				email,
				file,
				description,
			};
			const allErrors = ReUse.validateRequired({ fields: fields });

			// Check if there are any errors
			if (Object.keys(allErrors).length > 0) {
				setAllErrors(allErrors);
				setLoading(false);
				return;
			}

			const payLoad = {
				first_name,
				last_name,
				phone,
				email,
				attachment: fileupload,
				description,
				career_id: 8,
			};

			const apiResp = await AppService.applyForJob(payLoad);
			const { message, response } = apiResp.data;
			setLoading(false);
			dispatch(successMessage(message));
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/career");
				}, 1000);
			}, 1000);

			ReUse.resetErrors({ fields: allErrors });
			ReUse.resetForm({ fields: fieldsData });
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			ReUse.resetErrors({ fields: allErrors });
			ReUse.resetForm({ fields: fieldsData });
			dispatch(successMessage(""));
		}
	};

	const handleFileUpload = (e) => {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		ReUse.handleFileUploads(e).then((result) => {
			const { fileOutput, fileUpload } = result;
			setFile(fileOutput);
			setFileupload(fileUpload);
		});
	};

	const { first_name, last_name, email, phone, description } = fieldsData ?? {};

	

	return (
		<>
			<div className="row">
				<div className="col-md-12">
					<div className="applicaiton-form">
						<form>
							<div className="row">
								<div className="col-md-6">
									<div className="application-form-input">
										<input
											type="text"
											placeholder="First Name"
											onChange={handleChange}
											name="first_name"
											value={first_name}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="application-form-input">
										<input
											type="text"
											placeholder="Last Name"
											onChange={handleChange}
											name="last_name"
											value={last_name}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="application-form-input">
										<input
											type="text"
											placeholder="Email Address"
											onChange={handleChange}
											name="email"
											value={email}
										/>
										<FieldError fieldName={allErrors?.email} />
									</div>
								</div>
								<div className="col-md-6">
									<div className="application-form-input">
										<input
											type="text"
											placeholder="Contact Number"
											onChange={handleChange}
											name="phone"
											value={phone}
										/>
										<FieldError fieldName={allErrors?.phone} />
									</div>
								</div>
								<div className="col-md-12">
									<div className="application-form-input">
										<textarea
											class="form-control"
											id="exampleFormControlTextarea1"
											rows="3"
											placeholder="Additional Comment"
											onChange={handleChange}
											name="description"
											value={description}
										></textarea>

										<FieldError fieldName={allErrors?.description} />
									</div>
								</div>
								<div className="col-md-6">
									<div className="application-button-input">
										<input
											type="file"
											className="custom-file-input"
											id="attachments"
											//   accept="image/*"
											onChange={handleFileUpload}
											multiple={false}
										/>

										<FieldError fieldName={allErrors?.file} />
										{/* <div className="mt-2">
                      {file ? (
                        <img
                          src={fileupload}
                          className="image-fluid image-width"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      ) : null}
                    </div> */}
										{/* <button> */}
										{/* <span>
                        Attach CV{" "}
                        <img
                          className="img-fluid"
                          alt="attach-img"
                          src={attach}
                        />
                      </span>
                      <input
                        type="file"
                        className="for-hide-input"
                        onChange={handleFileUpload}
                        multiple={false}
                      />
                      <div className="mt-2">
                        {file ? (
                          <img
                            src={fileupload}
                            className="image-fluid image-width"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                            }}
                            alt=""
                          />
                        ) : null}
                      </div> */}
										{/* </button> */}
									</div>
								</div>
								<div className="col-md-6 this-colom-hide-on-responsive"></div>
								<div className="col-md-6">
									<div className="apply-now-btn">
										<CustomButton
											loading={loading}
											onClickHandler={onSubmitHandler}
											customClassName="button"
										>
											Apply Now
										</CustomButton>
										<ResponseMessage />
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default JobApplicationForm;
