import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import AccessControl from "../../Components/Auth/AccessControl";
import ReUse from "../../services/helpers/reUse";
import {
	clearResponseMessageAfterDelay,
	errorMessage,
	successMessage,
} from "../../redux/slices/responseMessage.slice";
import { toast } from "react-toastify";
import AppService from "../../services/appServices";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import CustomButton from "../../Components/ReUse/Components/Button";

function Sellerforget() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const [allErrors, setAllErrors] = useState({
		email: "",
	});

	const [fieldsData, setFieldsData] = useState({
		email: "",
	});

	function handleChange(e) {
		const resetErrors = ReUse.resetErrors({ fields: allErrors });
		setAllErrors(resetErrors);
		const { value, name } = e.target;
		setFieldsData({
			...fieldsData,
			[name]: value,
		});
	}

	const onSubmitHandler = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			const { email } = fieldsData;

			const fields = {
				email,
			};
			const allErrors = ReUse.validateRequired({ fields: fields });

			// Check if there are any errors
			if (Object.keys(allErrors).length > 0) {
				setAllErrors(allErrors);
				setLoading(false);
				return;
			}

			const payLoad = {
				email,
			};

			const apiResp = await AppService.sellerForgotPassword(payLoad);
			const { message, response } = apiResp.data;
			setLoading(false);
			dispatch(successMessage(message));
			setTimeout(() => {
				dispatch(successMessage("Navigating..."));
				setTimeout(() => {
					dispatch(successMessage(""));
					navigate("/seller-otp", { state: { email: email } });
				}, 1000);
			}, 1000);

			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
		} catch (err) {
			setLoading(false);
			dispatch(errorMessage(err?.response?.data?.message));
			dispatch(clearResponseMessageAfterDelay());
			ReUse.resetErrors({ fields: allErrors }); // allErrors is an object
			ReUse.resetForm({ fields: fieldsData }); // fieldsData is an object
			dispatch(successMessage(""));
		}
	};

	return (
		<>
			{/* <AccessControl allowedRoles={["seller"]}> */}
			{/* Seller Forget Sec */}
			<section className="seller-forget-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="seller-forget-form">
								<form>
									<div className="row">
										<div className="col-md-12">
											<div className="seller-forget-input">
												<div className="seller-forget-head">
													<h3>enter your email</h3>
												</div>
												<input
													type="text"
													onChange={handleChange}
													name="email"
													value={fieldsData?.email}
												/>
												{/* <p>
													An Email Has Been Sent To Reset The Password Haven't
													Received The Email Yet? Try Again
												</p> */}
												<div className="seller-forget-btn">
													{/* <Link to="/seller-otp">send</Link> */}
													<a>
														<CustomButton
															loading={loading}
															onClickHandler={onSubmitHandler}
															customClassName="btn"
														>
															Submit
														</CustomButton>
													</a>
													<ResponseMessage />
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ================== */}
			{/* </AccessControl> */}
		</>
	);
}

export default Sellerforget;
