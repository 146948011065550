import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedVariant: [],
    selectedVariantType: [],
    selectedVariations: [],
    counter: 0,
};

const ProductDetail = createSlice({
    name: "productDetail",
    initialState: initialState,
    reducers: {
        setSelectedVariant: (state, action) => {
            const { selectedValue, valueCategory } = action.payload;

            // Check if a variant with the same valueCategory exists
            const existingIndex = state.selectedVariations.findIndex(
                (variant) => variant?.valueCategory === valueCategory
            );

            // If a variant with the same category exists, replace it; otherwise, add the new one
            if (existingIndex !== -1) {
                state.selectedVariations[existingIndex] = action.payload;
                state.selectedVariantType[existingIndex] = (selectedValue);

            } else {
                state.selectedVariant.push(action.payload);
                state.selectedVariantType.push(selectedValue);
                state.selectedVariations.push(action.payload);
            }
        },

        setIncrementCounter: (state, action) => {
            state.counter = state.counter + 1;
        },

        setDecrementCounter: (state, action) => {
            state.counter = state.counter > 1 ? state.counter - 1 : 0
        },

        setClearVariants: (state, action) => {
            state.selectedVariant = [];
            state.selectedVariantType = [];
            state.selectedVariations = [];
            state.counter = 0;
        },
    },
});

export const ProductDetailActions = ProductDetail.actions;
export default ProductDetail.reducer;
