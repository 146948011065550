import React from "react";
import { useState } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ReUse from "../../services/helpers/reUse";
import { toast } from "react-toastify";
import AppService from "../../services/appServices";
import FieldError from "../../Components/ReUse/FieldError";
import ResponseMessage from "../../Components/ReUse/ResponseMessage";
import CustomButton from "../../Components/ReUse/Components/Button";
import Select from "react-select";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const AddressEditModal = ({
  editModalIsOpen,
  closeEditModal,
  propsData,
  handleChange,
  allErrors,
  addressUpdateHandler,
  AddressEditLoader,
  minifiedCountries,
  minifiedCities,
  minifiedStates,
  setSelectedCityId,
  setSelectedCountryId,
  setSelectedStateId,
  selectedCountryId,
  selectedCityId,
  selectedStateId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    id,
    first_name,
    last_name,
    phone,
    email,
    address,
    country_id,
    country_name,
    state_id,
    state_name,
    city_id,
    city_name,
    zip_code,
    default_status,
  } = propsData ?? {};

  setSelectedCountryId(country_id);
  setSelectedStateId(state_id);
  setSelectedCityId(city_id);

  return (
    <>
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="adress_modal_wrapper">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <input
                          type="text"
                          placeholder="first name"
                          onChange={(e) => handleChange(e)}
                          name="first_name"
                          defaultValue={first_name}
                        />
                        <FieldError fieldName={allErrors?.first_name} />

                        <div className="icon-of-edit-profile">
                          <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <input
                          type="text"
                          placeholder="last name"
                          onChange={(e) => handleChange(e)}
                          name="last_name"
                          defaultValue={last_name}
                        />
                        <FieldError fieldName={allErrors?.last_name} />

                        <div className="icon-of-edit-profile">
                          <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <input
                          type="email"
                          placeholder="Email "
                          name="email"
                          defaultValue={email}
                          onChange={(e) => handleChange(e)}
                        />
                        <FieldError fieldName={allErrors?.email} />

                        <div className="icon-of-edit-profile">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <input
                          type="text"
                          placeholder="phone"
                          name="phone"
                          defaultValue={phone}
                          onChange={(e) => handleChange(e)}
                        />
                        <FieldError fieldName={allErrors?.phone} />

                        <div className="icon-of-edit-profile">
                          <i class="fa fa-phone" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <input
                          type="text"
                          placeholder="address"
                          name="address"
                          defaultValue={address}
                          onChange={(e) => handleChange(e)}
                        />
                        <FieldError fieldName={allErrors?.address} />

                        <div className="icon-of-edit-profile">
                          <i
                            class="fa fa-calendar-minus-o"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <input
                          type="text"
                          placeholder="suite_apartment"
                          name="suite_apartment"
                          onChange={(e) => handleChange(e)}
                          defaultValue={address}
                        />
                        <FieldError fieldName={allErrors?.suite_apartment} />

                        <div className="icon-of-edit-profile">
                          <i
                            class="fa fa-calendar-minus-o"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="edit-profile-input">
                        <Select
                          isMulti={false}
                          options={minifiedCountries}
                          closeMenuOnSelect={true}
                          onChange={(selectedOption) => {
                            setSelectedCountryId(selectedOption?.value);
                          }}
                          value={minifiedCountries.find(
                            (option) => option?.value === selectedCountryId
                          )}
                        />
                        <FieldError fieldName={allErrors?.country} />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <Select
                          isMulti={false}
                          options={minifiedStates}
                          closeMenuOnSelect={true}
                          onChange={(selectedOption) => {
                            setSelectedStateId(selectedOption?.value);
                          }}
                          value={minifiedStates.find(
                            (option) => option?.value === selectedStateId
                          )}
                        />
                        <FieldError fieldName={allErrors?.state} />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <Select
                          isMulti={false}
                          options={minifiedCities}
                          closeMenuOnSelect={true}
                          onChange={(selectedOption) => {
                            setSelectedCityId(selectedOption?.value);
                          }}
                          value={minifiedCities.find(
                            (option) => option?.value === selectedCityId
                          )}
                        />
                        <FieldError fieldName={allErrors?.city} />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <input
                          type="text"
                          placeholder="zip_code"
                          name="zip_code"
                          onChange={(e) => handleChange(e)}
                          defaultValue={zip_code}
                        />
                        <FieldError fieldName={allErrors?.zip_code} />

                        <div className="icon-of-edit-profile">
                          <i
                            class="fa fa-calendar-minus-o"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="edit-profile-input">
                        <input
                          type="number"
                          placeholder="default_status"
                          name="default_status"
                          defaultValue={default_status}
                          onChange={(e) => handleChange(e)}
                        />
                        <FieldError fieldName={allErrors?.default_status} />

                        <div className="icon-of-edit-profile">
                          <i
                            class="fa fa-calendar-minus-o"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="profile-edit-btn">
                        <a>
                          <CustomButton
                            loading={AddressEditLoader}
                            onClickHandler={(e) => addressUpdateHandler(e)}
                            customClassName="btn"
                          >
                            Update
                          </CustomButton>
                        </a>
                        <ResponseMessage />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddressEditModal;
