import React from "react";
import { Link } from "react-router-dom";
import { homebanner1 } from "../../../Constant/Index";
import ReUse from "../../../services/helpers/reUse";

const MainBanner = ({ allBanners }) => {
	const isArrayHasIndex = ReUse.isArrayHasIndex({
		arrayInput: allBanners,
		index: 0,
	});

	const banner = isArrayHasIndex ? allBanners[0] : {};

	const {
		id,
		photo,
		banner_type,
		published,
		url,
		resource_type,
		title,
		sub_title,
		description,
	} = banner ?? {};

	return (
		isArrayHasIndex && (
			<div className="col-md-8">
				<div className="banner-first-img firts-banner-img ">
					<figure>
						<img
							className="img-fluid"
							alt="first-img"
							src={
								photo && photo !== null
									? photo
									: ReUse.defaultThumbnailImage({
											content: "No+Image+Available",
									  })
							}
							onError={ReUse.onImageError}
						/>
					</figure>

					<div className="first-banner-text wow animate__animated animate__fadeInDown">
						<div className="all-text">
							<h2>{ReUse.dangerouslySetInnerHTML({ content: title })}</h2>
							<h3>{sub_title}</h3>
							<p>{description}</p>
							<div className="main-btn">
								<Link to={`${url || "/shop"}`}>Shop Now</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	);
};

export default MainBanner;
