import { createSlice } from "@reduxjs/toolkit";

const initialProductsFilterState = {
  categoryId: null,
  subCategoryId: null,
  minPrice: null,
  maxPrice: null,
  sortByOrder: 2, // default sort value is ascending order
  showProducts: 12, // default show 12 products
  showGridCols: 4, // default Grid will have column of 4 each
};

const ProductsFilter = createSlice({
  name: "productsFilter",
  initialState: initialProductsFilterState,
  reducers: {
    setCatSubCatIds: (state, action) => {
      const { categoryId, subCategoryId } = action.payload;
      state.categoryId = categoryId;
      state.subCategoryId = subCategoryId;
    },
    setMinMaxPrices: (state, action) => {
      const { minPrice, maxPrice } = action.payload;
      state.minPrice = minPrice;
      state.maxPrice = maxPrice;
    },
    setSortOrder: (state, action) => {
      const { sortOrder } = action.payload;
      state.sortByOrder = sortOrder;
    },
    setShowProducts: (state, action) => {
      const { noOfProducts } = action.payload;
      state.showProducts = noOfProducts;
    },
    setGridCol: (state, action) => {
      const { eachCol } = action.payload;
      state.showGridCols = eachCol;
    },
  },
});

export const ProductsFilterActions = ProductsFilter.actions;
export default ProductsFilter.reducer;
