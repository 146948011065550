import React from 'react'

const RenderUploadedImage = ({ imageURL }) => {
    return (
        <div className="all-img-with-shadow">
            <figure>
                <img className="img-fluid" src={imageURL} />
            </figure>
        </div>
    )
}

export default RenderUploadedImage