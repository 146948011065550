import React from "react";
import {
	organic1,
	organic2,
	organic3,
	slider1,
	slider4,
} from "../../Constant/Index";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import ReUse from "../../services/helpers/reUse";

const CMProductCard = (propsData) => {
	const { product_id, product_name, unit_price, rating, thumbnail, slug } =
		propsData ?? {};
	return (
		<>
			<div className="col-md-4">
				<Link
					to={`/product-customize-detail/${slug}`}
					state={{
						product_id: slug,
						currentIndex: product_id,
					}}
				>
					<div className="slide-dv organic-food">
						<figure>
							<img
								className="img-fluid"
								alt="slid-1"
								src={
									thumbnail && thumbnail !== null
										? thumbnail
										: ReUse.defaultThumbnailImage({
												content: "No+Image+Available",
										  })
								}
								onError={ReUse.onImageError}
							/>
						</figure>
						<div className="slide-text">
							<p>{ReUse.sliceText({ content: product_name })}</p>
							<div className="slide-for-flex">
								<h5>${unit_price}</h5>
								<div className="slide-star">
									<Rating
										allowFraction={true}
										initialValue={rating}
										readonly={true}
										size={20}
									/>
								</div>
							</div>
						</div>
						<div className="product-heading">
							<div className="pro-head">
								<h5>Food</h5>
							</div>
						</div>
					</div>
				</Link>
			</div>
		</>
	);
};

export default CMProductCard;
