import React, { useEffect, useState } from 'react'
import { homebanner1, homebanner2, homebanner3 } from '../../Constant/Index'
import { Link } from 'react-router-dom'
import MainBanner from './HomeBanners.js/MainBanner'
import RightFirst from './HomeBanners.js/RightFirst'
import RightSecond from './HomeBanners.js/RightSecond'
import AppService from '../../services/appServices'
import ReUse from '../../services/helpers/reUse'

const Hero = () => {

    const [allBanners, setAllBanners] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const getAllBanners = AppService.getAllBanners();
        ReUse.getApiData(getAllBanners, setAllBanners, setLoading);
    }, []);



    return (
        <section className="main-banner-sec">
            <div className="container">
                <div className="row">
                    <MainBanner allBanners={allBanners} />
                    <div className="col-md-4">
                        <RightFirst
                            allBanners={allBanners}
                        />
                        <RightSecond
                            allBanners={allBanners}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero