import React from "react";
import { useState } from "react";

const VariantMeta = ({ variation, index, variantDataHandler, variantData }) => {
	const variantTitle = variation[`variation${index + 1}`];

	const currentVariantData = variantData[index];

	const { price, qty, sku, discountPrice } = currentVariantData ?? {};

	return (
		<tr>
			<td className="color-size-small">{variantTitle}</td>
			<td>
				<div className="table-input">
					<input
						type="text"
						name="price"
						onChange={(e) => variantDataHandler(e, index)}
						value={price}
					/>
				</div>
			</td>
			<td>
				<div className="table-input">
					<input
						type="text"
						name="discountPrice"
						onChange={(e) => variantDataHandler(e, index)}
						value={discountPrice}
					/>
				</div>
			</td>
			<td>
				<div className="table-input">
					<input
						type="text"
						name="qty"
						onChange={(e) => variantDataHandler(e, index)}
						value={qty}
					/>
				</div>
			</td>
			<td>
				<div className="table-input">
					<input
						type="text"
						name="sku"
						onChange={(e) => variantDataHandler(e, index)}
						value={sku}
					/>
				</div>
			</td>
		</tr>
	);
};

export default VariantMeta;
